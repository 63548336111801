import React, { useState } from 'react'
import { saveCity } from 'panel/common/requests'
import { CityDTO } from 'panel/dtos/CityDTO'
import CityForm from 'panel/components/CityForm'
import useCredentials from '../../hooks/useCredentials'
import CircularSpinner from '../../components/CircularSpinner'
import { CityErrorDTO } from '../../dtos/CityErrorDTO'

interface Props {
  afterSaveCallback?: () => void
}
const NewCityForm: React.FC<Props> = ({ afterSaveCallback }): JSX.Element => {
  const { credentials } = useCredentials()

  const [errors, setErrors] = useState<Partial<CityErrorDTO>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [city, setCity] = useState<CityDTO>({
    id: 0,
    name: '',
    featured: false,
    region: '',
    image: null,
    image_url: '',
  })

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const data = new FormData()
      data.append('name', city.name)
      data.append('region', city.region)
      data.append('featured', city.featured.toString())
      if (city.image) {
        data.append('image', city.image)
      }
      await saveCity(data, credentials)
      afterSaveCallback?.()
    } catch (e) {
      setErrors(e.response?.data)
    } finally {
      setLoading(false)
    }
  }

  return loading ? (
    <CircularSpinner />
  ) : (
    <CityForm city={city} handleSubmit={handleSubmit} handleChange={setCity} errors={errors} />
  )
}

export default NewCityForm
