import { Benefit, Credential, Maybe, Rate } from 'types/generatedGql'
import { BenefitPreviewDTO } from 'types'

export const getValueFromQueryParams = (queryString: string, key: string): string | null => {
  const queryParams = new URLSearchParams(queryString)
  return queryParams.get(key)
}

export const getBenefitPreviewData = (benefit: Benefit, isTablet: boolean): BenefitPreviewDTO => ({
  id: benefit?.id,
  slug: benefit?.slug,
  name: benefit?.name,
  city: benefit?.city,
  shortDescription: benefit?.description?.slice(0, 15),
  longDescription: benefit?.description,
  category: benefit?.category?.name,
  images: [isTablet ? benefit?.images[0]?.thumbnail : benefit?.images[0]?.large || benefit?.images[0]?.medium],
  benefitSummary: benefit?.benefitSummary,
  otherRateOffer: benefit?.otherRateOffer,
  rates: benefit?.rates,
})

export const configBenefitsForPreview = (data: Benefit[], isTablet: boolean): BenefitPreviewDTO[] => {
  if (!data) return []
  return data.map((benefit) => getBenefitPreviewData(benefit, isTablet))
}

export const handleCardNumberSpaces = (value = '', prevValue = ''): string => {
  if (prevValue?.length < value?.length) {
    return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')
  }
  return value.trim()
}

export const excludeSubdomainFromUrl = (pathname: string): string => {
  if (!pathname) return ''
  try {
    const url = new URL(pathname)
    const splitHost = url.host.split('.')
    url.host = splitHost.length > 2 ? splitHost.slice(1).join('.') : url.host
    return url.toString()
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const addSubdomainToPath = (
  href: string,
  subdomain: string,
  pathname?: string,
  credentials?: Credential
): string => {
  try {
    const url = new URL(href)
    const splitHost = url.hostname.split('.')
    if (splitHost.length <= 2 && subdomain) {
      url.hostname = [subdomain, ...splitHost].join('.')
    }
    if (pathname) {
      url.pathname = pathname
    }
    const params = new URLSearchParams('')
    if (credentials) {
      Object.keys(credentials).forEach((key: string) => {
        if (credentials[key] && key !== '__typename') {
          params.set(key, credentials[key])
        }
      })
    }
    url.search = params.toString()
    return url.toString()
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const getBenefitRatesText = (rates: Maybe<Rate>[]): string => {
  if (!rates) return ''
  return rates?.[0] ? `Rates from $${rates[0]?.execRate}` : ''
}

export const getBenefitInfo = (benefit: Maybe<Benefit | BenefitPreviewDTO>): string => {
  if (!benefit) return ''
  const { rates, otherRateOffer, benefitSummary } = benefit
  return rates?.length ? getBenefitRatesText(rates) : otherRateOffer || benefitSummary || ''
}
