import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const authLogin = createAsyncThunk('auth/login', async (body, { rejectWithValue }) => {
  try {
    const res = await axios.post('/api/panel/auth/sign_in', body)
    return res.data
  } catch (err) {
    return rejectWithValue(err.response?.data)
  }
})

export const authLogout = createAsyncThunk('auth/logout', async (body, { rejectWithValue, getState }) => {
  const state = getState()
  const { credentials } = state.panelAuth

  try {
    const res = await axios.delete('/api/panel/auth/sign_out', {
      headers: {
        'access-token': credentials['access-token'],
        client: credentials.client,
        'token-type': credentials['token-type'],
        uid: credentials.uid,
      },
    })
    return res.data
  } catch (err) {
    return rejectWithValue(err.response?.data)
  }
})

const authSlice = createSlice({
  name: 'panelAuth',
  initialState: {
    credentials: null,
    loggedIn: false,
    loading: false,
  },
  reducers: {
    clearAuth: (state) => {
      state.credentials = null
      state.loggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state) => {
        state.loading = false
      })
      .addCase(authLogin.fulfilled, (state, { payload }) => {
        state.credentials = payload.credentials
        state.loading = false
        state.loggedIn = true
      })
      .addCase(authLogin.rejected, (state) => {
        state.loading = false
      })
      .addCase(authLogout.pending, (state) => {
        state.loading = false
      })
      .addCase(authLogout.fulfilled, (state) => {
        state.credentials = null
        state.loading = false
        state.loggedIn = false
      })
      .addCase(authLogout.rejected, (state) => {
        state.loading = false
        state.credentials = null
        state.loggedIn = false
      })
  },
})

export const { clearAuth } = authSlice.actions

export default authSlice.reducer
