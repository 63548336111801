import { gql } from '@apollo/client'
export type Maybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
}

/** Autogenerated input type of AddFavorite */
export type AddFavoriteInput = {
  benefitSlug: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of AddFavorite */
export type AddFavoritePayload = {
  __typename?: 'AddFavoritePayload'
  benefit?: Maybe<Benefit>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of AddSubAccount */
export type AddSubAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName?: Maybe<Scalars['String']>
}

/** Autogenerated return type of AddSubAccount */
export type AddSubAccountPayload = {
  __typename?: 'AddSubAccountPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Scalars['String']>>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type Authenticatable = {
  __typename?: 'Authenticatable'
  email: Scalars['String']
}

export type Benefit = {
  __typename?: 'Benefit'
  additionalInformation?: Maybe<Scalars['String']>
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  benefitSummary?: Maybe<Scalars['String']>
  benefits?: Maybe<Array<Scalars['String']>>
  brand?: Maybe<Brand>
  category: Category
  city?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  favorited?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  hasCode?: Maybe<Scalars['Boolean']>
  hasLink?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  images: Array<BenefitImage>
  isNew: Scalars['Boolean']
  keyInsights?: Maybe<Array<Scalars['String']>>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  otherRateOffer?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  rates?: Maybe<Array<Rate>>
  redeemed?: Maybe<Scalars['Boolean']>
  redemptionInstruction?: Maybe<Scalars['String']>
  redemptionType?: Maybe<RedemptionType>
  region?: Maybe<Scalars['String']>
  relatedBenefits?: Maybe<Array<Benefit>>
  shortDescription?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  termsAndCondition?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
}

export type BenefitCollection = {
  __typename?: 'BenefitCollection'
  collection: Array<Benefit>
  metadata: CollectionMetadata
}

export type BenefitImage = {
  __typename?: 'BenefitImage'
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  original?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type Brand = {
  __typename?: 'Brand'
  benefits?: Maybe<Array<Benefit>>
  childrenCount: Scalars['Int']
  description?: Maybe<Scalars['String']>
  image?: Maybe<BrandImage>
  name?: Maybe<Scalars['String']>
  parentId?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  subbrands: Array<Brand>
}

export type BrandImage = {
  __typename?: 'BrandImage'
  original?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type Category = {
  __typename?: 'Category'
  benefits?: Maybe<Array<Benefit>>
  image?: Maybe<CategoryImage>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CategoryImage = {
  __typename?: 'CategoryImage'
  carousel?: Maybe<Scalars['String']>
  original?: Maybe<Scalars['String']>
}

export type CategoryMetadata = {
  __typename?: 'CategoryMetadata'
  business?: Maybe<Scalars['Int']>
  experiences?: Maybe<Scalars['Int']>
  hotels?: Maybe<Scalars['Int']>
  lifestyle?: Maybe<Scalars['Int']>
  travel?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of ChangePassword */
export type ChangePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
  newPasswordConfirmation: Scalars['String']
}

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type ChargifyProduct = {
  __typename?: 'ChargifyProduct'
  customPlan: Scalars['Boolean']
  id: Scalars['Int']
  maxSubuserNumber: Scalars['Int']
  numberOfSeats: Scalars['Int']
  oneTimeInitiationFee: Scalars['Int']
  price: Scalars['Int']
  totalPrice: Scalars['Int']
}

export type City = {
  __typename?: 'City'
  childrenCount: Scalars['Int']
  depth: Scalars['Int']
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<CityImage>
  lft: Scalars['Int']
  name?: Maybe<Scalars['String']>
  parentId?: Maybe<Scalars['Int']>
  region?: Maybe<Scalars['String']>
  regions: Array<City>
  rgt: Scalars['Int']
  slug?: Maybe<Scalars['String']>
}

export type CityImage = {
  __typename?: 'CityImage'
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  original?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata'
  currentPage: Scalars['Int']
  limitValue: Scalars['Int']
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
}

export type CompanyData = {
  __typename?: 'CompanyData'
  id: Scalars['String']
  name: Scalars['String']
}

export type CompanyDataRanges = {
  __typename?: 'CompanyDataRanges'
  annualRevenue: Array<CompanyData>
  fundingAcquiredSinceInception: Array<CompanyData>
  numberOfEmployees: Array<CompanyData>
}

export type Country = {
  __typename?: 'Country'
  id: Scalars['String']
  name: Scalars['String']
}

/** Autogenerated input type of CreateAccount */
export type CreateAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
  phone: Scalars['String']
}

/** Autogenerated return type of CreateAccount */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type Credential = {
  __typename?: 'Credential'
  accessToken: Scalars['String']
  client: Scalars['String']
  expiry: Scalars['Int']
  tokenType: Scalars['String']
  uid: Scalars['String']
}

/** Autogenerated input type of Deactivate */
export type DeactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
}

/** Autogenerated return type of Deactivate */
export type DeactivatePayload = {
  __typename?: 'DeactivatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of Invite */
export type InviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

/** Autogenerated return type of Invite */
export type InvitePayload = {
  __typename?: 'InvitePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of IsEnterprise */
export type IsEnterpriseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  numberOfSeats: Scalars['Int']
}

/** Autogenerated return type of IsEnterprise */
export type IsEnterprisePayload = {
  __typename?: 'IsEnterprisePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload'
  credentials: Credential
  errors?: Maybe<Array<Scalars['String']>>
  onboardingData?: Maybe<UserOnboardingData>
  preApplicationStep?: Maybe<Scalars['String']>
  subdomain?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated return type of Logout */
export type LogoutPayload = {
  __typename?: 'LogoutPayload'
  errors?: Maybe<Array<Scalars['String']>>
}

export type MembershipPlan = {
  __typename?: 'MembershipPlan'
  customPlan: Scalars['Boolean']
  id: Scalars['Int']
  maxSubuserNumber: Scalars['Int']
  numberOfSeats: Scalars['Int']
  oneTimeInitiationFee: Scalars['Int']
  price: Scalars['Int']
  totalPrice: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  addFavorite?: Maybe<AddFavoritePayload>
  addSubAccount?: Maybe<AddSubAccountPayload>
  changePassword?: Maybe<ChangePasswordPayload>
  createAccount?: Maybe<CreateAccountPayload>
  deactivate?: Maybe<DeactivatePayload>
  invite?: Maybe<InvitePayload>
  isEnterprise?: Maybe<IsEnterprisePayload>
  login?: Maybe<LoginPayload>
  logout?: Maybe<LogoutPayload>
  redeemBenefit?: Maybe<RedeemPayload>
  redeemOneTimeCode?: Maybe<RedeemWithRegistrationFormPayload>
  redeemWithRegistrationFormBenefit?: Maybe<RedeemWithRegistrationFormPayload>
  removeSubAccount?: Maybe<RemoveSubAccountPayload>
  requestSupport?: Maybe<RequestSupportPayload>
  resetPassword?: Maybe<ResetPasswordPayload>
  selectMembershipPlan?: Maybe<SelectMembershipPlanPayload>
  signUp?: Maybe<SignUpPayload>
  updateCreditCard?: Maybe<UpdateCreditCardPayload>
  updateOnboardingStep?: Maybe<UpdateOnboardingStepPayload>
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>
  updateUser?: Maybe<UpdateUserPayload>
  updateUserAddress?: Maybe<UpdateUserAddressPayload>
  updateUserCompanyData?: Maybe<UpdateUserCompanyDataPayload>
  userLogin?: Maybe<LoginPayload>
  userLogout?: Maybe<UserLogoutPayload>
  userResendConfirmation?: Maybe<UserResendConfirmationPayload>
  userSendPasswordReset?: Maybe<UserSendPasswordResetPayload>
  userSendPasswordResetWithToken?: Maybe<UserSendPasswordResetWithTokenPayload>
  userSignUp?: Maybe<SignUpPayload>
  userUpdatePassword?: Maybe<UserUpdatePasswordPayload>
  userUpdatePasswordWithToken?: Maybe<UserUpdatePasswordWithTokenPayload>
}

export type MutationAddFavoriteArgs = {
  input: AddFavoriteInput
}

export type MutationAddSubAccountArgs = {
  input: AddSubAccountInput
}

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MutationCreateAccountArgs = {
  input: CreateAccountInput
}

export type MutationDeactivateArgs = {
  input: DeactivateInput
}

export type MutationInviteArgs = {
  input: InviteInput
}

export type MutationIsEnterpriseArgs = {
  input: IsEnterpriseInput
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationRedeemBenefitArgs = {
  input: RedeemInput
}

export type MutationRedeemOneTimeCodeArgs = {
  input: RedeemWithRegistrationFormInput
}

export type MutationRedeemWithRegistrationFormBenefitArgs = {
  input: RedeemWithRegistrationFormInput
}

export type MutationRemoveSubAccountArgs = {
  input: RemoveSubAccountInput
}

export type MutationRequestSupportArgs = {
  input: RequestSupportInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationSelectMembershipPlanArgs = {
  input: SelectMembershipPlanInput
}

export type MutationSignUpArgs = {
  input: SignUpInput
}

export type MutationUpdateCreditCardArgs = {
  input: UpdateCreditCardInput
}

export type MutationUpdateOnboardingStepArgs = {
  input: UpdateOnboardingStepInput
}

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput
}

export type MutationUpdateUserCompanyDataArgs = {
  input: UpdateUserCompanyDataInput
}

export type MutationUserLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationUserResendConfirmationArgs = {
  email: Scalars['String']
  redirectUrl: Scalars['String']
}

export type MutationUserSendPasswordResetArgs = {
  email: Scalars['String']
  redirectUrl: Scalars['String']
}

export type MutationUserSendPasswordResetWithTokenArgs = {
  email: Scalars['String']
  redirectUrl: Scalars['String']
}

export type MutationUserSignUpArgs = {
  input: SignUpInput
}

export type MutationUserUpdatePasswordArgs = {
  currentPassword?: Maybe<Scalars['String']>
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type MutationUserUpdatePasswordWithTokenArgs = {
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  resetPasswordToken: Scalars['String']
}

export enum Order {
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export type Organization = {
  __typename?: 'Organization'
  name: Scalars['String']
  subdomain?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  /** Get list of benefits */
  benefits: BenefitCollection
  /** Get list of brands */
  brands: Array<Brand>
  /** Get list of categories */
  categories: Array<Category>
  /** Get list of cities */
  cities: Array<City>
  /** Get company data ranges */
  companyDataRanges: CompanyDataRanges
  currentUser: User
  /** Get detail of benefit */
  getBenefit: Benefit
  /** Get benefits by category */
  getBenefitsByCategory: BenefitCollection
  /** Get benefits by region */
  getBenefitsByRegion: BenefitCollection
  /** Get benefits that are for you */
  getBenefitsForYou: BenefitCollection
  /** Get detail of brand */
  getBrand: Brand
  /** Get detail of category */
  getCategory: Category
  /** Get info of city */
  getCity: City
  /** Get all countries */
  getCountries: Array<Country>
  /** Get featured benefits by category */
  getFeaturedBenefitsByCategory: BenefitCollection
  /** Get featured cities */
  getFeaturedCities: Array<City>
  /** Get benefits that appear on the homepag hero */
  getHomepageHeroBenefits: BenefitCollection
  /** Get benefits by city */
  getHotelsByCity: BenefitCollection
  /** Get all membership plans */
  getMembershipPlans: Array<ChargifyProduct>
  /** Get benefits that are popular */
  getPopularBenefits: BenefitCollection
  /** Get benefits related to the benefit passed in */
  getRelatedBenefits: BenefitCollection
  /** Search results */
  getResultsBySearch: Search
  /** Get favorite benefits of user */
  getUserFavoriteBenefits: BenefitCollection
  /** Get redemptions made by a user */
  getUserRedemptions: RedemptionCollection
  /** Get membership plan */
  membershipPlan: MembershipPlan
  /** Get list of benefits */
  nearbyBenefits: BenefitCollection
  /** Popular benefits by category */
  popularBenefitsByCategory: BenefitCollection
  /** Get list of sub-accounts */
  subAccounts: UserSubAccount
  userCheckPasswordToken: Authenticatable
  userConfirmAccount: Authenticatable
}

export type QueryBenefitsArgs = {
  category?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  order?: Maybe<Order>
  page?: Maybe<Scalars['Int']>
  scope?: Maybe<Scalars['String']>
}

export type QueryGetBenefitArgs = {
  slug: Scalars['String']
}

export type QueryGetBenefitsByCategoryArgs = {
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  slug: Scalars['String']
}

export type QueryGetBenefitsByRegionArgs = {
  limit?: Maybe<Scalars['Int']>
  name: Scalars['String']
  page?: Maybe<Scalars['Int']>
}

export type QueryGetBrandArgs = {
  slug: Scalars['String']
}

export type QueryGetCategoryArgs = {
  slug: Scalars['String']
}

export type QueryGetCityArgs = {
  slug: Scalars['String']
}

export type QueryGetFeaturedBenefitsByCategoryArgs = {
  categorySlug: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
}

export type QueryGetFeaturedCitiesArgs = {
  featured: Scalars['Boolean']
}

export type QueryGetHotelsByCityArgs = {
  limit?: Maybe<Scalars['Int']>
  order?: Maybe<Order>
  page?: Maybe<Scalars['Int']>
  slug: Scalars['String']
}

export type QueryGetRelatedBenefitsArgs = {
  slug: Scalars['String']
}

export type QueryGetResultsBySearchArgs = {
  categorySlug?: Maybe<Scalars['String']>
  order?: Maybe<Order>
  page?: Maybe<Scalars['Int']>
  term: Scalars['String']
}

export type QueryGetUserFavoriteBenefitsArgs = {
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
}

export type QueryGetUserRedemptionsArgs = {
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  period?: Maybe<Scalars['String']>
}

export type QueryNearbyBenefitsArgs = {
  limit?: Maybe<Scalars['Int']>
  order?: Maybe<Order>
  page?: Maybe<Scalars['Int']>
}

export type QueryPopularBenefitsByCategoryArgs = {
  categorySlug: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
}

export type QueryUserCheckPasswordTokenArgs = {
  redirectUrl?: Maybe<Scalars['String']>
  resetPasswordToken: Scalars['String']
}

export type QueryUserConfirmAccountArgs = {
  confirmationToken: Scalars['String']
  redirectUrl: Scalars['String']
}

export type Rate = {
  __typename?: 'Rate'
  category?: Maybe<Scalars['String']>
  execRate?: Maybe<Scalars['String']>
  standardRate?: Maybe<Scalars['String']>
}

/** Autogenerated input type of Redeem */
export type RedeemInput = {
  benefitSlug: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of Redeem */
export type RedeemPayload = {
  __typename?: 'RedeemPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  redemption?: Maybe<Redemption>
}

/** Autogenerated input type of RedeemWithRegistrationForm */
export type RedeemWithRegistrationFormInput = {
  additionalInfo?: Maybe<Scalars['String']>
  benefitSlug: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  userEmail: Scalars['String']
  userName: Scalars['String']
  userPhone?: Maybe<Scalars['String']>
}

/** Autogenerated return type of RedeemWithRegistrationForm */
export type RedeemWithRegistrationFormPayload = {
  __typename?: 'RedeemWithRegistrationFormPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  redemption?: Maybe<Redemption>
}

export type Redemption = {
  __typename?: 'Redemption'
  benefit: Benefit
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  redemptionCode?: Maybe<Scalars['String']>
  redemptionData: Scalars['JSON']
  redemptionInstruction?: Maybe<Scalars['String']>
  redemptionLink?: Maybe<Scalars['String']>
  redemptionType: RedemptionType
  user: User
  withinThisYear?: Maybe<Scalars['Boolean']>
}

export type RedemptionCollection = {
  __typename?: 'RedemptionCollection'
  collection: Array<Redemption>
  metadata: CollectionMetadata
}

export enum RedemptionType {
  GeneralCode = 'general_code',
  PersonalizedCode = 'personalized_code',
  ReferralLink = 'referral_link',
  Registration = 'registration',
}

/** Autogenerated input type of RemoveSubAccount */
export type RemoveSubAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
}

/** Autogenerated return type of RemoveSubAccount */
export type RemoveSubAccountPayload = {
  __typename?: 'RemoveSubAccountPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of RequestSupport */
export type RequestSupportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message: Scalars['String']
  subject: Scalars['String']
}

/** Autogenerated return type of RequestSupport */
export type RequestSupportPayload = {
  __typename?: 'RequestSupportPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  code: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type Search = {
  __typename?: 'Search'
  benefits: BenefitCollection
  categoryMetadata: CategoryMetadata
}

/** Autogenerated input type of SelectMembershipPlan */
export type SelectMembershipPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of SelectMembershipPlan */
export type SelectMembershipPlanPayload = {
  __typename?: 'SelectMembershipPlanPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  about?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyWebsite?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateCreditCard */
export type UpdateCreditCardInput = {
  cardNumber: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  cvc: Scalars['String']
  expiryDate: Scalars['String']
  name: Scalars['String']
  promotionalCode?: Maybe<Scalars['String']>
}

/** Autogenerated return type of UpdateCreditCard */
export type UpdateCreditCardPayload = {
  __typename?: 'UpdateCreditCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateOnboardingStep */
export type UpdateOnboardingStepInput = {
  categories?: Maybe<Array<Scalars['String']>>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  step: Scalars['String']
}

/** Autogenerated return type of UpdateOnboardingStep */
export type UpdateOnboardingStepPayload = {
  __typename?: 'UpdateOnboardingStepPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  nextStep?: Maybe<Scalars['String']>
  nextStepNumber?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of UpdatePaymentMethod */
export type UpdatePaymentMethodInput = {
  cardNumber?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  cvc: Scalars['String']
  expiryDate: Scalars['String']
  keepCardNumber?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
}

/** Autogenerated return type of UpdatePaymentMethod */
export type UpdatePaymentMethodPayload = {
  __typename?: 'UpdatePaymentMethodPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateUserAddress */
export type UpdateUserAddressInput = {
  address: Scalars['String']
  apartment: Scalars['String']
  city: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  country: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
}

/** Autogenerated return type of UpdateUserAddress */
export type UpdateUserAddressPayload = {
  __typename?: 'UpdateUserAddressPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateUserCompanyData */
export type UpdateUserCompanyDataInput = {
  additionalInformation: Scalars['String']
  annualRevenue: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  companyName: Scalars['String']
  companyWebsite: Scalars['String']
  fundingAcquiredSinceInception: Scalars['String']
  linkedinProfileUrl: Scalars['String']
  numberOfEmployees: Scalars['String']
  position: Scalars['String']
}

/** Autogenerated return type of UpdateUserCompanyData */
export type UpdateUserCompanyDataPayload = {
  __typename?: 'UpdateUserCompanyDataPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  about?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  apartment?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyWebsite?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  isPhotoUploaded?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type User = {
  __typename?: 'User'
  about?: Maybe<Scalars['String']>
  address?: Maybe<UserAddress>
  companyName?: Maybe<Scalars['String']>
  companyWebsite?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  currentYearRedemptionsCount: Scalars['Int']
  customPlan?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  favoriteBenefits?: Maybe<Array<Benefit>>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isDemo: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  lastSignInAt?: Maybe<Scalars['String']>
  maxSubAccountCount?: Maybe<Scalars['Int']>
  membershipId?: Maybe<Scalars['String']>
  onboardingData?: Maybe<UserOnboardingData>
  organization?: Maybe<Organization>
  parentId?: Maybe<Scalars['Int']>
  paymentMethod?: Maybe<UserPaymentMethod>
  photo?: Maybe<UserImage>
  position?: Maybe<Scalars['String']>
  preApplicationStep?: Maybe<Scalars['String']>
  redemptions?: Maybe<Array<Redemption>>
  redemptionsCount: Scalars['Int']
  rememberCreatedAt?: Maybe<Scalars['ISO8601DateTime']>
  subAccountCount?: Maybe<Scalars['Int']>
  subAccounts?: Maybe<Array<User>>
  thisYearRedemptionsCount: Scalars['Int']
  uid: Scalars['String']
}

export type UserAddress = {
  __typename?: 'UserAddress'
  address?: Maybe<Scalars['String']>
  apartment?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type UserImage = {
  __typename?: 'UserImage'
  original?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

/** Autogenerated return type of UserLogout */
export type UserLogoutPayload = {
  __typename?: 'UserLogoutPayload'
  authenticatable: Authenticatable
}

export type UserOnboardingData = {
  __typename?: 'UserOnboardingData'
  stepName?: Maybe<Scalars['String']>
  stepNumber?: Maybe<Scalars['Int']>
  stepsCount: Scalars['Int']
}

export type UserPaymentMethod = {
  __typename?: 'UserPaymentMethod'
  cardType: Scalars['String']
  expirationMonth: Scalars['String']
  expirationYear: Scalars['String']
  lastFourDigitsOfCard: Scalars['String']
  name: Scalars['String']
}

/** Autogenerated return type of UserResendConfirmation */
export type UserResendConfirmationPayload = {
  __typename?: 'UserResendConfirmationPayload'
  message: Scalars['String']
}

/** Autogenerated return type of UserSendPasswordReset */
export type UserSendPasswordResetPayload = {
  __typename?: 'UserSendPasswordResetPayload'
  message: Scalars['String']
}

/** Autogenerated return type of UserSendPasswordResetWithToken */
export type UserSendPasswordResetWithTokenPayload = {
  __typename?: 'UserSendPasswordResetWithTokenPayload'
  message: Scalars['String']
}

export type UserSubAccount = {
  __typename?: 'UserSubAccount'
  accounts: Array<User>
  id: Scalars['Int']
  remaining?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of UserUpdatePassword */
export type UserUpdatePasswordPayload = {
  __typename?: 'UserUpdatePasswordPayload'
  authenticatable: Authenticatable
}

/** Autogenerated return type of UserUpdatePasswordWithToken */
export type UserUpdatePasswordWithTokenPayload = {
  __typename?: 'UserUpdatePasswordWithTokenPayload'
  authenticatable: Authenticatable
  /** Authentication credentials. Resource must be signed_in for credentials to be returned. */
  credentials?: Maybe<Credential>
}
