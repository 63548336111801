import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { applyParamsToUrlPath, downloadFile } from '../../utils'
import { BenefitPageView } from '../../dtos/BenefitDTO'
import DefaultLayout from '../../layouts/DefaultLayout'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { usePagination } from '../../hooks/usePagination'

const PageViews: React.FC = (): JSX.Element => {
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [pageViews, setPageViews] = useState<Array<BenefitPageView>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()

  const loadRedemptions = useCallback(async () => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/benefits/${params.id}/page_views`),
        {
          headers: credentials,
        }
      )
      setPageViews(res.data.page_views || [])
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id, setTotalPageCount])

  const loadPageViewsCsv = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/benefits/${params.id}/page_views/export`, {
        headers: credentials,
      })
      downloadFile(res.data, 'page_views', 'csv')
    } catch (e) {
      setError(e?.message)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadRedemptions()
  }, [loadRedemptions])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        <button onClick={loadPageViewsCsv} className='button is-link' type='button'>
          Download .csv
        </button>
      </div>
      <TableWrapper
        title='Page views'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!pageViews?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>Page name</th>
              <th>Page type</th>
              <th>User name</th>
              <th>User email</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>
            {pageViews.map((row) => (
              <tr key={row.page_id}>
                <td>{row.page_name}</td>
                <td>{row.page_type}</td>
                <td>{row.user_name}</td>
                <td>{row.user_email}</td>
                <td>{row.created_at && new Date(row.created_at).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default PageViews
