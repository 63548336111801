import React from 'react'
import DeleteButton from 'components/shared/buttons/DeleteButton'
import SectionSecondary from 'components/shared/SectionSecondary'
import DefaultModal from 'components/shared/DefaultModal'
import ConfirmButton from 'components/shared/buttons/ConfirmButton'
import { Props as DefaultModalProps } from 'components/shared/DefaultModal/DefaultModal'
import { Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { REMOVE_SUBACCOUNT_MUTATION } from 'constants/mutations'
import { Maybe } from 'types/generatedGql'
import { useStyles } from './styles'
import ErrorMessage from '../../../../../panel/components/ErrorMessage'

interface Props extends Omit<DefaultModalProps, 'toggleButton' | 'content' | 'actions'> {
  email: Maybe<string>
  getSubAccounts: () => void
}

const DeleteAccountModal: React.FC<Props> = ({ email, getSubAccounts, ...props }) => {
  const classes = useStyles()

  const handleDelSubAccount = async () => {
    try {
      await delSubAccount({
        variables: {
          email,
        },
      })
      getSubAccounts()
    } catch (error) {
      console.log({ error })
    }
  }

  const [delSubAccount, { loading, error }] = useMutation(REMOVE_SUBACCOUNT_MUTATION)

  return (
    <DefaultModal
      toggleButton={DeleteButton}
      toggleButtonFullWidth={false}
      content={
        <SectionSecondary title='Delete an account' className={classes.deleteSubAccount}>
          <Typography variant='body1' className={classes.body1}>
            Proceed with the account deletion?
          </Typography>
          {error && <ErrorMessage text={error?.message} />}
        </SectionSecondary>
      }
      actions={<ConfirmButton loading={loading} onClick={handleDelSubAccount} />}
      {...props}
    />
  )
}

export default DeleteAccountModal
