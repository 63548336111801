import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { UserDTO } from '../../dtos/UserDTO'
import ShowUserForm from '../../components/UserForm/ShowUserForm'
import DefaultLayout from '../../layouts/DefaultLayout'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'

const ShowAdmin: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { credentials, hasEditPermission } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [admin, setAdmin] = useState<Partial<UserDTO>>({})
  const [loadDataError, setLoadDataError] = useState('')

  const loadAdmin = useCallback(async (): Promise<void> => {
    try {
      const res = await axios.get(`/api/panel/admins/${params.id}`, {
        headers: credentials,
      })
      setAdmin(res.data)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadAdmin()
  }, [loadAdmin])

  return (
    <DefaultLayout>
      <ShowUserForm
        userData={admin}
        loading={loading}
        error={loadDataError}
        headerActions={
          hasEditPermission &&
          admin && (
            <Link to={`/panel/admins/${admin.id}/edit`} className='button is-link'>
              Edit
            </Link>
          )
        }
      />
    </DefaultLayout>
  )
}

export default ShowAdmin
