import React from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import './HomepageManagement.scss'
import HomepageSection from './HomepageSection'

const HomepageManagement: React.FC = (): JSX.Element => {
  return (
    <DefaultLayout>
      <h4 className='is-size-4 has-text-centered mb-4'>Homepage management</h4>
      <div className='box'>
        <HomepageSection title='Hero section' queryParamName='is_hero' />

        <HomepageSection title='Popular benefits' queryParamName='is_popular' />

        <HomepageSection title='Featured benefits' queryParamName='is_featured' />
      </div>
    </DefaultLayout>
  )
}

export default HomepageManagement
