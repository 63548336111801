import Section from 'components/shared/Section'
import React, { useEffect, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { Box } from '@material-ui/core'
import { useStyles } from 'pages/Contact/styles'

const Contact: React.FC = () => {
  const classes = useStyles()
  const [scriptLegacyLoaded, setScriptLegacyLoaded] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const scriptLegacy = document.createElement('script')
    scriptLegacy.src = 'https://js.hsforms.net/forms/v2-legacy.js'
    scriptLegacy.addEventListener('load', () => {
      setScriptLegacyLoaded(true)
    })
    document.head.appendChild(scriptLegacy)

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    scriptLegacy.addEventListener('load', () => {
      setScriptLoaded(true)
    })
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
      document.head.removeChild(scriptLegacy)
    }
  }, [])

  useEffect(() => {
    if (scriptLegacyLoaded && scriptLoaded) {
      const timer = setTimeout(() => {
        const scriptCreateForm = document.createElement('script')
        scriptCreateForm.text = `hbspt.forms.create({ region: "na1", portalId: "9151302", formId: "9dc7005a-932c-474a-98f2-49811ccd3209", target: "#hbspt_form_target", cssClass: "${classes.hubspotForm}" });`
        document.head.appendChild(scriptCreateForm)
      }, 500)
      return () => {
        if (timer) {
          clearTimeout(timer)
        }
      }
    }
  }, [classes.hubspotForm, scriptLegacyLoaded, scriptLoaded])

  return (
    <PageLayout curlyHeader title='Contact'>
      <Section>
        <Box my={4}>
          If you have a partnership request, general feedback, or require assistance, please contact us using the form
          below and a member of our team will reply within 1-2 business days:
        </Box>
        <Box p={4} display='flex' justifyContent='center' className={classes.formBox}>
          <Box className={classes.formContent}>
            <div id='hbspt_form_target' />
          </Box>
        </Box>
      </Section>
    </PageLayout>
  )
}

export default Contact
