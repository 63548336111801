import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    submitButton: {
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1),
      },
    },
    formRow: {
      [theme.breakpoints.down('md')]: {
        '&:not(:last-of-type)': {
          marginBottom: theme.spacing(1),
        },
        '& input.MuiOutlinedInput-input': {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        },
      },
    },
    header: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
        marginBottom: theme.spacing(1),
      },
    },
  }
})
