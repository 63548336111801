import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.primary.light}`
  return {
    hero: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#313131',
      minHeight: '320px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '230px',
      },
    },
    leftBox: {
      borderRight: border,
      [theme.breakpoints.down('md')]: {
        borderRight: 'none',
        borderBottom: border,
        paddingRight: 0,
        paddingBottom: theme.spacing(4),
      },
    },
    rightBox: {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: 0,
      },
    },
    borderBottomBox: {
      borderBottom: border,
    },
    tabsWrapper: {
      backgroundColor: '#fff',
      padding: theme.spacing(2, 8, 0, 8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 4),
      },
      '& a': {
        textTransform: 'none',
      },
    },
    overviewItem: {
      textAlign: 'center',
      padding: theme.spacing(0, 2),
    },
    overviewValue: {
      paddingTop: theme.spacing(2),
    },
    titleSiblingWrapper: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    totalRedemptionsButton: {
      display: 'flex',
      backgroundColor: '#F1EADE',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    addSubAccountButton: {
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'nowrap',
      },
    },
    body: {
      padding: theme.spacing(0, 8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    table: {
      '& .MuiTableCell-body': {
        padding: theme.spacing(3, 2),
      },
    },
    redemptionsTable: {
      '& .MuiTableCell-body': {
        padding: theme.spacing(2),
      },
    },
    categoryRow: {
      textTransform: 'capitalize',
    },
    thumbnailRowImg: {
      height: '80px',
      width: '80px',
      [theme.breakpoints.down('sm')]: {
        height: '70px',
        width: '70px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '60px',
        width: '60px',
      },
    },
    hideSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    hideXs: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    accountHomeWrapper: {
      border: '2px solid #333333',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    caption: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    h3: {
      textAlign: 'center',
    },
    digitalCard: {
      padding: theme.spacing(2, 0),
    },
    memberOfText: {
      marginBottom: theme.spacing(3),
    },
    membershipInfoWrapper: {
      border: '2px solid #828282',
    },
    textfield: {
      marginBottom: theme.spacing(2),
      color: 'black',
    },
    inputNotchedOutline: {
      borderColor: '#E2E2E2 !important',
    },
    inputLabel: {
      color: '#777',
    },
    input: {
      color: '#000',
    },
    spinner: {
      color: '#fff',
    },
  }
})
