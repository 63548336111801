import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { RouterPath } from '../../router/routes'
import useGetQueryParam from '../../hooks/useGetQueryParam'

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const email = useGetQueryParam('email')
  const token = useGetQueryParam('token')
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)
    setError('')

    const formData = new FormData()
    formData.append('email', email || '')
    formData.append('password', password)
    formData.append('token', token || '')

    try {
      await axios.post(`/api/set_admin_password`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      history.push(RouterPath.LOG_IN)
    } catch (err) {
      console.log(err)
      setError(err?.response?.data?.error)
      setIsSubmitting(false)
    }
  }

  return (
    <DefaultLayout hideNavbar>
      <div className='container'>
        <div className='columns is-justify-content-center'>
          <div className='column is-half'>
            <div className='box'>
              <h4 className='is-size-4 has-text-centered'>Reset password</h4>
              <form onSubmit={handleSubmit}>
                <div className='field'>
                  <label className='label'>Email</label>
                  <div className='control'>
                    <input type='email' className='input' name='email' value={email || ''} disabled />
                  </div>
                </div>

                <div className='field'>
                  <label className='label'>Password</label>
                  <div className='control'>
                    <input
                      type='password'
                      className='input'
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>

                <div className='field'>
                  <button
                    type='submit'
                    className={`button is-primary is-fullwidth ${isSubmitting ? 'is-loading' : ''}`}
                    disabled={isSubmitting}
                  >
                    Reset password
                  </button>
                </div>
                {error && <p className='has-text-danger'>{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ResetPassword
