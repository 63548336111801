import { Area } from 'react-easy-crop/types'

export function getCroppedImg(imageDataURL: string, crop: Area): Promise<string | null> {
  return new Promise((resolve) => {
    const image = new Image()
    image.src = imageDataURL
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      if (image.width === crop.width && image.height === crop.height && !crop.x && !crop.y) {
        return resolve(imageDataURL)
      }
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')

      const pixelRatio = window.devicePixelRatio
      canvas.width = crop.width * pixelRatio
      canvas.height = crop.height * pixelRatio
      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        )
      }

      return resolve(canvas.toDataURL('image/jpeg'))
    }
  })
}
