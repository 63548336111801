import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { applyParamsToUrlPath, getBooleanFieldText } from '../../utils'
import { BenefitRedemption } from '../../dtos/BenefitDTO'
import DefaultLayout from '../../layouts/DefaultLayout'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { usePagination } from '../../hooks/usePagination'

const UserBenefitRedemptions: React.FC = (): JSX.Element => {
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [memberName, setMemberName] = useState<string>('')
  const [redemptions, setRedemptions] = useState<Array<BenefitRedemption>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()

  const loadRedemptions = useCallback(async () => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/members/${params.id}/redemptions`),
        {
          headers: credentials,
        }
      )
      setMemberName(res.data.member_name)
      setRedemptions(res.data.redemptions)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id, setTotalPageCount])

  useEffect(() => {
    loadRedemptions()
  }, [loadRedemptions])

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <TableWrapper
        title={`Benefit redemptions history ${memberName ? `of ${memberName}` : ''}`}
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!redemptions?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>Benefit code</th>
              <th>Benefit name</th>
              <th>Created at</th>
              <th>Code redeemed</th>
              <th>Code viewed</th>
            </tr>
          </thead>
          <tbody>
            {redemptions.map((row) => (
              <tr key={row.benefit_code}>
                <td>{row.benefit_code}</td>
                <td>{row.benefit_name}</td>
                <td>{row.created_at && new Date(row.created_at).toDateString()}</td>
                <td>{getBooleanFieldText(row.code_redeemed)}</td>
                <td>{getBooleanFieldText(row.code_viewed)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default UserBenefitRedemptions
