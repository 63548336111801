import React, { useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { Box, TextField, Typography, Link } from '@material-ui/core'
import LoginPageImage from 'images/login-page-image.png'
import { Link as RouterLink } from 'react-router-dom'
import { BoxLayout } from 'components/layouts/BoxLayout'
import { RoutePath } from 'constants/RoutePath'
import ButtonAction from 'components/shared/ButtonAction'
import useLoginRedirect from 'hooks/useLoginRedirect'
import useSubdomain from 'hooks/useSubdomain'
import { useMutation, useQuery } from '@apollo/client'
import { LOGIN_MUTATION } from 'constants/mutations'
import { GET_ORGANIZATION } from 'constants/queries'
import LoginPageAlternateImage from './images/login-alternate.svg'
import { useStyles } from './styles'

export const Login = (): React.ReactElement => {
  const classes = useStyles()
  const { hasSubDomain, subDomain } = useSubdomain()
  const { loginRedirect } = useLoginRedirect()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION)

  const { data: organizationData, loading: organizationDataLoading } = useQuery(GET_ORGANIZATION, {
    variables: { subdomain: subDomain },
    skip: !hasSubDomain,
  })

  const orgImage = organizationData?.getOrganization?.loginImage?.medium || LoginPageAlternateImage

  const orgLogo = organizationData?.getOrganization?.logo?.medium

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const resp = await login({ variables: { email, password } })
      const { credentials, subdomain, preApplicationStep, onboardingData } = resp.data.login
      loginRedirect({ credentials, subdomain, preApplicationStep, onboardingStep: onboardingData?.stepName })
    } catch (err) {
      setLoginErrorMessage(err?.response?.data?.error)
    }
  }

  return (
    <PageLayout>
      <Box className={classes.modal} mt={4}>
        <BoxLayout
          sideImage={hasSubDomain ? orgImage : LoginPageImage}
          customSideImageLoading={organizationDataLoading}
          customLogo={orgLogo}
          contentMaxWidth={480}
        >
          <Typography variant='h4' className={classes.h4}>
            Log In
          </Typography>
          {hasSubDomain && (
            <Box mb={3} className={classes.subdomainTextBox}>
              <Typography variant='body1'>
                Your EXEC login email will match the one you use to login to your core membership experience.
              </Typography>
            </Box>
          )}
          <Box mb={4} className={classes.accessTextBox}>
            <Typography variant='body2' component='span'>
              Don&apos;t have an account?&nbsp;
            </Typography>
            <Typography variant='body2' component={RouterLink} to={RoutePath.CreateAccount} color='primary'>
              Request access.
            </Typography>
          </Box>

          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <TextField
              className={classes.textfield}
              InputLabelProps={{ classes: { root: classes.inputLabel } }}
              InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
              type='text'
              label='Username or Email'
              variant='outlined'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
            />
            <TextField
              className={classes.textfield}
              InputLabelProps={{ classes: { root: classes.inputLabel } }}
              InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
              type='password'
              label='Password'
              variant='outlined'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
            />
            {error && <Typography color='error'>{error?.message}</Typography>}
            {loginErrorMessage && <Typography color='error'>{loginErrorMessage}</Typography>}
            <Box mt={3} mb={2} className={classes.submitButton}>
              <ButtonAction
                text='Log In'
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                loading={loading}
                fullWidth
              />
            </Box>
            <Typography variant='body2' component={RouterLink} to={RoutePath.ForgotPassword} color='primary'>
              Forgot Password?
            </Typography>
          </form>
        </BoxLayout>
      </Box>
      <Box className={classes.modal} mt={4}>
        <Typography variant='h6' className={classes.h4}>
          Interested in learning more about EXEC? Visit{' '}
          <Link target='_blank' href='http://joinExec.com'>
            joinEXEC.com
          </Link>
        </Typography>
      </Box>
    </PageLayout>
  )
}
