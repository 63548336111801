import React, { ReactNode } from 'react'
import moment from 'moment'

export const dayFormat = 'YYYY-MM-DD'

export const getExpiredValue = (endDate: string | undefined): string | ReactNode => {
  if (!endDate) {
    return '-'
  }
  const endDateMoment = moment(endDate, dayFormat)
  if (endDateMoment.isSame(moment(), 'd')) {
    return 'Expires today'
  }
  const diffInDays = endDateMoment.diff(moment(), 'd', true)
  if (diffInDays <= 0) {
    return <span className='has-text-danger'>Expired</span>
  }
  const roundedDays = Math.ceil(diffInDays)
  return `in ${roundedDays} day${roundedDays === 1 ? '' : 's'}`
}
