import React from 'react'
import { Box, Typography, Select, MenuItem, SelectProps, OutlinedInput } from '@material-ui/core'
import { Order } from 'types/generatedGql'
import { useHistory, useLocation } from 'react-router-dom'
import { SORT_QUERY_KEY } from 'hooks/useSorting'
import { useStyles } from './styles'

interface Props extends SelectProps {
  sortBy: Order
  onSortChange: (order: Order) => void
}

const SortBenefitsSelect: React.FC<Props> = ({ classes: selectClasses, sortBy, onSortChange, ...props }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e?.target?.value) {
      const { value } = e.target
      const params = new URLSearchParams(location.search)
      params.set(SORT_QUERY_KEY, value as string)
      history.push({
        pathname: location.pathname,
        search: `?${params.toString()}`,
      })
      onSortChange(value as Order)
    }
  }

  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='body1' component='span' className={classes.typography}>
        Sort by
      </Typography>
      <Select
        variant='outlined'
        value={sortBy}
        onChange={onChange}
        id='sortBy'
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        input={<OutlinedInput classes={{ notchedOutline: classes.notchedOutline }} />}
        classes={{
          select: classes.select,
          ...selectClasses,
        }}
        {...props}
      >
        <MenuItem value={Order.UpdatedAt}>Newest</MenuItem>
        <MenuItem value={Order.Name}>Name</MenuItem>
      </Select>
    </Box>
  )
}

export default SortBenefitsSelect
