import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    notchedOutline: {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    typography: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        fontSize: '0.9rem',
      },
    },
  }
})
