import React from 'react'
import { Link, LinkProps } from '@material-ui/core'

const AddSubAccountLinkButton: React.FC<LinkProps> = ({ ...props }) => {
  return (
    <Link component='a' underline='always' style={{ cursor: 'pointer' }} {...props}>
      Add a sub-account
    </Link>
  )
}

export default AddSubAccountLinkButton
