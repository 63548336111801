import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState, useCallback } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'constants/queries'
import { OnboardingLayout } from 'pages/Onboarding/OnboardingLayout'
import { Box, Checkbox, Container, Divider, Typography } from '@material-ui/core'
import BenefitsDescription from 'pages/Onboarding/Descriptions/BenefitsDescription'
import { BenefitCategory } from 'constants/Benefits'
import WelcomeDescription from 'pages/Onboarding/Descriptions/WelcomeDescription'
import ApplicationSuccessMain from 'pages/PreApplication/ApplicationSuccess/ApplicationSuccessMain'
import Dots from 'components/shared/Dots'
import ButtonAction from 'components/shared/ButtonAction'
import { useStyles } from 'pages/Onboarding/styles'
import ShareExperienceDescription from 'pages/Onboarding/Descriptions/ShareExperienceDescription'
import AddSubAccountModal from 'components/shared/modals/AddSubAccountModal'
import { Mutation } from 'types/generatedGql'
import { UPDATE_ONBOARDING_STEP } from 'constants/mutations'
import AddSubAccountLinkButton from 'pages/Onboarding/Descriptions/AddSubAccountLinkButton'
import SuccessDescription from 'pages/Onboarding/Descriptions/SuccessDescription'
import CircularProgress from '@material-ui/core/CircularProgress'
import HotelsImage from './images/hotels.png'
import DinnerImage from './images/dinner.jpeg'
import AirplaneImage from './images/airplane.png'
import AirplaneInsideImage from './images/airplane-inside.png'
import SpaImage from './images/spa.png'
import RelaxImage from './images/relax.png'
import KayakImage from './images/kayak.png'
import OceanImage from './images/ocean.png'

export interface StepData {
  pictures?: string[]
  title?: string
  description?: ReactNode | string
}

export enum StepName {
  Welcome = 'Welcome',
  Benefits = 'Benefits',
  WhatWantToSee = 'WhatWantToSee',
  OrganizationSuccess = 'OrganizationSuccess',
  ShareExperience = 'ShareExperience',
  Success = 'Success',
}

const HotelsAndTravelsCategory = `${BenefitCategory.Hotels} + ${BenefitCategory.Travel}`
const categories = [
  HotelsAndTravelsCategory,
  BenefitCategory.Lifestyle,
  BenefitCategory.Experiences,
  BenefitCategory.Business,
]

const handleCategoriesToServer = (selectedCategories: typeof categories): BenefitCategory[] => {
  const result: BenefitCategory[] = []
  selectedCategories.forEach((category) => {
    if (category === HotelsAndTravelsCategory) {
      result.push(BenefitCategory.Hotels)
      result.push(BenefitCategory.Travel)
    } else {
      result.push(category as BenefitCategory)
    }
  })
  return result
}

const getStepLayout = (
  stepName: StepName,
  userName: string,
  setStep: Dispatch<SetStateAction<StepName>>,
  selectedCategories: BenefitCategory[],
  setCategory: (category: BenefitCategory, checked: boolean) => void
): StepData => {
  switch (stepName) {
    case StepName.Welcome:
    default:
      return {
        title: `Welcome aboard, ${userName}!`,
        pictures: [HotelsImage, DinnerImage, AirplaneImage],
        description: <WelcomeDescription />,
      }
    case StepName.Benefits:
      return {
        title: 'Hotel and travel benefits.',
        pictures: [SpaImage, AirplaneInsideImage],
        description: <BenefitsDescription />,
      }
    case StepName.ShareExperience:
      return {
        title: 'Share the EXEC experience.',
        pictures: [RelaxImage, OceanImage, KayakImage],
        description: <ShareExperienceDescription />,
      }
    case StepName.WhatWantToSee:
      return {
        title: 'See what you want to see.',
        description: (
          <>
            <p>We’re very excited for you to begin enjoying your benefits.</p>
            <p>
              In order to optimize your experience, please share what interests you most about EXEC Membership.
              <br />
              <Typography component='span' color='primary'>
                You may select more than one option.
              </Typography>
            </p>
            <Divider />
            <Box my={2}>
              {categories.map((category) => (
                <div key={category}>
                  <Checkbox
                    checked={selectedCategories.includes(category as BenefitCategory)}
                    onChange={(event) => setCategory(category as BenefitCategory, event.currentTarget.checked)}
                  />{' '}
                  {category}
                </div>
              ))}
            </Box>
            <Divider />
          </>
        ),
      }
    case StepName.OrganizationSuccess:
      return {
        title: 'You’re all set. Enjoy your EXEC experience!',
        description: <ApplicationSuccessMain />,
      }
    case StepName.Success:
      return {
        title: 'You’re all set. Enjoy your EXEC experience!',
        description: <SuccessDescription />,
      }
  }
}

const Onboarding: React.FC = () => {
  const classes = useStyles()
  const { data: userData, loading, refetch: refetchUserData } = useQuery(GET_CURRENT_USER)
  const [step, setStep] = useState(StepName.Welcome)
  const [error, setError] = useState('')
  const [stepNumber, setStepNumber] = useState(1)
  const [selectedCategories, setSelectedCategories] = useState<BenefitCategory[]>([])

  const [updateOnboardingStep, { loading: submitting, error: updateError }] = useMutation(UPDATE_ONBOARDING_STEP, {
    onCompleted: (mutationData: Mutation) => {
      if (mutationData?.updateOnboardingStep?.nextStep) {
        setStep(mutationData?.updateOnboardingStep?.nextStep as StepName)
        setStepNumber(mutationData?.updateOnboardingStep?.nextStepNumber as number)
      }
    },
  })

  const onNext = async () => {
    setError('')
    if (step === StepName.WhatWantToSee && !selectedCategories.length) {
      setError('Please choose categories.')
      return
    }
    await updateOnboardingStep({
      variables: {
        step,
        ...(selectedCategories.length ? { categories: handleCategoriesToServer(selectedCategories) } : {}),
      },
    })
  }

  useEffect(() => {
    if (userData?.currentUser?.onboardingData?.stepName) {
      setStep(userData?.currentUser?.onboardingData?.stepName)
      setStepNumber(userData?.currentUser?.onboardingData?.stepNumber)
    }
  }, [userData?.currentUser?.onboardingData])

  const onSuccessInit = useCallback(async () => {
    if ([StepName.Success, StepName.OrganizationSuccess].includes(step)) {
      await updateOnboardingStep({
        variables: { step },
      })
      await refetchUserData()
    }
  }, [refetchUserData, step, updateOnboardingStep])

  useEffect(() => {
    onSuccessInit()
  }, [onSuccessInit])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [step])

  const setCategory = (category: BenefitCategory, checked: boolean) => {
    setSelectedCategories((prevCategories) => {
      if (checked) {
        return [...prevCategories, category]
      }
      return [...prevCategories].filter((prevCategory) => prevCategory !== category)
    })
  }

  const stepLayout = getStepLayout(step, userData?.currentUser?.firstName, setStep, selectedCategories, setCategory)

  return (
    <PageLayout externalNavigation curlyHeader style={{ backgroundColor: '#343434' }} hideNavLinks>
      <Container maxWidth='md' className={classes.container}>
        <Box px={4} pt={10} pb={4} className={classes.wrapper}>
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '5rem' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <OnboardingLayout {...stepLayout} />

              {error && (
                <Box mt={2}>
                  <Typography color='error'>{error}</Typography>
                </Box>
              )}

              {updateError && (
                <Box mt={2}>
                  <Typography color='error'>{updateError?.message}</Typography>
                </Box>
              )}

              {[StepName.Benefits, StepName.WhatWantToSee, StepName.ShareExperience].includes(step) && (
                <>
                  <Box mt={4} mb={2}>
                    <Dots count={userData?.currentUser?.onboardingData?.stepsCount} current={stepNumber} />
                  </Box>

                  <Box display='flex' justifyContent='center'>
                    <ButtonAction
                      text='Next'
                      variant='contained'
                      color='primary'
                      size='large'
                      loading={submitting}
                      onClick={onNext}
                    />
                  </Box>
                </>
              )}

              {step === StepName.Welcome && (
                <Box mt={4} display='flex' justifyContent='center'>
                  <ButtonAction
                    text='Begin tour'
                    variant='contained'
                    color='primary'
                    size='large'
                    loading={submitting}
                    onClick={onNext}
                  />
                </Box>
              )}

              {step === StepName.ShareExperience && !!userData?.currentUser?.maxSubAccountCount && (
                <Box mt={2} display='flex' justifyContent='center'>
                  <AddSubAccountModal addButton={AddSubAccountLinkButton} isOnboarding onNext={onNext} />
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
    </PageLayout>
  )
}

export default Onboarding
