import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import DefaultLayout from '../../layouts/DefaultLayout'
import { applyParamsToUrlPath, downloadFile } from '../../utils'
import { LeadDTO, LeadStatus, Plan } from '../../dtos/LeadDTO'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'
import { LeadFilter } from './LeadsFilter'
import { leadStatusMap } from './const'

const getStatusCell = (status: LeadStatus): ReactNode => {
  switch (status) {
    case LeadStatus.PENDING:
    case LeadStatus.INCOMPLETE:
      return <span>{leadStatusMap[status]}</span>
    case LeadStatus.APPROVED:
      return <span className='has-text-success'>{leadStatusMap[status]}</span>
    case LeadStatus.REJECTED:
    case LeadStatus.FAILED_PAYMENT:
      return <span className='has-text-danger'>{leadStatusMap[status]}</span>
    default:
      return <span>{leadStatusMap[status] || status}</span>
  }
}

const Leads: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [leads, setLeads] = useState<Array<LeadDTO>>([])

  const loadLeads = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/leads'), {
        headers: credentials,
      })
      setLeads(res.data.records)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadLeads()
  }, [loadLeads])

  const downloadLeads = async () => {
    try {
      const res = await axios.get('/api/panel/leads/export_csv', {
        headers: credentials,
      })
      downloadFile(res.data, 'leads')
    } catch (e) {
      setError(e?.message)
      console.error(e.message)
    }
  }

  return (
    <DefaultLayout>
      <TableWrapper
        title='Leads'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!leads?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <button onClick={downloadLeads} className='button' type='button'>
                Download CSV
              </button>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <LeadFilter applyFilters={applyFilters} />
            </tr>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Company</th>
              <th>Revenue</th>
              <th>Seats</th>
              <th>Date</th>
              <th>Plan</th>
              <th>Status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead.id} className='is-clickable' onClick={() => history.push(`/panel/leads/${lead.id}`)}>
                <th>{lead.id}</th>
                <td>{lead.full_name}</td>
                <td>{lead.company_name}</td>
                <td>{lead.annual_revenue || '-'}</td>
                <td>{lead.seats}</td>
                <td>{moment(lead.created_at).format('MMMM Do YYYY hh:mm')}</td>
                <td>{(lead.plan === Plan.custom && 'Custom') || (lead.seats && `${lead.seats} Seats`) || '-'}</td>
                <td>{getStatusCell(lead.lead_status)}</td>
                <td>
                  <ActionsCell>
                    {hasEditPermission && (
                      <Link to={`/panel/leads/${lead.id}/edit`} className='button is-text mr-2'>
                        Edit
                      </Link>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Leads
