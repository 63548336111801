import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    blockWithLine: {
      fontSize: '24px',
      width: '400px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    logo: {
      height: '18px',
      position: 'relative',
      top: '4px',
    },
  }
})
