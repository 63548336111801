import React, { useState } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { Mutation } from 'types/generatedGql'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import DefaultModalContent from 'components/shared/DefaultModal/DefaultModalContent'
import { SET_IS_ENTERPRISE } from 'constants/mutations'
import ButtonAction from '../../../components/shared/ButtonAction'

interface Props {
  isOpened: boolean
  closeModal: () => void
  onSuccess: () => void
}

const EnterpriseModal: React.FC<Props> = ({ isOpened, closeModal, onSuccess }) => {
  const [seatsCount, setSeatsCount] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const handleCompleted = (mutationData: Mutation) => {
    if (mutationData?.isEnterprise?.message === 'success') {
      setIsSuccess(true)
    }
  }

  const [selectIsEnterprise, { loading: submitting, error }] = useMutation(SET_IS_ENTERPRISE, {
    onCompleted: handleCompleted,
  })

  return (
    <DefaultModalContent
      isModalOpen={isOpened}
      toggleModal={closeModal}
      content={
        isSuccess ? (
          <>
            <Typography component='h2' color='primary'>
              All set!
            </Typography>
            <Box my={2}>
              A member of our sales team will reach out to you via phone to assist you in completing your EXEC account
              setup.
            </Box>

            <Box mb={2} mt={4}>
              <Grid container spacing={0} alignItems='center' justify='center'>
                <ButtonAction
                  text='Next'
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={() => onSuccess()}
                />
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Typography component='h2' color='primary'>
              <strong>How many seats will you need?</strong>
            </Typography>
            <Box my={2}>
              Are you looking for a single seat, or perhaps an enterprise for your company or organization? Our EXEC
              Concierge team can reach out with custom solutions for you.
            </Box>
            <Box my={2}>
              <TextFieldCustom
                value={seatsCount}
                required
                type='number'
                placeholder='Example: 40'
                onChange={(event) => setSeatsCount(event.target.value)}
                fullWidth
              />
            </Box>

            {error && (
              <Box my={2}>
                <Typography color='error'>{error?.message}</Typography>
              </Box>
            )}

            <Box mb={2} mt={4}>
              <Grid container spacing={0} alignItems='center' justify='center'>
                <ButtonAction
                  text='Next'
                  variant='contained'
                  color='primary'
                  size='large'
                  loading={submitting}
                  onClick={() => selectIsEnterprise({ variables: { numberOfSeats: Number(seatsCount) } })}
                />
              </Grid>
            </Box>
          </>
        )
      }
    />
  )
}

export default EnterpriseModal
