import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
import { getAllCities } from '../../common/requests'
import { CityDTO } from '../../dtos/CityDTO'
import { CitiesFilters } from './Filters'
import { applyParamsToUrlPath } from '../../utils'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'

const Cities: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [cities, setCities] = useState<CityDTO[]>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination()

  const loadCities = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const response = await getAllCities(
        credentials,
        applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/cities')
      )
      if (response.status === 200 && response.data) {
        setCities(response.data.cities)
        setTotalPageCount(response.data.meta.total_pages)
      }
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadCities()
  }, [loadCities])

  return (
    <DefaultLayout>
      <TableWrapper
        title='Cities'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!cities?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <Link to='/panel/cities/new' className='button is-link'>
                New
              </Link>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <CitiesFilters applyFilters={applyFilters} />
            </tr>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Region</th>
              <th>Featured</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {cities.map((city) => (
              <tr key={city.id} className='is-clickable' onClick={() => history.push(`/panel/cities/${city.id}`)}>
                <th>{city.id}</th>
                <td>{city.name}</td>
                <td>{city.region}</td>
                <td>{city.featured ? 'YES' : 'NO'}</td>
                <td>
                  <ActionsCell>
                    <Link to={`/panel/cities/${city.id}`} className='button mr-2'>
                      Show
                    </Link>
                    {hasEditPermission && (
                      <Link to={`/panel/cities/${city.id}/edit`} className='button mr-0'>
                        Edit
                      </Link>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Cities
