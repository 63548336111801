export enum BenefitCategory {
  Experiences = 'Experiences',
  Lifestyle = 'Lifestyle',
  Business = 'Business',
  Hotels = 'Hotels',
  Travel = 'Travel',
}

export interface Brand {
  id: number
  name: string
  image: string
}

export interface Redemption {
  id: number
  name: string
  category: BenefitCategory
  thumbnail: string
  redeemedDate: string
}
