import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import routes from 'constants/Routes'
import theme from 'theme'
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import store, { RootState } from 'reducer/store'
import { useDispatch, useSelector } from 'react-redux'
import PrivateRoute from 'components/shared/PrivateRoute'
// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'
import { setAuth } from 'reducer/auth/authSlice'
import ScrollToTop from 'components/shared/ScrollToTop'

const persistor = persistStore(store)

const link = createHttpLink({
  uri: '/graphql',
})

const PlatformApp: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const credentials = useSelector((state: RootState) => state.auth.credentials)

  useEffect(() => {
    const appEnvironment = (document.querySelector('meta[name=environment]') as HTMLMetaElement)?.content
    if (appEnvironment !== 'production') {
      return () => {}
    }
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-18LR5P48QL'
    document.head.appendChild(script)

    const scriptDataLayer = document.createElement('script')
    scriptDataLayer.async = true
    scriptDataLayer.text =
      "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-18LR5P48QL');"
    document.head.appendChild(scriptDataLayer)
    return () => {
      document.head.removeChild(script)
      document.head.removeChild(scriptDataLayer)
    }
  }, [])

  const logOutLink = onError(({ networkError }): void => {
    if (
      networkError &&
      networkError.name === 'ServerError' &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      dispatch(setAuth(null))
    }
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(credentials
          ? {
              'access-token': credentials?.accessToken,
              client: credentials?.client,
              uid: credentials?.uid,
              'token-type': credentials?.tokenType,
            }
          : {}),
      },
    }
  })

  const client = new ApolloClient({ link: ApolloLink.from([logOutLink, authLink, link]), cache: new InMemoryCache() })

  return (
    <PersistGate persistor={persistor} loading={null}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            <Switch>
              {routes.map(({ page: Page, props, noAuth, id }) => {
                if (noAuth) {
                  return (
                    <Route exact {...props} key={id}>
                      <Page />
                    </Route>
                  )
                }
                return (
                  <PrivateRoute exact {...props} key={id}>
                    <Page />
                  </PrivateRoute>
                )
              })}
            </Switch>
          </ThemeProvider>
        </ApolloProvider>
      </BrowserRouter>
    </PersistGate>
  )
}

export default PlatformApp
