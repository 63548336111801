import React from 'react'
import { Box, BoxProps } from '@material-ui/core'
import NavigationLink from 'components/shared/NavigationLink'
import { NavigationLinkProps } from '../NavigationLink/NavigationLink'

export interface NavigationLinksProps extends BoxProps {
  items: NavigationLinkProps[]
  onClick?: () => void
}

const NavigationLinks: React.FC<NavigationLinksProps> = ({ items, onClick, ...props }) => {
  return (
    <Box my={1} mx={0.5} {...props}>
      {items.map((item) => (
        <NavigationLink key={item.text} {...(onClick ? { onClick } : {})} {...item} />
      ))}
      <NavigationLink isAnchor to='https://www.joinexec.com/journal' text='The Journal' target='_blank' />
    </Box>
  )
}

export default NavigationLinks
