import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    card: {
      position: 'relative',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 47.58%, rgba(0, 0, 0, 0.85) 100%)',
    },
    cardMedia: {
      position: 'relative',
      height: '30vh',
      [theme.breakpoints.down('sm')]: {
        height: '20vh',
      },
    },
    name: {
      marginTop: theme.spacing(1),
      color: '#fff',
    },
  }
})
