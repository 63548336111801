import { useLocation } from 'react-router-dom'

interface Result {
  getQueryParams: (queries: string[]) => Record<string, string>
}

const useGetQueryParams = (): Result => {
  const location = useLocation()

  const getQueryParams = (queries: string[]): Record<string, string> => {
    const queryParams = new URLSearchParams(location.search)
    const params: Record<string, string> = {}

    queries.forEach((query: string) => {
      const value = queryParams.get(query)
      if (value) {
        params[query] = value
      }
    })

    return params
  }

  return { getQueryParams }
}

export default useGetQueryParams
