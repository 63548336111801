import React, { FC, useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import parseHtml from 'html-react-parser'
import TableWrapper from '../../layouts/TableWrapper'
import { ActionsCell } from '../ActionsCell'
import { dayFormat } from '../../pages/Benefits/helpers'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import Modal from '../Modal/Modal'
import NewNoteForm from './NewNoteForm'

interface Props {
  memberId?: string
  notesCount?: number
}

interface Note {
  id: number
  created_at: string
  added_by: string
  content: string
}

const UserNotes: FC<Props> = ({ memberId, notesCount: initialNotesCount }) => {
  const { hasEditPermission, credentials } = useCredentials()
  const [notes, setNotes] = useState<Array<Note>>([])
  const [notesCount, setNotesCount] = useState<number>(initialNotesCount || 0)
  const [isNewFormOpened, setIsNewFormOpened] = useState<boolean>(false)
  const [isNotesVisible, setNotesVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()

  const loadNotes = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(`/api/panel/members/${memberId}/notes`, {
        headers: credentials,
      })
      setNotes(res.data.notes)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
      const notesElement = document.getElementById('notes')
      notesElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [credentials, memberId, setTotalPageCount])

  useEffect(() => {
    if (isNotesVisible && !isNewFormOpened) {
      loadNotes()
    }
  }, [loadNotes, isNotesVisible, isNewFormOpened])

  const onSaveSuccess = () => {
    setNotesCount((prevState) => prevState + 1)
    setIsNewFormOpened(false)
  }

  const onDeleteNote = async (id: number) => {
    if (confirm('Do you want to delete note?')) {
      try {
        setError('')
        await axios.delete(`/api/panel/members/${memberId}/notes/${id}`, {
          headers: credentials,
        })
        setNotes((prevState) => prevState.filter((note) => note.id !== id))
        setNotesCount((prevState) => prevState - 1)
      } catch (e) {
        setError(e?.message)
      }
    }
  }

  return (
    <div className='field'>
      <p className='label'>User notes</p>
      <div className='control is-flex is-align-items-center'>
        <button type='button' className='button mr-4' onClick={() => setNotesVisible((prevState) => !prevState)}>
          {isNotesVisible ? 'Hide notes' : `Show ${notesCount} ${notesCount > 1 ? 'notes' : 'note'}`}
        </button>
        {hasEditPermission && (
          <button type='button' className='button' onClick={() => setIsNewFormOpened(true)}>
            New note
          </button>
        )}
      </div>

      {isNotesVisible && (
        <TableWrapper
          loading={loading}
          isError={!!error}
          flatStyling
          isEmpty={!notes?.length}
          boxClassname='mt-4'
          pagination={{
            totalPageCount,
            currentPage: currentPageNumber,
            onChangePage: setPageNumber,
          }}
        >
          <table className='table is-fullwidth' id='notes'>
            <thead>
              <tr>
                <th>Created at</th>
                <th>Added by</th>
                <th>Note</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note) => (
                <tr key={note.id}>
                  <th>{note.created_at && moment(note.created_at, dayFormat).format('MMMM Do YYYY')}</th>
                  <td>{note.added_by}</td>
                  <td>
                    <div
                      className='control editor-formatted'
                      style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}
                    >
                      {parseHtml(note.content)}
                    </div>
                  </td>
                  <td>
                    <ActionsCell>
                      {hasEditPermission && (
                        <button type='button' className='button is-danger' onClick={() => onDeleteNote(note.id)}>
                          Delete
                        </button>
                      )}
                    </ActionsCell>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <Modal title='New note' opened={isNewFormOpened} onClose={() => setIsNewFormOpened(false)} width={710}>
        <NewNoteForm memberId={memberId} onSaveSuccess={onSaveSuccess} />
      </Modal>
    </div>
  )
}

export default UserNotes
