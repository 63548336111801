import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { UserDTO } from '../../dtos/UserDTO'
import { Pagination } from '../../components/Pagination'
import { applyParamsToUrlPath } from '../../utils'
import { OrganizationDTO } from '../../dtos/OrganizationDTO'
import CircularSpinner from '../../components/CircularSpinner'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { ActionsCell } from '../../components/ActionsCell'
import CopyToClipboard from 'react-copy-to-clipboard'
import SubOrganizationsTable from '../../components/SubOrganizationTable'

const ShowOrganization: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { credentials, hasEditPermission } = useCredentials()
  const [loading, setLoading] = useState(true)
  const [loadDataError, setLoadDataError] = useState('')
  const [totalPageCount, setTotalPageCount] = useState(null)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [organization, setOrganization] = useState<OrganizationDTO | null>()
  const [users, setUsers] = useState<Array<UserDTO>>([])
  const [link, setLink] = useState('')
  const [linkLoading, setLinkLoading] = useState(false)

  const loadOrganizationData = useCallback(async () => {
    try {
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/organizations/${params.id}`),
        {
          headers: credentials,
        }
      )
      setOrganization(res.data.organization)
      setUsers(res.data.users)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id])

  useEffect(() => {
    loadOrganizationData()
  }, [loadOrganizationData])

  const deleteUser = async (id: string) => {
    if (confirm('Do you want to delete?')) {
      try {
        await axios.delete(`/api/panel/organizations/${params.id}/users/${id}`, {
          headers: credentials,
        })
        const newRows = users.filter((user) => user.id !== id)
        setUsers(newRows)
      } catch (e) {
        setLoadDataError(e.message)
      }
    }
  }

  const getLink = useCallback(async () => {
    try {
      setLinkLoading(true)
      const res = await axios.put(
        `/api/panel/organizations/${params.id}/generate_members_upload_link`,
        {},
        { headers: credentials }
      )
      setLink(res.data.upload_link)
    } catch (e) {
      console.log(e)
    } finally {
      setLinkLoading(false)
    }
  }, [credentials, params.id])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between is-align-items-center'>
        <BackButton />
        <div className='is-flex is-justify-content-space-between is-align-items-center'>
          {link ? (
            <div className='mr-2 is-flex'>
              <span className='mr-4'>{link}</span>
              <CopyToClipboard text={link} options={{ format: 'text/plain' }}>
                <button type='button' className='button' style={{ height: '30px' }}>
                  <span className='icon is-small'>
                    <i className='fas fa-clone' />
                  </span>
                </button>
              </CopyToClipboard>
            </div>
          ) : (
            <button type='button' onClick={getLink} className={`button is-link mr-2 ${linkLoading && 'is-loading'}`}>
              Generate link
            </button>
          )}

          <Link to={`/panel/organizations/${params.id}/membership_lists`} className='button is-link mr-2'>
            Membership lists
          </Link>
          {hasEditPermission && (
            <Link to={`/panel/organizations/${params.id}/members/new`} className='button is-link mr-2'>
              New user
            </Link>
          )}
          {hasEditPermission && organization && (
            <Link to={`/panel/organizations/${organization.id}/edit`} className='button is-link'>
              Edit
            </Link>
          )}
        </div>
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {loading ? (
        <CircularSpinner />
      ) : (
        organization && (
          <>
            <div className='mb-4'>
              <h4 className='is-size-4 has-text-centered'>Users of {organization.name}</h4>
            </div>
            <div className='box'>
              <table className='table is-fullwidth'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Role</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((row) => (
                    <tr key={row.id} className='is-clickable' onClick={() => history.push(`/panel/members/${row.id}`)}>
                      <th>{row.id}</th>
                      <td>{row.email}</td>
                      <td>{row.first_name}</td>
                      <td>{row.last_name}</td>
                      <td>{row.role}</td>
                      <td>
                        <ActionsCell>
                          <Link to={`/panel/members/${row.id}`} className='button is-text mr-2'>
                            Show
                          </Link>
                          {hasEditPermission && (
                            <Link
                              to={`/panel/organizations/${organization.id}/members/${row.id}/edit`}
                              className='button is-text mr-2'
                            >
                              Edit
                            </Link>
                          )}
                          {hasEditPermission && (
                            <button type='button' className='button is-danger' onClick={() => deleteUser(row.id)}>
                              Delete
                            </button>
                          )}
                        </ActionsCell>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalPageCount={totalPageCount}
                currentPage={currentPageNumber}
                onChangePage={setCurrentPageNumber}
              />
            </div>
          </>
        )
      )}
      <SubOrganizationsTable />
    </DefaultLayout>
  )
}

export default ShowOrganization
