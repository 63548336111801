import { RoutePath } from 'constants/RoutePath'
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { ApplicationAddressForm } from 'pages/PreApplication/ApplicationAddressForm'
import { Box } from '@material-ui/core'
import { useStyles } from 'components/shared/FormWrapper/styles'
import FormWrapper from 'components/shared/FormWrapper'
import { ApplicationCompanyForm } from 'pages/PreApplication/ApplicationCompanyForm'
import ApplicationHeader from 'pages/PreApplication/ApplicationHeader'
import { ApplicationPlan } from 'pages/PreApplication/ApplicationPlan'
import { ApplicationSuccess } from 'pages/PreApplication/ApplicationSuccess'
import ApplicationNote from 'pages/PreApplication/ApplicationNote'
import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER, GET_MEMBERSHIP_PLAN } from 'constants/queries'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory, useLocation } from 'react-router-dom'
import { ApplicationCardForm } from './ApplicationCardForm'

interface StepData {
  component: ReactElement
  title: string
  description?: string
}

export const steps = [
  { number: 1, name: 'updateUserAddress' },
  { number: 2, name: 'updateUserCompanyData' },
  { number: 3, name: 'selectMembershipPlan' },
  { number: 4, name: 'updateCreditCard' },
  { number: 5, name: 'success' },
]

const PreApplication: React.FC = () => {
  const classes = useStyles()
  const { data: userData, loading, refetch } = useQuery(GET_CURRENT_USER)
  const [step, setStep] = useState(1)
  const [totalPrice, setTotalPrice] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const isUnlockingFullAccess = query.get('unlockFullAccess')
  const benefitName = query.get('benefitName')

  const onReactivateSuccess = () => refetch().then(() => history.push(RoutePath.Home))
  const onUnlockSuccess = () =>
    refetch().then(() => history.push(benefitName ? `/client/benefits/${benefitName}/details` : RoutePath.Home))

  const { loading: planLoading } = useQuery(GET_MEMBERSHIP_PLAN, { skip: step !== 4 })

  const getStepLayout = (): StepData => {
    switch (step) {
      case 1:
      default:
        return {
          component: <ApplicationAddressForm onSuccess={() => setStep(2)} />,
          title: 'Personal information',
          description: 'Let’s get you started on our application process.',
        }
      case 2:
        return {
          component: <ApplicationCompanyForm onSuccess={() => setStep(3)} />,
          title: 'Company information',
          description: 'Please share a little information about your company.',
        }
      case 3:
        return {
          component: <ApplicationPlan onSuccess={(thankScreen) => (thankScreen ? setShowSuccess(true) : setStep(4))} />,
          title: 'Membership information',
          description: 'Almost done! Choose a plan.',
        }
      case 4:
        return {
          component: (
            <ApplicationCardForm
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              onSuccess={() =>
                isUnlockingFullAccess
                  ? onUnlockSuccess()
                  : userData?.currentUser.previousSubscription
                  ? onReactivateSuccess()
                  : setShowSuccess(true)
              }
            />
          ),
          title: 'Credit card information',
          description: isUnlockingFullAccess
            ? 'Upgrade your EXEC Membership and instantly receive access to the full suite of EXEC benefits, including elite statuses, VIP upgrades, and many other perks only available in the full EXEC membership.'
            : userData?.currentUser.previousSubscription
            ? `Welcome back ${userData?.currentUser.firstName}, as someone that previously had access to EXEC, you may update your billing information below and reactivate your EXEC Membership at a special discounted annual rate of $${totalPrice} per year.`
            : 'Last step! Enter your credit card information.',
        }
    }
  }

  const stepLayout = getStepLayout()

  useEffect(() => {
    if (isUnlockingFullAccess) {
      return setStep(4)
    }
    if (userData?.currentUser?.preApplicationStep === 'success') {
      setShowSuccess(true)
    } else if (userData?.currentUser?.preApplicationStep) {
      const currentStep = steps.find(({ name }) => name === userData?.currentUser?.preApplicationStep)
      if (currentStep?.number) {
        setStep(currentStep?.number)
      }
    }
  }, [userData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step, showSuccess])

  return (
    <PageLayout title='Application' externalNavigation curlyHeader>
      {(loading || planLoading) && (
        <div style={{ textAlign: 'center', marginTop: '5rem' }}>
          <CircularProgress />
        </div>
      )}
      {showSuccess && <ApplicationSuccess />}
      {!loading && !planLoading && !showSuccess && (
        <>
          {step === 1 && <ApplicationHeader />}
          <Box className={classes.formBox} py={6} mt={4}>
            <FormWrapper title={stepLayout.title} description={stepLayout.description}>
              {stepLayout.component}
            </FormWrapper>
          </Box>
          <ApplicationNote />
        </>
      )}
    </PageLayout>
  )
}

export default PreApplication
