import { UserRole } from '../const'

export const adminInitialState = {
  email: '',
  first_name: '',
  last_name: '',
  about: '',
  internal_id: '',
  address: '',
  phone: '',
  role: UserRole.Admin,
}

export const memberInitialState = {
  ...adminInitialState,
  about: '',
  active: false,
  annual_revenue: '',
  apartment: '',
  city: '',
  company_name: '',
  company_website: '',
  country: '',
  funding_acquired_since_inception: '',
  is_demo: false,
  linkedin_profile_url: '',
  max_sub_account: '',
  number_of_employees: '',
  organization_id: '',
  sub_organization_id: '',
  position: '',
  role: UserRole.Member,
  state: '',
  zip_code: '',
}
