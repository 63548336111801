import Section from 'components/shared/Section'
import React, { useEffect } from 'react'
import PageLayout from 'components/layouts/PageLayout'

const About: React.FC = () => {
  return (
    <PageLayout>
      <Section title='About Us'>
        <b>The Membership For Executives</b>
        <br />
        <br />
        EXEC is an exclusive, highly curated hotel, travel, lifestyle, and business benefits program designed to improve
        the lives of busy executives by providing access to some of the world’s leading brands and experiences. When you
        become a Member of EXEC, you unlock access, heightened levels of hospitality, and negotiated pricing through an
        exclusive collection of hundreds of benefits. Now you can upgrade your life in ways that were previously
        unattainable.
        <br />
        <br />
        Our EXEC Hotels & Resorts Collection includes negotiated rates and added amenities at properties around the
        world for your business and leisure travel or elite status with leading travel brands. Our Members receive
        access to private golf, business, and social clubs or pro athlete guided one-of-a-kind experiences. To ensure
        there is something for everyone, we offer a variety of lifestyle offerings that weave in fashion, food, and
        function.
        <br />
        <br />
        Our Membership is primarily comprised of a portfolio of invitation-only organizations where top executives and
        entrepreneurs develop as leaders and build connections and visibility. As an upgrade to traditional benefits,
        forward-thinking companies provide their C-Suite with EXEC’s unparalleled benefits and opportunities, while
        investors and VC firms often reward their portfolio’s founding teams with access to one of the most privileged
        Membership programs in the world.
      </Section>
    </PageLayout>
  )
}

export default About
