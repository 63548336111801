import React, { useEffect } from 'react'
import { Card as CardContainer, CardMedia, Box, Typography, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import ExecLogo from 'images/exec-logo.svg'
import { GET_CURRENT_USER } from 'constants/queries'
import Ball from './images/bigBall.svg'
import { useStyles } from './styles'
import ProfileImage from 'components/shared/ProfileImage'

interface Props {
  isOpened: boolean
  closeModal: () => void
}

const Card: React.FC<Props> = ({ isOpened, closeModal }) => {
  const classes = useStyles()

  const [getCurrentUser, { data: userData }] = useLazyQuery(GET_CURRENT_USER)

  useEffect(getCurrentUser, [getCurrentUser])

  const user = userData?.currentUser

  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`

  return (
    <Modal className={classes.modal} open={isOpened} onClose={closeModal}>
      <CardContainer className={classes.cardContainer}>
        <CloseIcon onClick={closeModal} className={classes.closeButton} />
        <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <ProfileImage alt='avatar' src={user?.photo.thumbnail} avatarClassName={classes.avatar} />
          <Box position='absolute' width='100%' left='0' top='80px' zIndex='100'>
            <Typography variant='h5' component='div' align='center' className={classes.name}>
              {fullName}
            </Typography>
            <Typography variant='body2' component='div' align='center' className={classes.website}>
              {user?.companyWebsite}
            </Typography>
          </Box>
          <Box position='absolute' width='100%' top='230px' zIndex='100'>
            <Typography variant='h4' color='primary' component='div' align='center'>
              {user?.membershipId?.match(/.{1,4}/g).join(' ')}
            </Typography>
            <Typography variant='caption' component='div' align='center' className={classes.membershipDate}>
              Member since {moment(user?.createdAt).format('MMMM do, YYYY')}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            width='100%'
            top='370px'
            zIndex='100'
            className={classes.execLogo}
          >
            <img className={classes.logo} src={ExecLogo} alt='logo' />
            <Typography variant='h5' component='div'>
              EXEC
            </Typography>
          </Box>
          <CardMedia component='img' alt='' image={Ball} className={classes.cardMediaBall} />
        </Box>
      </CardContainer>
    </Modal>
  )
}

export default Card
