import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    headerBox: {
      position: 'relative',
      minHeight: '40px',
    },
    actions: {
      position: 'absolute',
      right: 0,
      top: 0,
      '@media(max-width: 900px)': {
        position: 'static',
        margin: '8px 0',
      },
    },
  }
})
