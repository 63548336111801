import React from 'react'
import { Link, LinkProps } from '@material-ui/core'
import { Link as RouterLink, NavLink } from 'react-router-dom'

import { useStyles } from './styles'

export interface NavigationLinkProps extends LinkProps<RouterLink> {
  text: string
  onClick?: () => void
  isAnchor?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ text, className, onClick, isAnchor, ...props }) => {
  const classes = useStyles()

  if (isAnchor) {
    return (
      <a className={classes.link} href={props.to as string} target={props.target}>
        {text}
      </a>
    )
  }

  return (
    <Link
      activeStyle={{ textDecoration: 'underline', textDecorationColor: '#C97E48' }}
      className={`${classes.link} ${className || ''}`}
      component={NavLink}
      {...(onClick ? { onClick } : {})}
      {...props}
    >
      {text}
    </Link>
  )
}

export default NavigationLink
