import React, { useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Container, useMediaQuery, useTheme } from '@material-ui/core'
import { useStyles } from './styles'

export interface HeroBottomComponentWrapperProps extends BoxProps {
  setParentMarginBottom: (marginBottom: number) => void
}

const calculateTranslateY = (isMobile: boolean, componentHeight: number) => {
  // Always translateY by 50% on desktop screens
  if (!isMobile) return 50

  // If component is too big for mobile screens, use a bigger translation on y-axis
  if (componentHeight > 140) return 80
  return 50
}

const HeroBottomComponentWrapper: React.FC<HeroBottomComponentWrapperProps> = ({
  setParentMarginBottom,
  children,
  className,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ref = useRef<HTMLDivElement>(null)
  const [componentHeight, setComponentHeight] = useState<number>(32)
  const translateY = calculateTranslateY(isMobile, componentHeight)
  // Calculate the parents bottom margin to be equal to the number of pixels that translateY moved the component below the parent
  const parentMarginBottom = (componentHeight * translateY) / 100
  const classes = useStyles({ translateY })

  useEffect(() => {
    setComponentHeight(ref?.current?.clientHeight || 32)
  }, [ref?.current?.clientHeight])

  useEffect(() => {
    setParentMarginBottom(parentMarginBottom)
  }, [setParentMarginBottom, parentMarginBottom])

  return (
    <Box {...{ ref }} className={`${classes.content} ${classes.bottomContent} ${className || ''}`} {...props}>
      <Container maxWidth='lg' className={classes.bottomContentContainer}>
        <>{children}</>
      </Container>
    </Box>
  )
}

export default HeroBottomComponentWrapper
