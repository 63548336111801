export enum LeadStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  INCOMPLETE = 'incomplete',
  PENDING_PAYMENT = 'pending_payment',
  FAILED_PAYMENT = 'failed_payment',
}

export enum Plan {
  regular = 'regular',
  custom = 'custom',
}

export interface LeadDTO {
  id: string
  additional_information: string
  application_submission_date?: string
  address: string
  amount_charged?: number
  annual_revenue: string
  apartment: string
  card_type: string
  chargify_product_id: number
  chargify_product_name: string
  chargify_product_initiation: string
  city: string
  company_name: string
  company_website: string
  country: string
  created_at: string
  email: string
  first_name: string
  full_name: string
  funding_acquired_since_inception: string
  last_date_charged?: string
  last_four_digits_of_card: string
  last_name: string
  lead_status: LeadStatus
  linkedin_profile_url: string
  number_of_employees: string
  phone: string
  plan: Plan
  position: string
  renewal_date?: string
  seats: number
  state: string
  zip_code: string
}

export interface Range {
  id: string
  name: string
}

export interface CompanyRange {
  annual_revenue: Range[]
  funding_acquired_since_inception: Range[]
  number_of_employees: Range[]
}
