import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import useCredentials from '../hooks/useCredentials'
import { RouterPath } from '../router/routes'

interface PrivateRouteDTO {
  path: string
  exact: boolean
  children: JSX.Element
}

const PrivateRoute: React.FC<PrivateRouteDTO> = ({ path, exact, children, ...rest }): JSX.Element => {
  const { credentials } = useCredentials()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        credentials ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: RouterPath.LOG_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
