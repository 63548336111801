import React from 'react'
import { Box, CardMedia, GridList, Typography } from '@material-ui/core'
import { RoutePath } from 'constants/RoutePath'
import ButtonLink from 'components/shared/ButtonLink'
import SuccessImage1 from 'pages/Onboarding/images/success-1.png'
import SuccessImage2 from 'pages/Onboarding/images/success-2.png'
import SuccessImage3 from 'pages/Onboarding/images/success-3.png'

const SuccessDescription: React.FC = () => {
  return (
    <>
      <Box mt={4} mb={2} display='flex' justifyContent='center'>
        <ButtonLink text='Explore EXEC' to={RoutePath.Home} />
      </Box>

      <GridList cols={3} spacing={4} style={{ width: '100%' }} cellHeight='auto'>
        <CardMedia component='img' src={SuccessImage1} />
        <CardMedia component='img' src={SuccessImage2} />
        <CardMedia component='img' src={SuccessImage3} />
      </GridList>

      <Box mt={2}>
        Need help?{' '}
        <Typography
          color='primary'
          component='a'
          target='_blank'
          href='https://meetings.hubspot.com/scott-poniewaz/new-member-onboarding-call'
        >
          Schedule a call
        </Typography>{' '}
        with one of our EXEC concierge team members to further assist you with getting started with your Membership now.
      </Box>
    </>
  )
}

export default SuccessDescription
