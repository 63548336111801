import React from 'react'
import { Box, GridList, Hidden, Typography, CardMedia, Divider } from '@material-ui/core'
import ExecLogo from 'images/exec-logo.svg'
import classnames from 'classnames'
import ButtonLink from 'components/shared/ButtonLink'
import { RoutePath } from 'constants/RoutePath.ts'
import { useStyles } from './styles'
import SuccessImage1 from '../images/success-1.png'
import SuccessImage2 from '../images/success-2.png'
import SuccessImage3 from '../images/success-3.png'

interface Props {
  isPreApplication?: boolean
}

const ApplicationSuccessMain: React.FC<Props> = ({ isPreApplication }) => {
  const classes = useStyles()

  return (
    <Box mb={8} display='flex' flexDirection='column' alignItems='center'>
      <Box p={2} className={classnames(classes.blockWithLine)}>
        <Box mb={1}>
          <Divider variant='middle' />
        </Box>
        {isPreApplication ? (
          <>
            Discover life’s&nbsp;
            <Typography component='span' color='primary' variant='h5'>
              benefits
            </Typography>
            .
          </>
        ) : (
          <>
            Explore your&nbsp;
            <Typography component='span' color='primary' variant='h5'>
              benefits
            </Typography>
            .
          </>
        )}
      </Box>

      <Hidden smDown>
        <GridList cols={3} spacing={4} style={{ width: '100%' }} cellHeight='auto'>
          <CardMedia component='img' src={SuccessImage1} />
          <CardMedia component='img' src={SuccessImage2} />
          <CardMedia component='img' src={SuccessImage3} />
        </GridList>
      </Hidden>

      <Hidden mdUp>
        <img src={SuccessImage3} alt='img3' style={{ width: '100%' }} />
      </Hidden>

      <Box p={2} className={classnames(classes.blockWithLine)}>
        {isPreApplication ? (
          <>
            Enjoy <img className={classes.logo} src={ExecLogo} alt='logo' />.
          </>
        ) : (
          <Box mb={2}>
            <ButtonLink text='EXEC main page' to={RoutePath.Home} size='large' bold />
          </Box>
        )}
        <Box mt={1}>
          <Divider />
        </Box>
      </Box>
    </Box>
  )
}

export default ApplicationSuccessMain
