import ExecLogo from 'images/exec-logo.svg'
import React from 'react'
import {
  Card,
  CardProps,
  CardContent,
  Typography,
  Divider,
  Box,
  IconButton,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import MoreDetailsButton from 'components/shared/buttons/MoreDetailsButton'
import Label from 'components/shared/Label'
import { useHistory } from 'react-router-dom'
import BenefitShowcaseCarousel from 'components/shared/BenefitShowcaseCarousel'
import parse from 'html-react-parser'
import { useMutation } from '@apollo/client'
import { ADD_FAVORITE_MUTATION } from 'constants/mutations'
import useToggle from 'hooks/useToggle'
import CarouselItem from 'components/shared/CarouselItem'
import MarketplaceLogo from 'images/marketplace_by_exec.png'
import { Benefit } from 'types/generatedGql'
import { CategorySlug } from 'types'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { getBenefitInfo } from 'utils'
import LinesEllipsis from 'react-lines-ellipsis'
import { useStyles } from './styles'

interface BenefitShowcaseProps extends Omit<CardProps, 'id'> {
  useOverlay?: boolean
  benefit: Benefit
}

const BenefitShowcase: React.FC<BenefitShowcaseProps> = ({ benefit, useOverlay, className, ...props }) => {
  const {
    city,
    category,
    name,
    slug,
    description,
    shortDescription,
    favorited: initialFavorite,
    images,
    isNew,
    isMarketplace,
  } = benefit
  const [favorited, toggleFavorite] = useToggle(initialFavorite)
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isTablet } = useGetWindowSize()
  const isHotel = category.slug === CategorySlug.HOTELS
  const benefitPath = `/client/benefits/${slug}/details`

  const [addFavorite] = useMutation(ADD_FAVORITE_MUTATION)

  const handleFavoriteClicked = async () => {
    try {
      await addFavorite({
        variables: {
          benefitSlug: slug,
        },
      })
      toggleFavorite()
    } catch (e) {
      console.error(e)
    }
  }

  const goToPath = () => history.push(benefitPath)
  const info = getBenefitInfo(benefit)

  const classes = useStyles()

  return (
    <Card className={`${classes.card} ${className || ''}`} onClick={() => isMobile && goToPath()} {...props}>
      {isMarketplace && <img className={classes.marketplaceLogo} src={MarketplaceLogo} alt='logo' />}
      <Box className={classes.media}>
        {isNew && <div className={classes.newLabel}>NEW</div>}
        {images.length > 1 ? (
          <BenefitShowcaseCarousel images={images} />
        ) : (
          <CarouselItem image={isTablet ? images[0]?.thumbnail : images[0]?.medium} />
        )}
      </Box>

      <CardContent className={classes.content}>
        {/* Mobile and tablet view */}
        <Hidden mdUp>
          <Label text={isHotel ? city : category?.name} />
          <Typography variant='body2' color='textSecondary' className={classes.shortDescription}>
            {name}
          </Typography>
          <Divider className={classes.mobileDivider} />
          {info && (
            <Typography variant='body2' color='primary' className={classes.deal}>
              {info}
            </Typography>
          )}
        </Hidden>

        {/* Desktop view */}
        <Hidden smDown>
          {isHotel && (
            <Typography variant='caption' color='textSecondary' className={classes.city}>
              {city}
            </Typography>
          )}
          <Typography component='h5' variant='h5' className={classes.name}>
            {name}
          </Typography>

          {shortDescription && (
            <Typography variant='body2' color='textSecondary' className={classes.longDescription}>
              <LinesEllipsis text={shortDescription} maxLine='4' ellipsis='...' trimRight basedOn='letters' />
            </Typography>
          )}

          {!shortDescription && description && (
            <Typography variant='body2' color='textSecondary' className={classes.longDescription}>
              {parse(description || '')}
            </Typography>
          )}

          {info && (
            <Typography variant='body2' color='primary' className={classes.deal}>
              {info}
            </Typography>
          )}
          <Divider />
          <Box display='flex' alignItems='center' justifyContent='space-between' mt={1}>
            <IconButton onClick={handleFavoriteClicked}>
              {favorited ? <FavoriteIcon className={classes.icon} /> : <FavoriteBorderIcon className={classes.icon} />}
            </IconButton>
            <MoreDetailsButton to={benefitPath} />
          </Box>
        </Hidden>
      </CardContent>
    </Card>
  )
}

export default BenefitShowcase
