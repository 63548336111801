import React, { useState } from 'react'
import AddSubAccountButton from 'components/shared/buttons/AddSubAccountButton'
import SectionSecondary from 'components/shared/SectionSecondary'
import InviteButton from 'components/shared/buttons/InviteButton'
import { DefaultModalProps } from 'components/shared/DefaultModal/DefaultModal'
import { Box, ButtonProps, TextField, Typography, useTheme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useMutation } from '@apollo/client'
import { ADD_SUBACCOUNT_MUTATION } from 'constants/mutations'
import ButtonAction from 'components/shared/ButtonAction'
import { useStyles } from './styles'
import DefaultModalContent from 'components/shared/DefaultModal/DefaultModalContent'
import useToggle from 'hooks/useToggle'

interface Props extends Omit<DefaultModalProps, 'toggleButton' | 'content' | 'actions'> {
  getSubAccounts?: () => void
  addButton?: React.FC
  addButtonProps?: ButtonProps
  isOnboarding?: boolean
  onNext?: () => void
  remainingSubAccounts: number
  validateData?: any
  validateLoading?: boolean
  showValidateError?: () => void
  validateErrors?: string
}

const AddSubAccountModal: React.FC<Props> = ({
  addButton: AddButton,
  addButtonProps,
  getSubAccounts,
  getAddButton,
  isOnboarding,
  onNext,
  remainingSubAccounts,
  validateData,
  validateLoading,
  showValidateError,
  validateErrors,
  ...props
}) => {
  const theme = useTheme()
  const [isInviteSent, setIsInviteSent] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [step, setStep] = useState<1 | 2>(1)
  const classes = useStyles()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const response = await addSubAccount({
        variables: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email,
        },
      })
      if (response?.data?.addSubAccount?.errors) {
        setError(response.data.addSubAccount.errors.join(' '))
      } else {
        onAddSuccess()
      }
    } catch (e) {
      console.error(e)
      setError(e.message)
    }
  }

  const clearFormData = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
  }

  const onAddSuccess = () => {
    setIsInviteSent(true)
    clearFormData()
    getSubAccounts?.()
  }

  const [addSubAccount, { loading }] = useMutation(ADD_SUBACCOUNT_MUTATION)

  const amount = validateData?.validateSubAccount?.amountInCents / 100

  const [isModalOpen, toggleModal] = useToggle(false)

  const handleOpenAddSubaccountModal = () => {
    if (validateErrors) {
      showValidateError!()
    } else {
      toggleModal()
    }
  }

  return (
    <>
      {AddButton ? (
        <AddButton onClick={toggleModal} {...addButtonProps} />
      ) : (
        <AddSubAccountButton
          onClick={handleOpenAddSubaccountModal}
          loading={validateLoading}
          disabled={validateLoading}
          {...addButtonProps}
        />
      )}
      <DefaultModalContent
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        width={isInviteSent ? 450 : 'auto'}
        runAfterClose={() => {
          setIsInviteSent(false)
          setStep(1)
        }}
        content={
          isInviteSent ? (
            <Box mb={4}>
              <Typography variant='h4' color='primary'>
                <strong>Invite sent!</strong>&nbsp;
                <CheckCircleOutlineIcon color='primary' className={classes.icon} />
              </Typography>
              <Box mt={4}>Your sub-account has been added and should receive an activation link in their email.</Box>
              {isOnboarding && (
                <Box mt={4} display='flex' flexDirection='column' alignItems='center'>
                  <Box>
                    <ButtonAction text='Next' onClick={() => onNext?.()} />
                  </Box>
                  <Box mt={2}>
                    <Typography
                      style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                      component='a'
                      color='primary'
                      onClick={() => {
                        clearFormData()
                        setIsInviteSent(false)
                      }}
                    >
                      Add another sub-account
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <SectionSecondary title='Add a sub-account.' titleColor='primary' className={classes.addSubAccount}>
              {loading ? (
                <Box my={4} textAlign='center'>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {!!remainingSubAccounts ? (
                    <Box mb={2}>Sub-account domain must match the primary user’s domain.</Box>
                  ) : (
                    <Box mb={2}>
                      Please note: You are adding a sub-account that exceeds your subscription limit. To add a
                      sub-users, you will be charged a non-refundable, prorated amount of ${amount} for every additional
                      sub-account that you add. The annual renewal cost for this sub-user, which aligns with your annual
                      renewal date, will be {validateData?.validateSubAccount?.fullFormattedPrice}.
                    </Box>
                  )}
                  <form autoComplete='off' onSubmit={handleSubmit}>
                    <Box display={step === 1 ? 'block' : 'none'}>
                      <TextField
                        className={classes.textfield}
                        InputLabelProps={{ classes: { root: classes.inputLabel } }}
                        InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
                        type='text'
                        label='First name'
                        variant='outlined'
                        value={firstName}
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                        fullWidth
                      />
                      <TextField
                        className={classes.textfield}
                        InputLabelProps={{ classes: { root: classes.inputLabel } }}
                        InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
                        type='text'
                        label='Last name'
                        variant='outlined'
                        value={lastName}
                        required
                        onChange={(e) => setLastName(e.target.value)}
                        fullWidth
                      />
                      <TextField
                        className={classes.textfield}
                        InputLabelProps={{ classes: { root: classes.inputLabel } }}
                        InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
                        type='email'
                        label='Email Address'
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        variant='outlined'
                        fullWidth
                      />
                    </Box>
                    <Box fontWeight={600} display={step === 2 ? 'block' : 'none'}>
                      <Box
                        borderBottom={`1px solid ${theme.palette.divider}`}
                        py={2}
                        color={theme.palette.primary.main}
                      >
                        1 seat
                      </Box>
                      <Box borderBottom={`1px solid ${theme.palette.divider}`} py={2}>
                        ${amount}/yr
                      </Box>
                      <Box borderBottom={`1px solid ${theme.palette.divider}`} py={2}>
                        1 Sub user
                      </Box>
                      <Box mt={4} mb={7} fontWeight={500}>
                        <Typography>Total today:</Typography>
                        <Box display='flex' alignItems='center' border='1px solid #333333' p={2} mt={0.5}>
                          <Box fontWeight={600} fontSize={24} mr={2}>
                            ${amount}
                          </Box>
                          <Box fontWeight={400} fontSize={14} mr={1}>
                            +
                          </Box>
                          <Box fontWeight={500}>applicable taxes</Box>
                        </Box>
                      </Box>
                    </Box>
                    {error && <Typography color='error'>{error}</Typography>}
                    {step === 1 && !remainingSubAccounts ? (
                      <ButtonAction onClick={() => setStep(2)} type='button' text='Next' size='large' fullWidth bold />
                    ) : (
                      !isInviteSent && <InviteButton type='submit' />
                    )}
                  </form>
                </>
              )}
            </SectionSecondary>
          )
        }
        {...props}
      />
    </>
  )
}

export default AddSubAccountModal
