import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, TextFieldProps } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper/styles'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import Dots from 'components/shared/Dots'
import { useMutation, useQuery } from '@apollo/client'
import { Mutation } from 'types/generatedGql'
import ButtonAction from 'components/shared/ButtonAction'
import InputMask from 'react-input-mask'
import { UPDATE_CREDIT_CARD, VALIDATE_COUPON } from 'constants/mutations'
import { GET_MEMBERSHIP_PLAN } from 'constants/queries'
import { handleCardNumberSpaces } from 'utils'
import { useStyles } from './styles'

interface Props {
  onSuccess: () => void
  totalPrice: number
  setTotalPrice: (v: number) => void
}

const initialData = {
  name: '',
  cardNumber: '',
  expiryDate: '',
  cvc: '',
  promotionalCode: '',
}

const handleValue = (value: string, fieldName: string, prevData?: typeof initialData) => {
  if (fieldName === 'cardNumber') {
    return handleCardNumberSpaces(value, prevData[fieldName])
  }
  return value
}

const ApplicationCardForm: React.FC<Props> = ({ onSuccess, totalPrice, setTotalPrice }) => {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const [data, setData] = useState(initialData)

  const {
    data: planData,
    loading,
    error: planError,
  } = useQuery(GET_MEMBERSHIP_PLAN, {
    onCompleted: (queryData) => setTotalPrice(queryData.membershipPlan.totalPrice),
  })
  const plan = planData?.membershipPlan

  const [updateCreditCard, { loading: submitting, error, reset: resetCreditCard }] = useMutation(UPDATE_CREDIT_CARD, {
    onCompleted: (mutationData: Mutation) => {
      if (mutationData?.updateCreditCard?.message === 'success') {
        onSuccess()
      }
    },
  })

  const [validateCoupon, { data: discountData, loading: discountLoading, error: discountError, reset: resetDiscount }] =
    useMutation(VALIDATE_COUPON, {
      onCompleted: (queryData) => {
        const {
          validateCoupon: {
            coupon: { amount, percentage, discountType },
          },
        } = queryData

        if (discountType === 'amount') {
          setTotalPrice(plan.totalPrice - amount)
        } else {
          setTotalPrice(plan.totalPrice - plan.totalPrice * (percentage / 100))
        }
      },
    })

  const onChange = (value: string, fieldName: keyof typeof initialData) => {
    setData((prevData) => ({ ...prevData, [fieldName]: handleValue(value, fieldName, prevData) }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    resetDiscount()
    await updateCreditCard({ variables: { ...data, cardNumber: data.cardNumber.replaceAll(' ', '') } })
  }

  return (
    <form onSubmit={onSubmit}>
      {!plan?.customPlan && (
        <Box mb={4}>
          Please provide your payment details. Your card will not be charged until your EXEC Membership has been
          approved.
        </Box>
      )}

      <Grid container className={classes.container}>
        <Grid item xs={6}>
          <Box pr={4} className={classes.leftBox}>
            <Box className={formClasses.formRow}>
              <TextFieldCustom
                label='Name as it appears on card'
                type='text'
                isTopLabel
                required
                value={data.name}
                onChange={(event) => onChange(event.target.value, 'name')}
                fullWidth
              />
            </Box>

            <Box className={formClasses.formRow}>
              <TextFieldCustom
                label='Card number'
                type='tel'
                isTopLabel
                required
                value={data.cardNumber}
                onChange={(event) => onChange(event.target.value, 'cardNumber')}
                fullWidth
              />
            </Box>

            <Box className={formClasses.formRow}>
              <InputMask
                mask='99/99'
                type='text'
                value={data.expiryDate}
                onChange={(event) => onChange(event.target.value, 'expiryDate')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextFieldCustom label='Expiration' isTopLabel required fullWidth {...inputProps} />
                )}
              </InputMask>

              <InputMask
                mask='9999'
                type='text'
                value={data.cvc}
                onChange={(event) => onChange(event.target.value?.replaceAll('_', ''), 'cvc')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextFieldCustom label='CVC' isTopLabel required fullWidth {...inputProps} />
                )}
              </InputMask>
            </Box>

            <Box className={formClasses.formRow}>
              <TextFieldCustom
                label='Promotional code (optional)'
                isTopLabel
                value={data.promotionalCode}
                onChange={(event) => {
                  if (discountData) {
                    resetDiscount()
                    setTotalPrice(plan.totalPrice)
                  }
                  onChange(event.target.value, 'promotionalCode')
                }}
                fullWidth
              />
            </Box>
            {discountError && (
              <Box mt={-2} mb={1}>
                <Typography color='error'>{discountError?.message}</Typography>
              </Box>
            )}
            <Box>
              <ButtonAction
                text='Submit code'
                type='button'
                variant='contained'
                color='primary'
                onClick={() => {
                  resetCreditCard()
                  validateCoupon({
                    variables: { promotionalCode: data.promotionalCode },
                  })
                }}
                loading={discountLoading}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          {loading && (
            <Grid container alignItems='center' justify='center'>
              <CircularProgress />
            </Grid>
          )}
          {plan && (
            <Box
              pl={4}
              className={classes.rightBox}
              display='flex'
              justifyContent='space-between'
              flexDirection='column'
              height='100%'
            >
              <div>
                <h2 style={{ marginTop: 0 }}>Your EXEC membership</h2>
                <Box py={2} className={classes.rightRow}>
                  <Typography color='primary' style={{ fontWeight: 'bold' }}>
                    {plan.numberOfSeats} seats
                  </Typography>
                </Box>
                <Box py={2} className={classes.rightRow}>
                  ${plan.price}/yr + ${plan.oneTimeInitiationFee} initiation-fee
                </Box>
                <Box py={2} className={classes.rightRow}>
                  1 primary user + {plan.maxSubuserNumber} Sub-users
                </Box>
              </div>

              <Box className={classes.totalBox}>
                <Box display='flex'>
                  <Box mr={1}>
                    <Typography>Total today: </Typography>
                  </Box>
                  {discountData && (
                    <Typography color='error'>
                      (
                      {discountData.validateCoupon.coupon.discountType === 'amount'
                        ? `$${discountData.validateCoupon.coupon.amount} `
                        : `${discountData.validateCoupon.coupon.percentage}% `}
                      Discount Applied)
                    </Typography>
                  )}
                </Box>
                <Box p={2} className={classes.total}>
                  <span className={classes.totalText}>${totalPrice.toFixed(2)}</span> + applicable taxes
                </Box>
              </Box>
            </Box>
          )}
          {planError && <Typography color='error'>{planError?.message}</Typography>}
        </Grid>
      </Grid>

      <Box mb={2} mt={4}>
        <Dots count={4} current={4} />
      </Box>

      {error && (
        <Box my={2}>
          <Typography color='error'>{error?.message}</Typography>
        </Box>
      )}

      <Grid container spacing={0} alignItems='center' justify='center'>
        <ButtonAction
          text='Pay and submit application'
          type='submit'
          variant='contained'
          color='primary'
          size='large'
          loading={submitting}
        />
      </Grid>
    </form>
  )
}

export default ApplicationCardForm
