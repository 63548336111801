import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
  dotsMarginBottom?: number
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    carousel: {
      height: '100%',
      '& .carousel, .slider-wrapper, .slider': {
        height: '100%',
      },
      '& .control-dots': {
        marginBottom: ({ dotsMarginBottom }) => (dotsMarginBottom || 10) + 10,
        [theme.breakpoints.down('sm')]: {
          marginBottom: ({ dotsMarginBottom }) => (dotsMarginBottom || 10) * 1.25 * 0.2 + 10,
        },
      },
    },
    primary: {
      '& .control-dots': {
        bottom: ({ dotsMarginBottom }) => (dotsMarginBottom ? 0 : '15%'),
      },
    },
    secondary: {
      '& .control-dots': {
        bottom: 0,
        textAlign: 'left',
        padding: theme.spacing(0, 0, 2, 2),
        margin: 0,
        [theme.breakpoints.down('sm')]: {
          top: 0,
          padding: theme.spacing(2, 0, 0, 2),
        },
      },
    },
  }
})
