import CopyToClipboard from 'react-copy-to-clipboard'
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ShowFormData } from '../../components/DefaultShowForm'
import { UserDTO } from '../../dtos/UserDTO'
import { getLeadFormData } from '../Leads/const'
import { LeadDTO } from '../../dtos/LeadDTO'
import { getBooleanFieldText } from '../../utils'
import { AdminRoles, UserRole, userRoleMap } from '../../const'
import UserNotes from '../../components/UserNotes/UserNotes'

export const getMemberShowFormData = (member?: Partial<UserDTO>, isEdit?: boolean): ShowFormData[] => {
  if (!member) {
    return []
  }
  const isAdmin = AdminRoles.includes(member.role as UserRole)
  return [
    ...getLeadFormData(member as LeadDTO, isEdit, member.role as UserRole),
    {
      fieldLabel: 'Internal ID',
      fieldValue: member.internal_id,
      fieldName: 'internal_id',
    },
    {
      fieldLabel: 'About',
      fieldValue: member.about,
      fieldName: 'about',
    },
    {
      fieldLabel: 'Sub accounts',
      fieldValue: `${member.children_count || '0'}/${member.max_sub_account || '0'}`,
      fieldName: 'max_sub_account',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Organization',
      fieldValue: `${member.organization_name} ${member.organization_id && `(ID: ${member.organization_id})`}`,
      fieldName: 'organization_name',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Sub-organization',
      fieldValue: member.sub_organization_name
        ? `${member.sub_organization_name} ${member.sub_organization_id && `(ID: ${member.sub_organization_id})`}`
        : undefined,
      fieldName: 'sub_organization_name',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Custom plan',
      fieldValue: getBooleanFieldText(member.custom_plan),
      fieldName: 'custom_plan',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Preferred categories',
      fieldValue: member.preferred_categories?.length ? member.preferred_categories?.join(', ') : null,
      fieldName: 'preferred_categories',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Role',
      fieldValue: (member.role && userRoleMap[member.role as UserRole]) || member.role,
      fieldName: 'role',
    },
    {
      fieldLabel: 'Activation link',
      fieldValue: member.activation_link && (
        <>
          <span className='mr-4'>{member.activation_link}</span>
          <CopyToClipboard text={member.activation_link} options={{ format: 'text/plain' }}>
            <button type='button' className='button' style={{ height: '30px' }}>
              <span className='icon is-small'>
                <i className='fas fa-clone' />
              </span>
            </button>
          </CopyToClipboard>
        </>
      ),
      fieldName: 'activation_link',
    },
    {
      fieldLabel: 'Activation status',
      fieldValue: member.activation_status || 'Not activated',
      fieldName: 'activation_status',
    },
    {
      fieldLabel: 'Activated at',
      fieldValue: member.activated_at && moment(member.activated_at).format('MMMM Do YYYY'),
      fieldName: 'activated_at',
    },
    {
      fieldLabel: 'Deactivated at',
      fieldValue: member.deactivated_at && moment(member.deactivated_at).format('MMMM Do YYYY'),
      fieldName: 'deactivated_at',
    },
    {
      fieldLabel: 'Previously deactivated at',
      fieldValue: member.previously_deactivated_at && moment(member.previously_deactivated_at).format('MMMM Do YYYY'),
      fieldName: 'previously_deactivated_at',
    },
    {
      fieldLabel: 'Children count',
      fieldValue: (
        <>
          <span>{member.children_count || '0'}</span>
          {!!member.children_count && (
            <Link to={`/panel/members/${member.id}/subusers`} className='is-text ml-2'>
              Show sub users
            </Link>
          )}
        </>
      ),
      fieldName: 'children_count',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Last sign in at',
      fieldValue: (
        <>
          <span className='is-text mr-2'>
            {member.last_sign_in_at ? new Date(member.last_sign_in_at).toDateString() : 'Unknown'}
          </span>
          {!isAdmin && (
            <Link to={`/panel/members/${member.id}/history`} className='is-text'>
              Show sign in history
            </Link>
          )}
        </>
      ),
      fieldName: 'last_sign_in_at',
    },
    {
      fieldLabel: 'Deleted at',
      fieldValue: member.deleted_at && new Date(member.deleted_at).toDateString(),
      fieldName: 'deleted_at',
    },
    {
      fieldLabel: 'Expiration date',
      fieldValue: member.expiration_date && new Date(member.expiration_date).toDateString(),
      fieldName: 'expiration_date',
    },
    {
      fieldLabel: 'Hubspot ID',
      fieldValue: member.hubspot_id,
      fieldName: 'hubspot_id',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Membership ID',
      fieldValue: member.membership_id,
      fieldName: 'membership_id',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Parent ID',
      fieldValue: (
        <>
          <span>{member.parent_id}</span>
          <Link to={`/panel/members/${member.parent_id}`} className='is-text ml-2'>
            Show parent member
          </Link>
        </>
      ),
      fieldName: 'parent_id',
      hideField: !member.parent_id || isAdmin,
    },
    {
      fieldLabel: 'Created by admin',
      fieldValue: getBooleanFieldText(member.created_by_admin),
      fieldName: 'created_by_admin',
    },
    {
      fieldLabel: 'Set password token',
      fieldValue: getBooleanFieldText(member.set_password_token),
      fieldName: 'set_password_token',
    },
    {
      fieldLabel: 'Redemptions count',
      fieldValue: member.redemptions_count && (
        <>
          <span className='mr-2'>{member.redemptions_count || '0'}</span>
          {!!member.redemptions_count && (
            <Link to={`/panel/members/${member.id}/redemptions`} className='is-text'>
              Show redemptions history
            </Link>
          )}
        </>
      ),
      fieldName: 'redemptions_count',
      hideField: isAdmin,
    },
    // TODO: add page views count from backend
    {
      fieldLabel: 'Page views',
      fieldValue: (
        <>
          <Link to={`/panel/members/${member.id}/page_views`} className='is-text'>
            Show page views history
          </Link>
        </>
      ),
      fieldName: 'page_views',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Active',
      fieldValue: getBooleanFieldText(member.active),
      fieldName: 'active',
    },
    {
      fieldLabel: 'Demo user',
      fieldValue: getBooleanFieldText(member.is_demo),
      fieldName: 'is_demo',
    },
    {
      fieldLabel: '',
      fieldValue: <UserNotes memberId={member.id as string} notesCount={member.notes_count} />,
      fieldName: 'notes',
      hideField: isAdmin,
    },
  ]
}
