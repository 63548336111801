import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    hero: {
      transition: 'all 0.5s ease-in-out',
    },
    headline: {
      textTransform: 'capitalize',
    },
    content: {
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    innerContent: {
      color: 'white',
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    innerContentContainer: {
      width: '70%',
    },
    typography: {
      marginBottom: theme.spacing(2),
    },
  }
})
