import React, { SelectHTMLAttributes, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import { OrganizationDTO } from '../dtos/OrganizationDTO'
import useCredentials from '../hooks/useCredentials'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  organizationId?: string
  organizationName?: string
  placeholder?: string
  onOrganizationSelect: (value: string) => void
  disabled?: boolean
}

const OrganizationsDropdown: React.FC<Props> = ({
  organizationId,
  organizationName,
  onOrganizationSelect,
  placeholder,
  disabled,
  ...props
}) => {
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [organizations, setOrganizations] = useState<Array<OrganizationDTO> | null>(null)

  const loadOrganizations = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/organizations?sort_by=name`, {
        headers: credentials,
      })
      setOrganizations(res.data.organizations)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [credentials])

  useEffect(() => {
    loadOrganizations()
  }, [loadOrganizations])

  return loading ? (
    <CircularProgress />
  ) : (
    <div className='select'>
      <select
        value={organizationId || organizationName}
        onChange={(event) => onOrganizationSelect(event.target.value)}
        disabled={disabled}
        {...props}
      >
        <option value=''>{placeholder || 'Select organization'}</option>
        {organizations?.map(({ id, name }) => (
          <option value={organizationId !== undefined ? id : name} key={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default OrganizationsDropdown
