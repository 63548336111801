export enum DestinationCategory {
  POPULAR = 1,
  NA = 2,
  SA = 3,
  EU = 4,
  AS = 5,
  OC = 6,
  AF = 7,
  AN = 8,
}

const destinationCategoryText: ReadonlyMap<DestinationCategory, string> = new Map([
  [DestinationCategory.POPULAR, 'Popular destinations'],
  [DestinationCategory.NA, 'North America'],
  [DestinationCategory.SA, 'South America'],
  [DestinationCategory.EU, 'Europe'],
  [DestinationCategory.AS, 'Asia'],
  [DestinationCategory.OC, 'Oceania'],
  [DestinationCategory.AF, 'Africa'],
  [DestinationCategory.AN, 'Antarctica'],
])

export const displayDestinationCategory = (category: DestinationCategory): string =>
  destinationCategoryText.get(category) || 'Cities'

export default DestinationCategory
