import { createSlice } from '@reduxjs/toolkit'

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState: {
    searchTerm: '',
  },
  reducers: {
    setTerm(state, { payload }) {
      state.searchTerm = payload
    },
  },
})

export const { setTerm } = benefitsSlice.actions

export default benefitsSlice.reducer
