import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    media: {
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    digitalCard: {
      marginTop: theme.spacing(4),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    benefitName: {
      position: 'absolute',
      padding: theme.spacing(0, 6, 3, 6),
      color: '#fff',
      bottom: 0,
      [theme.breakpoints.down('xs')]: {
        color: '#000',
        textAlign: 'center',
        position: 'initial',
        padding: theme.spacing(4, 0, 2, 0),
      },
    },
    howToRedeem: {
      padding: 0,
    },
    formInput: {
      color: 'black',
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    redeemButton: {
      textTransform: 'uppercase',
    },
  }
})
