import React, { useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import axios from 'axios'
import { Box, Button, TextField, Typography, FormControlLabel, Checkbox, Link } from '@material-ui/core'
import LoginPageImage from 'images/login-page-image.png'
import useToggle from 'hooks/useToggle'
import { useHistory, useLocation } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper'
import { BoxLayout } from 'components/layouts/BoxLayout'
import { RoutePath } from 'constants/RoutePath'
import useLoginRedirect from 'hooks/useLoginRedirect'
import { useMutation, useQuery } from '@apollo/client'
import { LOGIN_MUTATION } from 'constants/mutations'
import { useStyles } from '../Login/styles'
import { validatePassword } from '../../../utils/validation'
import { GET_ORGANIZATION } from 'constants/queries'
import LoginPageAlternateImage from 'pages/Login/images/login-alternate.svg'
import useSubdomain from 'hooks/useSubdomain'

export const SetPassword = (): React.ReactElement => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const email = params.get('email')
  const token = params.get('token')
  const { loginRedirect } = useLoginRedirect()
  const classes = useStyles()
  const formClasses = useFormStyles()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [errors, setErrors] = useState<Array<string>>([])
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [acceptTerms, toggleAcceptTerms] = useToggle(false)

  const isResetPassword = window.location.pathname === '/client/reset-password'
  // const [setFirstTimePassword, { loading, error }] = useMutation(SET_FIRST_TIME_PASSWORD_MUTATION)

  const [login] = useMutation(LOGIN_MUTATION)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setErrors([])
    const passwordValidationDetails = validatePassword(password)
    if (passwordValidationDetails?.length) {
      setErrors(passwordValidationDetails.map(({ message }) => message))
      return
    }
    if (!acceptTerms) {
      setErrors(['Terms of service not accepted.'])
      return
    }
    if (password !== passwordConfirmation) {
      setErrors(['Password and Confirm Password must match.'])
      return
    }
    setIsSubmitting(true)
    const formData = new FormData()
    formData.append('password', password)
    formData.append('passwordConfirmation', passwordConfirmation)
    formData.append('email', email as string)
    formData.append('token', token as string)

    try {
      const path = isResetPassword ? `/api/reset_password` : `/api/set_password`
      await axios.post(path, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const resp = await login({ variables: { email, password } })
      const { credentials, subdomain, preApplicationStep, onboardingData } = resp.data.login
      loginRedirect({ credentials, subdomain, preApplicationStep, onboardingStep: onboardingData?.stepName })
    } catch (err) {
      setLoginErrorMessage(err?.response?.data?.error)
      console.log(err?.response?.data?.error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const goToLogin = () => history.push('/client/login')

  const { hasSubDomain, subDomain } = useSubdomain()

  const { data: organizationData, loading: organizationDataLoading } = useQuery(GET_ORGANIZATION, {
    variables: { subdomain: subDomain },
    skip: !hasSubDomain,
  })

  const orgImage = organizationData?.getOrganization?.loginImage?.medium || LoginPageAlternateImage

  const orgLogo = organizationData?.getOrganization?.logo?.medium

  return (
    <PageLayout hideNavBar>
      <Box className={classes.modal} mt={4}>
        <BoxLayout
          sideImage={hasSubDomain ? orgImage : LoginPageImage}
          customSideImageLoading={organizationDataLoading}
          customLogo={orgLogo}
          contentMaxWidth={480}
        >
          <Typography variant='h5'>
            {isResetPassword ? 'Reset Your Account Password' : 'Activate Your Account'}
          </Typography>

          <Typography variant='h6'>
            <br />
            {email}
          </Typography>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <TextField
              className={classes.textfield}
              InputLabelProps={{ classes: { root: classes.inputLabel } }}
              InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
              type='password'
              label='Password'
              variant='outlined'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
            />
            <TextField
              className={classes.textfield}
              InputLabelProps={{ classes: { root: classes.inputLabel } }}
              InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
              type='password'
              label='Password Confirmation'
              variant='outlined'
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              fullWidth
            />
            {!!errors?.length && (
              <ul className={formClasses.errorsList}>
                {errors.map((message) => (
                  <li key={message}>
                    <Typography color='error'>{message}</Typography>
                  </li>
                ))}
              </ul>
            )}
            {loginErrorMessage && <Typography color='error'>{loginErrorMessage}</Typography>}
            <FormControlLabel
              control={<Checkbox checked={acceptTerms} onChange={toggleAcceptTerms} name='terms' color='primary' />}
              label='By activating you are agreeing to our terms of service.'
            />
            <Box mt={2}>
              <Link color='primary' href={RoutePath.Terms} target='_blank'>
                Terms and services
              </Link>
            </Box>
            {!isResetPassword && (
              <Box mt={2}>
                <Typography variant='body2' component='span'>
                  Already activated your EXEC membership? Login&nbsp;
                  <Link href='#' onClick={goToLogin}>
                    here
                  </Link>
                </Typography>
              </Box>
            )}
            <Button type='submit' variant='contained' color='primary' size='large' fullWidth>
              {isSubmitting && <CircularProgress className={formClasses.spinner} size={24} />}
              {!isSubmitting && (isResetPassword ? 'Reset Password' : 'Set Password')}
            </Button>
          </form>
        </BoxLayout>
      </Box>
    </PageLayout>
  )
}
