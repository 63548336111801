import { Dispatch, SetStateAction, useState } from 'react'

type ToggleHook = (initialState?: boolean) => [boolean, () => void, Dispatch<SetStateAction<boolean>>]

const useToggle: ToggleHook = (initialState) => {
  const [state, setState] = useState<boolean>(initialState || false)
  const toggleState = () => setState((prevState) => !prevState)
  return [state, toggleState, setState]
}

export default useToggle
