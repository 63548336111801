import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ButtonLink from 'components/shared/ButtonLink'

interface IAbsentInfoProps {
  info: string
  buttonText?: string
  link?: string
}

const AbsentInfo: React.FC<IAbsentInfoProps> = ({ info, buttonText, link }) => {
  return (
    <>
      <Typography variant='h6' gutterBottom>
        {info}
      </Typography>
      {buttonText && link && (
        <Box mt={2}>
          <ButtonLink bold text={buttonText} to={link} />
        </Box>
      )}
    </>
  )
}

export default AbsentInfo
