export const getBooleanFieldText = (field?: boolean): string => {
  return field ? 'Yes' : 'No'
}

export const handleCamelCaseToSnake = (string: string): string => {
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const applyParamsToUrlPath = (params: Record<string, string | number> = {}, urlPath = ''): string => {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    searchParams.append(handleCamelCaseToSnake(key), params[key].toString())
  })
  return `${urlPath}?${searchParams.toString()}`
}

export const alphabeticalSortCallback = (fieldA: string, fieldB: string): number => {
  if (fieldA < fieldB) {
    return -1
  }
  if (fieldA > fieldB) {
    return 1
  }
  return 0
}

export const downloadFile = (arrayBuffer: ArrayBuffer, filename: string, extension = 'csv'): void => {
  const aLink = document.createElement('a')
  const blob = new Blob(['\ufeff', arrayBuffer])
  const objectURL = URL.createObjectURL(blob)
  aLink.download = `${filename}.${extension}`
  aLink.href = objectURL
  aLink.click()
}
