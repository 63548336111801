import React, { FC, useEffect, useState } from 'react'

export interface PaginationProps {
  currentPage: number
  totalPageCount: number | null
  onChangePage: (pageNumber: number) => void
  navigateWithoutRange?: boolean
}

const countOfVisiblePages = 5

const generateRange = (start: number, limit: number): Array<number> => {
  const res = []
  const end = start + countOfVisiblePages - 1
  const isLimitInRange = limit <= end && limit >= start
  // eslint-disable-next-line no-plusplus
  for (let i = start; i <= (isLimitInRange ? limit : end); i++) {
    res.push(i)
  }
  return res
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPageCount, onChangePage, navigateWithoutRange }) => {
  const [previousIsDisabled, setPreviousIsDisabled] = useState(true)
  const [nextIsDisabled, setNextIsDisabled] = useState(false)
  const [range, setRange] = useState<Array<number>>([])

  useEffect(() => {
    setPreviousIsDisabled(range[0] === 1)
    setNextIsDisabled(range[range.length - 1] === totalPageCount)
  }, [currentPage, range, totalPageCount])

  useEffect(() => {
    if (totalPageCount) {
      setRange(generateRange(1, totalPageCount))
    }
  }, [totalPageCount])

  const onClickNext = () => {
    if (totalPageCount) {
      setRange((previousRange) => generateRange(previousRange[countOfVisiblePages - 1] + 1, totalPageCount))
    }
  }

  const onClickPrevious = () => {
    if (totalPageCount) {
      setRange((previousRange) => generateRange(previousRange[0] - countOfVisiblePages, totalPageCount))
    }
  }

  if (navigateWithoutRange) {
    return (
      <div className='pagination-list is-flex is-justify-content-center'>
        <button
          type='button'
          className='button pagination-link previous'
          disabled={currentPage === 1}
          onClick={() => onChangePage(currentPage - 1)}
        >
          Previous
        </button>

        <button
          type='button'
          className='button pagination-link next'
          disabled={nextIsDisabled}
          onClick={() => onChangePage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    )
  }

  return (
    <div className='pagination-list is-flex is-justify-content-center'>
      <button
        type='button'
        className='button pagination-link previous'
        disabled={previousIsDisabled}
        onClick={onClickPrevious}
      >
        Previous
      </button>
      {range.map((number) => (
        <button
          type='button'
          key={number}
          className={`button pagination-link${number === currentPage ? ' is-current' : ''}`}
          onClick={() => onChangePage(number)}
        >
          {number}
        </button>
      ))}
      <button type='button' className='button pagination-link next' disabled={nextIsDisabled} onClick={onClickNext}>
        Next
      </button>
    </div>
  )
}

export { Pagination }
