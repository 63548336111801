import React, { useState, KeyboardEvent } from 'react'

export interface OrganizationFilter {
  name: string
  subdomain: string
  website: string
}

interface Props {
  applyFilters: (filters: OrganizationFilter) => void
}

const OrganizationFilters: React.FC<Props> = ({ applyFilters }) => {
  const [filters, setFilters] = useState<OrganizationFilter>({
    name: '',
    subdomain: '',
    website: '',
  })

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters(filters)
    }
  }

  const onSetFilters = (value: string, fieldName: keyof OrganizationFilter) => {
    setFilters((prevFilters) => ({ ...prevFilters, [fieldName]: value }))
  }

  return (
    <>
      <th> </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          placeholder='Name'
          onChange={(e) => onSetFilters(e.target.value, 'name')}
          value={filters.name}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          placeholder='Subdomain'
          onChange={(e) => onSetFilters(e.target.value, 'subdomain')}
          value={filters.subdomain}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          placeholder='Website'
          onChange={(e) => onSetFilters(e.target.value, 'website')}
          value={filters.website}
          onKeyDown={onKeyDown}
        />
      </th>
      <th className='is-flex is-justify-content-flex-end'>
        <button type='button' className='button' onClick={() => applyFilters(filters)}>
          <span className='icon is-small'>
            <i className='fas fa-search' />
          </span>
        </button>
      </th>
    </>
  )
}

export { OrganizationFilters }
