import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    logoBox: {
      padding: theme.spacing(0.4, 0.2),
      cursor: 'pointer',
    },
    logo: {
      height: 34,
      width: 34,
      marginRight: theme.spacing(1),
      color: '#fff',
    },
    marketplaceLogo: {
      height: 50,
    },
    text: {
      userSelect: 'none',
    },
  }
})
