import React from 'react'
import { Card, CardProps, CardMedia, Box, Container, Typography } from '@material-ui/core'
import { useStyles } from './styles'

export interface CarouselItemWithContentProps extends CardProps {
  image: string
  title?: string
  subtitle?: string
  button?: React.FC
  buttonComponent?: React.ReactNode
  useOverlay?: boolean
  overlayHero?: boolean
}

const CarouselItemWithContent: React.FC<CarouselItemWithContentProps> = ({
  image,
  title,
  subtitle,
  button: Button,
  buttonComponent,
  useOverlay,
  overlayHero,
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Card
      className={`${classes.card} ${className || ''} ${useOverlay ? classes.cardOverlay : ''}
      ${overlayHero ? classes.cardOverlayHero : ''}`}
      {...props}
    >
      {image && (
        <CardMedia
          height='100vh'
          component='img'
          alt='Carousel item'
          image={image}
          className={`${classes.cardMedia} ${useOverlay ? classes.cardMediaOverlay : ''}`}
        />
      )}
      <Box className={`${classes.content} ${classes.innerContent}`}>
        <Container maxWidth='sm' className={`${classes.innerContentContainer}`}>
          {title && (
            <Typography component='h1' variant='h4' className={`${classes.typography} ${classes.title}`}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant='body1' className={classes.typography}>
              {subtitle}
            </Typography>
          )}
          {Button && <Button />}
          {buttonComponent}
        </Container>
      </Box>
    </Card>
  )
}

export default CarouselItemWithContent
