import React from 'react'
import UAParser from 'ua-parser-js'
import { useHistory } from 'react-router-dom'
import { Box, BoxProps, NativeSelect, TextField } from '@material-ui/core'
import { Autocomplete, Value } from '@material-ui/lab'
import { displayDestinationCategory } from 'constants/DestinationCategory'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useQuery } from '@apollo/client'
import { GET_CITIES } from 'constants/queries'
import { City } from 'types/generatedGql'
import classnames from 'classnames'
import { useStyles } from './styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const WhereToGoSelect: React.FC<BoxProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const { data, loading } = useQuery(GET_CITIES)

  // TODO: revise later
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */

  const cities = data ? data.cities : []
  const browserInfoParser = new UAParser()
  const isMobileDevice = ['mobile', 'tablet'].includes(browserInfoParser?.getDevice()?.type)

  const goToPath = (event: React.ChangeEvent<any>, newVal: Value<any, any, any, any>) => {
    if (!newVal.name) return
    history.push(`/client/hotels/list/featured-city/${newVal.name!.replace(/\s+/g, '-').toLowerCase()}`)
  }

  const goToPathMobile = (event: React.ChangeEvent<any>) => {
    if (!event.target.value) return
    history.push(`/client/hotels/list/featured-city/${event.target.value!.replace(/\s+/g, '-').toLowerCase()}`)
  }

  return (
    <Box
      {...props}
      display='flex'
      justifyContent='center'
      minWidth={320}
      maxWidth={500}
      width='30%'
      margin='0 auto'
      position='relative'
    >
      {isMobileDevice ? (
        <>
          {loading && (
            <Box position='absolute' bottom='23px' zIndex={10} right='74px'>
              <CircularProgress size={10} />
            </Box>
          )}
          <NativeSelect
            id='where-to-go-native'
            onChange={goToPathMobile}
            disabled={loading}
            disableUnderline
            classes={{ select: classnames(classes.filledInput, classes.autocomplete, classes.select) }}
          >
            <option value=''>Where do you want to go?</option>
            {cities.map((city: City) => (
              <option key={city.slug} value={city.name as string}>
                {city.name}
              </option>
            ))}
          </NativeSelect>
        </>
      ) : (
        <Autocomplete
          onChange={goToPath}
          classes={{
            root: classes.autocomplete,
            inputRoot: classes.filledInput,
            option: classes.option,
            groupLabel: classes.groupLabel,
            groupUl: classes.groupUl,
            endAdornment: classes.endAdornment,
          }}
          id='where-to-go'
          groupBy={(option: City) =>
            displayDestinationCategory(option.featured ? 'Popular destinations' : option.region)
          }
          options={cities}
          fullWidth
          popupIcon={<KeyboardArrowDownIcon />}
          getOptionLabel={(option: City) => option.name as string}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Where do you want to go?'
              placeholder='Search for a city'
              variant='filled'
              classes={{ root: classes.textfield }}
              InputLabelProps={{ ...params.InputLabelProps, classes: { root: classes.inputLabel } }}
            />
          )}
        />
      )}
    </Box>
  )
}

export default WhereToGoSelect
