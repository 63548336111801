import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { UserDTO } from '../../dtos/UserDTO'
import ShowUserForm from '../../components/UserForm/ShowUserForm'
import DefaultLayout from '../../layouts/DefaultLayout'
import useCredentials from '../../hooks/useCredentials'
import { RouterPath } from '../../router/routes'
import { cancelMember, deleteMember } from '../../common/requests'
import TransactionsHistoryTable from '../../components/TransactionsHistoryTable'

const ShowMember: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { credentials, hasEditPermission } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [member, setMember] = useState<Partial<UserDTO>>({})
  const [loadDataError, setLoadDataError] = useState('')

  const loadMember = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/members/${params.id}`, {
        headers: credentials,
      })
      setMember(res.data.member)
    } catch (err) {
      setLoadDataError(err.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadMember()
  }, [loadMember])

  const deleteUser = async () => {
    if (confirm('Do you want to delete?')) {
      try {
        await deleteMember(params.id, credentials)
        history.push(RouterPath.MEMBERS_VIEW)
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  const cancelUser = async () => {
    if (confirm('Do you want to cancel?')) {
      try {
        await cancelMember(params.id, credentials)
        setMember((p) => ({ ...p, activation_status: 'Canceled' }))
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  return (
    <DefaultLayout>
      <ShowUserForm
        userData={member}
        loading={loading}
        error={loadDataError}
        onGoBack={() => history.push(RouterPath.MEMBERS_VIEW)}
        headerActions={
          <>
            {' '}
            {member.parent_id === null && hasEditPermission && (
              <Link to={`/panel/members/new?parent=${member.id}`} className='button is-link mr-2'>
                New sub user
              </Link>
            )}
            {hasEditPermission && member && (
              <Link to={`/panel/members/${member.id}/edit`} className='button is-link mr-2'>
                Edit
              </Link>
            )}
            {hasEditPermission && member && (
              <button
                type='button'
                className='button is-link mr-2'
                disabled={member.activation_status === 'Canceled'}
                onClick={cancelUser}
              >
                Cancel
              </button>
            )}
            {hasEditPermission && member && (
              <button type='button' className='button is-danger' onClick={deleteUser}>
                Delete
              </button>
            )}
          </>
        }
      />
      <TransactionsHistoryTable />
    </DefaultLayout>
  )
}

export default ShowMember
