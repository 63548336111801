import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import benefitsSlice from 'reducer/benefitsSlice'
import authSlice from './auth/authSlice'
import modalSlice from './modal/modalSlice'

const authConfig = {
  key: 'auth',
  version: 1,
  storage,
}

const persistedAuthReducer = persistReducer(authConfig, authSlice)

const rootReducer = {
  auth: persistedAuthReducer,
  modal: modalSlice,
  benefits: benefitsSlice,
}

export default rootReducer
