import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import AddPaymentMethodModal from 'pages/MyAccount/Payments/AddPaymentMethodModal'
import { GET_PAYMENT_METHOD } from 'constants/queries'
import ButtonAction from 'components/shared/ButtonAction'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PaymentSystem } from 'pages/MyAccount/Payments/const'
import { useStyles } from './styles'
import AmericanExpressImage from './images/amex.svg'
import DiscoverImage from './images/discover.svg'
import DefaultCardImage from './images/credit-card-default.svg'
import MasterCardImage from './images/mastercard.svg'
import VisaImage from './images/visa.svg'

const getPaymentSystemImage = (cardType: string) => {
  switch (cardType) {
    case PaymentSystem.AMERICAN_EXPRESS:
      return AmericanExpressImage
    case PaymentSystem.DISCOVER:
      return DiscoverImage
    case PaymentSystem.MASTERCARD:
      return MasterCardImage
    case PaymentSystem.VISA:
      return VisaImage
    default:
      return DefaultCardImage
  }
}

const Payments: React.FC = () => {
  const classes = useStyles()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { data, error, loading, refetch } = useQuery(GET_PAYMENT_METHOD)
  const paymentMethod = data?.currentUser?.paymentMethod

  const cardTypeName = paymentMethod?.cardType
    ?.replace(/_/g, ' ')
    .replace(/ [a-z]|^([a-zA-Z])/g, (letter: string) => `${letter.toUpperCase()}`)

  return (
    <Box py={4}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h4'>Payment methods</Typography>
        {!paymentMethod && !loading && (
          <ButtonAction
            type='button'
            text='Add payment method'
            size='large'
            bold
            onClick={() => setIsModalOpened(true)}
          />
        )}
      </Box>

      {error && <Typography className='has-text-danger my-2'>{error?.message || 'Something went wrong'}</Typography>}

      {loading ? (
        <Box mt={4} display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={4} p={2} className={classes.cardBox}>
          {paymentMethod ? (
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex'>
                <img className={classes.logo} src={getPaymentSystemImage(paymentMethod.cardType)} alt='logo' />
                <Box display='flex' flexDirection='column' alignItems='space-between'>
                  <Typography component='div' style={{ lineHeight: '22px' }}>
                    {cardTypeName}
                  </Typography>
                  <Typography component='div' style={{ fontSize: '14px' }}>
                    Ending in {paymentMethod.lastFourDigitsOfCard} &#8226; Exp: {paymentMethod.expirationMonth}/
                    {paymentMethod.expirationYear}
                  </Typography>
                </Box>
              </Box>
              <ButtonAction text='Edit/Update' onClick={() => setIsModalOpened(true)} />
            </Box>
          ) : (
            <Box className={classes.emptyText}>No payment method has been added to your account</Box>
          )}
        </Box>
      )}

      {isModalOpened && (
        <AddPaymentMethodModal
          paymentMethod={paymentMethod}
          refetchPaymentMethods={refetch}
          isOpened={isModalOpened}
          closeModal={() => setIsModalOpened(false)}
        />
      )}
    </Box>
  )
}

export default Payments
