import React from 'react'
import { Backdrop, Card, Fade, IconButton, Modal, CardContent, CardActions, ModalProps } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './styles'

export interface DefaultModalProps extends Omit<ModalProps, 'open' | 'children'> {
  runAfterClose?: () => void
  header?: React.ReactNode
  content: React.ReactNode
  actions?: React.ReactNode
  width?: number | string
}

export interface DefaultModalContentProps extends DefaultModalProps {
  toggleModal: () => void
  isModalOpen: boolean
}

const DefaultModalContent: React.FC<DefaultModalContentProps> = ({
  actions,
  header,
  content,
  runAfterClose = () => {},
  className,
  toggleModal,
  isModalOpen,
  width,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Modal
      className={`${className || ''} ${classes.modal}`}
      open={isModalOpen}
      onClose={() => {
        toggleModal()
        runAfterClose()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={isModalOpen}>
        <Card className={classes.card} style={{ ...(width ? { width } : {}) }}>
          <>
            <IconButton
              onClick={() => {
                toggleModal()
                runAfterClose()
              }}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
            {header}
            <CardContent className={classes.content}>{content}</CardContent>
            {actions && <CardActions className={classes.actions}>{actions}</CardActions>}
          </>
        </Card>
      </Fade>
    </Modal>
  )
}

export default DefaultModalContent
