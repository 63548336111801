import { makeStyles } from '@material-ui/core'
import HeaderBackground from 'images/header-background.svg'

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      '& section + hr:last-of-type': {
        display: 'none',
      },
    },
    titleBox: {
      backgroundImage: `url(${HeaderBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      color: '#fff',
      minHeight: '130px',
      zIndex: 100,
    },
    negativeOffsetTop: {
      marginTop: theme.spacing(-10),
      backgroundColor: '#fff',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(-8),
      },
    },
    grayTheme: {
      backgroundColor: '#828282',
    },
  }
})
