import React from 'react'
import { Container, Box, BoxProps, Typography } from '@material-ui/core'
import Footer from 'components/shared/Footer'
import NavigationBar from 'components/shared/NavigationBar'
import { useSelector } from 'react-redux'
import { ApolloError } from '@apollo/client'
import { useStyles } from './styles'

export interface PageLayoutProps extends BoxProps {
  transparentNavigation?: boolean
  externalNavigation?: boolean
  negativeOffsetTop?: boolean
  title?: string
  hero?: React.ReactNode
  error?: ApolloError
  hideNavBar?: boolean
  curlyHeader?: boolean
  hideNavLinks?: boolean
  grayTheme?: boolean
}

const PageLayout: React.FC<PageLayoutProps> = ({
  transparentNavigation,
  externalNavigation,
  negativeOffsetTop,
  hero,
  title,
  className,
  hideNavBar,
  curlyHeader,
  hideNavLinks,
  error,
  children,
  grayTheme,
  ...props
}) => {
  const auth = useSelector((state) => state.auth)
  const classes = useStyles()

  return (
    <Box
      display='flex'
      minHeight='100vh'
      flexDirection='column'
      className={grayTheme ? classes.grayTheme : undefined}
      {...props}
    >
      <Box className={curlyHeader ? classes.titleBox : ''}>
        {!hideNavBar && (
          <NavigationBar
            isTransparent={transparentNavigation}
            hideNavLinks={hideNavLinks}
            externalNavBar={!auth.credentials || externalNavigation}
          />
        )}

        {title && (
          <Box mt={6} mb={12}>
            <Container maxWidth='lg'>
              <Typography variant='h4' component='h2' color='textSecondary'>
                {title}
              </Typography>
            </Container>
          </Box>
        )}
      </Box>

      {hero}

      <Container
        maxWidth='lg'
        component='main'
        className={`${classes.container} ${negativeOffsetTop ? classes.negativeOffsetTop : ''} ${className || ''}`}
      >
        <>
          {error && (
            <Typography variant='h4' component='h2' className='has-text-danger my-6'>
              {error?.message || 'Something went wrong'}
            </Typography>
          )}
          {children}
        </>
      </Container>
      <Footer />
    </Box>
  )
}

export default PageLayout
