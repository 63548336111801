import React from 'react'
import { Box, BoxProps, Avatar, Collapse, ClickAwayListener, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import NavigationLink from 'components/shared/NavigationLink'
import { RoutePath } from 'constants/RoutePath'
import useToggle from 'hooks/useToggle'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { setAuth } from 'reducer/auth/authSlice'
import { LOGOUT_MUTATION } from 'constants/mutations'
import { useStyles } from './styles'

export interface SettingsDropdownProps extends BoxProps {
  alt: string
  src?: string
  avatarClassName?: string
}

const SettingsDropdown: React.FC<SettingsDropdownProps> = ({ alt, src, avatarClassName, ...props }) => {
  const classes = useStyles()
  const [isMenuOpen, toggleMenu, setIsMenuOpen] = useToggle(false)
  const closeMenu = () => setIsMenuOpen(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const onLogoutSuccess = () => {
    dispatch(setAuth(null))
    history.push('/client/login')
  }

  const [logout] = useMutation(LOGOUT_MUTATION, { onCompleted: onLogoutSuccess })

  const handleLogout = (event) => {
    event.preventDefault()
    logout()
  }

  return (
    <Box {...props}>
      <ClickAwayListener onClickAway={closeMenu}>
        <Box position='relative'>
          <Avatar className={`${classes.avatar} ${avatarClassName || ''}`} src={src} alt={alt} onClick={toggleMenu}>
            {alt}
          </Avatar>
          <Collapse in={isMenuOpen} className={classes.dropdown}>
            <Box display='flex' flexDirection='column' justifyContent='center' onClick={closeMenu}>
              <NavigationLink text='Account' to={RoutePath.MyAccount} />
              <Divider className={classes.dividerHorizontal} color='primary' />
              <NavigationLink text='Log out' to='#' onClick={handleLogout} />
            </Box>
          </Collapse>
        </Box>
      </ClickAwayListener>
    </Box>
  )
}

export default SettingsDropdown
