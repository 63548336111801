import React from 'react'
import { Box, BoxProps, Typography } from '@material-ui/core'
import { TypographyColors } from 'types'
import classnames from 'classnames'
import { useStyles } from './styles'

interface SectionSecondaryProps extends Omit<BoxProps, 'title'> {
  title?: string | React.ReactNode
  titleColor?: TypographyColors
  hideTitleSm?: boolean
  backgroundColor?: string
  borderColor?: string
  fullWidthSm?: boolean
  fixedOnBottom?: boolean
}

const SectionSecondary: React.FC<SectionSecondaryProps> = ({
  title,
  titleColor,
  hideTitleSm,
  backgroundColor,
  borderColor,
  fullWidthSm,
  fixedOnBottom,
  children,
  className,
  ...props
}) => {
  const classes = useStyles({ backgroundColor, borderColor })

  return (
    <Box
      component='section'
      className={`${classes.section} ${className || ''} ${fixedOnBottom ? classes.fixedOnBottom : ''}`}
      {...props}
    >
      {title && (
        <Box pb={2} mb={2} className={classnames(classes.header, hideTitleSm && classes.hideOnSm)}>
          <Typography variant='h6' {...(titleColor ? { color: titleColor } : {})}>
            {title}
          </Typography>
        </Box>
      )}
      <Box className={fullWidthSm ? classes.fullWidthSm : ''}>{children}</Box>
    </Box>
  )
}

export default SectionSecondary
