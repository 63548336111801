import React, { useState } from 'react'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
import OrganizationForm from '../../components/OrganizationForm'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'

const NewOrganization: React.FC = () => {
  const { credentials } = useCredentials()
  const history = useHistory()

  const [formData, setFormData] = useState({
    name: '',
    subdomain: '',
    description: '',
    website: '',
  })

  const [validationErrors, setValidationErrors] = useState({})

  const handleChange = (newData) => {
    setFormData(newData)
  }

  const handleSubmit = async (data) => {
    try {
      await axios.post('/api/panel/organizations', data, {
        headers: credentials,
      })
      history.goBack()
    } catch (error) {
      setValidationErrors(error.response?.data)
    }
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <OrganizationForm
        title='New Organization'
        formData={formData}
        errors={validationErrors}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </DefaultLayout>
  )
}

export default NewOrganization
