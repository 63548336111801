import React from 'react'
import { CityDTO } from 'panel/dtos/CityDTO'
import { CityErrorDTO } from 'panel/dtos/CityErrorDTO'
import { Phrases } from 'constants/phrases'
import { regions } from '../const'

interface CityFormState {
  city: CityDTO
  handleSubmit: () => void
  handleChange: (data: CityDTO) => void
  errors: CityErrorDTO
}

const CityForm: React.FC<CityFormState> = ({ city, handleSubmit, handleChange, errors }): JSX.Element => {
  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    handleSubmit()
  }

  const onUploadClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // possibility to load same file again
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <form onSubmit={onSubmit} autoComplete='off'>
      <div className='field'>
        <label className='label'>Name*</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            required
            value={city.name}
            onChange={(event) => handleChange({ ...city, name: event.target.value })}
          />
        </div>
        {errors.name &&
          errors.name.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              Name {message}
            </p>
          ))}
      </div>
      <div className='field'>
        <label htmlFor='name' className='label'>
          Region*
          <div className='control'>
            <div className='select'>
              <select value={city.region} onChange={(event) => handleChange({ ...city, region: event.target.value })}>
                <option value=''>Select region...</option>
                {regions.map(({ name }) => (
                  <option value={name}>{name}</option>
                ))}
              </select>
            </div>
          </div>
        </label>
        {errors.region &&
          errors.region.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              Region {message}
            </p>
          ))}
      </div>
      <div className='field'>
        <div className='control'>
          <label className='checkbox' htmlFor='featured'>
            <input
              id='featured'
              name='featured'
              type='checkbox'
              checked={city.featured}
              onChange={(event) => handleChange({ ...city, featured: event.target.checked })}
              onClick={onUploadClick}
            />{' '}
            Featured
          </label>
        </div>
      </div>
      <div className='field'>
        <label className='label' htmlFor='image'>
          Image
          <div className='control'>
            <div className='file'>
              <input
                id='image'
                name='image'
                type='file'
                className='file-input'
                accept='image/*'
                onChange={(event) => {
                  if (event.target.files) {
                    handleChange({ ...city, image: event.target.files[0] })
                  }
                }}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='fas fa-upload' />
                </span>
                <span className='file-label'>Choose a file…</span>
              </span>
            </div>
          </div>
        </label>
      </div>
      {city.image_url && (
        <div className='field'>
          <div className='columns'>
            <div className='column is-one-third'>
              <figure className='image is-4by3'>
                <img src={city.image_url} alt='benefit' />
              </figure>
            </div>
          </div>
        </div>
      )}
      <div className='field'>
        <button className='button is-primary' type='submit'>
          Save
        </button>
        {!!errors && !!Object.values(errors).length && <div className='has-text-danger mt-2'>{Phrases.FormError}</div>}
      </div>
    </form>
  )
}

export default CityForm
