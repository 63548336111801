import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    gridWrapper: {
      marginRight: theme.spacing(-6),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(-4),
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    grid: {
      margin: theme.spacing(-3),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(-2),
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: 0,
        width: 'max-content',
      },
    },
    gridItem: {
      padding: theme.spacing(1),
      height: '45vh',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        height: '30vh',
        marginLeft: theme.spacing(2),
        display: 'inline-block',
      },
    },
    short: {
      width: '432px',
      [theme.breakpoints.down('md')]: {
        width: '300px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
    standard: {
      width: '272px',
    },
    long: {
      height: '378px',
      [theme.breakpoints.down('md')]: {
        height: '296px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '216px',
      },
    },
    carouselWidth: {
      [theme.breakpoints.down('sm')]: {
        width: '80vw',
      },
    },
  }
})
