import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    select: {
      backgroundColor: theme.palette.background.paper,
      color: 'black',
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: '#bdbdbd !important',
    },
    topLabel: {
      color: theme.palette.common.black,
    },
    selectRoot: {
      maxHeight: '56px',
    },
  }
})
