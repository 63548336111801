import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    bodyLayoutWrapper: {
      padding: theme.spacing(2, 2, 0, 2),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 1, 0, 1),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0.5, 0, 0.5),
      },
    },
  }
})
