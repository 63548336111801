/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import CircularProgress from '@material-ui/core/CircularProgress'

// InfiniteScroll library has a mediocre support for TS so we had to force an 'any' type
const InfiniteScroller: React.FC<any> = ({ children, hasMore, fetchMore, isFetching, ...props }) => {
  return (
    <InfiniteScroll
      pageStart={0}
      hasMore={hasMore}
      loadMore={fetchMore}
      loader={<div/>}
      {...props}
    >
      {children}
    </InfiniteScroll>
  )
}

export default InfiniteScroller
