import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textfield: {
      marginBottom: theme.spacing(2),
      color: 'black',
    },
    inputNotchedOutline: {
      borderColor: '#E2E2E2 !important',
    },
    inputLabel: {
      color: '#777',
    },
    input: {
      color: '#000',
    },
    h4: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    submitButton: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
    },
    subdomainTextBox: {
      '& > p': {
        [theme.breakpoints.down('md')]: {
          fontSize: '14px !important',
        },
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    accessTextBox: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
