import React, { useState, Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { BenefitDTO, BenefitImage } from '../../dtos/BenefitDTO'
import MediaCard from '../../components/MediaCard'
import './HomepageManagement.scss'
import CircularSpinner from '../../components/CircularSpinner'
import ErrorMessage from '../../components/ErrorMessage'

interface Props {
  benefits: Array<BenefitDTO>
  setBenefits: Dispatch<SetStateAction<BenefitDTO[]>>
  title: string
  onSaveOrder: () => void
  loading?: boolean
  updating?: boolean
  error?: string
  success?: boolean
}

const getImageSrc = (windowWidth: number, image?: BenefitImage): string | undefined => {
  if (!image) {
    return undefined
  }
  if (windowWidth <= 780) {
    return image.mobile
  }
  return image.thumbnail
}

const DraggableSection: React.FC<Props> = ({
  title,
  benefits,
  setBenefits,
  loading,
  updating,
  error,
  success,
  onSaveOrder,
}): JSX.Element => {
  const history = useHistory()
  const [draggableId, setDraggableId] = useState<string | null>(null)
  const { windowWidth } = useGetWindowSize()

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDraggableId((e.target as HTMLDivElement).id)
  }

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDraggableId(null)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!draggableId) {
      return
    }

    const currentElement: HTMLElement | null = (e.target as HTMLDivElement).classList.contains('draggableCard')
      ? (e.target as HTMLDivElement)
      : (e.target as HTMLDivElement).closest('.draggableCard')
    const currentElementId = currentElement?.id
    if (draggableId === currentElementId) {
      return
    }

    const draggableBenefit = benefits.find(({ id }) => id === Number(draggableId))
    const currentBenefitIndex = benefits.findIndex(({ id }) => id === Number(currentElementId))

    const newBenefits = benefits.filter(({ id }) => id !== Number(draggableId))
    if (draggableBenefit) {
      newBenefits.splice(currentBenefitIndex, 0, draggableBenefit)
    }
    setBenefits(newBenefits)
    setDraggableId(null)
  }

  return (
    <div
      className='mb-6'
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => e.preventDefault()}
      onDragStart={(e) => handleDragStart(e)}
      onDragEnd={(e) => handleDragEnd(e)}
    >
      <h4 className='is-size-5 has-text-centered mb-4'>{title}</h4>
      {loading ? (
        <CircularSpinner />
      ) : (
        <>
          <div className='columns is-multiline is-variable is-2'>
            {benefits.map((benefit) => (
              <div key={benefit.id} className='column is-3-tablet is-2-desktop'>
                <MediaCard
                  id={String(benefit.id)}
                  draggable='true'
                  className='draggableCard homepage-card is-clickable'
                  imageSrc={getImageSrc(windowWidth as number, benefit.image)}
                  onClick={() => history.push(`/panel/benefits/${benefit.id}`)}
                  // footer={
                  //   hasEditPermission && (
                  //     <button type='button' className='button card-footer-item' onClick={() => {}}>
                  //       Remove
                  //     </button>
                  //   )
                  // }
                >
                  <h4 className='is-size-6 mb-0'>{benefit.name}</h4>
                </MediaCard>
              </div>
            ))}
          </div>
          <div className='is-flex mt-4 is-justify-content-center' style={{ position: 'relative' }}>
            <button type='button' className={`button is-primary ${updating ? 'is-loading' : ''}`} onClick={onSaveOrder}>
              Save
            </button>
            {success && (
              <span className='has-text-primary' style={{ position: 'absolute', top: '-27px' }}>
                Updated successfully!
              </span>
            )}
          </div>
        </>
      )}
      {error && <ErrorMessage text={error} />}
    </div>
  )
}

export default DraggableSection
