import { useHistory, useLocation } from 'react-router-dom'

interface Props<T> {
  setQueryParam: (name: string, value: string) => void
  setQueryParams: (dataObj: T) => void
}

const useSetQueryParam = <T extends Record<string, any>>(): Props<T> => {
  const history = useHistory()
  const location = useLocation()

  const setQueryParam = (name: string, value: string) => {
    const params = new URLSearchParams(location.search)
    params.set(name, value)
    history.push({
      pathname: location.pathname,
      search: `${params.toString()}`,
    })
  }

  const setQueryParams = (dataObj: T) => {
    const params = new URLSearchParams(location.search)
    Object.keys(dataObj).forEach((key: string) => {
      if (dataObj[key]) {
        params.set(key, dataObj[key])
      } else {
        params.delete(key)
      }
    })
    history.push({
      pathname: location.pathname,
      search: `?${params.toString()}`,
    })
  }

  return {
    setQueryParam,
    setQueryParams,
  }
}

export default useSetQueryParam
