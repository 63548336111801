import React from 'react'
import { Box, Grid, Container } from '@material-ui/core'
import ApplicationSuccessMain from 'pages/PreApplication/ApplicationSuccess/ApplicationSuccessMain'

const ApplicationSuccess: React.FC = () => {
  return (
    <Container maxWidth='md'>
      <Grid container spacing={0} direction='column' alignItems='center' justify='center'>
        <h2>Thank you.</h2>
        <Box mt={2} textAlign='center'>
          Your application has successfully been submitted and is under review.
        </Box>
        <Box mb={6} textAlign='center'>
          Please allow our Membership team 1-2 business days to review your application. Upon acceptance, someone from
          our Concierge team will reach out to you via email with further instructions to activate your EXEC Membership.
        </Box>
        <ApplicationSuccessMain isPreApplication />
      </Grid>
    </Container>
  )
}

export default ApplicationSuccess
