import React, { FC } from 'react'
import { UserDTO } from '../../dtos/UserDTO'
import { DefaultShowForm, DefaultShowFormProps } from '../DefaultShowForm'
import { getMemberShowFormData } from '../../pages/Members/const'

interface Props extends Omit<DefaultShowFormProps, 'headerName' | 'formData'> {
  userData: Partial<UserDTO>
  loading: boolean
}

const UserForm: FC<Props> = ({ userData, loading, ...props }) => {
  return (
    userData && (
      <DefaultShowForm
        headerName={`${userData.first_name} ${userData.last_name}`}
        formData={getMemberShowFormData(userData)}
        loading={loading}
        {...props}
      />
    )
  )
}

export default UserForm
