import React from 'react'

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

interface Props extends AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success'
  onClose: () => void
}

const Alert: React.FC<Props> = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default Alert
