import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import UserForm from '../../../components/UserForm/UserForm'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { UserDTO } from '../../../dtos/UserDTO'
import ErrorMessage from '../../../components/ErrorMessage'
import { memberInitialState } from '../../const'
import { UserRole } from '../../../const'
import useCredentials from '../../../hooks/useCredentials'
import BackButton from '../../../components/BackButton'

const EditOrganizationUser: FC = () => {
  const params = useParams<{ id: string; userId: string }>()
  const history = useHistory()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<UserDTO>>(memberInitialState)
  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadUserData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/organizations/${params.id}/users/${params.userId}/edit`, {
        headers: credentials,
      })
      setFormData(res.data)
    } catch (e) {
      setLoadDataError(e.message)
    }
  }, [credentials, params.id, params.userId])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      setSubmitting(true)
      await axios.put(`/api/panel/organizations/${params.id}/users/${params.userId}`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <div className='columns'>
        <div className='column is-half'>
          <div className='mb-4'>
            <BackButton />
          </div>
          <h4 className='is-size-4 mb-4'>Edit User</h4>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            roles={[UserRole.Member]}
            isEdit
            onChange={handleChange}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditOrganizationUser
