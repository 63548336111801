import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  cardMedia: {
    position: 'relative',
    objectFit: 'cover',
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    minHeight: '100%',
  },
  cardOverlay: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
  },
  cardMediaOverlay: {
    position: 'relative',
    zIndex: -1,
  },
})
