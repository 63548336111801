import Section from 'components/shared/Section'
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from 'pages/MyAccount/styles'
import Card from 'pages/Card'
import { User } from 'types/generatedGql'
import moment from 'moment'
import ButtonAction from 'components/shared/ButtonAction'
import { RoutePath } from 'constants/RoutePath'
import { Link as RouterLink } from 'react-router-dom'
import { DayFormat } from 'const'

interface Props {
  currentUser?: User
  userFullName: string
}

export const AccountHome: React.FC<Props> = ({ currentUser, userFullName }) => {
  const classes = useStyles()
  const [isCardModalOpened, setCardModalOpened] = useState(false)

  if (!currentUser) return null

  return (
    <Section>
      <Box display='flex' p={4} className={classes.accountHomeWrapper}>
        <Box
          display='flex'
          flex='1'
          flexDirection='column'
          justifyContent='space-between'
          pr={4}
          className={classes.leftBox}
        >
          <div>
            <Box pb={4} className={classes.borderBottomBox}>
              <Box mb={2}>
                <Typography variant='h4'>{userFullName}</Typography>
              </Box>

              <Typography variant='body2'>
                Member of {currentUser?.organization?.name} since {moment(currentUser?.createdAt).format('MMMM YY')}
              </Typography>
            </Box>

            <Box py={4}>
              <Box p={1} display='flex' justifyContent='space-between' className={classes.membershipInfoWrapper}>
                <Typography variant='body2'>Membership</Typography>
                <Typography variant='body2'>#{currentUser.membershipId}</Typography>
              </Box>
            </Box>
          </div>

          <Box>
            <ButtonAction text='View digital card' type='button' onClick={() => setCardModalOpened(true)} />
          </Box>
        </Box>

        <Box pl={4} flex='1' className={classes.rightBox}>
          <Box pb={4} className={classes.borderBottomBox}>
            <Box mb={2}>
              <Typography variant='h4'>Account overview</Typography>
            </Box>

            <Typography variant='body2'>
              Last login: {currentUser?.lastSignInAt ? moment(currentUser?.lastSignInAt).format(DayFormat) : '-'}
            </Typography>
          </Box>

          <Box pt={4}>
            <Grid container spacing={0}>
              <Grid item md={4} className={classes.overviewItem}>
                <Typography variant='body2'>Affiliation</Typography>
                <Box pt={2} className={classes.overviewValue}>
                  {currentUser?.organization?.name}
                </Box>
              </Grid>

              <Grid item md={4} className={classes.overviewItem}>
                <Typography variant='body2'>Redemptions</Typography>
                <Box pt={2} className={classes.overviewValue}>
                  {currentUser?.redemptionsCount || 0}
                </Box>
              </Grid>

              <Grid item md={4} className={classes.overviewItem}>
                <Typography variant='body2'>Sub-accounts</Typography>
                <Box py={2} className={classes.overviewValue}>
                  {currentUser?.subAccountCount || 0}/
                  <Typography variant='body2' color='primary' component='span'>
                    {currentUser?.maxSubAccountCount || 0}
                  </Typography>
                </Box>
                <Typography component={RouterLink} to={`${RoutePath.MyAccount}/sub-accounts`} color='primary'>
                  Manage
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Card isOpened={isCardModalOpened} closeModal={() => setCardModalOpened(false)} />
    </Section>
  )
}
