import React from 'react'
import { Card, CardMedia, Typography, Box } from '@material-ui/core'
import Label from 'components/shared/Label'
import { Link, useHistory } from 'react-router-dom'
import { BenefitPreviewDTO } from 'types'
import { getBenefitInfo } from 'utils'
import { useStyles } from './styles'

interface BenefitPreviewProps {
  useOverlay?: boolean
  benefit: BenefitPreviewDTO
}

const BenefitPreview: React.FC<BenefitPreviewProps> = ({ benefit, useOverlay, className, ...props }) => {
  const { city, images, category, name, slug } = benefit
  const classes = useStyles()
  const history = useHistory()
  const info = getBenefitInfo(benefit)

  const goToBenefit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.push(`/client/benefits/${slug}/details`)
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={goToBenefit}>
      <Card className={`${classes.card} ${className || ''}`} {...props}>
        {images[0] && name && (
          <CardMedia component='img' alt={name} image={images[0]} title={name} className={classes.cardMedia} />
        )}
        <div className={classes.cardOverlay}>
          <Box position='absolute' bottom={0} left={0} right={0} m={2} display='flex' justifyContent='space-between'>
            <Box display='flex' flexDirection='column' justifyContent='flex-end'>
              {name && (
                <Typography variant='body1' component='div' color='textSecondary'>
                  {name}
                </Typography>
              )}
              {city && (
                <Typography variant='body1' component='div' color='textSecondary'>
                  {city}
                </Typography>
              )}
              {info && (
                <Typography variant='body2' color='primary'>
                  {info}
                </Typography>
              )}
            </Box>
            {category && (
              <Box display='flex' alignItems='flex-end'>
                <Label text={category} className={classes.categoryLabel} />
              </Box>
            )}
          </Box>
        </div>
      </Card>
    </Link>
  )
}

export default BenefitPreview
