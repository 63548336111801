import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './styles'

export interface ButtonActionProps extends ButtonProps {
  text: string
  bold?: boolean
  loading?: boolean
}

const ButtonAction: React.FC<ButtonActionProps> = ({ text, bold, loading, className = '', ...props }) => {
  const classes = useStyles()

  return (
    <Button variant='contained' color='primary' className={`${className} ${bold ? classes.bold : ''}`} {...props}>
      {text}
      {loading && <CircularProgress size={16} style={{ color: '#fff' }} />}
    </Button>
  )
}

export default ButtonAction
