import React from 'react'
import { Container, Box, Typography } from '@material-ui/core'
import { useStyles } from './styles'

interface Props {
  title: string
  description?: string
}
const FormWrapper: React.FC<Props> = ({ children, title, description }) => {
  const classes = useStyles()
  return (
    <Container maxWidth='md'>
      {description && (
        <Box mb={4}>
          <Typography variant='h5'>{description}</Typography>
        </Box>
      )}
      <div className={classes.formHeaderContainer}>
        <Typography variant='h5' className={classes.formHeader}>
          {title}
        </Typography>
        <div className={classes.divider} />
      </div>
      <Box className={classes.formBody}>{children}</Box>
    </Container>
  )
}

export default FormWrapper
