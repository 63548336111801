import React, { useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import axios from 'axios'
import LoginPageImage from 'images/login-page-image.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link as RouterLink } from 'react-router-dom'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper'
import { BoxLayout } from 'components/layouts/BoxLayout'
import { RoutePath } from 'constants/RoutePath'
import { useStyles } from '../Login/styles'

export const ForgotPasword = (): React.ReactElement => {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)
    const formData = new FormData()
    formData.append('email', email)

    try {
      await axios.post(`/api/forgot_password`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setShowSuccess(true)
    } catch (err) {
      console.log(err)
      setError(err?.response?.data?.error)
      setIsSubmitting(false)
    }
  }

  return (
    <PageLayout hideNavBar>
      <Box className={classes.modal} mt={4}>
        <BoxLayout sideImage={LoginPageImage}>
          <Typography variant='h4' className={classes.h4}>
            Forgot Password
          </Typography>

          {showSuccess ? (
            <Box mt={2}>
              <Typography>An reset password email will be sent to you shortly!</Typography>
              <Box mt={2}>
                <Typography variant='body2' color='primary' component={RouterLink} to={RoutePath.Login}>
                  Take me back to log in
                </Typography>
              </Box>
            </Box>
          ) : (
            <form noValidate autoComplete='off' onSubmit={handleSubmit}>
              <TextField
                className={classes.textfield}
                InputLabelProps={{ classes: { root: classes.inputLabel } }}
                InputProps={{ classes: { root: classes.input, notchedOutline: classes.inputNotchedOutline } }}
                type='text'
                label='Username or Email'
                variant='outlined'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
              />
              {error && <Typography color='error'>{error}</Typography>}
              <Button
                className={classes.loginButton}
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                fullWidth
              >
                {isSubmitting ? (
                  <CircularProgress className={formClasses.spinner} size={24} />
                ) : (
                  'Send Me Reset Password Instructions'
                )}
              </Button>
            </form>
          )}
        </BoxLayout>
      </Box>
    </PageLayout>
  )
}
