import { BenefitDTO } from '../../dtos/BenefitDTO'

export const applyFormData = (benefit: BenefitDTO): FormData => {
  const formData = new FormData()
  formData.append('additional_information', benefit.additional_information || '')
  formData.append('excluded_organizations', JSON.stringify(benefit.excluded_organizations))
  formData.append('included_organizations', JSON.stringify(benefit.included_organizations))
  formData.append('start_date', benefit.start_date || '')
  formData.append('end_date', benefit.end_date || '')
  formData.append('address_1', benefit.address_1 || '')
  formData.append('address_2', benefit.address_2 || '')
  formData.append('benefits', JSON.stringify(benefit.benefits.map(({ value }) => value)))
  formData.append('brand_id', String(benefit.brand_id))
  formData.append('category_id', String(benefit.category_id))
  formData.append('other_rate_offer', benefit.other_rate_offer || '')
  formData.append('city_id', String(benefit.city_id))
  formData.append('country', benefit.country)
  formData.append('description', benefit.description)
  formData.append('short_description', benefit.short_description)
  formData.append('featured', benefit.featured.toString())
  formData.append('name', benefit.name)
  formData.append('postal_code', benefit.postal_code || '')
  formData.append('region', benefit.region || '')
  formData.append('terms_and_condition', benefit.terms_and_condition || '')
  formData.append('benefit_summary', benefit.benefit_summary || '')
  formData.append('redemption_type', benefit.redemption_type || '')
  formData.append('redemption_code', benefit.redemption_code || '')
  formData.append('redemption_code_csv', benefit.redemption_code_csv || '')
  formData.append('redemption_link', benefit.redemption_link || '')
  formData.append('redemption_instruction', benefit.redemption_instruction || '')
  formData.append('active', String(benefit.active))
  formData.append('is_popular', String(benefit.is_popular))
  formData.append('is_hero', String(benefit.is_hero))
  formData.append('is_marketplace', String(benefit.is_marketplace))
  formData.append('website', benefit.website || '')
  formData.append(
    'rates',
    JSON.stringify(
      benefit.rates.map(({ category, exec_rate, standard_rate }) => ({ category, exec_rate, standard_rate }))
    )
  )
  if (benefit.fileImages && benefit.fileImages.length > 0) {
    benefit.fileImages.forEach((file: File) => {
      formData.append(`images[]`, file)
    })
  } else {
    formData.append('no_images', String(true))
  }
  return formData
}
