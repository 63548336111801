/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useState, useEffect } from 'react'
import BenefitShowcase from 'components/shared/BenefitShowcase'
import Section from 'components/shared/Section'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import PageLayout from 'components/layouts/PageLayout'
import Hero from 'components/shared/Hero'
// @ts-ignore
import HotelsHome from 'images/hotelshome.jpeg'
import MapTrifoldIcon from 'images/map-trifold.svg'
import ListIcon from 'images/list-bullets.svg'
import WhereToGoSelect from 'components/shared/WhereToGoSelect'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_HOTELS_BY_CITY, GET_CATEGORY, GET_CITY, GET_MAP_MARKERS } from 'constants/queries'
import { Benefit, CollectionMetadata } from 'types/generatedGql'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { Box, Button, makeStyles } from '@material-ui/core'
import MarkerBottomIcon from 'images/marker-bottom.svg'
import { ChangeEventValue } from 'google-map-react'
import { HotelsMap } from 'components/shared/HotelsMap/HotelsMap'

interface Params {
  city: string
}

export interface Marker {
  lat: number
  lng: number
  name: string
  path: string
}

const useStyles = makeStyles(() => {
  return {
    mapButton: {
      height: 56,
      borderRadius: 28,
      padding: '0 32px',
      backgroundColor: '#4F4F4F',
      color: '#fff',
      fontSize: 20,
      boxShadow: 'none',
      position: 'relative',
      zIndex: 999,

      '& img': {
        marginLeft: 16,
      },
    },
    marker: {
      backgroundColor: '#fff',
      minWidth: 86,
      padding: 6,
      textAlign: 'center',
      border: '1px solid black',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
    section: {
      position: 'static',
    },
    main: {
      width: '100%',
      maxWidth: '100%',
      position: 'relative',
    },
  }
})

const PAGE_LIMIT = 25

const HotelsFeaturedCities: React.FC = () => {
  const { city } = useParams<Params>()
  const [activeCity, setActiveCity] = useState(city)
  const classes = useStyles()
  const history = useHistory()
  const [hotels, setHotels] = useState<Array<Benefit>>([])
  const { isTablet } = useGetWindowSize()
  const [isSorting, setIsSorting] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [metadata, setMetadata] = useState<Partial<CollectionMetadata>>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
  })
  const [isFetching, setIsFetching] = useState(true)
  const [isMapView, setMapView] = useState(false)

  const [dynamicMarkers, setDynamicMarkers] = useState<Marker[]>([])

  const onFetchSuccess = (data: any) => {
    setTotalResults(data.getHotelsByCity.metadata.totalCount)
    setCurrentPage(data.getHotelsByCity.metadata.currentPage)
    hotels.push(...data.getHotelsByCity.collection)
    setHotels(hotels)
    setIsFetching(false)
  }

  const currentMarkers: Marker[] = hotels?.map(
    (item) =>
      ({
        lat: item.latitude,
        lng: item.longitude,
        name: item.name,
        path: `/client/benefits/${item.slug}/details`,
      } as Marker)
  )

  const [getHotelsByCity, { error: hotelsByCityError }] = useLazyQuery(GET_HOTELS_BY_CITY, {
    onCompleted: onFetchSuccess,
  })

  const [getMapMarkers] = useLazyQuery(GET_MAP_MARKERS, {
    onCompleted: (data) => {
      const newMarkers = data.getHotelsByCoords?.collection?.map(
        (item: Benefit) =>
          ({
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
            path: `/client/benefits/${item.slug}/details`,
          } as Marker)
      )
      setDynamicMarkers(newMarkers)
    },
  })

  const [getCity, { data: cityData, error }] = useLazyQuery(GET_CITY)

  const [getCategory, { data: categoryData }] = useLazyQuery(GET_CATEGORY)

  const fetchCity = useCallback(() => {
    getCity({ variables: { slug: city } })
  }, [city, getCity])

  const fetchHotels = useCallback(
    (page: number) => {
      getHotelsByCity({ variables: { slug: city, page, limit: PAGE_LIMIT } })
    },
    [city, getHotelsByCity]
  )

  const fetchMoreHotels = useCallback(() => {
    if (!hotelsByCityError && !isFetching) {
      setIsFetching(true)
      fetchHotels(currentPage + 1)
    }
  }, [currentPage, fetchHotels, hotelsByCityError, isFetching])

  // Fetch city hotels on mount
  useEffect(() => {
    fetchHotels(currentPage)
    fetchCity()
  }, [currentPage, fetchCity, fetchHotels])

  useEffect(() => {
    if (activeCity !== city) {
      setActiveCity(city)
      setIsFetching(true)
      setTotalResults(0)
      setHotels([])
      fetchHotels(1)
      fetchCity()
    }
  }, [city])

  const pageTitle = cityData?.getCity?.name
  const heroImage = isTablet ? cityData?.getCity?.image?.medium : cityData?.getCity?.image?.large

  const defaultMapProps = {
    center: {
      lat: currentMarkers?.[0]?.lat || 60.955413,
      lng: currentMarkers?.[0]?.lng || 30.337844,
    },
    zoom: 11,
  }

  const handleMapChange = ({ bounds }: ChangeEventValue) => {
    getMapMarkers({ variables: { coords: bounds } })
  }

  return (
    <PageLayout
      className={isMapView ? classes.main : undefined}
      hero={
        <Hero
          bottomComponent={WhereToGoSelect}
          height='50vh'
          maxHeight='320px'
          minHeight='270px'
          carousalClassName='hotels_hero'
          carousel={{
            items: [
              {
                image: heroImage,
                title: pageTitle,
              },
            ],
          }}
        />
      }
    >
      <Section
        title={`${totalResults} Hotels`}
        childrenClassname={isMapView ? classes.section : undefined}
        titleSibling={
          !isFetching &&
          !isSorting &&
          currentMarkers?.length && (
            <Button
              className={classes.mapButton}
              variant='contained'
              onClick={() => setMapView((prevState) => !prevState)}
            >
              {isMapView ? (
                <>
                  Show List <img src={ListIcon} alt='map-list' />
                </>
              ) : (
                <>
                  Show Map <img src={MapTrifoldIcon} alt='map-trifold' />
                </>
              )}
            </Button>
          )
        }
        fullWidthSm
      >
        {!isSorting && !isFetching && isMapView ? (
          <HotelsMap
            markers={dynamicMarkers.length ? dynamicMarkers : currentMarkers}
            onChange={handleMapChange}
            defaultCenter={defaultMapProps.center}
            defaultZoom={defaultMapProps.zoom}
          />
        ) : (
          <>
            {isSorting ? (
              <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                <CircularProgress />
              </div>
            ) : (
              <InfiniteScroller
                loadMore={fetchMoreHotels}
                hasMore={totalResults > hotels.length}
                isFetching={isFetching}
              >
                {hotels.map((item) => {
                  return <BenefitShowcase key={item.id} benefit={item} />
                })}
              </InfiniteScroller>
            )}
            {isFetching && (
              <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
      </Section>
    </PageLayout>
  )
}

export default HotelsFeaturedCities
