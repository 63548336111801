import ButtonLink from 'components/shared/ButtonLink'
import FilterBenefitsCheckboxes from 'components/shared/FilterBenefitsCheckboxes'
import { RoutePath } from 'constants/RoutePath'
import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BenefitShowcase from 'components/shared/BenefitShowcase'
import Section from 'components/shared/Section'
import SortBenefitsSelect from 'components/shared/SortBenefitsSelect'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import PageLayout from 'components/layouts/PageLayout'
import { Box, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Benefit, CollectionMetadata, Order } from 'types/generatedGql'
import { GET_BENEFITS, GET_CURRENT_USER } from 'constants/queries'
import useSorting from 'hooks/useSorting'
import { useStyles } from './styles'

interface Params {
  category: string
}

type PaginationMetadata = Omit<CollectionMetadata, 'limitValue'>

const renderEmptyState = () => (
  <Box my={8} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
    <Typography variant='body2' color='primary'>
      We could not find any matching results.
    </Typography>
    <Typography variant='body2' color='primary'>
      You can try browsing through All Benefits or returning to our Home page. Click here to{' '}
      <Link href={RoutePath.Contact}>Suggest A Benefit.</Link>
    </Typography>
    <Box mt={4}>
      <ButtonLink text='Explore all benefits' to='/client/benefits/category/all' />
    </Box>
  </Box>
)

export const Benefits: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { category } = useParams<Params>()
  const location = useLocation()
  const [collection, setCollection] = useState<Benefit[]>([])
  const [metadata, setMetadata] = useState<PaginationMetadata>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
  })
  const [sortOrder, setSortOrder] = useSorting()
  const [isFetching, setIsFetching] = useState(true)
  const [prevCategory, setPrevCategory] = useState(category)

  const handleFetchMoreCompleted = () => {
    if (data) {
      const { benefits } = data
      if (prevCategory !== category) {
        setCollection(benefits.collection)
        setPrevCategory(category)
      } else {
        collection.push(...benefits.collection)
        setCollection(collection)
      }
      setMetadata(benefits.metadata)
      setIsFetching(false)
    }
  }

  const [getBenefits, { data, error }] = useLazyQuery(GET_BENEFITS, {
    onCompleted: handleFetchMoreCompleted,
    fetchPolicy: 'cache-and-network',
  })

  const query = new URLSearchParams(location.search)

  const marketplaceOnly = !!query.get('marketplaceOnly')
  const execOnly = !!query.get('execOnly')

  const fetchBenefits = useCallback(
    (page: number) => {
      setIsFetching(true)
      getBenefits({
        variables: {
          page: page || 1,
          category,
          order: sortOrder,
          marketplaceOnly,
          execOnly,
        },
      })
    },
    [category, getBenefits, sortOrder, marketplaceOnly, execOnly]
  )

  useEffect(() => {
    setCollection([])
    fetchBenefits(1)
  }, [sortOrder, fetchBenefits, category, marketplaceOnly, execOnly])

  const onSortSelect = (order: Order) => {
    setSortOrder(order)
  }

  const fetchMoreBenefits = useCallback(async () => {
    if (!error && !isFetching) {
      fetchBenefits(metadata.currentPage + 1)
    }
  }, [error, isFetching, fetchBenefits, metadata.currentPage])

  const { data: userData } = useQuery(GET_CURRENT_USER)

  return (
    <PageLayout error={error}>
      <Box mt={4} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h2' className={classes.category}>
          {category}
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          {userData?.currentUser?.marketplaceMembership && <FilterBenefitsCheckboxes />}
          <SortBenefitsSelect onSortChange={onSortSelect} sortBy={sortOrder} />
        </Box>
      </Box>
      <Section className={classes.resultsSection} fullWidthSm>
        <InfiniteScroller
          loadMore={fetchMoreBenefits}
          hasMore={collection.length < metadata.totalCount}
          isFetching={isFetching}
        >
          {!isFetching && !collection.length
            ? renderEmptyState()
            : collection.map((item) => {
                return <BenefitShowcase key={item.id} benefit={item} />
              })}
        </InfiniteScroller>
        {isFetching && (
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        )}
      </Section>
    </PageLayout>
  )
}
