import React from 'react'
import CarouselItem from 'components/shared/CarouselItem'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { Props as CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel'
import { BenefitImage } from 'types/generatedGql'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { useStyles } from './styles'

export interface DefaultCarouselProps
  extends Partial<Omit<CarouselProps, 'showStatus' | 'showThumbs' | 'emulateTouch'>> {
  images: BenefitImage[]
  useOverlay?: boolean
  dotsPosition?: 'primary' | 'secondary'
  dotsMarginBottom?: number
}

const DefaultCarousel: React.FC<DefaultCarouselProps> = ({
  images,
  useOverlay,
  dotsPosition = 'primary',
  dotsMarginBottom,
  className,
  interval,
  ...props
}) => {
  const classes = useStyles({ dotsMarginBottom })
  const { isMobile } = useGetWindowSize()

  return (
    <Carousel
      className={`${className || ''} ${classes.carousel} ${classes[dotsPosition]}`}
      showStatus={false}
      showThumbs={false}
      emulateTouch
      {...props}
    >
      {images?.map((image) => (
        <CarouselItem key={image.medium} image={isMobile ? image.thumbnail : image.medium} useOverlay={useOverlay} />
      ))}
    </Carousel>
  )
}

export default DefaultCarousel
