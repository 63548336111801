import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    section: {
      padding: theme.spacing(4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 0),
      },
    },
    overflowContentSm: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
        scrollbarWidth: 'none',
      },
    },
    hideOnSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    // To revise later: https://forum.webflow.com/t/solution-to-small-horizontal-scroll-when-using-100vw-known-browser-bug/69043
    fullWidthSm: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        // width: 'calc(100vw - 20px)',
        width: '100vw',
        left: '50%',
        // marginLeft: 'calc(-50vw + 10px)',
        marginLeft: '-50vw',
      },
    },
  }
})
