import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
    dropdown: {
      position: 'absolute',
      top: 'calc(100% + 4px)',
      right: 0,
      width: 'max-content',
      maxWidth: '300px',
      backgroundColor: theme.palette.tertiary.main,
      border: '1px solid white',
      borderRadius: '2px',
      boxShadow: '1px 1px 1px #333',
    },
    dividerHorizontal: {
      opacity: 0.4,
      margin: theme.spacing(0, 1),
      backgroundColor: theme.palette.quaternary.main,
    },
  }
})
