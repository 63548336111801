import React, { useState, KeyboardEvent } from 'react'
import { LeadStatus } from '../../dtos/LeadDTO'
import { leadStatusMap } from './const'

export interface LeadsFilterFields {
  status: LeadStatus | ''
  name: string
  company: string
}

interface Props {
  applyFilters: (filters: LeadsFilterFields) => void
}

const LeadFilter: React.FC<Props> = ({ applyFilters }) => {
  const [filters, setFilters] = useState<LeadsFilterFields>({
    status: '',
    name: '',
    company: '',
  })

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters(filters)
    }
  }

  const onSetFilters = (value: string, fieldLabel: keyof LeadsFilterFields) => {
    setFilters((prevFilters) => ({ ...prevFilters, [fieldLabel]: value }))
  }

  const onSelectFilters = (value: string, fieldLabel: keyof LeadsFilterFields) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [fieldLabel]: value }
      applyFilters(newFilters)
      return newFilters
    })
  }

  return (
    <>
      <th> </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Name'
          value={filters.name}
          onChange={(e) => onSetFilters(e.target.value, 'name')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Company'
          value={filters.company}
          onChange={(e) => onSetFilters(e.target.value, 'company')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th> </th>
      <th> </th>
      <th> </th>
      <th> </th>
      <th>
        <div className='select'>
          <select name='status' value={filters.status} onChange={(e) => onSelectFilters(e.target.value, 'status')}>
            <option value=''>All</option>
            {Object.values(LeadStatus).map((status) => (
              <option key={status} value={status}>
                {leadStatusMap[status as LeadStatus]}
              </option>
            ))}
          </select>
        </div>
      </th>
      <th className='is-flex is-justify-content-flex-end'>
        <button type='button' className='button' onClick={() => applyFilters(filters)}>
          <span className='icon is-small'>
            <i className='fas fa-search' />
          </span>
        </button>
      </th>
    </>
  )
}

export { LeadFilter }
