import React, { useEffect, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import useLoginRedirect from 'hooks/useLoginRedirect'
import { useMutation } from '@apollo/client'
import { CCO_LOGIN_MUTATION } from 'constants/mutations'
import { useLocation, useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import CircularSpinner from '../../../panel/components/CircularSpinner'

export const CcoToken = (): React.ReactElement => {
  const { search } = useLocation()
  const { replace } = useHistory()
  const [error, setError] = useState()
  const { loginRedirect } = useLoginRedirect(true)
  const [ccoLogin] = useMutation(CCO_LOGIN_MUTATION)

  const token = new URLSearchParams(search).get('token')

  const handleLogin = async () => {
    try {
      const resp = await ccoLogin({ variables: { token } })
      const { credentials, subdomain, preApplicationStep, onboardingData } = resp.data.ccoLogin
      loginRedirect({ credentials, subdomain, preApplicationStep, onboardingStep: onboardingData?.stepName })
    } catch (e) {
      setError(e.message)
      setTimeout(() => {
        replace('/client/login')
      }, 2000)
    }
  }

  useEffect(() => {
    handleLogin()
  }, [])

  return (
    <PageLayout>
      <CircularSpinner />
      <Box textAlign='center'>{error && <Typography color='error'>{error || 'Something went wrong'}</Typography>}</Box>
    </PageLayout>
  )
}

export default CcoToken
