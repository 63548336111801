import React, { FC, SyntheticEvent, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import useCredentials from '../../hooks/useCredentials'

interface Props {
  memberId?: string
  onSaveSuccess?: () => void
}

const NewNoteForm: FC<Props> = ({ memberId, onSaveSuccess }) => {
  const { credentials } = useCredentials()
  const [noteText, setNoteText] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitError, setSubmitError] = useState<string>('')

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    try {
      setSubmitError('')
      const data = new FormData()
      data.append('content', noteText)
      await axios.post(`/api/panel/members/${memberId}/notes`, data, {
        headers: credentials,
      })
      onSaveSuccess?.()
    } catch (e) {
      setSubmitError(e?.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className='field'>
        <div className='control'>
          <div className='control'>
            <CKEditor
              editor={ClassicEditor}
              data={noteText}
              onChange={(event, editor) => {
                setNoteText(editor.getData())
              }}
            />
          </div>
        </div>
      </div>
      <div className='field'>
        <button className={`button is-primary ${submitting && 'is-loading'}`} type='submit' disabled={submitting}>
          Save
        </button>
        {submitError && <div className='has-text-danger mt-2'>{submitError}</div>}
      </div>
    </form>
  )
}

export default NewNoteForm
