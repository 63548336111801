import React, { ReactNode } from 'react'

interface Props {
  className?: string
  imageSrc?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  footer?: ReactNode
}

const MediaCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  className = '',
  imageSrc,
  children,
  footer,
  onClick,
  ...props
}): JSX.Element => {
  return (
    <div className={`box card ${className}`} {...props}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={onClick} className={`${onClick ? 'is-clickable' : ''}`}>
        <div className='card-image'>
          <figure
            className='image is-4by3'
            style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
          >
            {imageSrc ? (
              <img src={imageSrc} alt='Media card' draggable='false' />
            ) : (
              <div className='is-flex is-justify-content-center has-text-white'>No image</div>
            )}
          </figure>
        </div>
        <div className='card-content' style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
          <div className='content is-size-3'>{children}</div>
        </div>
      </div>
      {footer && <footer className='card-footer'>{footer}</footer>}
    </div>
  )
}

export default MediaCard
