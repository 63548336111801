import React from 'react'

const WelcomeDescription: React.FC = () => {
  return (
    <>
      <p>
        We would like to take a few moments to introduce you to EXEC and help you get the most out of your Membership.
        EXEC is an exclusive, highly curated hotel, travel, lifestyle, and business benefits program designed to improve
        the lives of busy executives.
      </p>
      <p>Here are a few of the types of benefits you now have access to:</p>
      <p>
        <ul>
          <li>Private rates and added amenities at hotels & resorts around the globe.</li>
          <li>
            Airline and travel savings paired with access to select elite status benefits, and other premium travel
            services.
          </li>
          <li>
            Lifestyle and business benefits to improve your life in and out of the office. Access to special events and
            adventures like never before.
          </li>
        </ul>
      </p>
    </>
  )
}

export default WelcomeDescription
