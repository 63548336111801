import React from 'react'
import { Card, CardProps, CardMedia } from '@material-ui/core'
import { Maybe } from 'types/generatedGql'
import { useStyles } from './styles'

export interface CarouselItemProps extends CardProps {
  image?: Maybe<string>
  useOverlay?: boolean
}

const CarouselItem: React.FC<CarouselItemProps> = ({ image, useOverlay, className, ...props }) => {
  const classes = useStyles()

  return (
    <Card className={`${classes.card} ${className || ''} ${useOverlay ? classes.cardOverlay : ''} `} {...props}>
      <CardMedia
        height='100%'
        component='img'
        alt='Carousel item'
        image={image}
        className={`${classes.cardMedia} ${useOverlay ? classes.cardMediaOverlay : ''}`}
      />
    </Card>
  )
}

export default CarouselItem
