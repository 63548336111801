/* eslint-disable react/no-array-index-key */
import React from 'react'
import CarouselItemWithContent, { CarouselItemWithContentProps } from 'components/shared/CarouselItemWithContent'
import { Carousel } from 'react-responsive-carousel'
import { CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel/types'
import { useStyles } from './styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Box, Card, CardMedia, Container, Typography } from '@material-ui/core' // requires a loader

export interface CarouselWithContentProps
  extends Partial<Omit<CarouselProps, 'showStatus' | 'showThumbs' | 'emulateTouch'>> {
  items: CarouselItemWithContentProps[]
  useOverlay?: boolean
  overlayHero?: boolean
  dotsPosition?: 'primary' | 'secondary'
  dotsMarginBottom?: number
}

const CarouselWithContent: React.FC<CarouselWithContentProps> = ({
  items,
  useOverlay,
  dotsPosition = 'primary',
  dotsMarginBottom,
  className,
  overlayHero,
  ...props
}) => {
  const classes = useStyles({ dotsMarginBottom })

  if (items.length === 1) {
    return (
      <CarouselItemWithContent
        {...items[0]}
        useOverlay={useOverlay}
        overlayHero={overlayHero}
        className={`${className || ''}`}
      />
    )
  }

  return items.length ? (
    <Carousel
      className={`${className || ''} ${classes.carousel} ${classes[dotsPosition]}`}
      showStatus={false}
      showThumbs={false}
      emulateTouch
      {...props}
    >
      {items.map((item, index) => {
        const { button: Button } = item
        return (
          // <CarouselItemWithContent key={index} {...item} useOverlay={useOverlay} overlayHero={overlayHero} />
          // Took out JSX from CarouselItemWithContent because react-responsive-carousel
          // doesn't support custom components as children
          // See https://github.com/leandrowd/react-responsive-carousel/blob/master/TROUBLESHOOTING.md
          <div
            className={`${classes.card} ${className || ''} ${useOverlay ? classes.cardOverlay : ''}
      ${overlayHero ? classes.cardOverlayHero : ''}`}
            {...props}
          >
            {item.image && (
              <img
                height='100vh'
                alt='Carousel item'
                src={item.image}
                className={`${classes.cardMedia} ${useOverlay ? classes.cardMediaOverlay : ''}`}
              />
            )}
            <Box className={`${classes.content} ${classes.innerContent}`}>
              <Container maxWidth='sm' className={`${classes.innerContentContainer}`}>
                {item.title && (
                  <Typography component='h1' variant='h4' className={`${classes.typography} ${classes.title}`}>
                    {item.title}
                  </Typography>
                )}
                {item.subtitle && (
                  <Typography variant='body1' className={classes.typography}>
                    {item.subtitle}
                  </Typography>
                )}
                {Button && <Button />}
                {item.buttonComponent}
              </Container>
            </Box>
          </div>
        )
      })}
    </Carousel>
  ) : null
}

export default CarouselWithContent
