import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Pagination } from '../../components/Pagination'
import { applyParamsToUrlPath } from '../../utils'
import CircularSpinner from '../../components/CircularSpinner'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { ActionsCell } from '../../components/ActionsCell'
import Modal from '../../components/Modal/Modal'
import { UploadDTO } from '../../dtos/UploadDTO'

type ParsingStatus = 'parse_started' | 'partially_imported' | 'completed'

const loadingPercentageMap: Record<ParsingStatus, string> = {
  parse_started: '20%',
  partially_imported: '60%',
  completed: '100%',
}

const OrganizationMembershipLists: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadDataError, setLoadDataError] = useState('')
  const [totalPageCount, setTotalPageCount] = useState(null)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [uploads, setUploads] = useState<UploadDTO[] | null>()
  const [parseRequestLoading, setParseRequestLoading] = useState(false)
  const [parsingError, setParsingError] = useState('')
  const [parsingInfo, setParsingInfo] = useState<{ status: ParsingStatus; docId: number | null; filename: string }>({
    status: '' as ParsingStatus,
    docId: null,
    filename: '',
  })

  const loadUploadsData = useCallback(async () => {
    setLoading(true)
    try {
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/organizations/${params.id}/members_uploads`),
        {
          headers: credentials,
        }
      )
      setUploads(res.data.uploads)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id])

  const parseDocument = async (docId: number, filename: string) => {
    setParseRequestLoading(true)
    try {
      const res = await axios.post(
        `/api/panel/organizations/${params.id}/members_uploads/${docId}/parse`,
        {},
        {
          headers: credentials,
        }
      )
      setParsingInfo({ status: res.data.status as ParsingStatus, docId, filename })
    } catch (e) {
      setParsingError(e.message)
    } finally {
      setParseRequestLoading(false)
    }
  }

  const checkParsingStatus = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/organizations/${params.id}/members_uploads/${parsingInfo.docId}`, {
        headers: credentials,
      })

      setParsingInfo((prevState) => ({ ...prevState, status: res.data.status }))
    } catch (e) {
      setParsingError(e.message)
    }
  }, [credentials, params.id, parsingInfo.docId])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (parsingInfo.status === 'parse_started' || parsingInfo.status === 'partially_imported') {
      setModalOpen(true)
      interval = setInterval(() => {
        if (parsingInfo.status === 'completed') {
          clearInterval(interval)
        } else {
          checkParsingStatus()
        }
      }, 3000)
    }

    return () => clearInterval(interval)
  }, [checkParsingStatus, parsingInfo.status])

  const onModalClose = () => {
    setParsingInfo({ docId: null, status: '' as ParsingStatus, filename: '' })
    setModalOpen(false)
  }

  useEffect(() => {
    loadUploadsData()
  }, [loadUploadsData])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {parsingError && <ErrorMessage text={parsingError} />}
      {loading ? (
        <CircularSpinner />
      ) : (
        <>
          <div className='mb-4'>
            <h4 className='is-size-4 has-text-centered'>Membership Lists</h4>
          </div>
          <div className='box'>
            <table className='table is-fullwidth'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Filename</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {uploads?.map((row) => (
                  <tr key={row.id} className='is-clickable'>
                    <th>{row.id}</th>
                    <td>
                      <a href={row.file_url} className='button is-text mr-2' download>
                        {row.filename}
                      </a>
                    </td>
                    <td>
                      <ActionsCell>
                        <button
                          onClick={() => parseDocument(row.id, row.filename)}
                          className={`button is-text mr-2 ${parseRequestLoading && 'is-loading'}`}
                          type='button'
                          disabled={parseRequestLoading}
                        >
                          Parse
                        </button>
                      </ActionsCell>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPageCount={totalPageCount}
              currentPage={currentPageNumber}
              onChangePage={setCurrentPageNumber}
            />
          </div>
        </>
      )}
      <Modal opened={modalOpen} onClose={onModalClose} width={403}>
        <div className='p-4'>
          <h1 className='is-size-4 has-text-weight-bold' style={{ color: '#C97E48' }}>
            Parsing {parsingInfo.filename}
          </h1>
          <div className='is-size-7 mt-3 mb-1'>Parsing....({loadingPercentageMap[parsingInfo.status]})</div>
          <div className='is-flex' style={{ height: 48, borderRadius: 2, overflow: 'hidden' }}>
            <div style={{ backgroundColor: '#C97E48', width: loadingPercentageMap[parsingInfo.status] }} />
            <div className='is-bordered' style={{ flex: 1, border: '1px solid #BDBDBD', borderLeft: 'none' }} />
          </div>
          <div className='mt-6 is-flex is-justify-content-flex-end'>
            <button
              onClick={() => setModalOpen(false)}
              disabled={parsingInfo.status !== 'completed'}
              type='button'
              className='button is-bordered is-medium'
            >
              Completed
            </button>
          </div>
          {parsingError && (
            <div className='is-flex is-justify-content-flex-end mt-2'>
              <Typography color='error'>{parsingError || 'Something went wrong'}</Typography>
            </div>
          )}
        </div>
      </Modal>
    </DefaultLayout>
  )
}

export default OrganizationMembershipLists
