import React, { useState, FC } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import UserForm from '../../components/UserForm/UserForm'
import { UserDTO } from '../../dtos/UserDTO'
import { UserRole } from '../../const'
import { memberInitialState } from '../const'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import useGetQueryParam from '../../hooks/useGetQueryParam'
import { validatePassword } from '../../../utils/validation'

const NewMember: FC = () => {
  const history = useHistory()
  const { credentials } = useCredentials()
  const parentMemberId = useGetQueryParam('parent')
  const [formData, setFormData] = useState<Partial<UserDTO>>({
    ...memberInitialState,
    active: true,
    password: '',
    max_sub_account: 0,
    ...(parentMemberId && { parent_id: parentMemberId, complimentary_sub_user: true }),
  })
  const [validationErrors, setValidationErrors] = useState({})
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [formError, setFormError] = useState('')

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      if (data.password) {
        const passwordValidationDetails = validatePassword(data.password)
        if (passwordValidationDetails?.length) {
          setValidationErrors({ password: passwordValidationDetails.map(({ message }) => message) })
          return
        }
      }
      setSubmitting(true)
      await axios.post(`/api/panel/members`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
      if (err?.response?.data?.errors?.length) {
        setFormError(err.response.data.errors.join(', '))
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <h4 className='is-size-4 mb-4'>New Member</h4>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            roles={[UserRole.Member]}
            onChange={handleChange}
            formError={formError}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default NewMember
