import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    deleteSubAccount: {
      padding: 0,
    },
    icon: {
      fontSize: theme.spacing(9),
    },
    body1: {
      marginTop: theme.spacing(2, 0),
    },
  }
})
