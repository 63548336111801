import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    revealButton: {
      zIndex: 1,
      minHeight: '60px',
    },
    codeWrapper: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: 0,
      [theme.breakpoints.down('sm')]: {
        left: '5vh',
      },
      width: '100%',
      justifyContent: 'center',
    },
    codeFlexWrapper: {
      display: 'flex',
      '& > *': {
        border: '1px solid #ced1d5',
        borderRight: 0,
        borderRadius: 0,
      },
      '& > *:first-child': {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
      '& > *:last-child': {
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        borderRight: '1px solid #ced1d5',
      },
    },
    code: {
      padding: theme.spacing(1.5),
      maxWidth: '300px',
      minWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    copyToClipboardButton: {
      backgroundColor: theme.palette.quaternary.main,
    },
    goToLinkButton: {
      backgroundColor: theme.palette.quaternary.main,
    },
    bold: {
      fontWeight: 'bold',
    },
  }
})
