import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import DefaultLayout from 'panel/layouts/DefaultLayout'
import CircularSpinner from 'panel/components/CircularSpinner'
import { getCity } from 'panel/common/requests'
import { CityDTO } from 'panel/dtos/CityDTO'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'

const ShowCity: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { credentials, hasEditPermission } = useCredentials()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadDataError, setLoadDataError] = useState('')
  const [city, setCity] = useState<CityDTO>({
    id: 0,
    name: '',
    featured: false,
    region: '',
    image: null,
    image_url: '',
  })

  const loadCity = useCallback(async (): Promise<void> => {
    try {
      const response = await getCity(id, credentials)
      if (response.status === 200 && response.data) {
        setCity(response.data)
      }
    } catch (err) {
      setLoadDataError(err.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, id])

  useEffect(() => {
    loadCity()
  }, [loadCity])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        {hasEditPermission && city && (
          <Link to={`/panel/cities/${city.id}/edit`} className='button is-link'>
            Edit
          </Link>
        )}
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {loading ? (
        <CircularSpinner />
      ) : (
        <div className='mb-4'>
          <h4 className='is-size-4 has-text-centered'>{city.name}</h4>
          <div className='box'>
            <div className='field'>
              <p className='label'>Name</p>
              <div className='control'>
                <span>{city.name}</span>
              </div>
            </div>
            <div className='field'>
              <p className='label'>Region</p>
              <div className='control'>
                <span>{city.region}</span>
              </div>
            </div>
            <div className='field'>
              <p className='label'>Featured</p>
              <div className='control'>
                <span>{city.featured ? 'YES' : 'NO'}</span>
              </div>
            </div>
            {city.image_url && (
              <div className='field'>
                <div className='columns'>
                  <div className='column is-one-third'>
                    <figure className='image is-4by3'>
                      <img src={city.image_url} alt='benefit' />
                    </figure>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  )
}

export default ShowCity
