import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Maybe, UserImage } from 'types/generatedGql'
import ProfileImage from 'components/shared/ProfileImage'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer/store'
import CropPhotoModal from 'pages/MyAccount/ProfilePhoto/CropPhoto'
import DefaultModalContent from 'components/shared/DefaultModal/DefaultModalContent'
import { Area } from 'react-easy-crop/types'
import { getCroppedImg } from 'pages/MyAccount/utils'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PHOTO } from 'constants/mutations'
import { useStyles } from './styles'
import EditIcon from './images/edit-icon.svg'

interface Props {
  userId: number
  userPhoto: Maybe<UserImage>
}

const FIVE_MEGABYTES = 5242880

const ProfilePhoto: React.FC<Props> = ({ userId, userPhoto }) => {
  const classes = useStyles()
  const credentials = useSelector((state: RootState) => state.auth.credentials)
  const [avatarPreview, setAvatarPreview] = useState<string>('')
  const [file, setFile] = useState<File | undefined>()
  const [avatarUploading, setAvatarUploading] = useState<boolean>(false)
  const [avatarError, setAvatarError] = useState<string>('')
  const [cropPhotoModalOpened, setCropPhotoModalOpened] = useState<boolean>(false)

  const [updateUserProfile] = useMutation(UPDATE_USER_PHOTO)

  const handleAvatarUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files[0])
      setCropPhotoModalOpened(true)
    }
  }

  const applyCrop = async (cropArea: Area) => {
    if (file) {
      setAvatarUploading(true)
      setAvatarError('')
      try {
        const croppedImageURL = await getCroppedImg(URL.createObjectURL(file), cropArea)
        if (file.size > FIVE_MEGABYTES) {
          setAvatarError('Image size must be less than 5MB')
          return
        }
        const formData = new FormData()
        formData.append('photo', file)
        formData.append('width', String(cropArea.width))
        formData.append('height', String(cropArea.height))
        formData.append('top', String(cropArea.y))
        formData.append('left', String(cropArea.x))
        await axios.post(`/api/panel/members/${userId}/upload_photo`, formData, {
          headers: {
            'access-token': credentials.accessToken,
            client: credentials.client,
            uid: credentials.uid,
          },
        })
        if (croppedImageURL) {
          setAvatarPreview(croppedImageURL)
        }
        await updateUserProfile({ variables: { isPhotoUploaded: true } })
      } catch (err) {
        setAvatarError(err?.message)
        console.error(err)
      } finally {
        setAvatarUploading(false)
        setCropPhotoModalOpened(false)
      }
    }
  }

  const onUploadInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // possibility to load same file again
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <>
      <Box className={classes.avatarUploadWrapper}>
        <label htmlFor='avatarUpload'>
          <ProfileImage
            avatarClassName={classes.avatarPreview}
            src={avatarPreview || userPhoto?.thumbnail}
            alt='Avatar upload preview'
          />
          <img src={EditIcon} className={classes.avatarUploadText} alt='edit-profile-avatar' />
          {avatarUploading && <CircularProgress className={classes.avatarProgress} />}

          <input
            id='avatarUpload'
            name='avatarUpload'
            hidden
            type='file'
            accept='image/*'
            onChange={handleAvatarUpload}
            onClick={onUploadInputClick}
          />
        </label>

        {!!avatarError && (
          <Typography variant='caption' color='error' className={classes.errorText}>
            {avatarError || 'Upload photo error'}
          </Typography>
        )}
      </Box>
      <DefaultModalContent
        content={file && <CropPhotoModal image={URL.createObjectURL(file)} onApplyCrop={applyCrop} />}
        toggleModal={() => setCropPhotoModalOpened((prevState) => !prevState)}
        isModalOpen={cropPhotoModalOpened}
      />
    </>
  )
}

export default ProfilePhoto
