import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { RedemptionType } from 'types/generatedGql'
import parseHtml from 'html-react-parser'
import DefaultLayout from '../../layouts/DefaultLayout'
import { BenefitDTO } from '../../dtos/BenefitDTO'
import CircularSpinner from '../../components/CircularSpinner'
import { getBooleanFieldText } from '../../utils'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { dayFormat, getExpiredValue } from './helpers'
import { OrganizationDTO } from '../../dtos/OrganizationDTO'
import { redemptionTypeMap } from '../../const'

const handleExcludedOrganizations = (
  allOrganizations: Array<OrganizationDTO>,
  excludedOrganizationIds: Array<string>
): Array<OrganizationDTO> => {
  return allOrganizations.filter(({ id }) => excludedOrganizationIds.includes(String(id)))
}

const ShowBenefit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { credentials, hasEditPermission } = useCredentials()
  const [benefit, setBenefit] = useState<Partial<BenefitDTO | null>>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadDataError, setLoadDataError] = useState('')
  const [excludedOrganizations, setExcludedOrganizations] = useState<Array<OrganizationDTO>>([])
  const [includedOrganizations, setIncludedOrganizations] = useState<Array<OrganizationDTO>>([])

  const loadData = useCallback(async (): Promise<void> => {
    try {
      const benefitResponse = await axios.get(`/api/panel/benefits/${params.id}`, {
        headers: credentials,
      })
      setBenefit(benefitResponse.data)

      const organizationsResponse = await axios.get(`/api/panel/organizations?sort_by=name`, {
        headers: credentials,
      })
      if (organizationsResponse?.data?.organizations) {
        setExcludedOrganizations(
          handleExcludedOrganizations(
            organizationsResponse.data.organizations,
            benefitResponse.data.excluded_organizations
          )
        )
        setIncludedOrganizations(
          handleExcludedOrganizations(
            organizationsResponse.data.organizations,
            benefitResponse.data.included_organizations
          )
        )
      }
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        {hasEditPermission && benefit && (
          <Link to={`/panel/benefits/${benefit.id}/edit`} className='button is-link'>
            Edit
          </Link>
        )}
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {loading && <CircularSpinner />}
      {benefit && !loading && (
        <div style={{ wordBreak: 'break-word' }}>
          <div className='mb-4'>
            <h4 className='is-size-4 has-text-centered'>{benefit.name}</h4>
          </div>
          <div className='box'>
            <div className='field'>
              <p className='label'>Name</p>
              <div className='control'>
                <span>{benefit.name}</span>
              </div>
            </div>
            {benefit.slug && (
              <div className='field'>
                <p className='label'>Slug</p>
                <div className='control'>
                  <span>{benefit.slug}</span>
                </div>
              </div>
            )}
            {benefit.category?.name && (
              <div className='field'>
                <p className='label'>Category</p>
                <div className='control'>
                  <span>{benefit.category.name}</span>
                </div>
              </div>
            )}
            <div className='field'>
              <p className='label'>Redemption Type</p>
              <div className='control'>
                <span>{redemptionTypeMap[benefit.redemption_type as RedemptionType]}</span>
              </div>
            </div>
            {benefit.redemption_code && (
              <div className='field'>
                <p className='label'>Redemption Code</p>
                <div className='control'>
                  <span>{benefit.redemption_code}</span>
                </div>
              </div>
            )}
            {benefit.redemption_link && (
              <div className='field'>
                <p className='label'>Redemption Link</p>
                <div className='control'>
                  <span>{benefit.redemption_link}</span>
                </div>
              </div>
            )}
            {benefit.redemption_instruction && (
              <div className='field'>
                <p className='label'>Redemption Instruction</p>
                <div className='control editor-formatted'>{parseHtml(benefit.redemption_instruction)}</div>
              </div>
            )}
            {benefit.claimed_one_time_codes_count !== null && (
              <div className='field'>
                <p className='label'>Claimed redemptions</p>
                <div className='control'>
                  <span className='mr-2'>{benefit.claimed_one_time_codes_count || '0'}</span>
                  <Link to={`/panel/benefits/${benefit.id}/claimed_redemptions`} className='is-link'>
                    Show claimed redemptions
                  </Link>
                </div>
              </div>
            )}
            {benefit.registered_redemptions_count !== null && (
              <div className='field'>
                <p className='label'>Registered redemptions</p>
                <div className='control'>
                  <span className='mr-2'>{benefit.registered_redemptions_count || '0'}</span>
                  <Link to={`/panel/benefits/${benefit.id}/registered_redemptions`} className='is-link'>
                    Show registered redemptions
                  </Link>
                </div>
              </div>
            )}
            <div className='field'>
              <p className='label'>Page views</p>
              <div className='control'>
                <Link to={`/panel/benefits/${benefit.id}/page_views`} className='is-link'>
                  Show page views
                </Link>
              </div>
            </div>

            {!!benefit.start_date && (
              <div className='field'>
                <p className='label'>Start date</p>
                <div className='control'>
                  <span>{moment(benefit.start_date, dayFormat).format('MMMM Do YYYY')}</span>
                </div>
              </div>
            )}
            {!!benefit.end_date && (
              <div className='field'>
                <p className='label'>End date</p>
                <div className='control'>
                  <span>{moment(benefit.end_date, dayFormat).format('MMMM Do YYYY')}</span>
                </div>
              </div>
            )}
            {!!benefit.end_date && (
              <div className='field'>
                <p className='label'>Expires</p>
                <div className='control'>{getExpiredValue(benefit.end_date)}</div>
              </div>
            )}
            {!!benefit.images?.length && (
              <div className='field'>
                <p className='label'>Images</p>
                <div className='is-flex is-flex-wrap-wrap'>
                  {benefit.images.map(({ image }) => (
                    <img src={image} className='mb-2 mr-2' alt='Benefit' key={image} style={{ width: '45%' }} />
                  ))}
                </div>
              </div>
            )}
            {benefit.brand?.name && (
              <div className='field'>
                <p className='label'>Brand</p>
                <div className='control'>
                  <div className='control'>
                    <span>{benefit.brand.name}</span>
                  </div>
                </div>
              </div>
            )}
            {benefit.website && (
              <div className='field'>
                <p className='label'>Website</p>
                <div className='control'>
                  <a href={benefit.website} className='is-link' target='_blank' rel='noreferrer'>
                    {benefit.website}
                  </a>
                </div>
              </div>
            )}
            {benefit.short_description && (
              <div className='field'>
                <p className='label'>Description preview</p>
                <div className='control editor-formatted'>{parseHtml(benefit.short_description)}</div>
              </div>
            )}
            {benefit.description && (
              <div className='field'>
                <p className='label'>Description</p>
                <div className='control editor-formatted'>{parseHtml(benefit.description)}</div>
              </div>
            )}
            {benefit.additional_information && (
              <div className='field'>
                <p className='label'>Additional information</p>
                <div className='control'>
                  <span>{benefit.additional_information}</span>
                </div>
              </div>
            )}
            {benefit.address_1 && (
              <div className='field'>
                <p className='label'>Address 1</p>
                <div className='control'>
                  <span>{benefit.address_1}</span>
                </div>
              </div>
            )}
            {benefit.address_2 && (
              <div className='field'>
                <p className='label'>Address 2</p>
                <div className='control'>
                  <span>{benefit.address_2}</span>
                </div>
              </div>
            )}
            {benefit.city && (
              <div className='field'>
                <p className='label'>City</p>
                <div className='control'>
                  <span>{benefit.city}</span>
                </div>
              </div>
            )}
            {benefit.region && (
              <div className='field'>
                <p className='label'>Region</p>
                <div className='control'>
                  <span>{benefit.region}</span>
                </div>
              </div>
            )}
            {benefit.postal_code && (
              <div className='field'>
                <p className='label'>Postal code</p>
                <div className='control'>
                  <span>{benefit.postal_code}</span>
                </div>
              </div>
            )}
            {benefit.country && (
              <div className='field'>
                <p className='label'>Country</p>
                <div className='control'>
                  <span>{benefit.country}</span>
                </div>
              </div>
            )}
            {!!benefit.benefits?.length && (
              <div className='field'>
                <p className='label'>Benefits</p>
                <div className='control'>
                  <span>{benefit.benefits.join(', ')}</span>
                </div>
              </div>
            )}
            {!!benefit.rates?.length && (
              <div className='field'>
                <p className='label'>Rates</p>
                <div className='control'>
                  {benefit.rates.map(({ category, exec_rate, standard_rate }) => (
                    <p key={`${category};${exec_rate};${standard_rate}`}>
                      <span>Category: {category || 'empty'}, </span>
                      <span>Exec rate: {exec_rate || 'empty'}, </span>
                      <span>Standard rate: {standard_rate || 'empty'}.</span>
                    </p>
                  ))}
                </div>
              </div>
            )}
            {benefit.terms_and_condition && (
              <div className='field'>
                <p className='label'>Terms and conditions</p>
                <div className='control'>
                  <span>{benefit.terms_and_condition}</span>
                </div>
              </div>
            )}
            {benefit.benefit_summary && (
              <div className='field'>
                <p className='label'>Benefit Summary</p>
                <div className='control'>
                  <span>{benefit.benefit_summary}</span>
                </div>
              </div>
            )}
            {benefit.other_rate_offer && (
              <div className='field'>
                <p className='label'>Other rate offer</p>
                <div className='control'>
                  <span>{benefit.other_rate_offer}</span>
                </div>
              </div>
            )}
            {!!excludedOrganizations?.length && (
              <div className='field'>
                <p className='label'>Excluded organizations</p>
                <div className='control'>{excludedOrganizations.map(({ name }) => name).join(', ')}</div>
              </div>
            )}
            {!!includedOrganizations?.length && (
              <div className='field'>
                <p className='label'>Included organizations</p>
                <div className='control'>{includedOrganizations.map(({ name }) => name).join(', ')}</div>
              </div>
            )}
            <div className='field'>
              <p className='label'>Active</p>
              <div className='control'>
                <span>{getBooleanFieldText(benefit.active)}</span>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <p className='label'>Featured</p>
                <div className='control'>{benefit.featured ? 'Yes' : 'No'}</div>
              </div>
            </div>
            <div className='field'>
              <p className='label'>Hero section</p>
              <div className='control'>
                <span>{getBooleanFieldText(benefit.is_hero)}</span>
              </div>
            </div>
            <div className='field'>
              <p className='label'>Popular section</p>
              <div className='control'>
                <span>{getBooleanFieldText(benefit.is_popular)}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </DefaultLayout>
  )
}

export default ShowBenefit
