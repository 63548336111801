import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { RootState } from '../reducer/store'
import { authLogout } from '../reducer/auth'
import { RouterPath } from '../router/routes'

const navbarItems = [
  { path: RouterPath.MAIN, label: 'Organizations' },
  { path: RouterPath.LEADS_VIEW, label: 'Leads' },
  { path: RouterPath.BENEFITS_VIEW, label: 'Benefits' },
  { path: RouterPath.CITIES_VIEW, label: 'Cities' },
  { path: RouterPath.MEMBERS_VIEW, label: 'Members' },
  { path: RouterPath.ADMINS_VIEW, label: 'Admins' },
  { path: RouterPath.HOME_PAGE_MANAGEMENT, label: 'Homepage' },
]
const Navbar: React.FC = (): JSX.Element => {
  const panelAuth = useSelector((state: RootState) => state.panelAuth)
  const dispatch = useDispatch()
  const [isOpened, setIsOpened] = useState(false)
  const handleLogout = () => {
    dispatch(authLogout())
  }

  const authItem = (
    <div className='navbar-item'>
      <div className='buttons'>
        {panelAuth.loggedIn ? (
          <button type='button' className='button is-primary' onClick={handleLogout}>
            Log out
          </button>
        ) : (
          <Link to={RouterPath.LOG_IN}>Login</Link>
        )}
      </div>
    </div>
  )

  return (
    <nav className='navbar has-shadow'>
      <div className='container'>
        <div className='navbar-brand'>
          <Link className='navbar-item' to='/panel'>
            <h4 className='is-size-4'>EXEC Admin panel</h4>
          </Link>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <div
            role='navigation'
            className='navbar-burger'
            data-target='navbarMenu'
            onClick={() => setIsOpened((previousValue) => !previousValue)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>

        <div
          id='navbarMenu'
          className={classnames('navbar-menu', 'is-active')}
          style={{ ...(!isOpened && { display: 'none' }) }}
        >
          <div className='navbar-item has-dropdown'>
            {navbarItems.map(({ path, label }) => (
              <Link className='navbar-item' to={path} key={label}>
                {label}
              </Link>
            ))}
            {authItem}
          </div>
        </div>

        <div className='navbar-menu'>
          <div className='navbar-start'>
            {navbarItems.map(({ path, label }) => (
              <Link className='navbar-item' to={path} key={label}>
                {label}
              </Link>
            ))}
          </div>
          <div className='navbar-end'>{authItem}</div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
