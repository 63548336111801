import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import BenefitShowcase from 'components/shared/BenefitShowcase'
import SortBenefitsSelect from 'components/shared/SortBenefitsSelect'
import { Box, Typography, Tabs, Link } from '@material-ui/core'
import TitleWithCaption from 'components/shared/TitleWithCaption'
import Section from 'components/shared/Section'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import PageLayout from 'components/layouts/PageLayout'
import CircularProgress from '@material-ui/core/CircularProgress'
import RouterTab from 'components/shared/RouterTab'
import { useLazyQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { CategorySlug } from 'types'
import { Benefit, CategoryMetadata, CollectionMetadata, Order } from 'types/generatedGql'
import { initialPaginationMetadata } from 'constants/common'
import ButtonLink from 'components/shared/ButtonLink'
import { RoutePath } from 'constants/RoutePath'
import useSorting from 'hooks/useSorting'
import { useStyles } from './styles'
import { GET_SEARCH_RESULTS } from './query'

interface Params {
  category: string
}

type PaginationMetadata = Omit<CollectionMetadata, 'limitValue'>

const getTotalCount = (allCategoriesMetadata: Record<CategorySlug, number>): number => {
  return Object.values(allCategoriesMetadata).reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  )
}

const renderEmptyState = () => (
  <Box my={6} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
    <Typography variant='body2' color='primary'>
      We could not find any matching results. Please check your spelling or adjust your search area.
    </Typography>
    <Typography variant='body2' color='primary'>
      You can also try browsing through All Benefits or returning to our Home page. Click here to{' '}
      <Link href={RoutePath.Contact}>Suggest A Benefit.</Link>
    </Typography>
    <Box mt={4}>
      <ButtonLink text='Explore all benefits' to='/client/benefits/category/all' />
    </Box>
  </Box>
)

const SearchResults: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { category } = useParams<Params>()
  const searchTerm = useSelector((state) => state.benefits.searchTerm)
  const [isFetching, setIsFetching] = useState(true)
  const [paginationMetadata, setPaginationMetadata] = useState<PaginationMetadata>(initialPaginationMetadata)
  const [sortOrder, setSortOrder] = useSorting()
  const [benefits, setBenefits] = useState<Benefit[]>([])
  const [totalResultsCount, setTotalResultsCount] = useState<number>(0)
  const [categoriesMetadata, setCategoriesMetadata] = useState<CategoryMetadata>()
  const classes = useStyles()

  useEffect(() => {
    if (!searchTerm) {
      history.push(RoutePath.BenefitsList)
    }
  }, [history, searchTerm])

  const handleFetchMoreCompleted = (res) => {
    if (res?.getResultsBySearch) {
      const { getResultsBySearch } = res
      setBenefits((prevCollection) => [...prevCollection, ...getResultsBySearch.benefits.collection])
      setPaginationMetadata(getResultsBySearch.benefits.metadata)
      const { business, experiences, hotels, lifestyle, travel } = getResultsBySearch.categoryMetadata
      setTotalResultsCount(getTotalCount({ business, experiences, hotels, lifestyle, travel }))
      setCategoriesMetadata({ business, experiences, hotels, lifestyle, travel })
      setIsFetching(false)
    }
  }

  const [getSearchResults, { error }] = useLazyQuery(GET_SEARCH_RESULTS, {
    onCompleted: (res) => {
      handleFetchMoreCompleted(res)
    },
  })

  const fetchBenefits = useCallback(
    (page: number) => {
      setIsFetching(true)
      getSearchResults({
        variables: { page, term: searchTerm, category, order: sortOrder },
      })
    },
    [category, getSearchResults, searchTerm, sortOrder]
  )

  useEffect(() => {
    setBenefits([])
    setPaginationMetadata(initialPaginationMetadata)
    fetchBenefits(1)
  }, [category, fetchBenefits, sortOrder])

  const fetchMoreBenefits = useCallback(async () => {
    if (!error && !isFetching) {
      fetchBenefits(paginationMetadata.currentPage + 1)
    }
  }, [error, fetchBenefits, isFetching, paginationMetadata.currentPage])

  const onSortSelect = (order: Order) => {
    setSortOrder(order)
  }

  return (
    <PageLayout error={error}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TitleWithCaption caption={{ text: 'Results for' }} name={searchTerm.replace('-', ' ').toLowerCase()} />
        <SortBenefitsSelect onSortChange={onSortSelect} sortBy={sortOrder} />
      </Box>

      <Tabs
        variant='scrollable'
        aria-label='categories-tabs'
        scrollButtons='auto'
        indicatorColor='primary'
        value={location.pathname}
      >
        <RouterTab label={`All benefits (${totalResultsCount || 0})`} value='/client/search-results/all' />
        <RouterTab label={`Hotels (${categoriesMetadata?.hotels || 0})`} value='/client/search-results/hotels' />
        <RouterTab
          label={`Lifestyle (${categoriesMetadata?.lifestyle || 0})`}
          value='/client/search-results/lifestyle'
        />
        <RouterTab label={`Travel (${categoriesMetadata?.travel || 0})`} value='/client/search-results/travel' />
        <RouterTab
          label={`Experiences (${categoriesMetadata?.experiences || 0})`}
          value='/client/search-results/experiences'
        />
        <RouterTab label={`Business (${categoriesMetadata?.business || 0})`} value='/client/search-results/business' />
      </Tabs>

      <Section className={classes.resultsSection} fullWidthSm>
        {!!benefits?.length && (
          <InfiniteScroller
            loadMore={fetchMoreBenefits}
            hasMore={benefits.length < paginationMetadata.totalCount}
            isFetching={isFetching}
          >
            {benefits?.map((benefit) => {
              return <BenefitShowcase key={benefit.id} benefit={benefit} />
            })}
          </InfiniteScroller>
        )}
        {isFetching && (
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        )}
        {!isFetching && !benefits?.length && renderEmptyState()}
      </Section>
    </PageLayout>
  )
}

export default SearchResults
