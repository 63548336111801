import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    body: {
      backgroundColor: '#fff',
      width: 594,
      margin: '-50px auto 0 auto',
      padding: '80px 64px',
    },
    h4: {
      fontSize: 32,
      fontWeight: 600,
    },
    textfield: {
      border: '1px solid #828282',
      color: '#4F4F4F',
      padding: '18px 24px',
      fontSize: 12,
      '& input': {
        padding: 0,
        textTransform: 'uppercase',
      },
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    dragAndDrop: {
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px dashed #828282',
    },
    blackText: {
      color: '#333333',
      textTransform: 'uppercase',
      fontSize: 12,
      marginTop: 16,
      fontWeight: 500,
      letterSpacing: '1px',
    },
    greyText: {
      color: '#BDBDBD',
      fontSize: 12,
      textTransform: 'uppercase',
      marginTop: 16,
      fontWeight: 500,
      letterSpacing: '1px',
    },
    fileButton: {
      color: '#2F80ED',
      textTransform: 'uppercase',
      fontSize: 12,
      marginTop: 16,
      fontWeight: 500,
      letterSpacing: '1px',
      fontFamily: ' Proxima Nova, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;',
    },
    acceptedFiles: {
      color: ' #828282',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '130%',
      marginTop: 12,
    },
    uploadedFileWrapper: {
      padding: '15px 24px',
      border: '1px solid #828282',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      color: '#2F80ED',
      fontSize: 12,
      marginTop: 16,
      fontWeight: 500,
      letterSpacing: '1px',
      fontFamily: ' Proxima Nova, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;',
    },
  }
})
