import React from 'react'
import { Box, Grid, GridProps } from '@material-ui/core'
import BenefitPreview from 'components/shared/BenefitPreview'
import { BenefitPreviewDTO } from 'types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './styles'
import ErrorMessage from '../../../../panel/components/ErrorMessage'

export interface BenefitPreviewsProps extends GridProps {
  items: Array<BenefitPreviewDTO>
  useOverlay?: boolean
  variant: 'standard' | 'long'
  carouselWideItem?: boolean
  loading?: boolean
  error?: string
}

const BenefitPreviews: React.FC<BenefitPreviewsProps> = ({
  items,
  variant,
  useOverlay,
  carouselWideItem = false,
  loading,
  error,
  ...props
}) => {
  const classes = useStyles()

  return loading ? (
    <Box mt={4} display='flex' justifyContent='center'>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {error && (
        <Box my={2}>
          <ErrorMessage text={error} />
        </Box>
      )}
      <Box className={classes.gridWrapper}>
        <Grid container className={classes.grid} {...props}>
          {items.map((item) => (
            <Grid
              item
              md={variant === 'long' ? 6 : 4}
              key={item.id}
              className={`${classes.gridItem} ${classes[variant]} ${carouselWideItem ? classes.carouselWidth : ''}`}
            >
              <BenefitPreview useOverlay={useOverlay} benefit={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default BenefitPreviews
