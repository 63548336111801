import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import rootReducer from 'reducer'
import thunk from 'redux-thunk'

const loggerMiddleware = createLogger()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const store = configureStore({
  reducer: combineReducers({ ...rootReducer }),
  middleware: [loggerMiddleware, thunk],
})

export default store
