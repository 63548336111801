import React, { SelectHTMLAttributes, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import { OrganizationDTO } from '../dtos/OrganizationDTO'
import useCredentials from '../hooks/useCredentials'
import { useParams } from 'react-router-dom'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  organizationId?: string
  subOrganizationId?: string
  organizationName?: string
  placeholder?: string
  onOrganizationSelect: (value: string) => void
}

const SubOrganizationsDropdown: React.FC<Props> = ({
  organizationId,
  organizationName,
  onOrganizationSelect,
  placeholder,
  subOrganizationId,
  ...props
}) => {
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [subOrganizations, setSubOrganizations] = useState<Array<OrganizationDTO> | null>(null)

  const loadSubOrganizations = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/organizations/${organizationId}/sub_organizations`, {
        headers: credentials,
      })
      setSubOrganizations(res.data.sub_organizations)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [credentials, organizationId])

  useEffect(() => {
    loadSubOrganizations()
  }, [loadSubOrganizations, organizationId])

  return loading ? (
    <CircularProgress />
  ) : (
    <div className='select'>
      <select
        value={subOrganizationId || organizationName}
        onChange={(event) => onOrganizationSelect(event.target.value)}
        {...props}
      >
        <option value=''>{placeholder || 'Select sub-organization'}</option>
        {subOrganizations?.map(({ id, name }) => (
          <option value={subOrganizationId !== undefined ? id : name} key={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SubOrganizationsDropdown
