import React, { FC } from 'react'
import { Modal as MaterialModal } from '@material-ui/core'
import { useStyles } from './styles'

interface Props {
  title?: string
  opened: boolean
  onClose: () => void
  width?: number
}

const Modal: FC<React.HTMLAttributes<HTMLElement> & Props> = ({ onClose, title = '', children, opened, width }) => {
  const classes = useStyles({ width })

  return (
    <MaterialModal open={opened}>
      <div className='column is-flex is-justify-content-center is-align-items-center' style={{ height: '100%' }}>
        <div className={`box ${classes.modalBox}`}>
          <div className='is-flex is-justify-content-space-between is-align-items-center mb-4'>
            <h4 className='title mb-0 mr-4 is-size-4'>{title}</h4>
            <button type='button' className='delete' aria-label='close' onClick={onClose} />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </MaterialModal>
  )
}

export default Modal
