import React, { useState, FC, useCallback, useEffect } from 'react'
import axios from 'axios'
import useCredentials from '../../hooks/useCredentials'
import CircularSpinner from '../../components/CircularSpinner'
import ErrorMessage from '../../components/ErrorMessage'
import Modal from '../../components/Modal/Modal'

interface ChargifyProduct {
  id: string
  name: string
}

const initialFormData = {
  chargify_product_id: '',
  initiation: '',
}

interface Props {
  leadId?: string
  refetchLead: () => void
}

const initiations = ['full', 'half', 'no']

const AssignProductButton: FC<Props> = ({ leadId, refetchLead }) => {
  const { credentials } = useCredentials()
  const [assignModalOpened, setAssignModalOpened] = useState<boolean>(false)
  const [formData, setFormData] = useState<typeof initialFormData>(initialFormData)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadDataError, setLoadDataError] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [products, setProducts] = useState<ChargifyProduct[]>([])

  const loadProducts = useCallback(async () => {
    try {
      const res = await axios.get('/api/panel/chargify_products', {
        headers: credentials,
      })
      setProducts(res.data.records)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials])

  useEffect(() => {
    loadProducts()
  }, [loadProducts])

  const assignProduct = async () => {
    setError('')
    if (!formData.chargify_product_id || !formData.initiation) {
      setError('Please choose options')
      return
    }
    try {
      setSubmitting(true)
      await axios.patch(`/api/panel/leads/${leadId}/assign_chargify_product`, formData, {
        headers: credentials,
      })
      setAssignModalOpened(false)
      refetchLead?.()
    } catch (err) {
      setError(err.response?.message || 'Something went wrong')
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (name: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <>
      <button type='button' className='button is-info' onClick={() => setAssignModalOpened(true)}>
        Assign Chargify product
      </button>
      <Modal
        width={400}
        opened={assignModalOpened}
        onClose={() => setAssignModalOpened(false)}
        title='Assign Chargify product'
      >
        {loading ? (
          <CircularSpinner />
        ) : (
          <>
            <div className='field'>
              <div className='control'>
                <div className='select is-fullwidth'>
                  <select
                    required
                    value={formData.chargify_product_id}
                    onChange={(event) => handleChange('chargify_product_id', event.target.value)}
                  >
                    <option value={undefined}>Product</option>
                    {products.map(({ id, name }) => (
                      <option value={id}>{name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <div className='select is-fullwidth'>
                  <select
                    required
                    value={formData.initiation}
                    onChange={(event) => handleChange('initiation', event.target.value)}
                  >
                    <option value={undefined}>Initiation</option>
                    {initiations.map((item) => (
                      <option value={item}>{item[0].toUpperCase() + item.slice(1)}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='is-flex is-justify-content-center mt-4'>
              <button type='button' className={`button is-info ${submitting && 'is-loading'}`} onClick={assignProduct}>
                Send payment request
              </button>
            </div>
          </>
        )}
        {(loadDataError || error) && <ErrorMessage text={loadDataError || error} />}
      </Modal>
    </>
  )
}

export default AssignProductButton
