import React from 'react'
import { ModalProps } from '@material-ui/core'
import { ButtonActionProps } from 'components/shared/ButtonAction'
import DefaultModalContent, { DefaultModalProps } from 'components/shared/DefaultModal/DefaultModalContent'
import useToggle from 'hooks/useToggle'

export interface Props extends DefaultModalProps, Omit<ModalProps, 'open' | 'children'> {
  toggleButton: React.FC<Omit<ButtonActionProps, 'text'>>
  toggleButtonFullWidth?: boolean
  toggleButtonClassName?: string
}

const DefaultModal: React.FC<Props> = ({
  toggleButton: ToggleButton,
  toggleButtonFullWidth = true,
  toggleButtonClassName,
  ...props
}) => {
  const [isModalOpen, toggleModal] = useToggle(false)

  return (
    <>
      <ToggleButton onClick={toggleModal} fullWidth={toggleButtonFullWidth} className={toggleButtonClassName || ''} />
      <DefaultModalContent isModalOpen={isModalOpen} toggleModal={toggleModal} {...props} />
    </>
  )
}

export default DefaultModal
