import React, { FC } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { AutocompleteInput } from './AutocompleteInput'

interface Props {
  onChange: (value: string) => void
  placeholder?: string
  onSelect?: (value: string) => void
  address?: string
}

const PlacesAutocomplete: FC<Props> = ({ address = '', onSelect, onChange, placeholder }) => {
  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'googleMapInit',
    requestOptions: {},
    debounce: 300,
  })

  const onInputChange = (value: string) => {
    setValue(value)
    onChange(value)
  }

  const onAddressSelect = (value: string) => {
    setValue(value, false)
    clearSuggestions()
    onChange(value)
    onSelect?.(value)
  }

  return (
    <AutocompleteInput
      matches={data.map(({ description }) => description)}
      onSelect={onAddressSelect}
      onChange={onInputChange}
      clearSuggestions={clearSuggestions}
      value={address}
      placeholder={placeholder}
    />
  )
}

export { PlacesAutocomplete }
