import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { UserDTO } from '../../dtos/UserDTO'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { deleteMember } from '../../common/requests'
import BackButton from '../../components/BackButton'

const SubUsers: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const { id: parentMemberId } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [subUsers, setSubUsers] = useState<Array<UserDTO>>([])
  const [parentName, setParentName] = useState<Array<UserDTO>>([])

  const loadsubUsers = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(`/api/panel/members/${parentMemberId}/users`, {
        headers: credentials,
      })
      setSubUsers(res.data.users)
      setParentName(res.data.member_name)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, parentMemberId])

  useEffect(() => {
    loadsubUsers()
  }, [loadsubUsers])

  const deleteSubUser = async (id: string) => {
    if (confirm('Do you want to delete?')) {
      try {
        await deleteMember(id, credentials)
        const newRows = subUsers.filter((user) => user.id !== id)
        setSubUsers(newRows)
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        {hasEditPermission && (
          <Link to={`/panel/members/new?parent=${parentMemberId}`} className='button is-link'>
            New sub user
          </Link>
        )}
      </div>
      <TableWrapper
        title={`Sub users of ${parentName}`}
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!subUsers?.length}
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {subUsers?.map((user) => (
              <tr key={user.id}>
                <th>{user.id}</th>
                <td>{user.email}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>
                  <div className='is-flex is-justify-content-flex-end'>
                    <Link to={`/panel/members/${user.id}`} className='button is-text mr-2'>
                      Show
                    </Link>
                    {hasEditPermission && (
                      <Link to={`/panel/members/${user.id}/edit`} className='button is-text mr-2'>
                        Edit
                      </Link>
                    )}
                    {hasEditPermission && (
                      <button type='button' className='button is-danger' onClick={() => deleteSubUser(user.id)}>
                        Delete
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default SubUsers
