import React from 'react'

interface Props {
  text?: string
}

const ErrorMessage: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  className = '',
  text,
}): JSX.Element => {
  return <h2 className={`has-text-danger mb-2 ${className}`}>{text || 'Something is wrong'}</h2>
}

export default ErrorMessage
