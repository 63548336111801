import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { applyParamsToUrlPath } from '../utils'
import { OrganizationDTO } from '../dtos/OrganizationDTO'
import TableWrapper from '../layouts/TableWrapper'
import useCredentials from '../hooks/useCredentials'
import { usePagination } from '../hooks/usePagination'
import { ActionsCell } from './ActionsCell'

const SubOrganizationsTable: React.FC = () => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [rows, setRows] = useState<Array<OrganizationDTO>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()

  const loadSubOrganizations = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/organizations/${params.id}/sub_organizations`),
        {
          headers: credentials,
        }
      )
      setRows(res.data.sub_organizations)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, setTotalPageCount])

  useEffect(() => {
    loadSubOrganizations()
  }, [loadSubOrganizations])

  const deleteOrg = async (id: string) => {
    if (confirm('Do you want to delete?')) {
      try {
        await axios.delete(`/api/panel/organizations/${params.id}/sub_organizations/${id}`, {
          headers: credentials,
        })
        loadSubOrganizations()
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  return (
    <TableWrapper
      title='Sub-organizations'
      loading={loading}
      isError={!!error}
      errorMessage={error}
      isEmpty={!rows?.length}
      pagination={{
        totalPageCount,
        currentPage: currentPageNumber,
        onChangePage: setPageNumber,
      }}
      actions={
        hasEditPermission && (
          <div className='mb-4 is-flex is-justify-content-flex-end'>
            <Link to={`/panel/organizations/${params.id}/new_sub_organization`} className='button is-link'>
              New
            </Link>
          </div>
        )
      }
    >
      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Subdomain</th>
            <th>Chargify Subdomain</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id} className='is-clickable'>
              <th>{row.id}</th>
              <td>{row.name}</td>
              <td>{row.subdomain}</td>
              <td>{row.chargify_subdomain}</td>
              <td>
                <ActionsCell>
                  {hasEditPermission && (
                    <Link
                      to={`/panel/organizations/${params.id}/edit_sub_organization/${row.id}`}
                      className='button is-text mr-2'
                    >
                      Edit
                    </Link>
                  )}
                  {hasEditPermission && (
                    <button type='button' className='button is-danger' onClick={() => deleteOrg(row.id)}>
                      Delete
                    </button>
                  )}
                </ActionsCell>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}

export default SubOrganizationsTable
