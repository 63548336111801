import React, { useEffect, useState } from 'react'
import RevealButton from 'components/shared/buttons/RevealButton'
import SectionSecondary from 'components/shared/SectionSecondary'
import { Box, CardMedia, ModalProps, Typography } from '@material-ui/core'
import DefaultModal from 'components/shared/DefaultModal'
import ParseHtml from 'html-react-parser'
import { useLazyQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'constants/queries'
import { Benefit, Maybe, RedemptionType } from 'types/generatedGql'
import RegistrationForm from 'components/shared/modals/RedeemBenefitModal/RegistrationForm'
import ButtonAction from 'components/shared/ButtonAction'
import { useStyles } from './styles'

interface Props {
  slug: string
  benefit: Benefit
}

const getRevealButtonRedemptionType = (type: Maybe<RedemptionType>): string => {
  switch (type) {
    case RedemptionType.ReferralLink:
      return 'link'
    case RedemptionType.GeneralCode:
    case RedemptionType.PersonalizedCode:
      return 'code'
    default:
      return ''
  }
}

const RedeemBenefitModal: React.FC<Omit<ModalProps, 'open' | 'children'> & Props> = (props) => {
  const classes = useStyles()
  const { benefit, slug } = props
  const { redemptionType } = benefit
  const [isRedeemed, setIsRedeemed] = useState(false)

  const [getCurrentUser, { data: userData, loading: userDataLoading }] = useLazyQuery(GET_CURRENT_USER)
  const user = userData?.currentUser

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  const renderContent = (type: Maybe<RedemptionType>) => {
    switch (type) {
      case RedemptionType.ReferralLink:
        return 'Please follow the link to access your benefit on our partner’s website'
      case RedemptionType.GeneralCode:
      case RedemptionType.PersonalizedCode:
        return 'Please use your personal redemption code by following the link to our partner’s website'
      case RedemptionType.Registration:
        return 'Please fill the form'
      default:
        return ''
    }
  }

  return (
    <DefaultModal
      toggleButton={(buttonProps) => (
        <ButtonAction
          type='button'
          text='Redeem'
          size='large'
          bold
          fullWidth
          disabled={user?.isDemo}
          {...buttonProps}
        />
      )}
      toggleButtonClassName={classes.redeemButton}
      runAfterClose={() => setIsRedeemed(false)}
      header={
        !!benefit?.images?.length && (
          <Box position='relative'>
            <CardMedia
              className={classes.media}
              image={benefit?.images?.length && benefit?.images[0] ? benefit?.images[0]?.medium : ''}
              title='Contemplative Reptile'
              component='img'
            />
            {/* <DigitalCard className={classes.digitalCard} /> */}
            <Typography variant='h4' className={classes.benefitName}>
              {benefit.name}
            </Typography>
          </Box>
        )
      }
      content={
        isRedeemed ? (
          <Box display='flex' justifyContent='center' mb={2}>
            <Typography variant='h4' color='primary'>
              Thank you!
            </Typography>
          </Box>
        ) : (
          <SectionSecondary title='How To Redeem' className={classes.howToRedeem}>
            {benefit.slug === 'united-airlines' ? (
              <>
                <p>
                  1) Copy your EXEC email address: <i>{user && `${user.membershipId}@exec-member.com`}</i>
                </p>
                <p>
                  2) Click the link and enter your exec-member.com email alias on the United airlines form to verify
                  your affiliation to EXEC.
                </p>
              </>
            ) : (
              <>
                {benefit.redemptionInstruction
                  ? ParseHtml(benefit.redemptionInstruction)
                  : renderContent(redemptionType)}
                {redemptionType === RedemptionType.Registration && (
                  <RegistrationForm slug={slug} user={user} setIsRedeemed={setIsRedeemed} />
                )}
              </>
            )}
          </SectionSecondary>
        )
      }
      actions={
        redemptionType !== RedemptionType.Registration &&
        !isRedeemed && (
          <RevealButton
            text={`Reveal ${getRevealButtonRedemptionType(redemptionType)}`}
            bold
            benefit={benefit}
            disabled={userDataLoading}
            slug={slug}
          />
        )
      }
      {...props}
    />
  )
}

export default RedeemBenefitModal
