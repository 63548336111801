import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { BenefitDTO } from '../../dtos/BenefitDTO'
import './HomepageManagement.scss'
import DraggableSection from './DraggableSection'
import { applyParamsToUrlPath } from '../../utils'
import useCredentials from '../../hooks/useCredentials'

interface Props {
  title: string
  queryParamName: string
}

const getOrderIds = (benefits: BenefitDTO[]): number[] => {
  return benefits.map(({ id }) => id)
}

const HomepageSection: React.FC<Props> = ({ title, queryParamName }): JSX.Element => {
  const { credentials } = useCredentials()

  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [benefits, setBenefits] = useState<Array<BenefitDTO>>([])

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [success])

  const loadBenefits = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      setError('')
      const res = await axios.get(applyParamsToUrlPath({ [queryParamName]: 'true' }, '/api/panel/benefits'), {
        headers: credentials,
      })
      setBenefits(res.data.benefits)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, queryParamName])

  useEffect(() => {
    loadBenefits()
  }, [loadBenefits])

  const onSaveOrder = async () => {
    try {
      setUpdating(true)
      setError('')

      const formData = new FormData()
      formData.set('order', JSON.stringify(getOrderIds(benefits)))
      await axios.post(applyParamsToUrlPath({ [queryParamName]: 'true' }, '/api/panel/benefits/reorder'), formData, {
        headers: credentials,
      })
      setSuccess(true)
    } catch (e) {
      setError(e?.message)
    } finally {
      setUpdating(false)
    }
  }

  return (
    <DraggableSection
      benefits={benefits}
      setBenefits={setBenefits}
      title={title}
      loading={loading}
      updating={updating}
      error={error}
      success={success}
      onSaveOrder={onSaveOrder}
    />
  )
}

export default HomepageSection
