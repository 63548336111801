import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const defaultExecTheme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: 'Proxima Nova, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: 16,
    },
    palette: {
      type: 'light',
      text: {
        secondary: '#fff',
      },
      primary: {
        main: '#C97E48',
        light: '#C97E4859',
      },
      secondary: {
        main: '#B7985C',
      },
      tertiary: {
        main: '#111',
      },
      quaternary: {
        main: '#EEE',
      },
      neutrals: {
        black: '#333333',
        mediumDark: '#828282',
      },
    },
    overrides: {
      MuiDivider: {
        root: {
          backgroundColor: '#EAEAEA',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          borderColor: '#fff',
        },
        notchedOutline: {
          borderColor: '#fff !important',
        },
      },
      MuiLink: {
        underlineHover: {
          textDecoration: 'underline',
        },
      },
      MuiCheckbox: {
        root: {
          color: '#BDBDBD',
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          letterSpacing: '1px',
          textTransform: 'none',
        },
        sizeLarge: {
          fontSize: '0.9rem',
          padding: '0.7rem 1.4rem',
        },
        sizeSmall: {
          fontSize: '0.7rem',
          padding: '0.5rem 0.7rem',
        },
      },
      MuiPaper: {
        root: {
          borderRadius: 0,
        },
        rounded: {
          borderRadius: 0,
        },
      },
      MuiContainer: {
        root: {
          width: '90%',
        },
      },
      MuiCardMedia: {
        root: {
          userSelect: 'none',
        },
      },
      MuiInputLabel: {
        root: {
          color: '#fff',
          fontSize: '1rem',
        },
      },
      MuiInputBase: {
        root: {
          color: '#fff',
        },
        input: {
          height: '1em',
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: '80px',
          padding: '0 1rem',
          [breakpoints.up('sm')]: {
            minHeight: '80px',
            padding: '0 2rem',
          },
          [breakpoints.up('md')]: {
            padding: '0 3rem',
          },
          [breakpoints.up('lg')]: {
            minHeight: '80px',
            padding: '0 4rem',
          },
        },
      },
      MuiTabs: {
        root: {
          borderBottom: '1px solid #EAEAEA',
          color: 'black',
          '& .Mui-selected': {
            opacity: '1 !important',
            color: '#C97E48',
          },
          '& .MuiTab-textColorInherit': {
            opacity: 0.6,
          },
        },
      },
      MuiTab: {
        root: {
          paddingLeft: '2px',
          paddingRight: '2px',
          marginRight: '2rem',
          minWidth: 'unset !important',
        },
        wrapper: {
          alignItems: 'start',
          width: 'unset',
        },
      },
      MuiTable: {
        root: {
          border: '2px solid #000',
          padding: '1rem 2rem',
          [breakpoints.down('sm')]: {
            padding: '0.75rem',
          },
          [breakpoints.down('xs')]: {
            padding: '0.5rem',
          },
          borderCollapse: 'separate',
          '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            borderBottom: '1px solid #C97E48',
          },
        },
      },
      MuiTableCell: {
        root: {
          [breakpoints.down('sm')]: {
            padding: '0.75rem',
          },
          [breakpoints.down('xs')]: {
            padding: '0.5rem',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#fff',
          },
          '.MuiTabPanel-root': {
            padding: '0 !important',
          },
        },
      },
    },
  })
)

export default defaultExecTheme
