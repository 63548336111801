import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { Link, useHistory } from 'react-router-dom'
import { authLogin } from '../../reducer/auth'
import DefaultLayout from '../../layouts/DefaultLayout'
import { RouterPath } from '../../router/routes'

interface ErrorParams {
  message: string
}

const getForgotPasswordLink = (email: string): string => {
  const params = new URLSearchParams()
  params.set('email', email || '')
  return `${RouterPath.FORGOT_PASSWORD}?${params.toString()}`
}

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<ErrorParams>({
    message: '',
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(
      authLogin({
        email,
        password,
      })
    )
      .then(unwrapResult)
      .then(() => {
        history.push(RouterPath.MAIN)
      })
      .catch((err: ErrorParams) => {
        setError(err)
      })
  }

  return (
    <DefaultLayout hideNavbar>
      <div className='container'>
        <div className='columns is-justify-content-center'>
          <div className='column is-half'>
            <div className='box'>
              <h4 className='is-size-4 has-text-centered'>Log In</h4>
              <form onSubmit={handleSubmit}>
                <div className='field'>
                  <label className='label'>Email</label>
                  <div className='control'>
                    <input
                      type='email'
                      className='input'
                      name='email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Password</label>
                  <div className='control'>
                    <input
                      type='password'
                      className='input'
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>
                <div className='field'>
                  <button type='submit' className='button is-fullwidth is-primary'>
                    Login
                  </button>
                </div>
                {error.message && <p>{error.message}</p>}
                <Link to={getForgotPasswordLink(email)} className='is-link'>
                  Forgot password
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Login
