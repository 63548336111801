import React, { useState } from 'react'
import { Box, Typography, Button, Slide, IconButton, BoxProps } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import useToggle from 'hooks/useToggle'
import { useMutation } from '@apollo/client'
import { REDEEM_MUTATION } from 'constants/mutations'
import { Benefit, Redemption } from 'types/generatedGql'
import { useStyles } from './styles'

interface Props {
  slug: string
  benefit: Benefit
}

interface RevealButtonProps extends BoxProps {
  text: string
  href?: string
  bold?: boolean
  disabled?: boolean
}

const RevealButton: React.FC<RevealButtonProps & Props> = ({ text, bold, benefit, disabled, ...props }) => {
  const classes = useStyles()
  const { slug } = props
  const [isCodeRevealed, toggleCodeReveal] = useToggle(false)
  const [redemption, setRedemption] = useState<Partial<Redemption>>({})
  const [redeem] = useMutation(REDEEM_MUTATION)
  const handleRedeemClicked = async () => {
    let response
    try {
      response = await redeem({
        variables: {
          benefitSlug: slug,
        },
      })
      setRedemption(response.data.redeemBenefit.redemption)
      toggleCodeReveal()
    } catch (e) {
      console.error(e)
    }
  }

  const { redemptionLink: link, redemptionCode: code } = redemption

  return (
    <Box position='relative' width='100%' {...props}>
      <Slide timeout={{ appear: 0, enter: 0, exit: 1000 }} direction='up' in={!isCodeRevealed}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          size='large'
          className={`${classes.revealButton} ${bold ? classes.bold : ''}`}
          onClick={handleRedeemClicked}
          disabled={disabled}
        >
          {text}
        </Button>
      </Slide>
      <Box className={classes.codeWrapper}>
        {!disabled && (
          <Box className={classes.codeFlexWrapper}>
            <Typography variant='body2' className={classes.code}>
              {code || link || 'EXEC'}
            </Typography>
            {code && (
              <CopyToClipboard text={code} options={{ format: 'text/plain' }}>
                <IconButton className={classes.copyToClipboardButton}>
                  <AssignmentOutlinedIcon />
                </IconButton>
              </CopyToClipboard>
            )}
            {link && (
              <IconButton className={classes.goToLinkButton} component='a' target='_blank' href={link}>
                <ExitToAppIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default RevealButton
