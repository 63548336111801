import { RedemptionType } from 'types/generatedGql'

export const newBenefitInitialState = {
  id: 0,
  active: true,
  name: '',
  city_id: 0,
  city: '',
  category_id: 0,
  category: {
    id: 0,
    created_at: '',
    name: '',
    updated_at: '',
    slug: '',
  },
  brand_id: 0,
  brand: {
    children_count: 0,
    created_at: '',
    depth: 0,
    description: '',
    id: 0,
    lft: 0,
    name: '',
    parent_id: 0,
    rgt: 0,
    slug: '',
    updated_at: '',
  },
  additional_information: '',
  address_1: '',
  address_2: '',
  benefit_summary: '',
  benefits: [],
  country: '',
  description: '',
  short_description: '',
  end_date: '',
  excluded_organizations: [],
  included_organizations: [],
  featured: false,
  fileImages: [],
  images: [],
  is_popular: false,
  is_hero: false,
  other_rate_offer: '',
  postal_code: '',
  rates: [],
  redemption_code: '',
  redemption_instruction: '',
  redemption_link: '',
  redemption_request: false,
  redemption_type: RedemptionType.ReferralLink,
  region: '',
  slug: '',
  start_date: '',
  terms_and_condition: '',
  urls: [],
  website: '',
}

export const existingBenefitInitialState = { ...newBenefitInitialState, created_at: '' }
