import React from 'react'
import DefaultCarousel from 'components/shared/DefaultCarousel'
import { BenefitImage } from 'types/generatedGql'

interface Props {
  images: BenefitImage[]
}

const BenefitShowcaseCarousel: React.FC<Props> = ({ ...props }) => {
  return <DefaultCarousel showArrows={false} infiniteLoop dotsPosition='secondary' {...props} />
}

export default BenefitShowcaseCarousel
