import GoogleMapReact from 'google-map-react'
import { useHistory } from 'react-router-dom'
import React, { FC, useCallback } from 'react'
import { Marker as IMarker } from 'pages/HotelsFeaturedCities/HotelsFeaturedCities'
import { Box } from '@material-ui/core'
import MarkerBottomIcon from 'images/marker-bottom.svg'
import { useStyles } from './styles'

interface Props extends GoogleMapReact.Props {
  markers?: IMarker[]
}

const Marker = ({ text, path }: { text: string; path: string }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box minWidth='86px' minHeight='40px' className={classes.markerWrapper}>
      <Box className={classes.marker} onClick={() => history.push(path)}>
        {text}
      </Box>
      <Box mt='-2px' textAlign='center' width='100%'>
        <img src={MarkerBottomIcon} alt='marker' />
      </Box>
    </Box>
  )
}

const worldBounds = {
  north: 85,
  south: -85,
  west: -179,
  east: 179,
}

export const HotelsMap: FC<Props> = ({ markers, children, ...props }) => {
  const handleApiLoaded = useCallback(({ map }) => {
    map.setOptions({
      restriction: {
        latLngBounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(worldBounds.south, worldBounds.west),
          new google.maps.LatLng(worldBounds.north, worldBounds.east)
        ),
        strictBounds: false,
      },
    })
  }, [])

  return (
    <div style={{ height: '100vh', width: '100%', position: 'absolute', left: 0, top: '-28px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_PLACES_API_KEY as string }}
        options={{ fullscreenControl: false, zoomControl: false }}
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
        {...props}
      >
        {markers?.map((item) => (
          <Marker lat={item.lat} lng={item.lng} text={item.name as string} path={item.path} key={item.lat} />
        ))}
      </GoogleMapReact>
    </div>
  )
}
