import React, { useEffect, useState, useCallback, ChangeEvent } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import Validator from 'validator'
import { v4 as uidV4 } from 'uuid'
import { BenefitDTO } from 'panel/dtos/BenefitDTO'
import { CategoryDTO } from 'panel/dtos/CategoryDTO'
import { BrandDTO } from 'panel/dtos/BrandDTO'
import { BenefitErrorDTO } from 'panel/dtos/BenefitErrorDTO'
import { CityDTO } from 'panel/dtos/CityDTO'
import { getAllCities } from 'panel/common/requests'
import CircularSpinner from 'panel/components/CircularSpinner'
import { DropzoneArea } from 'material-ui-dropzone'
import { Phrases } from 'constants/phrases'
import { BenefitRate, BenefitTextDTO } from '../../dtos/BenefitDTO'
import { PlacesAutocomplete } from '../PlacesAutocomplete'
import Modal from '../Modal/Modal'
import NewCityForm from '../../pages/Cities/NewCityForm'
import useCredentials from '../../hooks/useCredentials'
import './BenefitForm.scss'
import ExcludedOrganizationsControl from './ExcludedOrganizationsControl'
import RedemptionsFields from './RedemptionsFields'
import { regions } from '../../const'
import { alphabeticalSortCallback } from '../../utils'
import NewBrandForm from '../../pages/Benefits/NewBrandForm'

interface BenefitFormState {
  formData: BenefitDTO
  onChange: (data: BenefitDTO) => void
  onSubmit: () => void
  submitting: boolean
  errors: BenefitErrorDTO
}

const MEGA_BYTES_10 = 10485760

const sortCities = (citiesWithRegionCodes: Array<CityDTO>) => {
  const sortedCities = [...citiesWithRegionCodes]
  sortedCities.sort((a: CityDTO, b: CityDTO) => {
    return alphabeticalSortCallback(a.region_code, b.region_code) || alphabeticalSortCallback(a.name, b.name)
  })
  const citiesRegionNA = sortedCities.filter(({ region_code }) => region_code === 'NA')
  return [...citiesRegionNA, ...sortedCities.filter(({ region_code }) => region_code !== 'NA')]
}

const BenefitForm: React.FC<BenefitFormState> = ({ formData, onChange, onSubmit, submitting, errors }): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<CategoryDTO[]>([])
  const [brands, setBrands] = useState<BrandDTO[]>([])
  const [cities, setCities] = useState<CityDTO[]>([])
  const [imageErrors, setImageErrors] = useState<string[]>([])
  const [newCityModalOpened, setNewCityModalOpened] = useState<boolean>(false)
  const [newBrandModalOpened, setNewBrandModalOpened] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<Partial<BenefitDTO>>({})

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setValidationErrors([])
    if (formData.website && !/^(http|https)/.test(String(formData.website).toLowerCase())) {
      setValidationErrors((prevErrors: Partial<BenefitDTO>) => ({
        ...prevErrors,
        website: ['Website should start from http/https.'],
      }))
      return
    }

    if (formData.website && !Validator.isURL(formData.website)) {
      setValidationErrors((prevErrors: Partial<BenefitDTO>) => ({
        ...prevErrors,
        website: ['Website is invalid.'],
      }))
      return
    }

    onSubmit()
  }

  const loadCities = useCallback(async () => {
    const resCities = await getAllCities(credentials)
    if (resCities.data?.cities) {
      const citiesWithRegionCodes = resCities.data.cities.map((city) => {
        const currentRegion = regions.find(({ name }) => name === city.region)
        city.region_code = currentRegion ? currentRegion.code : ''
        return city
      })
      setCities(sortCities(citiesWithRegionCodes))
    }
  }, [credentials])

  const loadBrands = useCallback(async () => {
    const resBrands = await axios.get('/api/panel/brands', {
      headers: credentials,
    })
    setBrands(resBrands.data)
  }, [credentials])

  const loadData = useCallback(async (): Promise<void> => {
    try {
      const resCategories = await axios.get('/api/panel/categories', {
        headers: credentials,
      })
      setCategories(resCategories.data)

      await loadBrands()

      await loadCities()

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [credentials, loadBrands, loadCities])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onChangeRateField = (event: ChangeEvent<HTMLInputElement>, id: string, fieldName: keyof BenefitRate) => {
    const updatedRates = formData.rates.map((rate: BenefitRate) => {
      if (id === rate.id) {
        rate[fieldName] = event.target.value
      }
      return rate
    })
    onChange({ ...formData, rates: updatedRates })
  }

  const addNewRateField = () => {
    const rates = [...formData.rates]
    rates.push({ id: uidV4(), category: '', exec_rate: '', standard_rate: '' })
    onChange({ ...formData, rates })
  }

  const onChangeBenefitField = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const updatedBenefits = formData.benefits.map((benefit: BenefitTextDTO) => {
      if (id === benefit.id) {
        benefit.value = event.target.value
      }
      return benefit
    })
    onChange({ ...formData, benefits: updatedBenefits })
  }

  const addNewBenefitField = () => {
    const benefits = [...formData.benefits]
    benefits.push({ id: uidV4(), value: '' })
    onChange({ ...formData, benefits })
  }

  const afterCitySaveCallback = async () => {
    await loadCities()
    setNewCityModalOpened(false)
  }

  const afterBrandSaveCallback = async () => {
    await loadBrands()
    setNewBrandModalOpened(false)
  }

  if (loading) {
    return <CircularSpinner />
  }

  const onCityChange = (cityId: string) => {
    const regionData = cities.find((city) => city.id === Number(cityId))
    onChange({ ...formData, city_id: cityId, region: regionData?.region || null })
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <div className='field'>
          <label className='label'>Name*</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              required
              value={formData.name}
              onChange={(event) => onChange({ ...formData, name: event.target.value })}
            />
          </div>
          {errors?.name?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='category' className='label'>
            Category*
            <div className='control'>
              <div className='select'>
                <select
                  name='category'
                  value={formData.category_id}
                  onChange={(event) => onChange({ ...formData, category_id: event.target.value })}
                >
                  <option value={0}>Select category...</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </label>
          {errors?.category?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <RedemptionsFields formData={formData} onChange={onChange} errors={errors} />

        <div className='field'>
          <label htmlFor='images' className='label'>
            Images
            <div className='control'>
              <DropzoneArea
                showAlerts={false}
                filesLimit={100}
                maxFileSize={MEGA_BYTES_10}
                initialFiles={formData.images.map(({ image }) => image)}
                onChange={(fileImages) => {
                  onChange({ ...formData, fileImages })
                }}
                onDropRejected={() => {
                  setImageErrors(['File is rejected. Max size is 10Mb'])
                }}
                onDrop={() => {
                  setImageErrors([])
                }}
              />
            </div>
          </label>
          {(imageErrors || errors?.images)?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Start date</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              type='date'
              value={formData.start_date}
              onChange={(event) => onChange({ ...formData, start_date: event.target.value })}
            />
          </div>
          {errors?.start_date?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>End date</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              type='date'
              value={formData.end_date}
              onChange={(event) => onChange({ ...formData, end_date: event.target.value })}
            />
          </div>
          {errors?.end_date?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='city' className='label'>
            City
            <div className='control'>
              <div className='select'>
                <select name='city' value={formData.city_id} onChange={(event) => onCityChange(event.target.value)}>
                  <option value={0}>Select city...</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                      {city.region_code ? ` - ${city.region_code}` : ''}
                    </option>
                  ))}
                </select>
              </div>
              {hasEditPermission && (
                <button type='button' onClick={() => setNewCityModalOpened(true)} className='button is-text ml-2 mr-2'>
                  New city
                </button>
              )}
            </div>
          </label>
          {errors?.city?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='brand' className='label'>
            Brand
            <div className='control'>
              <div className='select'>
                <select
                  name='brand'
                  value={formData.brand_id}
                  onChange={(event) => onChange({ ...formData, brand_id: event.target.value })}
                >
                  <option value={0}>Select brand...</option>
                  {brands.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
              {hasEditPermission && (
                <button type='button' onClick={() => setNewBrandModalOpened(true)} className='button is-text ml-2 mr-2'>
                  New brand
                </button>
              )}
            </div>
          </label>
          {errors?.brand?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Website</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.website}
              onChange={(event) => onChange({ ...formData, website: event.target.value })}
            />
          </div>
          {(validationErrors?.website || errors?.website)?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Description preview</label>
          <div className='control'>
            <textarea
              style={{ minHeight: '90px' }}
              autoComplete='none'
              className='input'
              value={formData.short_description}
              onChange={(event) => onChange({ ...formData, short_description: event.target.value })}
            />
          </div>
          {errors?.description?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Description</label>
          <div className='control'>
            <CKEditor
              editor={ClassicEditor}
              data={formData.description}
              onChange={(event, editor) => {
                onChange({ ...formData, description: editor.getData() })
              }}
            />
          </div>
          {errors?.description?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Benefits</label>
          <div className='control'>
            <div className='mb-2'>
              <button className='button is-small has-icons-left' type='button' onClick={addNewBenefitField}>
                <i className='fa fa-plus' />
              </button>
            </div>
            <ul>
              {formData.benefits?.map((benefit: BenefitTextDTO) => (
                <li key={benefit.id} className='mb-2'>
                  <div className='field is-flex'>
                    <div className='control is-flex-grow-1 has-icons-right'>
                      <input
                        type='text'
                        className='input'
                        value={benefit.value}
                        onChange={(e) => onChangeBenefitField(e, benefit.id)}
                      />
                    </div>
                    <button
                      className='button has-icons-left'
                      type='button'
                      onClick={() => {
                        const benefits = [...formData.benefits]
                        onChange({ ...formData, benefits: benefits.filter(({ id }) => id !== benefit.id) })
                      }}
                    >
                      <span className='icon'>
                        <i className='fa fa-minus' />
                      </span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {errors?.benefits?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Rates</label>
          <div className='control'>
            <div className='mb-2'>
              <button className='button is-small has-icons-left' type='button' onClick={addNewRateField}>
                <i className='fa fa-plus' />
              </button>
            </div>
            <ul>
              {formData.rates?.map((rate: BenefitRate) => (
                <li key={rate.id} className='mb-2'>
                  <div className='field has-addons'>
                    <div className='control has-icons-right is-flex'>
                      <input
                        type='text'
                        className='input'
                        value={rate.category}
                        placeholder='Category'
                        onChange={(event) => onChangeRateField(event, rate.id, 'category')}
                      />
                      <input
                        type='text'
                        className='input'
                        placeholder='EXEC Rate'
                        value={rate.exec_rate}
                        onChange={(event) => onChangeRateField(event, rate.id, 'exec_rate')}
                      />
                      <input
                        type='text'
                        className='input'
                        placeholder='Standard Rate'
                        value={rate.standard_rate}
                        onChange={(event) => onChangeRateField(event, rate.id, 'standard_rate')}
                      />
                    </div>
                    <button
                      className='button has-icons-left'
                      type='button'
                      onClick={() => {
                        const rates = [...formData.rates]
                        onChange({ ...formData, rates: rates.filter(({ id }) => id !== rate.id) })
                      }}
                    >
                      <span className='icon'>
                        <i className='fa fa-minus' />
                      </span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {errors?.rates?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='additionalInformation' className='label'>
            Additional information
            <div className='control'>
              <input
                name='additionalInformation'
                type='text'
                className='input'
                value={formData.additional_information}
                onChange={(event) => onChange({ ...formData, additional_information: event.target.value })}
              />
            </div>
          </label>
          {errors?.additional_information?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label className='label'>Address-1</label>
          <div className='control'>
            <PlacesAutocomplete
              onChange={(address) => onChange({ ...formData, address_1: address })}
              address={formData.address_1}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>Address-2</label>
          <div className='control'>
            <PlacesAutocomplete
              onChange={(address) => onChange({ ...formData, address_2: address })}
              address={formData.address_2}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>Postal code</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.postal_code}
              onChange={(event) => onChange({ ...formData, postal_code: event.target.value })}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>Country</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.country}
              onChange={(event) => onChange({ ...formData, country: event.target.value })}
            />
          </div>
        </div>
        <div className='field'>
          <label htmlFor='termsAndCondition' className='label'>
            Terms And Condition
            <div className='control'>
              <input
                name='termsAndCondition'
                className='input'
                value={formData.terms_and_condition}
                onChange={(event) => onChange({ ...formData, terms_and_condition: event.target.value })}
              />
            </div>
          </label>
          {errors?.terms_and_condition?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='benefitSummary' className='label'>
            Benefit Summary
            <div className='control'>
              <input
                name='benefitSummary'
                className='input'
                value={formData.benefit_summary}
                onChange={(event) => onChange({ ...formData, benefit_summary: event.target.value })}
              />
            </div>
          </label>
          {errors?.benefit_summary?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <label htmlFor='otherRateOffer' className='label'>
            Other Rate offer
            <div className='control'>
              <input
                name='otherRateOffer'
                className='input'
                value={formData.other_rate_offer}
                onChange={(event) => onChange({ ...formData, other_rate_offer: event.target.value })}
              />
            </div>
          </label>
          {errors?.other_rate_offer?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <label className='label'>Excluded organizations</label>
          <div className='control'>
            <ExcludedOrganizationsControl
              excludedOrganizations={formData.excluded_organizations}
              onChange={(excluded_organizations) => onChange({ ...formData, excluded_organizations })}
              disabled={formData.included_organizations?.length > 0}
            />
          </div>
          {errors?.excluded_organizations?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <label className='label'>Included organizations</label>
          <div className='control'>
            <ExcludedOrganizationsControl
              excludedOrganizations={formData.included_organizations}
              onChange={(included_organizations) => onChange({ ...formData, included_organizations })}
              disabled={formData.excluded_organizations?.length > 0}
            />
          </div>
          {errors?.included_organizations?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <div className='control'>
            <label htmlFor='active' className='checkbox'>
              <input
                name='active'
                type='checkbox'
                checked={formData.active}
                onChange={(event) => onChange({ ...formData, active: event.target.checked })}
              />{' '}
              Active
            </label>
          </div>
          {errors?.active?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <div className='control'>
            <label htmlFor='featured' className='checkbox'>
              <input
                name='featured'
                type='checkbox'
                checked={formData.featured}
                onChange={(event) => onChange({ ...formData, featured: event.target.checked })}
              />{' '}
              Featured
            </label>
          </div>
          {errors?.featured?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <div className='control'>
            <label htmlFor='popular' className='checkbox'>
              <input
                name='popular'
                type='checkbox'
                checked={formData.is_popular}
                onChange={(event) => onChange({ ...formData, is_popular: event.target.checked })}
              />{' '}
              Popular
            </label>
          </div>
          {errors?.is_popular?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <div className='control'>
            <label htmlFor='hero' className='checkbox'>
              <input
                name='hero'
                type='checkbox'
                checked={formData.is_hero}
                onChange={(event) => onChange({ ...formData, is_hero: event.target.checked })}
              />{' '}
              Hero
            </label>
          </div>
          {errors?.is_hero?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
        <div className='field'>
          <div className='control'>
            <label htmlFor='marketplace' className='checkbox'>
              <input
                name='marketplace'
                type='checkbox'
                checked={formData.is_marketplace}
                onChange={(event) => onChange({ ...formData, is_marketplace: event.target.checked })}
              />{' '}
              Marketplace
            </label>
          </div>
          {errors?.is_hero?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>

        <div className='field'>
          <button className={`button is-primary ${submitting && 'is-loading'}`} type='submit' disabled={submitting}>
            Save
          </button>
          {((!!errors && !!Object.values(errors).length) ||
            (!!validationErrors && !!Object.values(validationErrors).length)) && (
            <div className='has-text-danger mt-2'>{Phrases.FormError}</div>
          )}
        </div>
      </form>
      <Modal opened={newCityModalOpened} onClose={() => setNewCityModalOpened(false)} title='New city'>
        <NewCityForm afterSaveCallback={afterCitySaveCallback} />
      </Modal>
      <Modal opened={newBrandModalOpened} onClose={() => setNewBrandModalOpened(false)} title='New brand'>
        <NewBrandForm afterSaveCallback={afterBrandSaveCallback} />
      </Modal>
    </>
  )
}

export default BenefitForm
