import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import DefaultLayout from 'panel/layouts/DefaultLayout'
import CircularSpinner from 'panel/components/CircularSpinner'
import { getCity, updateCity } from 'panel/common/requests'
import { CityDTO } from 'panel/dtos/CityDTO'
import CityForm from 'panel/components/CityForm'
import { CityErrorDTO } from '../../dtos/CityErrorDTO'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'

const EditCity: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [loadDataError, setLoadDataError] = useState('')

  const { credentials } = useCredentials()

  const [errors, setErrors] = useState<Partial<CityErrorDTO>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [city, setCity] = useState<CityDTO>({
    id: 0,
    name: '',
    featured: false,
    region: '',
    image: null,
    image_url: '',
  })

  const loadCity = useCallback(async (): Promise<void> => {
    try {
      const response = await getCity(id, credentials)
      if (response.status === 200 && response.data) {
        setCity(response.data)
      }
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, id])

  useEffect(() => {
    loadCity()
  }, [loadCity])

  const handleSubmit = async () => {
    setLoading(true)
    const data = new FormData()
    data.append('name', city.name)
    data.append('region', city.region)
    data.append('featured', city.featured.toString())
    if (city.image) {
      data.append('image', city.image)
    }
    updateCity(city.id, data, credentials)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          history.goBack()
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.data) {
          setErrors(err.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <div className='columns'>
        <div className='column is-half'>
          <div className='mb-4'>
            <BackButton />
          </div>
          <h4 className='is-size-4 mb-4'>Edit City</h4>
          {loading ? (
            <CircularSpinner />
          ) : (
            <CityForm city={city} handleSubmit={handleSubmit} handleChange={setCity} errors={errors} />
          )}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditCity
