import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
  backgroundColor?: string
  borderColor?: string
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    section: {
      backgroundColor: ({ backgroundColor = 'none' }) => backgroundColor,
      border: ({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none'),
      padding: ({ backgroundColor, borderColor }) =>
        backgroundColor || borderColor ? theme.spacing(3) : theme.spacing(3, 0),
      marginTop: ({ backgroundColor, borderColor }) => (backgroundColor || borderColor ? theme.spacing(3) : 0),
      [theme.breakpoints.down('sm')]: {
        padding: ({ backgroundColor, borderColor }) =>
          backgroundColor || borderColor ? theme.spacing(2) : theme.spacing(2, 0),
        marginTop: ({ backgroundColor, borderColor }) => (backgroundColor || borderColor ? theme.spacing(2) : 0),
      },
    },
    fullWidthSm: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        left: '50%',
        marginLeft: '-50vw',
      },
    },
    hideOnSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    fixedOnBottom: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 10,
      },
    },
    header: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      textTransform: 'uppercase',
    },
  }
})
