import Section from 'components/shared/Section'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import DetailsButton from 'components/shared/buttons/DetailsButton'
import React, { useCallback, useEffect, useState } from 'react'
import { useStyles } from 'pages/MyAccount/styles'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_BENEFITS, GET_USER_REDEMPTIONS } from 'constants/queries'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import { Redemption, CollectionMetadata } from 'types/generatedGql'
import CircularProgress from '@material-ui/core/CircularProgress'
import { configBenefitsForPreview } from 'utils'
import useGetWindowSize from 'hooks/useGetWindowSize'
import moment from 'moment'
import { DayFormat } from 'const'
import BenefitPreviews from 'components/shared/BenefitPreviews'

export const History: React.FC = () => {
  const classes = useStyles()
  const { isTablet } = useGetWindowSize()
  const [collection, setCollection] = useState<Redemption[]>([])
  const [metadata, setMetadata] = useState<Partial<CollectionMetadata>>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
  })
  const [isFetching, setIsFetching] = useState(false)

  const {
    data: benefitsData,
    error: benefitsError,
    loading: benefitsLoading,
  } = useQuery(GET_BENEFITS, {
    variables: {
      limit: 6,
    },
  })

  const handleFetchMoreCompleted = () => {
    if (data) {
      const { getUserRedemptions } = data
      collection.push(...getUserRedemptions.collection)
      setCollection(collection)
      setMetadata(getUserRedemptions.metadata)
      setIsFetching(false)
    }
  }

  const [getRedemptions, { data, error, loading }] = useLazyQuery(GET_USER_REDEMPTIONS, {
    onCompleted: handleFetchMoreCompleted,
    fetchPolicy: 'network-only',
  })

  const fetchRedemptions = useCallback(
    (page: number) => {
      setIsFetching(true)
      getRedemptions({
        variables: {
          page: page || 1,
          limit: 10,
        },
      })
    },
    [getRedemptions]
  )

  useEffect(() => {
    setCollection([])
    fetchRedemptions(1)
  }, [fetchRedemptions])

  const fetchMoreRedemptions = useCallback(async () => {
    if (!error && !isFetching) {
      const currentPage = metadata?.currentPage || 0
      fetchRedemptions(currentPage + 1)
    }
  }, [error, fetchRedemptions, isFetching, metadata?.currentPage])

  return (
    <Section
      title={
        <>
          Your Redemptions (
          <Typography component='span' variant='h4' color='primary'>
            {metadata?.totalCount || 0}
          </Typography>
          )
        </>
      }
    >
      {!loading && !metadata?.totalCount && (
        <>
          <Box mb={4}>
            You haven&apos;t redeemed any benefits. Here are a few of our most popular benefits for you to explore:
          </Box>
          <BenefitPreviews
            items={configBenefitsForPreview(benefitsData?.benefits?.collection, isTablet)}
            variant='standard'
            useOverlay
            loading={benefitsLoading}
            error={benefitsError?.message}
          />
        </>
      )}
      {!!metadata?.totalCount && (
        <InfiniteScroller
          loadMore={fetchMoreRedemptions}
          hasMore={collection.length < metadata.totalCount}
          isFetching={isFetching}
        >
          <Table className={classes.redemptionsTable}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Thumbnail</strong>
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell className={classes.hideSm}>Category</TableCell>
                <TableCell className={classes.hideXs}>Redeemed date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {collection.map((redemption: Redemption) => {
                const benefitPath = `/client/benefits/${redemption.benefit.slug}/details`
                return (
                  <TableRow key={redemption.benefit.id}>
                    <TableCell>
                      <img
                        className={classes.thumbnailRowImg}
                        src={redemption.benefit.images[0]?.thumbnail}
                        alt={redemption.benefit.name}
                      />
                    </TableCell>
                    <TableCell>{redemption.benefit.name}</TableCell>
                    <TableCell className={`${classes.hideSm} ${classes.categoryRow}`}>
                      {redemption.benefit?.category?.name}
                    </TableCell>
                    <TableCell className={classes.hideXs}>
                      {redemption.createdAt && moment(redemption.createdAt).format(DayFormat)}
                    </TableCell>
                    <TableCell>
                      <DetailsButton to={benefitPath} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </InfiniteScroller>
      )}
      {(isFetching || loading) && (
        <div style={{ textAlign: 'center', marginTop: '5rem' }}>
          <CircularProgress className={classes.spinner} size={24} />
        </div>
      )}
    </Section>
  )
}
