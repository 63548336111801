import React from 'react'
import { Tab, TabProps } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useStyles } from './styles'

export interface RouterTabProps extends Omit<TabProps<RouterLink>, 'component' | 'to'> {
  value: string
}

const RouterTab: React.FC<RouterTabProps> = ({ value, className, ...props }) => {
  const classes = useStyles()
  return (
    <Tab
      disableRipple
      className={`${className || ''} ${classes.tab}`}
      component={RouterLink}
      to={value}
      value={value}
      {...props}
    />
  )
}

export default RouterTab
