import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    resultsSection: {
      paddingTop: 0,
    },
    resultsCount: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      },
    },
  }
})
