import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    autocomplete: {
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
    },
    inputLabel: {
      color: '#000',
    },
    textfield: {
      backgroundColor: '#fff',
    },
    filledInput: {
      backgroundColor: '#fff',
      border: 'none',
      color: '#000',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:focus': {
        backgroundColor: '#fff',
      },
      '&.MuiFilledInput-underline::after': {
        border: 'none',
      },
      '&.MuiFilledInput-underline::before': {
        border: 'none',
      },
    },
    select: {
      padding: theme.spacing(2, 8, 2, 2),
      minHeight: '25px',
      fontSize: '1rem',
      '&.MuiNativeSelect-select': {
        paddingRight: theme.spacing(8),
      },
    },
    option: {
      minHeight: '40px',
      fontSize: '1.25rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.1rem',
      },
      // https://github.com/mui-org/material-ui/issues/19692
      '&[data-focus="true"]': {
        backgroundColor: '#F2E5DB',
        borderColor: 'transparent',
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#CC9A74',
        borderColor: 'transparent',
      },
    },
    groupLabel: {
      lineHeight: '40px',
      paddingLeft: theme.spacing(3),
      fontWeight: 'bold',
      color: '#000',
      fontSize: '0.75rem',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    groupUl: {
      borderBottom: '1px solid #EAEAEA',
    },
    endAdornment: {
      '& .MuiSvgIcon-root': {
        color: '#000',
      },
    },
  }
})
