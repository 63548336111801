import moment from 'moment'
import React from 'react'
import { CompanyRange, LeadDTO, LeadStatus } from '../../dtos/LeadDTO'
import { ShowFormData } from '../../components/DefaultShowForm'
import { EditFormData } from '../../components/DefaultEditForm'
import { AdminRoles, UserRole } from '../../const'

export const leadStatusMap = {
  [LeadStatus.PENDING]: 'Pending',
  [LeadStatus.APPROVED]: 'Approved',
  [LeadStatus.REJECTED]: 'Rejected',
  [LeadStatus.INCOMPLETE]: 'Incomplete',
  [LeadStatus.PENDING_PAYMENT]: 'Waiting for payment',
  [LeadStatus.FAILED_PAYMENT]: 'Failed payment',
}

const dateFormat = 'MMMM Do YYYY HH:mm Z'

export const getLeadFormData = (
  lead?: Partial<LeadDTO>,
  companyRanges?: CompanyRange | null,
  isEdit?: boolean,
  role?: UserRole
): ShowFormData[] | EditFormData[] => {
  if (!lead) {
    return []
  }

  const isAdmin = role ? AdminRoles.includes(role as UserRole) : false
  return [
    ...(isEdit
      ? [
          {
            fieldLabel: 'First name',
            fieldValue: lead.first_name,
            fieldName: 'first_name',
            required: true,
          },
          {
            fieldLabel: 'Last name',
            fieldValue: lead.last_name,
            fieldName: 'last_name',
            required: true,
          },
        ]
      : [
          {
            fieldLabel: 'Created at',
            fieldValue: lead.created_at && moment(lead.created_at).format(dateFormat),
            fieldName: 'created_at',
          },
        ]),
    {
      fieldLabel: 'Company name',
      fieldValue: lead.company_name,
      fieldName: 'company_name',
      required: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Email',
      fieldValue: lead.email,
      fieldName: 'email',
      required: true,
    },
    {
      fieldLabel: 'Phone number',
      fieldValue: lead.phone,
      fieldName: 'phone',
      required: true,
    },
    {
      fieldLabel: 'Seats',
      fieldValue: lead.seats || '0',
      fieldName: 'seats',
      required: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Country',
      fieldValue: lead.country,
      fieldName: 'country',
      required: true,
    },
    {
      fieldLabel: 'State',
      fieldValue: lead.state,
      fieldName: 'state',
      required: true,
    },
    {
      fieldLabel: 'Zip code',
      fieldValue: lead.zip_code,
      fieldName: 'zip_code',
      required: true,
    },
    {
      fieldLabel: 'City',
      fieldValue: lead.city,
      fieldName: 'city',
      required: true,
    },
    {
      fieldLabel: 'Address',
      fieldValue: lead.address,
      fieldName: 'address',
      required: true,
    },
    {
      fieldLabel: 'Apartment',
      fieldValue: lead.apartment,
      fieldName: 'apartment',
    },
    {
      fieldLabel: 'Job title',
      fieldValue: lead.position,
      fieldName: 'position',
      required: true,
    },
    {
      fieldLabel: 'Annual revenue',
      fieldValue: lead.annual_revenue,
      fieldName: 'annual_revenue',
      required: true,
      hideField: isAdmin,
      control: companyRanges?.annual_revenue && (
        <div className='select'>
          <select name='annual_revenue' value={lead.annual_revenue}>
            {companyRanges.annual_revenue.map((range) => (
              <option key={range.id} value={range.id}>
                {range.name}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      fieldLabel: 'Funding acquired since inception',
      fieldValue: lead.funding_acquired_since_inception,
      fieldName: 'funding_acquired_since_inception',
      hideField: isAdmin,
      control: companyRanges?.funding_acquired_since_inception && (
        <div className='select'>
          <select name='annual_revenue' value={lead.funding_acquired_since_inception}>
            <option value=''>Select...</option>
            {companyRanges.funding_acquired_since_inception.map((range) => (
              <option key={range.id} value={range.id}>
                {range.name}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      fieldLabel: 'Number of employees',
      fieldValue: lead.number_of_employees,
      fieldName: 'number_of_employees',
      required: true,
      hideField: isAdmin,
      control: companyRanges?.number_of_employees && (
        <div className='select'>
          <select name='annual_revenue' value={lead.number_of_employees}>
            {companyRanges.number_of_employees.map((range) => (
              <option key={range.id} value={range.id}>
                {range.name}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      fieldLabel: 'Company website',
      fieldValue: lead.company_website,
      fieldName: 'company_website',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'LinkedIn profile URL',
      fieldValue: lead.linkedin_profile_url,
      fieldName: 'linkedin_profile_url',
      hideField: isAdmin,
    },
    {
      fieldLabel:
        'Please share what interests you most about EXEC Membership and whom you wish to provide Membership access to from your organization',
      fieldValue: lead.additional_information,
      fieldName: 'additional_information',
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Plan',
      fieldValue: lead.plan,
      fieldName: 'plan',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Chargify Product ID',
      fieldValue: lead.chargify_product_id,
      fieldName: 'chargify_product_id',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Chargify Product Description',
      fieldValue: lead.chargify_product_name,
      fieldName: 'chargify_product_name',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Chargify Product Initiation',
      fieldValue: lead.chargify_product_initiation,
      fieldName: 'chargify_product_initiation',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Card type',
      fieldValue: lead.card_type,
      fieldName: 'card_type',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Last 4 digits of card',
      fieldValue: lead.last_four_digits_of_card,
      fieldName: 'last_four_digits_of_card',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Application submission date',
      fieldValue: lead.application_submission_date && moment(lead.application_submission_date).format(dateFormat),
      fieldName: 'application_submission_date',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Renewal date',
      fieldValue: lead.renewal_date && moment(lead.renewal_date).format(dateFormat),
      fieldName: 'renewal_date',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Last date charged',
      fieldValue: lead.last_date_charged && moment(lead.last_date_charged).format(dateFormat),
      fieldName: 'last_date_charged',
      notEditable: true,
      hideField: isAdmin,
    },
    {
      fieldLabel: 'Amount charged',
      fieldValue: lead.amount_charged || '$0',
      fieldName: 'amount_charged',
      notEditable: true,
      hideField: isAdmin,
    },
  ]
}
