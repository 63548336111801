import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BenefitDTO } from '../../dtos/BenefitDTO'
import { saveBenefit } from '../../common/requests'
import DefaultLayout from '../../layouts/DefaultLayout'
import BenefitForm from '../../components/BenefitForm/BenefitForm'
import { BenefitErrorDTO } from '../../dtos/BenefitErrorDTO'
import { newBenefitInitialState } from './const'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { applyFormData } from '../../components/BenefitForm/utils'

const NewBenefit: React.FC = (): JSX.Element => {
  const history = useHistory()
  const { credentials } = useCredentials()
  const [benefitData, setBenefitData] = useState<BenefitDTO>(newBenefitInitialState)
  const [validationErrors, setValidationErrors] = useState<Partial<BenefitErrorDTO>>({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      const formData = applyFormData(benefitData)
      await saveBenefit(formData, credentials)
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <h4 className='is-size-4 mb-4'>New Benefit</h4>
          <BenefitForm
            formData={benefitData}
            onSubmit={handleSubmit}
            errors={validationErrors}
            onChange={setBenefitData}
            submitting={submitting}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default NewBenefit
