import { Dispatch, SetStateAction, useState } from 'react'
import { Order } from 'types/generatedGql'
import { getValueFromQueryParams } from 'utils'
import { useLocation } from 'react-router-dom'

export const SORT_QUERY_KEY = 'sortBy'

const useSorting = (): [Order, Dispatch<SetStateAction<Order>>] => {
  const location = useLocation()
  const [sortOrder, setSortOrder] = useState<Order>(
    (getValueFromQueryParams(location.search, SORT_QUERY_KEY) as Order) || Order.UpdatedAt
  )
  return [sortOrder, setSortOrder]
}

export default useSorting
