import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const CircularSpinner: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }): JSX.Element => {
  return (
    <div style={{ textAlign: 'center', marginTop: '5rem', paddingBottom: '5rem' }} className={className}>
      <CircularProgress />
    </div>
  )
}

export default CircularSpinner
