import React from 'react'
import { Card, CardProps, CardMedia, Typography, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { City } from 'types/generatedGql'
import { useStyles } from './styles'

const DestinationPreview: React.FC<City & Omit<CardProps, 'id'>> = ({ name, image, id, className, ...props }) => {
  const classes = useStyles()
  const destinationPath = `/client/hotels/list/featured-city/${name!.replace(/\s+/g, '-').toLowerCase()}`

  return (
    <Link to={destinationPath}>
      <Card className={`${classes.card} ${className || ''}`} {...props}>
        <CardMedia
          component='img'
          alt={name}
          image={image?.medium || 'https://picsum.photos/id/1029/384/200'}
          title={name}
          className={classes.cardMedia}
        />
        {name && (
          <Box position='absolute' bottom={0} left={0} m={2}>
            <Typography variant='body1' component='h5' className={classes.name}>
              {name}
            </Typography>
          </Box>
        )}
      </Card>{' '}
    </Link>
  )
}

export default DestinationPreview
