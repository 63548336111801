import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import Section from 'components/shared/Section'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_USER_MUTATION } from 'constants/mutations'
import { GET_CURRENT_USER } from 'constants/queries'
import Alert from 'components/shared/Alert'
import { UpdateUserInput, User } from 'types/generatedGql'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper'
import ButtonAction from 'components/shared/ButtonAction'
import { useStyles } from './styles'
import { useStyles as useAccountStyles } from '../styles'

type UserForm = Pick<
  User,
  'firstName' | 'lastName' | 'email' | 'companyName' | 'companyWebsite' | 'about' | 'position' | 'photo' | 'address'
> & { isPhotoUploaded: boolean }

interface Props {
  user: User
  onSaveSuccessCallback: (user: User) => void
}

const Profile: React.FC<Props> = ({ user, onSaveSuccessCallback }) => {
  const classes = useStyles()
  const accountClasses = useAccountStyles()
  const formClasses = useFormStyles()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [userForm, setUserForm] = useState<Partial<UpdateUserInput>>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    companyName: user.companyName,
    companyWebsite: user.companyWebsite,
    position: user.position,
    about: user.about || '',
    ...user.address,
  })

  const { data: currentUserData, loading: currentUserLoading } = useQuery(GET_CURRENT_USER)

  const [updateUserProfile, { loading: submitting, error }] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: (data) => {
      setAlertOpen(true)
      onSaveSuccessCallback(data.updateUser.user)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  })

  const handleLoadInvoice = () => {
    window.open(currentUserData?.currentUser?.lastInvoiceUrl, '_blank')
  }

  const handleSave = (e: React.SyntheticEvent) => {
    e?.preventDefault()
    setAlertOpen(false)
    updateUserProfile({
      variables: {
        ...userForm,
      },
    })
  }

  const handleInputChange = (field: keyof UserForm, value: string) => {
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      [field]: value,
    }))
  }

  const handleAddressInputChange = (field: keyof UserForm, value: string) => {
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      [field]: value,
    }))
  }

  const renderRequestMessage = () => {
    if (!alertOpen) return null

    let type: 'success' | 'error' = 'success'
    let message = 'Update success!'

    if (error) {
      type = 'error'
      message = 'Update failed! Please try again.'
    }

    return (
      <div style={{ marginBottom: '2rem' }}>
        <Alert severity={type} onClose={() => setAlertOpen(false)}>
          {message}
        </Alert>
      </div>
    )
  }

  // When error occur, show error
  useEffect(() => {
    if (error) setAlertOpen(true)
  }, [error])

  return (
    <form autoComplete='off' onSubmit={handleSave}>
      <Section title='Profile Settings'>
        {renderRequestMessage()}

        <Box display='flex' className={classes.container}>
          <Box pr={4} width='100%' className={classes.leftBox}>
            <Box pb={3} className={accountClasses.borderBottomBox}>
              <Box mb={2} mt={0}>
                <Typography variant='h6'>Personal Information</Typography>
              </Box>

              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='First Name'
                  type='text'
                  isTopLabel
                  required
                  value={userForm.firstName}
                  onChange={(event) => handleInputChange('firstName', event.target.value)}
                  fullWidth
                />

                <TextFieldCustom
                  label='Last name'
                  type='text'
                  isTopLabel
                  required
                  value={userForm.lastName}
                  onChange={(event) => handleInputChange('lastName', event.target.value)}
                  fullWidth
                />
              </Box>
              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='Email'
                  type='text'
                  isTopLabel
                  required
                  value={userForm.email}
                  onChange={(event) => handleInputChange('email', event.target.value)}
                  fullWidth
                />
                <TextFieldCustom
                  label='Company name'
                  type='text'
                  isTopLabel
                  value={userForm.companyName}
                  onChange={(event) => handleInputChange('companyName', event.target.value)}
                  fullWidth
                />
              </Box>

              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='Company website'
                  type='text'
                  isTopLabel
                  value={userForm.companyWebsite}
                  onChange={(event) => handleInputChange('companyWebsite', event.target.value)}
                  fullWidth
                />
                <TextFieldCustom
                  label='Job title'
                  type='text'
                  isTopLabel
                  value={userForm.position}
                  onChange={(event) => handleInputChange('position', event.target.value)}
                  fullWidth
                />
              </Box>
            </Box>

            <Box mt={2}>
              <Box mb={2} mt={0}>
                <Typography variant='h6'>Address</Typography>
              </Box>

              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='Address'
                  type='text'
                  isTopLabel
                  value={userForm.address}
                  onChange={(event) => handleAddressInputChange('address', event.target.value)}
                  fullWidth
                />

                <TextFieldCustom
                  label='Apartment, suite, etc.'
                  type='text'
                  isTopLabel
                  value={userForm.apartment}
                  onChange={(event) => handleAddressInputChange('apartment', event.target.value)}
                  fullWidth
                />
              </Box>

              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='City'
                  type='text'
                  isTopLabel
                  value={userForm.city}
                  onChange={(event) => handleAddressInputChange('city', event.target.value)}
                  fullWidth
                />

                <TextFieldCustom
                  label='State/province'
                  type='text'
                  isTopLabel
                  value={userForm.state}
                  onChange={(event) => handleAddressInputChange('state', event.target.value)}
                  fullWidth
                />
              </Box>

              <Box className={formClasses.formRow}>
                <TextFieldCustom
                  label='Country'
                  type='text'
                  isTopLabel
                  value={userForm.country}
                  onChange={(event) => handleAddressInputChange('country', event.target.value)}
                  fullWidth
                />

                <TextFieldCustom
                  label='ZIP/postal code'
                  type='text'
                  isTopLabel
                  value={userForm.zipCode}
                  onChange={(event) => handleAddressInputChange('zipCode', event.target.value)}
                  fullWidth
                />
              </Box>
            </Box>
          </Box>

          <Box pl={4} className={classes.rightBox}>
            <Box>
              <Box mb={2} mt={0}>
                <Typography variant='h6'>About me</Typography>
              </Box>
              <TextFieldCustom
                className={classes.aboutTextField}
                label='Bio'
                isTopLabel
                multiline
                fullWidth
                placeholder='Enter a brief description about yourself, what you do, your interests, etc. here.'
                rows={11}
                type='text'
                value={userForm.about}
                onChange={(event) => handleInputChange('about', event.target.value)}
                InputProps={{
                  classes: classes.aboutInput,
                }}
              />
            </Box>
            {currentUserData?.currentUser?.lastInvoiceUrl && (
              <Box mb={0} mt={4} className={classes.invoiceDescription}>
                <Typography variant='h6'>Latest Invoice</Typography>
                <Typography variant='body1'>
                  Your latest invoice is available to you. Please click on the link below to download a copy.
                </Typography>
                <Box mt={3} width='100%'>
                  <ButtonAction
                    fullWidth
                    type='button'
                    size='large'
                    text='Download Invoice'
                    onClick={handleLoadInvoice}
                    disabled={currentUserLoading}
                    loading={currentUserLoading}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box mt={3}>
          <ButtonAction type='submit' size='large' text='Save' loading={submitting} />
        </Box>
      </Section>
    </form>
  )
}

export { Profile }
