import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import ClickOutside from './ClickOutside'

interface Props {
  placeholder?: string
  matches: Array<string>
  onSelect: (selected: string) => void
  onChange: (value: string) => void
  clearSuggestions: () => void
  value?: string
}

const AutocompleteInput: FC<Props> = ({
  value = '',
  matches,
  onChange,
  onSelect,
  clearSuggestions,
  placeholder = '',
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(matches.length > 0)
  }, [matches])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onInputChange = (val: string) => {
    setInputValue(val)
    onChange(val)
  }

  const onSelectItem = (e: SyntheticEvent, match: string) => {
    e.preventDefault()
    onSelect(match)
  }

  return (
    <div className='control'>
      <div className={`dropdown${matches.length > 0 ? ' is-active' : ''}`} style={{ width: '100%' }}>
        <div className='dropdown-trigger' style={{ width: '100%' }}>
          <input
            autoComplete='none'
            className='input'
            value={inputValue}
            onChange={(e) => onInputChange(e.target.value)}
            placeholder={placeholder}
          />
        </div>
        <ClickOutside callback={clearSuggestions} className='dropdown-menu'>
          {isOpened && (
            <div className='dropdown-content'>
              {matches.map((match) => (
                <a href='/' className='dropdown-item' key={match} onClick={(e) => onSelectItem(e, match)}>
                  {match}
                </a>
              ))}
            </div>
          )}
        </ClickOutside>
      </div>
    </div>
  )
}

export { AutocompleteInput }
