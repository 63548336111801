import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey.A100}`
  return {
    container: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        '& > div': {
          maxWidth: '100%',
        },
      },
    },
    leftBox: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    rightBox: {
      borderLeft: border,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        border: 0,
        marginBottom: theme.spacing(2),
      },
    },
    rightRow: {
      borderBottom: border,
    },
    total: {
      width: '100%',
      border,
    },
    totalText: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    totalBox: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 0, 4, 0),
        borderBottom: border,
      },
    },
  }
})
