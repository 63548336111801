import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { applyParamsToUrlPath, downloadFile } from '../../utils'
import { OrganizationDTO } from '../../dtos/OrganizationDTO'
import { OrganizationFilters } from './Filters'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'

const Organizations: React.FC = () => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [rows, setRows] = useState<Array<OrganizationDTO>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination()

  const loadOrganizations = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/organizations'),
        {
          headers: credentials,
        }
      )
      setRows(res.data.organizations)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadOrganizations()
  }, [loadOrganizations])

  const deleteOrg = async (id: string) => {
    if (confirm('Do you want to delete?')) {
      try {
        await axios.delete(`/api/panel/organizations/${id}`, {
          headers: credentials,
        })
        loadOrganizations()
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  const downloadOrganizations = async () => {
    try {
      const res = await axios.get('/api/panel/organizations/export_csv', {
        headers: credentials,
      })
      downloadFile(res.data, 'organizations')
    } catch (e) {
      setError(e?.message)
      console.error(e.message)
    }
  }

  return (
    <DefaultLayout>
      <TableWrapper
        title='Organizations'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!rows?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <button onClick={downloadOrganizations} className='button mr-2' type='button'>
                Download CSV
              </button>
              <Link to='/panel/organizations/new' className='button is-link'>
                New
              </Link>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <OrganizationFilters applyFilters={applyFilters} />
            </tr>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Subdomain</th>
              <th>Website</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className='is-clickable' onClick={() => history.push(`/panel/organizations/${row.id}`)}>
                <th>{row.id}</th>
                <td>{row.name}</td>
                <td>{row.subdomain}</td>
                <td>{row.website}</td>
                <td>
                  <ActionsCell>
                    <Link to={`/panel/organizations/${row.id}`} className='button is-text mr-2'>
                      Show
                    </Link>
                    {hasEditPermission && (
                      <Link to={`/panel/organizations/${row.id}/edit`} className='button is-text mr-2'>
                        Edit
                      </Link>
                    )}
                    {hasEditPermission && (
                      <button type='button' className='button is-danger' onClick={() => deleteOrg(row.id)}>
                        Delete
                      </button>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Organizations
