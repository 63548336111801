import React, { ReactNode, SyntheticEvent } from 'react'
import { Phrases } from 'constants/phrases'
import CircularSpinner from './CircularSpinner'
import BackButton from './BackButton'
import ErrorMessage from './ErrorMessage'

export interface EditFormData {
  fieldName: string
  fieldLabel: string
  fieldValue: string | number | ReactNode
  required?: boolean
  type?: string
  control?: ReactNode
  notEditable?: boolean
}

export interface DefaultEditFormProps {
  name: string
  headerActions?: ReactNode
  footerActions?: ReactNode
  formData: EditFormData[]
  onChange: (name: string, value: string) => void
  loading?: boolean
  submitting?: boolean
  submitDisabled?: boolean
  onSubmit: () => void
  validationErrors?: Record<string, string>
  loadingError?: string
}

const DefaultEditForm: React.FC<DefaultEditFormProps> = ({
  name,
  formData,
  loading,
  headerActions,
  footerActions,
  onSubmit,
  submitting,
  submitDisabled,
  validationErrors,
  loadingError,
  onChange,
}) => {
  const onSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        {headerActions && <div>{headerActions}</div>}
      </div>
      <h4 className='is-size-4 has-text-centered mb-4'>{name}</h4>
      {loading ? (
        <CircularSpinner />
      ) : (
        <>
          <div className='box columns mt-4'>
            {loadingError && <ErrorMessage text={loadingError} />}
            <div className='column is-half p-0'>
              <form onSubmit={onSubmitForm} autoComplete='off' className='mb-0'>
                {formData?.map(({ fieldName, fieldLabel, fieldValue, control, notEditable, type, required }) =>
                  notEditable ? null : (
                    <div className='field' key={fieldName}>
                      <label className='label'>
                        {fieldLabel}
                        {required ? '*' : ''}
                      </label>
                      <div className='control'>
                        {control ? (
                          React.createElement(
                            'div',
                            { onChange: (event) => onChange(fieldName, event.target.value) },
                            control
                          )
                        ) : (
                          <input
                            type={type || 'text'}
                            className='input'
                            autoComplete='none'
                            value={fieldValue}
                            onChange={(event) => onChange(fieldName, event.target.value)}
                            required={required}
                          />
                        )}
                      </div>
                    </div>
                  )
                )}
                {footerActions && <div className='mt-4'>{footerActions}</div>}
                <div className='field'>
                  <button
                    className={`button is-primary ${submitting && 'is-loading'} mt-4`}
                    type='submit'
                    disabled={submitDisabled || submitting}
                  >
                    Save
                  </button>
                  {!!validationErrors && !!Object.values(validationErrors).length && (
                    <div className='has-text-danger mt-2'>{Phrases.FormError}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { DefaultEditForm }
