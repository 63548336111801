import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    textFieldWrapper: {
      position: 'relative',
    },
    textField: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      color: 'black',
    },
    inputLabel: {
      color: theme.palette.common.black,
    },
    topLabel: {
      color: theme.palette.common.black,
    },
    top: {
      position: 'absolute',
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: '#bdbdbd !important',
    },
  }
})
