import React from 'react'
import store from 'reducer/store'
import { Provider } from 'react-redux'
// @ts-ignore
import SmartBanner from 'react-smartbanner'
import PlatformApp from './PlatformApp'
import 'react-smartbanner/dist/main.css'
import '../styles/smartbanner.css'

const App: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <SmartBanner title='Exec Benefits' />
      <PlatformApp />
    </Provider>
  )
}

export default App
