import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import OrganizationForm from '../../components/OrganizationForm'
import DefaultLayout from '../../layouts/DefaultLayout'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { OrganizationDTO } from '../../dtos/OrganizationDTO'
import CircularSpinner from '../../components/CircularSpinner'

const EditOrganization: React.FC = () => {
  const { credentials } = useCredentials()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<OrganizationDTO>({
    name: '',
    subdomain: '',
    description: '',
    website: '',
  })

  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')

  const loadOrganization = useCallback(async () => {
    setLoading(true)
    try {
      const res = await axios.get(`/api/panel/organizations/${params.id}/edit`, {
        headers: credentials,
      })
      setFormData(res.data)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadOrganization()
  }, [loadOrganization])

  const handleChange = (newData) => {
    setFormData(newData)
  }

  const handleSubmit = async (data) => {
    try {
      await axios.put(`/api/panel/organizations/${params.id}`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (e) {
      setValidationErrors(e.response?.data)
    }
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {loading ? (
        <CircularSpinner className='is-center' />
      ) : (
        <OrganizationForm
          title='Edit Organization'
          formData={formData}
          onChange={handleChange}
          onSubmit={handleSubmit}
          errors={validationErrors}
        />
      )}
    </DefaultLayout>
  )
}

export default EditOrganization
