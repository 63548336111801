import { RoutePath } from 'constants/RoutePath'
import { Credential } from 'types/generatedGql'
import { addSubdomainToPath } from 'utils'
import { setAuth } from 'reducer/auth/authSlice'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useSubdomain from 'hooks/useSubdomain'

interface RedirectData {
  credentials: Credential
  preApplicationStep: string
  onboardingStep: string
  subdomain: string
}

interface Returns {
  loginRedirect: (data: RedirectData) => void
}

const useLoginRedirect = (noSubdomain?: boolean): Returns => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { hasSubDomain } = useSubdomain()

  const goToHostWithSubdomain = (routerPath: RoutePath, subdomain?: string, credentials?: Credential): void => {
    if (hasSubDomain || !subdomain) {
      history.push(routerPath)
      return
    }
    window.location.replace(
      addSubdomainToPath(window.location.origin, noSubdomain ? '' : subdomain, routerPath, credentials)
    )
  }

  const loginRedirect = ({ credentials, preApplicationStep, onboardingStep, subdomain }: RedirectData) => {
    dispatch(setAuth(credentials))
    if (preApplicationStep) {
      goToHostWithSubdomain(RoutePath.PreApplication, subdomain, credentials)
    } else if (onboardingStep) {
      goToHostWithSubdomain(RoutePath.Onboarding, subdomain, credentials)
    } else {
      goToHostWithSubdomain(RoutePath.Home, subdomain, credentials)
    }
  }

  return { loginRedirect }
}

export default useLoginRedirect
