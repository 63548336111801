import Validator from 'validator'
import { RedeemWithRegistrationFormInput } from 'types/generatedGql'

export function validate<T extends RedeemWithRegistrationFormInput>(
  formData: Partial<T>
): Partial<Record<keyof Partial<T>, string>> {
  const errors = {} as Record<keyof T, string>
  if (!formData.userEmail) {
    errors.userEmail = 'Email is required.'
  }
  if (formData.userEmail && !Validator.isEmail(formData.userEmail)) {
    errors.userEmail = 'Email is incorrect.'
  }
  if (formData.userPhone && !Validator.isMobilePhone(formData.userPhone)) {
    errors.userPhone = 'Phone number is incorrect.'
  }
  return errors
}
