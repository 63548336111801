import React from 'react'
import OrganizationsDropdown from '../OrganizationsDropdown'

interface BenefitFormState {
  excludedOrganizations: string[]
  onChange: (data: string[]) => void
  disabled?: boolean
}

const ExcludedOrganizationsControl: React.FC<BenefitFormState> = ({
  excludedOrganizations = [],
  onChange,
  disabled,
}): JSX.Element => {
  const onOrganizationSelect = (id: string, index: number) => {
    const organizations = [...excludedOrganizations]
    organizations[index] = id
    onChange(organizations)
  }

  const onRemove = (idToRemove: string) => {
    const organizations = [...excludedOrganizations]
    onChange(organizations.filter((currentId) => currentId !== idToRemove))
  }

  const onAdd = () => {
    const organizations = [...excludedOrganizations]
    organizations.push('')
    onChange(organizations)
  }

  return (
    <div className='control'>
      <div className='mb-2'>
        <button className='button is-small has-icons-left' type='button' onClick={onAdd} disabled={disabled}>
          <i className='fa fa-plus' />
        </button>
      </div>
      <ul>
        {excludedOrganizations.map((organizationId: string, index: number) => (
          <li key={index} className='mb-2'>
            <div className='field has-addons'>
              <div className='control has-icons-right'>
                <OrganizationsDropdown
                  organizationId={organizationId}
                  onOrganizationSelect={(id) => onOrganizationSelect(id, index)}
                  disabled={disabled}
                />
              </div>
              <button className='button has-icons-left' type='button' onClick={() => onRemove(organizationId)}>
                <span className='icon'>
                  <i className='fa fa-minus' />
                </span>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExcludedOrganizationsControl
