import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { applyParamsToUrlPath } from '../../utils'
import { UserDTO } from '../../dtos/UserDTO'
import { UserFilters } from '../../components/UserFilters'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'

const Admins: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [admins, setAdmins] = useState<Array<UserDTO>>([])

  const loadAdmins = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/admins'), {
        headers: credentials,
      })
      setAdmins(res.data.admins)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadAdmins()
  }, [loadAdmins])

  const deleteUser = async (id: string) => {
    if (confirm('Do you want to delete?')) {
      try {
        await axios.delete(`/api/panel/admins/${id}`, {
          headers: credentials,
        })
        const newRows = admins.filter((user) => user.id !== id)
        setAdmins(newRows)
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  return (
    <DefaultLayout>
      <TableWrapper
        title='Admins'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!admins?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <Link to='/panel/admins/new' className='button is-link'>
                New
              </Link>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <UserFilters applyFilters={applyFilters} isAdmin />
            </tr>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Activation status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.id} className='is-clickable' onClick={() => history.push(`/panel/admins/${admin.id}`)}>
                <th>{admin.id}</th>
                <td>{admin.email}</td>
                <td>{admin.first_name}</td>
                <td>{admin.last_name}</td>
                <td>{admin.activation_status || '-'}</td>
                <td>
                  <ActionsCell>
                    {hasEditPermission && (
                      <Link to={`/panel/admins/${admin.id}/edit`} className='button is-text mr-2'>
                        Edit
                      </Link>
                    )}
                    {hasEditPermission && (
                      <button type='button' className='button is-danger' onClick={() => deleteUser(admin.id)}>
                        Delete
                      </button>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Admins
