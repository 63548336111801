import axios, { AxiosResponse } from 'axios'
import { CredentialsDTO } from 'panel/dtos/CredentialsDTO'

export const saveBenefit = async (data: FormData, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  const result = await axios.post(`/api/panel/benefits/`, data, {
    headers: {
      ...credentials,
      'Content-Type': 'multipart/form-data',
    },
  })
  return result
}

export const getAllCities = async (credentials: CredentialsDTO, url?: string): Promise<AxiosResponse> => {
  const result = await axios.get(url || `/api/panel/cities`, {
    headers: {
      ...credentials,
      'Content-Type': 'application/json',
    },
  })
  return result
}

export const getCity = async (id: string, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  const result = await axios.get(`/api/panel/cities/${id}`, {
    headers: {
      ...credentials,
      'Content-Type': 'application/json',
    },
  })
  return result
}

export const saveCity = async (data: FormData, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  const result = await axios.post(`/api/panel/cities`, data, {
    headers: {
      ...credentials,
      'Content-Type': 'multipart/form-data',
    },
  })
  return result
}

export const saveBrand = async (
  data: { name: string; description: string },
  credentials: CredentialsDTO
): Promise<AxiosResponse> => {
  const result = await axios.post('/api/panel/brands', data, {
    headers: credentials,
  })

  return result
}

export const updateCity = async (id: number, data: FormData, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  const result = await axios.put(`/api/panel/cities/${id}`, data, {
    headers: {
      ...credentials,
      'Content-Type': 'multipart/form-data',
    },
  })
  return result
}

// Members
export const deleteMember = async (id: string, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  return axios.delete(`/api/panel/members/${id}`, {
    headers: credentials,
  })
}

export const cancelMember = async (id: string, credentials: CredentialsDTO): Promise<AxiosResponse> => {
  return axios.put(`/api/panel/members/${id}/cancel`, {}, { headers: credentials })
}
