import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import DefaultLayout from '../../layouts/DefaultLayout'
import { BenefitDTO } from '../../dtos/BenefitDTO'
import { applyParamsToUrlPath, downloadFile, getBooleanFieldText } from '../../utils'
import { BenefitFilters, BenefitFilterKeys } from './Filters'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'
import { getExpiredValue } from './helpers'
import useGetQueryParams from '../../hooks/useGetQueryParams'

const Benefits: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [rows, setRows] = useState<BenefitDTO[]>([])
  const { getQueryParams } = useGetQueryParams()
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination({
    filters: getQueryParams(Object.values(BenefitFilterKeys)),
  })

  const loadData = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/benefits'),
        {
          headers: credentials,
        }
      )
      setRows(res.data.benefits)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadData()
  }, [loadData])

  const deleteBenefit = async (id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this benefit',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#f14668',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/panel/benefits/${id}`, {
            headers: credentials,
          })
          const newRows = rows.filter((row) => row.id !== id)
          setRows(newRows)
        } catch (e) {
          console.error(e.message)
        }
      }
    })
  }

  const downloadTransactions = async () => {
    try {
      const res = await axios.get('/api/panel/benefits/export_csv', {
        headers: credentials,
      })
      downloadFile(res.data, 'members')
    } catch (e) {
      setError(e?.message)
      console.error(e.message)
    } finally {
    }
  }

  return (
    <DefaultLayout>
      <TableWrapper
        title='Benefits'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!rows?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <button onClick={downloadTransactions} className='button mr-2' type='button'>
                Download CSV
              </button>
              <Link to='/panel/benefits/new' className='button is-link'>
                New
              </Link>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <BenefitFilters
                applyFilters={applyFilters}
                initialFilters={getQueryParams(Object.values(BenefitFilterKeys))}
              />
            </tr>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Slug</th>
              <th>Category</th>
              <th>Brand</th>
              <th>Active</th>
              <th>Expires</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className='is-clickable' onClick={() => history.push(`/panel/benefits/${row.id}`)}>
                <th>{row.id}</th>
                <td style={{ wordBreak: 'break-word' }}>{row.name}</td>
                <td style={{ wordBreak: 'break-word' }}>{row.slug}</td>
                <td>{row.category?.name}</td>
                <td>{row.brand?.name}</td>
                <td>{getBooleanFieldText(row.active)}</td>
                <td>{getExpiredValue(row.end_date)}</td>
                <td>
                  <ActionsCell>
                    <Link to={`/panel/benefits/${row.id}`} className='button is-text mr-2'>
                      Show
                    </Link>
                    {hasEditPermission && (
                      <Link to={`/panel/benefits/${row.id}/edit`} className='button is-text mr-2'>
                        Edit
                      </Link>
                    )}
                    {hasEditPermission && (
                      <button type='button' className='button is-danger' onClick={() => deleteBenefit(row.id)}>
                        Delete
                      </button>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Benefits
