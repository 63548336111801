import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { v4 as uidV4 } from 'uuid'
import DefaultLayout from '../../layouts/DefaultLayout'
import BenefitForm from '../../components/BenefitForm/BenefitForm'
import { BenefitDTO, BenefitTextDTO } from '../../dtos/BenefitDTO'
import { BenefitErrorDTO } from '../../dtos/BenefitErrorDTO'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { applyFormData } from '../../components/BenefitForm/utils'

const EditBenefit: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { credentials } = useCredentials()
  const [benefit, setBenefit] = useState<BenefitDTO | null>()
  const [loadDataError, setLoadDataError] = useState('')
  const [validationErrors, setValidationErrors] = useState<Partial<BenefitErrorDTO>>({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadData = useCallback(async (): Promise<void> => {
    try {
      const res = await axios.get(`/api/panel/benefits/${id}/edit`, {
        headers: credentials,
      })
      const benefitData = { ...res?.data }
      benefitData.rates = res?.data?.rates.map((rate: Partial<BenefitTextDTO>) => ({ id: uidV4(), ...rate }))
      benefitData.benefits = res?.data?.benefits.map((benefitItem: string) => ({ id: uidV4(), value: benefitItem }))
      setBenefit(benefitData)
    } catch (e) {
      setLoadDataError(e.message)
    }
  }, [credentials, id])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleSubmit = async () => {
    try {
      if (!benefit) return
      setSubmitting(true)
      const formData = applyFormData(benefit)
      await axios.put(`/api/panel/benefits/${id}`, formData, {
        headers: {
          ...credentials,
          'Content-Type': 'multipart/form-data',
        },
      })
      history.goBack()
    } catch (err) {
      console.log('Errors: ', err)
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    // TODO use DefaultEditForm
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <div className='columns'>
        <div className='column is-half'>
          <div className='mb-4'>
            <BackButton />
          </div>
          <h4 className='is-size-4 mb-4'>Edit Benefit</h4>
          {benefit && (
            <BenefitForm
              onSubmit={handleSubmit}
              submitting={submitting}
              errors={validationErrors}
              formData={benefit}
              onChange={setBenefit}
            />
          )}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditBenefit
