import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../../layouts/DefaultLayout'
import UserForm from '../../../components/UserForm/UserForm'
import { UserDTO } from '../../../dtos/UserDTO'
import { memberInitialState } from '../../const'
import { UserRole } from '../../../const'
import useCredentials from '../../../hooks/useCredentials'
import BackButton from '../../../components/BackButton'

const NewOrganizationUser: FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<UserDTO>>({
    ...memberInitialState,
    password: '',
    organization_id: params?.id,
  })
  const [validationErrors, setValidationErrors] = useState({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      setSubmitting(true)
      await axios.post(`/api/panel/organizations/${params.id}/users`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <h4 className='is-size-4 mb-4'>New User</h4>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            roles={[UserRole.Member]}
            isEdit={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default NewOrganizationUser
