import React from 'react'
import { Box, BoxProps, Divider, Typography } from '@material-ui/core'
import { useStyles } from './styles'

interface SectionProps extends Omit<BoxProps, 'title'> {
  title?: string | React.ReactNode
  hideTitleSm?: boolean
  titleSibling?: React.ReactNode
  overflowContentSm?: boolean
  fullWidthSm?: boolean
  childrenClassname?: string
}

const Section: React.FC<SectionProps> = ({
  title,
  hideTitleSm,
  titleSibling,
  overflowContentSm,
  fullWidthSm,
  children,
  className,
  childrenClassname,
  ...props
}) => {
  const classes = useStyles()

  return (
    <>
      <Box component='section' className={`${classes.section} ${className || ''}`} {...props}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {title && (
            <Typography variant='h4' className={hideTitleSm ? classes.hideOnSm : ''}>
              {title}
            </Typography>
          )}
          {titleSibling}
        </Box>
        <Box
          mt={3}
          className={`${overflowContentSm ? classes.overflowContentSm : ''} ${
            fullWidthSm ? classes.fullWidthSm : ''
          } ${childrenClassname}`}
        >
          {children}
        </Box>
      </Box>
      <Divider />
    </>
  )
}

export default Section
