import React, { ReactNode } from 'react'
import { Box, Typography, GridList, CardMedia } from '@material-ui/core'

interface Props {
  title?: string
  pictures?: Array<string>
  description?: ReactNode | string
}
const OnboardingLayout: React.FC<Props> = ({ pictures, title, description }) => {
  return (
    <>
      {title && (
        <Box mb={2}>
          <Typography variant='h5' style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
      )}

      {pictures?.length && (
        <GridList cols={pictures.length} spacing={4} style={{ width: '100%' }} cellHeight='auto'>
          {pictures.map((picture) => (
            <CardMedia component='img' src={picture} />
          ))}
        </GridList>
      )}

      {description && <Box mt={2}>{description}</Box>}
    </>
  )
}

export default OnboardingLayout
