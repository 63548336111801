import { RedemptionType } from 'types/generatedGql'

export const regions = [
  {
    name: 'North America',
    code: 'NA',
  },
  {
    name: 'Africa',
    code: 'AF',
  },
  {
    name: 'Asia Pacific & Australia',
    code: 'APAC',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Caribbean',
    code: 'CB',
  },
  {
    name: 'Europe',
    code: 'EU',
  },
  {
    name: 'Europe & Middle East',
    code: 'EMEA',
  },
  {
    name: 'South America',
    code: 'SA',
  },
]

export enum UserRole {
  Member = 'user',
  Admin = 'admin',
  Support = 'support',
  Lead = 'lead',
}

export const userRoleMap: Record<UserRole, string> = {
  [UserRole.Member]: 'Member',
  [UserRole.Admin]: 'Admin',
  [UserRole.Support]: 'Support',
  [UserRole.Lead]: 'Lead',
}

export const redemptionTypeMap: Record<RedemptionType, string> = {
  [RedemptionType.ReferralLink]: 'Referral link',
  [RedemptionType.GeneralCode]: 'General Code',
  [RedemptionType.PersonalizedCode]: 'Personalized Code',
  [RedemptionType.Registration]: 'Registration form submission',
}

export const AdminRoles = [UserRole.Admin, UserRole.Support]
