import React, { DragEvent, useCallback, useRef, useState } from 'react'
import { Box, ButtonBase, InputBase, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { getValueFromQueryParams } from 'utils'
import PageLayout from 'components/layouts/PageLayout'
import { useStyles } from 'pages/CSVUpload/styles'
import ButtonAction from 'components/shared/ButtonAction'
import FileIcon from '../../../../assets/images/file-arrow-up.svg'

export const CSVUpload = () => {
  const classes = useStyles()
  const { search } = useLocation<{ search: { token: string; org_name: string } }>()
  const { replace } = useHistory()

  const [title, setTitle] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const onDragOver = useCallback((e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const onDragEnter = useCallback((e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const onDrop = useCallback((e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer

    if (files?.[0]) {
      setFile(files[0])
    }
  }, [])

  const token = getValueFromQueryParams(search, 'token')
  const orgName = getValueFromQueryParams(search, 'org_name')

  const handleSubmit = useCallback(async () => {
    const fd = new FormData()
    fd.append('users_csv', file as File)
    fd.append('title', title)
    try {
      setLoading(true)
      await axios.post(`organizations-members-upload/upload?token=${token}`, fd)
      setSuccess(true)
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }, [file, token, title])

  return (
    <PageLayout curlyHeader hideNavLinks grayTheme>
      <Box className={classes.body}>
        <Typography variant='h5' className={classes.h4}>
          Please add {orgName} members below:
        </Typography>
        <Box mt={5}>
          <Box className={classes.label}>TITLE</Box>
          <InputBase
            className={classes.textfield}
            type='text'
            placeholder='Enter Document title'
            disabled={success}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
        </Box>

        <Box mt={5} mb={7.5}>
          <Box className={classes.label}>ATTACH DOCUMENTS</Box>
          <input
            ref={inputRef}
            type='file'
            id='docpicker'
            accept='.csv'
            onChange={(e) => setFile(e.target.files![0])}
            hidden
          />
          {file?.name ? (
            <>
              <Box className={classes.uploadedFileWrapper}>
                <img src={FileIcon} alt='file-icon' /> <Box ml={2}>{file.name}</Box>
              </Box>
              {success ? (
                <Box className={classes.acceptedFiles}>Uploaded successfully</Box>
              ) : (
                <ButtonBase onClick={() => inputRef.current?.click()} className={classes.fileButton}>
                  Choose another file
                </ButtonBase>
              )}
            </>
          ) : (
            <Box className={classes.dragAndDrop} onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter}>
              <Typography className={classes.blackText}>Drag and Drop here</Typography>
              <Typography className={classes.greyText}>or</Typography>
              <ButtonBase onClick={() => inputRef.current?.click()} className={classes.fileButton}>
                Browse Files
              </ButtonBase>
            </Box>
          )}
          {!file?.name && <Box className={classes.acceptedFiles}>Accepted File Types: .csv</Box>}
        </Box>
        {success ? (
          <ButtonAction onClick={() => replace('/client')} text='Home Page' size='large' fullWidth />
        ) : (
          <ButtonAction
            onClick={handleSubmit}
            text='Upload'
            size='large'
            loading={loading}
            disabled={!file || !title}
            fullWidth
          />
        )}
        <Box mt={2}>
          <Typography color='error'>{error}</Typography>
        </Box>
      </Box>
    </PageLayout>
  )
}
