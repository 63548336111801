import React from 'react'
import CarouselWithContent, { CarouselWithContentProps } from 'components/shared/CarouselWithContent'

export type HeroCarouselCarouselProps = Omit<CarouselWithContentProps, 'infiniteLoop' | 'autoPlay' | 'interval'> & {
  overlayHero: boolean
}

const HeroCarouselCarousel: React.FC<HeroCarouselCarouselProps> = ({ overlayHero, ...props }) => {
  return <CarouselWithContent infiniteLoop interval={6000} autoPlay overlayHero={overlayHero} useOverlay {...props} />
}

export default HeroCarouselCarousel
