import { makeStyles } from '@material-ui/core'

interface Props {
  width?: number
}

export const useStyles = makeStyles(() => {
  const defaultWidth = 600
  return {
    modalBox: ({ width }: Props) => ({
      width: `${width || defaultWidth}px`,
      minHeight: '200px',
      maxHeight: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      [`@media (max-width:${width}px)`]: {
        width: '100%',
      },
    }),
  }
})
