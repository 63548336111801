import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    passwordInput: {
      inputSecurity: 'disc',
      '-webkit-text-security': 'disc',
    },
  }
})
