import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { applyParamsToUrlPath } from '../../utils'
import { LoginDTO } from '../../dtos/LoginDTO'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { usePagination } from '../../hooks/usePagination'

const UserLoginHistory: React.FC = (): JSX.Element => {
  const { credentials } = useCredentials()
  const params = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [loginHistory, setLoginHistory] = useState<Array<LoginDTO>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()
  const [memberName, setMemberName] = useState<string>()

  const loadLoginHistory = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/members/${params.id}/login_activities`),
        {
          headers: credentials,
        }
      )
      setMemberName(res.data.member_name)
      setLoginHistory(res.data.login_activities)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id, setTotalPageCount])

  useEffect(() => {
    loadLoginHistory()
  }, [loadLoginHistory])

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <TableWrapper
        title={`Login history ${memberName ? `of ${memberName}` : ''}`}
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!loginHistory?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>Login date</th>
              <th>IP address</th>
              <th>Client</th>
            </tr>
          </thead>
          <tbody>
            {loginHistory.map((loginHistoryItem) => (
              <tr key={loginHistoryItem.created_at}>
                <td>{loginHistoryItem.created_at && new Date(loginHistoryItem.created_at).toLocaleString()}</td>
                <td>{loginHistoryItem.ip_address}</td>
                <td>{loginHistoryItem.client}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default UserLoginHistory
