import { makeStyles } from '@material-ui/core'
import SuccessIcon from 'images/success.svg'

export const useStyles = makeStyles((theme) => {
  return {
    packageContainer: {
      color: theme.palette.quaternary.main,
      backgroundColor: theme.palette.tertiary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(4),
    },
    packageRow: {
      padding: theme.spacing(2, 0),
      lineHeight: '27px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 200,
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.grey.A100}`,
      },
      '&:first-of-type': {
        paddingTop: 0,
        paddingBottom: theme.spacing(3),
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
      '& h2': {
        margin: 0,
      },
    },
    button: {
      marginTop: theme.spacing(2),
    },
    subUsers: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    primaryUser: {
      fontWeight: 'bold',
    },
    usersOptions: {
      alignItems: 'flex-start',
    },
    usersOption: {
      marginLeft: theme.spacing(3),
      position: 'relative',
      '&::before': {
        content: `url(${SuccessIcon})`,
        height: '24px',
        width: '24px',
        position: 'absolute',
        left: `-${theme.spacing(3)}`,
        top: '6px',
      },
    },
  }
})
