import React from 'react'
import { Box, BoxProps, Avatar } from '@material-ui/core'
import { Link } from 'react-router-dom'

export interface ProfileImageProps extends BoxProps {
  alt: string
  src?: unknown
  url?: string
  avatarClassName?: string
}

const ProfileImage: React.FC<ProfileImageProps> = ({ alt, src, url, avatarClassName, ...props }) => {
  return (
    <Box {...props}>
      {url && (
        <Link to={url}>
          <Avatar className={avatarClassName || ''} src={src} alt={alt} />
        </Link>
      )}
      {!url && <Avatar className={avatarClassName || ''} src={src} alt={alt} />}
    </Box>
  )
}

export default ProfileImage
