import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    gridItem: {
      height: '30vh',
      [theme.breakpoints.down('sm')]: {
        height: '20vh',
      }
    },
    brands: {
      overflowX: 'scroll',
      scrollbarWidth: 'none',
    },
    brandItem: {
      height: '84px',
      [theme.breakpoints.up('md')]: {
        height: '146px',
      },
    },
    hotelsLink: {
      display: 'inline-block',
      marginBottom: '-2vh',
      marginTop: '4vh'
    },
    hotelLinkWrap: {
      textAlign: 'center'
    },
    hotelDiscovery: {
      display: 'flex',
      alignItems: 'normal'
    }
  }
})
