import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    card: {
      display: 'flex',
      position: 'relative',
      height: '210px',
      [theme.breakpoints.up('sm')]: {
        height: '280px',
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 47.58%, rgba(0, 0, 0, 0.85) 100%)',
        cursor: 'pointer',
        marginBottom: '5vh',
      },
      [theme.breakpoints.up('md')]: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        height: '320px',
        marginBottom: theme.spacing(2),
      },
    },
    media: {
      height: '100%',
      width: '100%',
      position: 'relative',
      // cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        position: 'inherit',
        zIndex: 0,
      },
    },
    marketplaceLogo: {
      height: 50,
      position: 'absolute',
      zIndex: 2,
      top: 16,
      right: 16,
      [theme.breakpoints.down('md')]: {
        height: 30,
      },
    },
    newLabel: {
      position: 'absolute',
      top: theme.spacing(2),
      left: 0,
      color: '#fff',
      fontSize: '0.7rem',
      background: theme.palette.primary.main,
      padding: theme.spacing(1, 2),
      zIndex: 2,
    },
    content: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      justifyContent: 'space-between',
      padding: theme.spacing(3, 3, 2, 3),
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        '&:last-child': {
          paddingBottom: theme.spacing(4),
        },
      },
      [theme.breakpoints.down('sm')]: {
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 47.58%, rgba(0, 0, 0, 0.85) 100%)',
        cursor: 'pointer',
      },
    },
    city: {
      color: '#999',
      letterSpacing: '0.1em',
      fontSize: '0.8rem',
    },
    name: {
      fontSize: '1.5rem',
    },
    shortDescription: {
      color: '#fff',
    },
    longDescription: {
      color: '#666',
      height: '80px',
      maxHeight: '80px',
      overflow: 'hidden',
      lineHeight: '20px',
      position: 'relative',
      marginTop: '-1rem',
      wordBreak: 'break-word',
      '& i': {
        fontStyle: 'normal',
      },
      '& strong, h2, h3, h4': {
        fontSize: '1rem',
        fontWeight: 'normal',
      },
    },
    deal: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    icon: {
      color: '#999',
    },
    mobileDivider: {
      backgroundColor: 'rgb(255, 255, 255, 0.2)',
    },
  }
})
