import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    aboutTextField: {
      color: '#000',
      width: '300px',
      '& .MuiInputBase-multiline': {
        backgroundColor: '#F9F9F9',
        padding: theme.spacing(2),
        '& textarea': {
          color: '#000',
        },
      },
      '& textarea::placeholder': {
        fontSize: '14px',
      },
    },
    aboutInput: {
      color: '#000',
      border: 'none',
    },
    leftBox: {
      borderRight: `1px solid ${theme.palette.primary.light}`,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        borderRight: 0,
      },
    },
    rightBox: {
      [theme.breakpoints.down('sm')]: {
        borderTop: `1px solid ${theme.palette.primary.light}`,
        padding: `${theme.spacing(2)} 0 0`,
        marginTop: theme.spacing(2),
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    invoiceDescription: {
      maxWidth: '300px',

      '& p': {
        fontSize: '16px',
        color: theme.palette.neutrals.mediumDark,
      },
    },
  }
})
