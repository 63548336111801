/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { Box, BoxProps, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { useStyles } from './styles'

interface BodyLayoutComponentProps {
  location: 'main' | 'aside'
  mobilePosition: number
  component: React.ReactNode
  backgroundColor?: string
}

export interface BodyLayoutProps extends BoxProps {
  components: BodyLayoutComponentProps[]
}

const BodyLayout: React.FC<BodyLayoutProps> = ({ components, className, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [sortedComponents, setSortedComponents] = useState<BodyLayoutComponentProps[]>([])
  const mainItems = components.filter((component) => component.location === 'main')
  const asideItems = components.filter((component) => component.location === 'aside')

  useEffect(() => {
    const sortedComponentsArr = [...components].sort((curr, next) =>
      curr.mobilePosition > next.mobilePosition ? 1 : -1
    )
    setSortedComponents(sortedComponentsArr)
  }, [components])

  return (
    <Box className={`${classes.bodyLayoutWrapper} ${className || ''}`} {...props}>
      {!isMobile ? (
        <Grid container>
          <Grid item md={7} lg={6}>
            {mainItems.map((item, index) => (
              <React.Fragment key={index}>{item.component}</React.Fragment>
            ))}
          </Grid>
          <Grid item md={1} lg={2} />
          <Grid item md={4}>
            {asideItems.map((item, index) => (
              <React.Fragment key={index}>{item.component}</React.Fragment>
            ))}
          </Grid>
        </Grid>
      ) : (
        sortedComponents.map((item, index) => <React.Fragment key={index}>{item.component}</React.Fragment>)
      )}
    </Box>
  )
}

export default BodyLayout
