import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  onGoBack?: () => void
}

const BackButton: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  className = '',
  onGoBack,
}): JSX.Element => {
  const history = useHistory()
  return (
    <>
      {history.length && (
        <button
          onClick={() => (onGoBack ? onGoBack() : history.goBack())}
          className={`button ${className}`}
          type='button'
        >
          Back
        </button>
      )}
    </>
  )
}

export default BackButton
