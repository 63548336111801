import React, { useState } from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setTerm } from 'reducer/benefitsSlice'
import { useStyles } from './styles'

const SearchInput: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const history = useHistory()

  const goToPath = () => {
    if (!searchTerm) return
    dispatch(setTerm(searchTerm))
    history.push('/client/search-results/all')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)
  const handleEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      goToPath()
    }
  }

  return (
    <TextField
      id='search-input'
      label='Search'
      variant='outlined'
      size='small'
      value={searchTerm}
      onKeyUp={handleEnterPress}
      name='searchTerm'
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' onClick={goToPath} className={classes.searchIcon}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export default SearchInput
