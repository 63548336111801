import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      fontSize: '18px',
      position: 'relative',
      top: -theme.spacing(6),
      '& li': {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        margin: '0',
        width: '100%',
      },
    },
    wrapper: {
      backgroundColor: '#fff',
    },
  }
})
