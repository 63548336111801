import React, { ChangeEvent, FC } from 'react'
import { Range } from '../dtos/LeadDTO'

interface Props {
  onChange: (e: ChangeEvent) => void
  value: string | number
  range?: Range[]
}

const Dropdown: FC<Props> = ({ onChange, value, range, ...rest }) => {
  return range ? (
    <div className='select'>
      <select name='annual_revenue' value={value} onChange={onChange} {...rest}>
        {range.map((item: Range) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <input autoComplete='none' className='input' value={value} onChange={onChange} {...rest} />
  )
}

export { Dropdown }
