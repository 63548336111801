import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    link: {
      padding: theme.spacing(1, 2),
      color: '#fff',
      textDecoration: 'none',
    },
  }
})
