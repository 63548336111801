import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Phrases } from 'constants/phrases'
import axios from 'axios'
import { UserDTO } from '../../dtos/UserDTO'
import { AdminRoles, UserRole } from '../../const'
import { PlacesAutocomplete } from '../PlacesAutocomplete'
import OrganizationsDropdown from '../OrganizationsDropdown'
import AdminPasswordField from './AdminPasswordField'
import useCredentials from '../../hooks/useCredentials'
import { CompanyRange } from '../../dtos/LeadDTO'
import { Dropdown } from '../Dropdown'
import SubOrganizationsDropdown from '../SubOrganizationsDropdown'

interface Props {
  formData: Partial<UserDTO>
  onChange: (formData: Partial<UserDTO>) => void
  onSubmit: (formData: Partial<UserDTO>) => void
  submitting: boolean
  errors: Partial<Record<keyof UserDTO, Array<string>>>
  isEdit?: boolean
  roles: Array<UserRole>
  formError?: string
}

const UserForm: FC<Props> = ({
  formData,
  onChange,
  onSubmit,
  submitting,
  errors,
  formError,
  isEdit = false,
  roles = [],
}) => {
  const { credentials } = useCredentials()
  const isCurrentUser = credentials?.uid === formData.email
  const canChangePassword = isEdit && AdminRoles.includes(formData.role as UserRole) && isCurrentUser
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [companyRanges, setCompanyRanges] = useState<CompanyRange | null>(null)

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    onSubmit(formData)
  }

  const loadCompanyRanges = useCallback(async () => {
    try {
      const res = await axios.get('/api/panel/leads/company_data_ranges', {
        headers: credentials,
      })
      setCompanyRanges(res.data)
    } catch (e) {
      console.error(e.message)
    }
  }, [credentials])

  useEffect(() => {
    loadCompanyRanges()
  }, [loadCompanyRanges])

  const onAddressChange = (address: string) => {
    onChange({ ...formData, address })
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className='field'>
        <label className='label'>Email*</label>
        <div className='control'>
          <input
            type='email'
            className='input'
            autoComplete='none'
            required
            value={formData.email}
            onChange={(event) => onChange({ ...formData, email: event.target.value })}
          />
        </div>
        {errors.email &&
          errors.email.map((message) => (
            <p className='help is-danger' key={message}>
              {message}
            </p>
          ))}
      </div>
      {canChangePassword && (
        <AdminPasswordField
          passwordErrors={errors?.password}
          password={formData.password}
          onPasswordChange={(password: string) => onChange({ ...formData, password })}
          isEdit={isEdit}
          currentRole={formData.role as UserRole}
          setSubmitDisabled={setSubmitDisabled}
        />
      )}
      {roles.length > 1 && (
        <div className='field'>
          <label className='label'>Role</label>
          <div className='control'>
            <div className='select'>
              <select value={formData.role} onChange={(event) => onChange({ ...formData, role: event.target.value })}>
                {roles.includes(UserRole.Member) && <option value={UserRole.Member}>User</option>}
                {roles.includes(UserRole.Admin) && <option value={UserRole.Admin}>Admin</option>}
                {roles.includes(UserRole.Support) && <option value={UserRole.Support}>Support</option>}
              </select>
            </div>
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Organization*</label>
          <div className='control'>
            <OrganizationsDropdown
              required
              organizationId={formData.organization_id}
              onOrganizationSelect={(id) => onChange({ ...formData, organization_id: id })}
            />
          </div>
          {errors.organization_id &&
            errors.organization_id.map((message) => (
              <p className='help is-danger' key={message}>
                {message}
              </p>
            ))}
        </div>
      )}
      {formData.organization_id && (
        <div className='field'>
          <label className='label'>Sub-organization*</label>
          <div className='control'>
            <SubOrganizationsDropdown
              organizationId={formData.organization_id}
              subOrganizationId={formData.sub_organization_id}
              onOrganizationSelect={(id) => onChange({ ...formData, sub_organization_id: id })}
            />
          </div>
          {errors.sub_organization_id &&
            errors.sub_organization_id.map((message) => (
              <p className='help is-danger' key={message}>
                {message}
              </p>
            ))}
        </div>
      )}
      <div className='field'>
        <label className='label'>First name*</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            required
            value={formData.first_name}
            onChange={(event) => onChange({ ...formData, first_name: event.target.value })}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Last name*</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            value={formData.last_name}
            required
            onChange={(event) => onChange({ ...formData, last_name: event.target.value })}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Internal ID</label>
        <div className='control'>
          <input
            className='input'
            value={formData.internal_id}
            onChange={(event) => onChange({ ...formData, internal_id: event.target.value })}
          />
        </div>
        {errors.internal_id &&
          errors.internal_id.map((message) => (
            <p className='help is-danger' key={message}>
              {message}
            </p>
          ))}
      </div>
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Company name</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.company_name}
              onChange={(event) => onChange({ ...formData, company_name: event.target.value })}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Company website</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.company_website}
              onChange={(event) => onChange({ ...formData, company_website: event.target.value })}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Annual revenue</label>
          <div className='control'>
            <Dropdown
              onChange={(event) => onChange({ ...formData, annual_revenue: event.target.value })}
              value={formData.annual_revenue!}
              range={companyRanges?.annual_revenue}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Funding acquired since inception</label>
          <div className='control'>
            <Dropdown
              onChange={(event) => onChange({ ...formData, funding_acquired_since_inception: event.target.value })}
              value={formData.funding_acquired_since_inception!}
              range={companyRanges?.funding_acquired_since_inception}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Number of employees</label>
          <div className='control'>
            <Dropdown
              onChange={(event) => onChange({ ...formData, number_of_employees: event.target.value })}
              value={formData.number_of_employees!}
              range={companyRanges?.number_of_employees}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>LinkedIn profile URL</label>
          <div className='control'>
            <input
              autoComplete='none'
              className='input'
              value={formData.linkedin_profile_url}
              onChange={(event) => onChange({ ...formData, linkedin_profile_url: event.target.value })}
            />
          </div>
        </div>
      )}
      <div className='field'>
        <label className='label'>Phone</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            value={formData.phone}
            onChange={(event) => onChange({ ...formData, phone: event.target.value })}
          />
        </div>
      </div>
      {roles.includes(UserRole.Member) || formData.role === UserRole.Member ? (
        <>
          <div className='field'>
            <label className='label'>Country</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.country}
                onChange={(event) => onChange({ ...formData, country: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>State</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.state}
                onChange={(event) => onChange({ ...formData, state: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>City</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.city}
                onChange={(event) => onChange({ ...formData, city: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>Zip code</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.zip_code}
                onChange={(event) => onChange({ ...formData, zip_code: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>Address</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.address}
                onChange={(event) => onChange({ ...formData, address: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>Apartment</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                value={formData.apartment}
                onChange={(event) => onChange({ ...formData, apartment: event.target.value })}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='field'>
          <label className='label'>Address</label>
          <div className='control'>
            <PlacesAutocomplete onChange={onAddressChange} address={formData.address} />
          </div>
        </div>
      )}
      <div className='field'>
        <label className='label'>About</label>
        <div className='control'>
          <input
            className='input'
            value={formData.about}
            onChange={(event) => onChange({ ...formData, about: event.target.value })}
          />
        </div>
      </div>
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Position</label>
          <div className='control'>
            <input
              className='input'
              value={formData.position}
              onChange={(event) => onChange({ ...formData, position: event.target.value })}
            />
          </div>
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <label className='label'>Max sub accounts</label>
          <div className='control'>
            <input
              className='input'
              type='number'
              value={formData.max_sub_account}
              onChange={(event) => onChange({ ...formData, max_sub_account: event.target.value })}
            />
          </div>
        </div>
      )}
      <div className='field'>
        <div className='control'>
          <label htmlFor='active' className='checkbox'>
            <input
              name='active'
              type='checkbox'
              checked={formData.active}
              onChange={(event) => onChange({ ...formData, active: event.target.checked })}
            />{' '}
            Active
          </label>
        </div>
        {errors?.active &&
          errors.active?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
      </div>
      {formData.parent_id && (
        <div className='field'>
          <div className='control'>
            <label htmlFor='active' className='checkbox'>
              <input
                name='complimentary_sub_user'
                type='checkbox'
                checked={formData.complimentary_sub_user}
                onChange={(event) => onChange({ ...formData, complimentary_sub_user: event.target.checked })}
              />{' '}
              Complimentary
            </label>
          </div>
          {errors?.complimentary_sub_user &&
            errors.complimentary_sub_user?.map((message: string) => (
              <p className='help is-danger' key={message.length}>
                {message}
              </p>
            ))}
        </div>
      )}
      {(roles.includes(UserRole.Member) || formData.role === UserRole.Member) && (
        <div className='field'>
          <div className='control'>
            <label htmlFor='is_demo' className='checkbox'>
              <input
                name='is_demo'
                type='checkbox'
                checked={formData.is_demo}
                onChange={(event) => onChange({ ...formData, is_demo: event.target.checked })}
              />{' '}
              Demo user
            </label>
          </div>
          {errors?.is_demo &&
            errors.is_demo?.map((message: string) => (
              <p className='help is-danger' key={message.length}>
                {message}
              </p>
            ))}
        </div>
      )}
      <div className='field'>
        <button
          className={`button is-primary ${submitting && 'is-loading'}`}
          type='submit'
          disabled={submitting || submitDisabled}
        >
          Save
        </button>
        {formError && <div className='has-text-danger mt-2'>{formError}</div>}
        {!!errors && !!Object.values(errors).length && <div className='has-text-danger mt-2'>{Phrases.FormError}</div>}
      </div>
    </form>
  )
}

export default UserForm
