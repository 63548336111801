import React from 'react'

const ShareExperienceDescription: React.FC = () => {
  return (
    <p>
      Whether you’re looking to wind down or enjoy one of the many experiences offered through our platform, EXEC
      believes that everything is better together. As the primary account holder, your membership offers you the ability
      to add (2) sub-accounts to your account. These additional Members you add will have complimentary access to{' '}
      <strong>all EXEC benefits</strong>. You may share access to these individuals now, or do so at a later time in
      your Membership management dashboard.
    </p>
  )
}

export default ShareExperienceDescription
