import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import UserForm from '../../components/UserForm/UserForm'
import { UserDTO } from '../../dtos/UserDTO'
import { UserRole } from '../../const'
import { adminInitialState } from '../const'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { validatePassword } from '../../../utils/validation'

const CreateAdmin: FC = () => {
  const history = useHistory()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<UserDTO>>({ ...adminInitialState, password: '' })
  const [validationErrors, setValidationErrors] = useState({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      if (data.password) {
        const passwordValidationDetails = validatePassword(data.password)
        if (passwordValidationDetails?.length) {
          setValidationErrors({ password: passwordValidationDetails.map(({ message }) => message) })
          return
        }
      }
      setSubmitting(true)
      await axios.post(`/api/panel/admins`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <h4 className='is-size-4 mb-4'>New Admin</h4>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            roles={[UserRole.Admin, UserRole.Support]}
            onChange={handleChange}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default CreateAdmin
