import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    resultsSection: {
      paddingTop: 0,
    },
    category: {
      textTransform: 'capitalize',
    },
  }
})
