import React, { SelectHTMLAttributes } from 'react'
import { RedemptionType } from 'types/generatedGql'
import { redemptionTypeMap } from '../../const'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  redemptionType?: RedemptionType
  onTypeSelect: (id: string) => void
}

const RedemptionTypeDropdown: React.FC<Props> = ({
  redemptionType = RedemptionType.ReferralLink,
  onTypeSelect,
  ...props
}) => {
  return (
    <div className='select'>
      <select value={redemptionType} onChange={(event) => onTypeSelect(event.target.value)} {...props}>
        {Object.keys(redemptionTypeMap).map((key) => (
          <option value={key} key={key}>
            {redemptionTypeMap[key as RedemptionType]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default RedemptionTypeDropdown
