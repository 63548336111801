import React, { FC, useState } from 'react'
import { saveBrand } from '../../common/requests'
import useCredentials from '../../hooks/useCredentials'
import CircularSpinner from '../../components/CircularSpinner'

interface Props {
  afterSaveCallback?: () => void
}

const NewBrandForm: FC<Props> = ({ afterSaveCallback }) => {
  const { credentials } = useCredentials()
  const [brand, setBrand] = useState({ name: '', description: '' })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await saveBrand(brand, credentials)
      afterSaveCallback?.()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <CircularSpinner />
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <div className='field'>
        <label className='label'>Name*</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            required
            value={brand.name}
            onChange={(event) => setBrand({ ...brand, name: event.target.value })}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Description</label>
        <div className='control'>
          <input
            autoComplete='none'
            className='input'
            value={brand.description}
            onChange={(event) => setBrand({ ...brand, description: event.target.value })}
          />
        </div>
      </div>
      <div className='field'>
        <button className='button is-primary' type='submit'>
          Save
        </button>
      </div>
    </form>
  )
}

export default NewBrandForm
