import React from 'react'
import { Box, Typography, Checkbox } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { getValueFromQueryParams } from 'utils'
import { useStyles } from './styles'

const FilterBenefitsCheckboxes: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(location.search)

  const execOnlyFilter = query.get('execOnly')
  const marketplaceOnlyFilter = query.get('marketplaceOnly')

  const handleChange = (value: boolean, name: 'execOnly' | 'marketplaceOnly') => {
    if (query.get(name)) {
      query.delete(name)
    } else {
      query.set(name, String(value))
      const otherFilter = name === 'execOnly' ? 'marketplaceOnly' : 'execOnly'
      !!query.get(otherFilter) && query.delete(otherFilter)
    }
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    })
  }

  return (
    <Box display='flex' alignItems='center' mr={3}>
      <Typography variant='body1' component='span' className={classes.typography}>
        Filter by
      </Typography>
      <Box>
        <Box>
          <Checkbox
            style={{ padding: 0 }}
            checked={!!execOnlyFilter}
            onChange={(e) => {
              handleChange(e.currentTarget.checked, 'execOnly')
              query.delete('marketplaceOnly')
            }}
          />{' '}
          <Typography component='span' variant='body2'>
            EXEC Exclusives
          </Typography>
        </Box>
        <Box>
          <Checkbox
            style={{ padding: 0 }}
            checked={!!marketplaceOnlyFilter}
            onChange={(e) => {
              handleChange(e.currentTarget.checked, 'marketplaceOnly')
              query.delete('execOnly')
            }}
          />{' '}
          <Typography component='span' variant='body2'>
            Marketplace Only
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default FilterBenefitsCheckboxes
