import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
import OrganizationForm from '../../components/OrganizationForm'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import ErrorMessage from '../../components/ErrorMessage'

const EditSubOrganization: React.FC = () => {
  const { credentials } = useCredentials()
  const history = useHistory()
  const params = useParams<{ id: string; sub_id: string }>()

  const [formData, setFormData] = useState({
    name: '',
    subdomain: '',
    description: '',
  })

  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')

  const loadSubOrganization = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/organizations/${params.id}/sub_organizations/${params.sub_id}`, {
        headers: credentials,
      })

      const { name, subdomain, description } = res.data.sub_organization
      setFormData({ name, subdomain, description })
    } catch (e) {
      setLoadDataError(e.message)
    }
  }, [credentials, params.id, params.sub_id])

  useEffect(() => {
    loadSubOrganization()
  }, [loadSubOrganization])

  const handleChange = (newData) => {
    setFormData(newData)
  }

  const handleSubmit = async (data) => {
    try {
      await axios.put(`/api/panel/organizations/${params.id}/sub_organizations/${params.sub_id}`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (error) {
      setValidationErrors(error.response?.data)
    }
  }

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <OrganizationForm
        title='Edit Sub Organization'
        formData={formData}
        errors={validationErrors}
        onChange={handleChange}
        onSubmit={handleSubmit}
        isSub
      />
    </DefaultLayout>
  )
}

export default EditSubOrganization
