import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'

export interface LabelProps extends ButtonProps {
  text: string
}

const Label: React.FC<LabelProps> = ({ text, ...props }) => {
  return (
    <Button variant='contained' size='small' color='primary' {...props}>
      {text}
    </Button>
  )
}

export default Label
