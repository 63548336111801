import React from 'react'
import ButtonLink, { ButtonLinkProps } from 'components/shared/ButtonLink'
import { Maybe } from 'types/generatedGql'

interface ViewCategoryButtonProps extends Omit<ButtonLinkProps, 'text'> {
  category: Maybe<string>
}
const ViewCategoryButton: React.FC<ViewCategoryButtonProps> = ({ category, ...props }) => {
  return <ButtonLink text={`View ${category || ''}`} size='large' bold {...props} />
}

export default ViewCategoryButton
