import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    dots: {
      display: 'flex',
    },
    dot: {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      backgroundColor: '#bdbdbd',
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    currentDot: {
      backgroundColor: theme.palette.primary.main,
    },
  }
})
