import passwordValidator from 'password-validator'

export const validatePassword = (
  password: string,
  options: Record<string, string | number> = {}
): Array<Record<string, string>> => {
  // eslint-disable-next-line new-cap
  const schema = new passwordValidator()
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(1).has().not().spaces()
  return schema.validate(password, { details: true, ...options }) as Array<Record<string, string>>
}
