import { Dispatch, SetStateAction, useState } from 'react'
import useGetQueryParam from './useGetQueryParam'
import useSetQueryParam from './useSetQueryParam'

interface Props<T> {
  filters: Partial<T>
}

interface Result<T> {
  filters: Partial<T>
  totalPageCount: number | null
  setTotalPageCount: Dispatch<SetStateAction<null>>
  currentPageNumber: number
  setPageNumber: (page: number) => void
  applyFilters: (filters: Partial<T>) => void
}

const usePagination = <T>(props?: Props<T>): Result<T> => {
  const pageNumber = useGetQueryParam('page')
  const { setQueryParam } = useSetQueryParam()
  const [totalPageCount, setTotalPageCount] = useState(null)
  const [currentPageNumber, setCurrentPageNumber] = useState((pageNumber && Number(pageNumber)) || 1)
  const [filters, setFilters] = useState<Partial<T>>(props?.filters || {})

  const setPageNumber = (page: number) => {
    setCurrentPageNumber(page)
    setQueryParam('page', page.toString())
  }

  const applyFilters = (filter: Partial<T>) => {
    setPageNumber(1)
    setFilters(filter)
  }

  return {
    filters,
    totalPageCount,
    setTotalPageCount,
    currentPageNumber,
    setPageNumber,
    applyFilters,
  }
}

export { usePagination }
