import React from 'react'

const BenefitsDescription: React.FC = () => {
  return (
    <>
      <p>
        Your EXEC hotel benefits can save you hundreds of dollars in just a single booking. You will also discover many
        added amenities you won&apos;t find in other hotel programs, including upgrades, food & beverage credits, or spa
        discounts. The hotel and travel benefits are where Members tend to find the most consistent value from EXEC.
        These are private rates that you don&apos;t find in the travel engines.
      </p>
      <p>
        As we all get back to traveling, you now have access to some of our most valuable benefits from well-known
        partners like United Airlines, St. Regis hotels, Rosewood Hotels, Marriott, and dozens more. You also will
        discover new brands that have been curated for EXEC.
      </p>
    </>
  )
}

export default BenefitsDescription
