import React from 'react'
import { Box, BoxProps, Typography, Grid } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import MyAccountButton from 'components/shared/buttons/MyAccountButton'
import WelcomeBoxItem from 'components/shared/WelcomeBoxItem'
import { GET_CURRENT_USER } from 'constants/queries'
import { useStyles } from './styles'

const WelcomeBox: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles()

  // NOTE: Add some error handling, discuss with the team first on how to handle error.
  const { data: currentUserData } = useQuery(GET_CURRENT_USER)
  const currentUser = currentUserData?.currentUser

  return (
    <Box height='100%' {...props}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={4} className={`${classes.gridItem} ${classes.welcomeMessage}`}>
          <Typography variant='h5' component='p'>
            Welcome back, {currentUser && currentUser.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} className={`${classes.gridItem} ${classes.content}`}>
          <WelcomeBoxItem title='Member of' text={currentUser?.organization?.name} />
          {currentUser?.marketplaceMembership ? (
            currentUser?.organization?.logo?.medium ? (
              <img width='110px' src={currentUser?.organization?.logo?.medium} alt={currentUser?.organization?.name} />
            ) : null
          ) : (
            <WelcomeBoxItem title='Redemptions' text={currentUser?.redemptionsCount || 0} />
          )}
        </Grid>
        <Grid item xs={12} md={3} className={classes.gridItem}>
          <MyAccountButton fullWidth className={classes.button} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default WelcomeBox
