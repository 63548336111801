import React from 'react'
import { Box, BoxProps, Divider, Grid, Hidden } from '@material-ui/core'
import { footerItems } from 'constants/Anchors'
import { socialIcons } from 'constants/SocialIcons'
import CopyrightText from '../CopyrightText'
import Logo from '../Logo'
import NavigationLinks from '../NavigationLinks'
import SocialIcons from '../SocialIcons'
import { useStyles } from './styles'

const Footer: React.FC<BoxProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <Box mt={16} component='footer' className={`${classes.footerBox} ${className || ''}`} {...props}>
      {/* Mobile and tablet view */}
      <Hidden mdUp>
        <Grid container alignItems='flex-start' className={classes.footerContainer}>
          <Grid item md={4}>
            <NavigationLinks display='flex' flexDirection='column' items={footerItems} />
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} md={8}>
            <CopyrightText m={3} />
            {/*<SocialIcons items={socialIcons} m={3} />*/}
          </Grid>
        </Grid>
      </Hidden>

      {/* Desktop view */}
      <Hidden smDown>
        <Grid container alignItems='center' className={classes.footerContainer}>
          <Grid item xs={12} md={3} lg={4}>
            <Logo withText m={3} />
            <CopyrightText m={3} />
            {/*<SocialIcons items={socialIcons} m={3} />*/}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <NavigationLinks items={footerItems} />
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  )
}

export default Footer
