import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    title: {
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #EAEAEA',
    },
    favoriteButton: {
      border: `1px solid ${theme.palette.primary.main}`,
      marginRight: theme.spacing(1),
      borderRadius: 0,
    },
    benefits: {
      marginTop: 0,
    },
    benefitsList: {
      margin: 0,
      paddingLeft: '18px',
    },
  }
})
