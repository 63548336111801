import { useEffect, useState } from 'react'

interface SubdomainProps {
  subDomain: string
  hasSubDomain: boolean
}

const useSubdomain = (): SubdomainProps => {
  const [subDomain, setSubDomain] = useState('')
  useEffect(() => {
    setSubDomain(window.location.hostname.split('.').reverse()[2])
  }, [])
  return { subDomain, hasSubDomain: !!subDomain }
}

export default useSubdomain
