import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import Navbar from '../components/Navbar'
import PermissionMessage from '../components/PermissionMessage'
import { pathsNeedsEditPermission } from '../router/routes'
import useCredentials from '../hooks/useCredentials'

const renderContent = (children: React.ReactNode, pathname: string, hasEditPermission: boolean) => {
  const needsPermission = pathsNeedsEditPermission.find((path) => matchPath(pathname, { path, exact: true }))
  if (needsPermission) {
    return hasEditPermission ? children : <PermissionMessage />
  }
  return children
}

interface Props {
  hideNavbar?: boolean
}

const DefaultLayout: React.FC<Props> = ({ children, hideNavbar }) => {
  const { hasEditPermission } = useCredentials()
  const { pathname } = useLocation()

  return (
    <section className='hero'>
      {!hideNavbar && (
        <div className='hero-head'>
          <Navbar />
        </div>
      )}
      <div className='hero-body'>
        <div className='container'>{renderContent(children, pathname, hasEditPermission)}</div>
      </div>
    </section>
  )
}

export default DefaultLayout
