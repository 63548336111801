import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import authSlice from './auth'

const loggerMiddleware = createLogger()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const authConfig = {
  key: 'panelAuth',
  version: 1,
  storage,
}

const persistedAuthReducer = persistReducer(authConfig, authSlice)

const rootReducer = {
  panelAuth: persistedAuthReducer,
}

const store = configureStore({
  reducer: combineReducers({ ...rootReducer }),
  middleware: [loggerMiddleware, thunk],
})

export default store
