import React from 'react'
import { MenuItem, OutlinedInput, Select, SelectProps, FormControl } from '@material-ui/core'
import { useStyles } from './styles'

export interface SelectItem {
  id: string | number
  name: string
}

interface Props {
  items: SelectItem[]
  isTopLabel?: boolean
}

const SelectCustom: React.FC<Props & SelectProps> = ({ isTopLabel, items, label, fullWidth, ...props }) => {
  const classes = useStyles()

  return (
    <FormControl fullWidth={fullWidth}>
      {isTopLabel && label && <label className={classes.topLabel}>{label}</label>}
      <Select
        type={'text' || props.type}
        variant={'outlined' || props.variant}
        className={props.className || ''}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          ...props.MenuProps,
        }}
        input={<OutlinedInput classes={{ root: classes.selectRoot, notchedOutline: classes.notchedOutline }} />}
        classes={{
          select: classes.select,
          ...props.classes,
        }}
        {...props}
        {...(!isTopLabel && { label })}
      >
        {items?.map((item: SelectItem) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectCustom
