import ButtonAction from 'components/shared/ButtonAction'
import { RoutePath } from 'constants/RoutePath'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import BodyLayout from 'components/layouts/BodyLayout'
import SectionSecondary from 'components/shared/SectionSecondary'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { Box, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'
import TitleWithCaption from 'components/shared/TitleWithCaption'
import RedeemBenefitModal from 'components/shared/modals/RedeemBenefitModal'
import PageLayout from 'components/layouts/PageLayout'
import Hero from 'components/shared/Hero'
import useToggle from 'hooks/useToggle'
import defaultBenefitImage from 'images/defaultbenefit-image.jpg'
import { GET_BENEFIT, GET_BENEFITS, GET_CURRENT_USER } from 'constants/queries'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import parse from 'html-react-parser'
import Section from 'components/shared/Section'
import BenefitPreviews from 'components/shared/BenefitPreviews'
import { BenefitPreviewDTO, CategorySlug } from 'types'
import { Benefit, BenefitImage, Rate } from 'types/generatedGql'
import { ADD_FAVORITE_MUTATION } from 'constants/mutations'
import { getBenefitPreviewData } from 'utils'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { useStyles } from './styles'

interface Params {
  slug: string
}

const configBenefitsForPreview = (
  relatedBenefits: Array<Benefit>,
  isTablet: boolean,
  benefitId?: number
): Array<BenefitPreviewDTO> => {
  const data = relatedBenefits.filter(({ id }) => id !== benefitId).slice(0, Math.min(3, relatedBenefits.length))
  return data.map((benefit) => getBenefitPreviewData(benefit, isTablet))
}

const BenefitDetails: React.FC = () => {
  const { slug } = useParams<Params>()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const [favorited, toggleFavorite, setFavorited] = useToggle(false)
  const { isTablet } = useGetWindowSize()

  const query = new URLSearchParams(location.search)

  const { data, error } = useQuery(GET_BENEFIT, {
    variables: {
      slug,
    },
  })

  const [addFavorite] = useMutation(ADD_FAVORITE_MUTATION)
  const handleFavoriteClicked = async () => {
    try {
      await addFavorite({
        variables: {
          benefitSlug: slug,
        },
      })
      toggleFavorite()
    } catch (e) {
      console.error(e)
    }
  }

  const benefit = data?.getBenefit

  const [getBenefits, { data: relatedBenefits }] = useLazyQuery(GET_BENEFITS, {
    variables: {
      page: 1,
      category: benefit?.category?.slug,
    },
  })

  useEffect(() => {
    if (benefit?.category?.slug !== CategorySlug.HOTELS) {
      getBenefits()
    }
  }, [benefit?.category?.slug, getBenefits])

  useEffect(() => {
    if (benefit?.favorited) {
      setFavorited(true)
    }
  }, [benefit, setFavorited])

  const benefitCarouselImages = benefit?.images?.length
    ? benefit.images.map((img: BenefitImage) => {
        return { image: isTablet ? img?.medium : img?.large }
      })
    : null
  const defaultCarouselImages = [{ image: defaultBenefitImage }]
  const isLocationPresent = benefit?.longitude && benefit?.latitude
  const addressInfo = `${benefit?.address1 || ''} ${benefit?.city || ''} ${benefit?.postalCode || ''}
    ${benefit?.country || ''}`
  const googleMapsImage = `https://maps.googleapis.com/maps/api/staticmap?center=${benefit?.latitude},${benefit?.longitude}&zoom=14&size=400x400&markers=color:red&key=AIzaSyDrh2tLypp5OrzSCxro3UWMXDCOUUHwzVY`
  const { data: userData } = useQuery(GET_CURRENT_USER)

  return (
    <PageLayout
      error={error}
      hero={
        <Hero
          height='68vh'
          maxHeight='540px'
          minHeight='320px'
          carousel={{
            dotsMarginBottom: 64,
            items: !benefitCarouselImages ? defaultCarouselImages : benefitCarouselImages,
          }}
        />
      }
      negativeOffsetTop
    >
      {benefit && (
        <>
          <BodyLayout
            components={[
              {
                component: (
                  <TitleWithCaption
                    caption={{ text: benefit.category.name, props: { color: 'primary' } }}
                    name={benefit?.name}
                    subtitle={addressInfo}
                    website={benefit.website}
                    brandName={benefit.brand?.name}
                    className={classes.title}
                  />
                ),
                location: 'main',
                mobilePosition: 0,
              },
              {
                component: <SectionSecondary>{parse(benefit?.description)}</SectionSecondary>,
                location: 'main',
                mobilePosition: 3,
              },
              {
                component: isLocationPresent && (
                  <SectionSecondary title='Location' fullWidthSm>
                    <img src={googleMapsImage} alt='Google maps location' />
                  </SectionSecondary>
                ),
                location: 'main',
                mobilePosition: 5,
              },
              {
                component: (
                  <SectionSecondary backgroundColor='#F9F9F9' fixedOnBottom>
                    <Box display='flex'>
                      <IconButton onClick={handleFavoriteClicked} className={classes.favoriteButton}>
                        {favorited ? <FavoriteIcon color='primary' /> : <FavoriteBorderIcon color='primary' />}
                      </IconButton>
                      {!userData?.currentUser?.marketplaceMembership ||
                      userData?.currentUser?.fullAccessPaid ||
                      benefit.isMarketplace ? (
                        <RedeemBenefitModal benefit={benefit} slug={slug} />
                      ) : (
                        <ButtonAction
                          onClick={() => {
                            query.set('unlockFullAccess', 'true')
                            query.set('benefitName', slug)
                            history.push({ pathname: RoutePath.PreApplication, search: query.toString() })
                          }}
                          type='button'
                          text='UNLOCK FULL ACCESS'
                          size='large'
                          bold
                          fullWidth
                        />
                      )}
                    </Box>
                  </SectionSecondary>
                ),
                location: 'aside',
                mobilePosition: 7,
              },
              {
                component: (
                  <SectionSecondary component='div'>
                    {!!benefit?.rates?.length && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell align='center'>EXEC Rate</TableCell>
                            <TableCell align='center'>Standard</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {benefit.rates.map((rate: Rate) => (
                            <TableRow key={JSON.stringify(rate)}>
                              <TableCell>{rate?.category}</TableCell>
                              <TableCell align='center'>
                                {rate?.execRate && !rate?.execRate?.endsWith('off') && '$'}
                                {rate?.execRate}
                              </TableCell>
                              <TableCell align='center'>
                                {rate?.standardRate && !rate?.standardRate?.endsWith('off') && '$'}
                                {rate?.standardRate}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </SectionSecondary>
                ),
                location: 'aside',
                mobilePosition: 1,
              },
              {
                component: (benefit?.benefits || benefit?.otherRateOffer || benefit?.benefitSummary) && (
                  <SectionSecondary title='Benefits' backgroundColor='#F1EADE' className={classes.benefits}>
                    {benefit?.benefitSummary && <Typography>{benefit?.benefitSummary}</Typography>}
                    {benefit?.otherRateOffer && <Typography>{benefit?.otherRateOffer}</Typography>}
                    {!!benefit?.benefits?.length && (
                      <ul className={classes.benefitsList}>
                        {benefit.benefits.map((b: string) => {
                          return (
                            <li key={b}>
                              <Typography>{b}</Typography>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </SectionSecondary>
                ),
                location: 'aside',
                mobilePosition: 2,
              },
              {
                component: benefit?.additionalInformation && (
                  <SectionSecondary title='Additional Information'>
                    {benefit?.additionalInformation || ''}
                  </SectionSecondary>
                ),
                location: 'aside',
                mobilePosition: 6,
              },
              {
                component: benefit?.termsAndCondition && (
                  <SectionSecondary title='Terms and Conditions'>{benefit?.termsAndCondition}</SectionSecondary>
                ),
                location: 'aside',
                mobilePosition: 4,
              },
            ]}
          />
          {relatedBenefits?.benefits?.collection?.length && (
            <Section title='Other Benefits You May Like' overflowContentSm>
              <BenefitPreviews
                items={configBenefitsForPreview(relatedBenefits.benefits?.collection, isTablet, benefit?.id)}
                variant='standard'
                useOverlay
              />
            </Section>
          )}
        </>
      )}
    </PageLayout>
  )
}

export default BenefitDetails
