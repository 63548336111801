import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    caption: {
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
    },
    defaultColor: {
      color: '#999',
    },
    name: {
      textTransform: 'capitalize',
    },
    websiteLink: {
      color: theme.palette.primary.main,
      border: '1px solid',
      padding: '5px',
    },
  }
})
