import React from 'react'
import { Box, BoxProps, Typography } from '@material-ui/core'
import { useStyles } from './styles'

const CopyrightText: React.FC<BoxProps> = (props) => {
  const classes = useStyles()
  return (
    <Box {...props}>
      <Typography variant='body2' component='p' className={classes.copyright}>
        &copy; {new Date().getFullYear()} EXEC, LLC. All rights reserved.
      </Typography>
    </Box>
  )
}

export default CopyrightText
