import React, { useState } from 'react'
import { Box, BoxProps } from '@material-ui/core'
import HeroBottomComponentWrapper from 'components/shared/HeroBottomComponentWrapper'
import { CarouselWithContentProps } from 'components/shared/CarouselWithContent'
import HeroCarousel from 'components/shared/HeroCarousel'
import { useStyles } from './styles'

export interface HeroBottomComponentProps extends BoxProps {
  getComponentHeight: (height: number) => void
}

export interface HeroProps extends BoxProps {
  carousel: CarouselWithContentProps
  bottomComponent?: React.FC
  overlayHero?: boolean
  carousalClassName?: string
}

const Hero: React.FC<HeroProps> = ({
  bottomComponent: BottomComponent,
  carousel,
  className,
  carousalClassName,
  overlayHero,
  ...props
}) => {
  const classes = useStyles()
  const [marginBottom, setMarginBottom] = useState<number>(16)

  return (
    <Box position='relative' mb={`${marginBottom}px`} className={`${classes.hero} ${className || ''}`} {...props}>
      <HeroCarousel
        dotsMarginBottom={marginBottom}
        overlayHero={overlayHero}
        {...carousel}
        className={`${carousalClassName || ''}`}
      />

      {BottomComponent && (
        <HeroBottomComponentWrapper setParentMarginBottom={setMarginBottom}>
          <BottomComponent />
        </HeroBottomComponentWrapper>
      )}
    </Box>
  )
}

export default Hero
