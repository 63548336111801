import React from 'react'
import BenefitPreviews from 'components/shared/BenefitPreviews'
import Section from 'components/shared/Section'
import AbsentInfo from 'components/shared/AbsentInfo'
import { configBenefitsForPreview } from 'utils'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { useQuery } from '@apollo/client'
import { GET_USER_FAVORITE_BENEFITS } from 'constants/queries'
import { Box, Typography } from '@material-ui/core'

const Favourites: React.FC = () => {
  const { isTablet } = useGetWindowSize()

  const {
    data: userFavoriteBenefitsData,
    error,
    loading,
  } = useQuery(GET_USER_FAVORITE_BENEFITS, {
    fetchPolicy: 'network-only',
  })

  const userFavorites = userFavoriteBenefitsData?.getUserFavoriteBenefits?.collection

  return (
    <Section
      title={
        <>
          Favorites (
          <Typography component='span' variant='h4' color='primary'>
            {userFavoriteBenefitsData?.getUserFavoriteBenefits?.metadata?.totalCount || 0}
          </Typography>
          )
        </>
      }
      overflowContentSm
    >
      {!userFavorites?.length && (
        <AbsentInfo
          info="You haven't favorited any benefits yet. Why not look around and you will find awesome benefits that you will love."
          buttonText='Explore Benefits'
          link='/client/benefits/category/all'
        />
      )}

      <Box mt={4}>
        <BenefitPreviews
          items={configBenefitsForPreview(userFavorites, isTablet)}
          variant='standard'
          useOverlay
          loading={loading}
          error={error?.message}
        />
      </Box>
    </Section>
  )
}

export default Favourites
