import { useState, useEffect } from 'react'

const TABLET_WIDTH = 1024
const MOBILE_WIDTH = 414

interface WindowSize {
  windowWidth?: number
  windowHeight?: number
  isTablet: boolean
  isMobile: boolean
}

const useGetWindowSize = (): WindowSize => {
  const [windowWidth, setWindowWidth] = useState<number>()
  const [windowHeight, setWindowHeight] = useState<number>()
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)

      if (window.innerWidth <= MOBILE_WIDTH) {
        setIsMobile(true)
        return
      }

      if (window.innerWidth <= TABLET_WIDTH) {
        setIsTablet(true)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    windowWidth,
    windowHeight,
    isTablet,
    isMobile,
  }
}

export default useGetWindowSize
