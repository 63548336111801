import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useStyles } from 'components/shared/TextFieldCustom/styles'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'

interface Props {
  isTopLabel?: boolean
}

const TextFieldCustom: React.FC<TextFieldProps & Props> = ({ fullWidth, isTopLabel, label, ...props }) => {
  const classes = useStyles()

  return (
    <FormControl fullWidth={fullWidth}>
      {isTopLabel && label && <label className={classes.topLabel}>{label}</label>}
      <TextField
        type={'text' || props.type}
        variant={'outlined' || props.variant}
        className={props.className || ''}
        InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel }, ...props.InputLabelProps }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            input: classes.textField,
          },
          ...props.InputProps,
        }}
        {...props}
        {...(!isTopLabel && { label })}
      />
    </FormControl>
  )
}

export default TextFieldCustom
