import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import classnames from 'classnames'
import moment from 'moment'
import DefaultLayout from '../../layouts/DefaultLayout'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import { LeadDTO, LeadStatus, Plan } from '../../dtos/LeadDTO'
import { DefaultShowForm } from '../../components/DefaultShowForm'
import { getLeadFormData, leadStatusMap } from './const'
import { RouterPath } from '../../router/routes'
import AssignProductButton from './AssignProductButton'

const ShowLead: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { credentials, hasEditPermission } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [approving, setApproving] = useState<boolean>(false)
  const [rejecting, setRejecting] = useState<boolean>(false)
  const [statusError, setStatusError] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [lead, setLead] = useState<Partial<LeadDTO>>({})
  const [loadDataError, setLoadDataError] = useState('')

  const loadLead = useCallback(async (): Promise<void> => {
    try {
      const res = await axios.get(`/api/panel/leads/${params.id}`, {
        headers: credentials,
      })
      setLead(res.data.record)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadLead()
  }, [loadLead])

  const deleteLead = async () => {
    if (confirm('Do you want to delete?')) {
      try {
        setStatusError('')
        await axios.delete(`/api/panel/leads/${lead.id}`, {
          headers: credentials,
        })
        history.push(RouterPath.LEADS_VIEW)
      } catch (e) {
        if (e.response?.data?.message) {
          console.error(e.response.data.message)
          setStatusError(e.response.data.message)
        } else {
          setStatusError(e?.message)
          console.error(e.message)
        }
      }
    }
  }

  const approveLead = async () => {
    if (confirm('Do you want to approve lead?')) {
      try {
        setApproving(true)
        setStatusError('')
        await axios.patch(`/api/panel/leads/${lead.id}/approve`, null, {
          headers: credentials,
        })
        history.push(`/panel/members/${lead.id}`)
      } catch (e) {
        if (e.response?.data?.message) {
          console.error(e.response.data.message)
          setStatusError(e.response.data.message)
        } else {
          setStatusError(e?.message)
          console.error(e.message)
        }
      } finally {
        setApproving(false)
      }
    }
  }

  const rejectLead = async () => {
    if (confirm('Do you want to reject lead?')) {
      try {
        setRejecting(true)
        setStatusError('')
        await axios.patch(`/api/panel/leads/${lead.id}/reject`, null, {
          headers: credentials,
        })
        await loadLead()
      } catch (e) {
        if (e.response?.data?.message) {
          console.error(e.response.data.message)
          setStatusError(e.response.data.message)
        } else {
          setStatusError(e?.message)
          console.error(e.message)
        }
      } finally {
        setRejecting(false)
      }
    }
  }

  const pendingLead = async () => {
    if (confirm('Do you want to set Pending status?')) {
      try {
        setPending(true)
        setStatusError('')
        await axios.patch(`/api/panel/leads/${lead.id}/pending`, null, {
          headers: credentials,
        })
        await loadLead()
      } catch (e) {
        if (e.response?.data?.message) {
          console.error(e.response.data.message)
          setStatusError(e.response.data.message)
        } else {
          setStatusError(e?.message)
          console.error(e.message)
        }
      } finally {
        setPending(false)
      }
    }
  }

  const onGoBack = () => {
    if (lead.lead_status === LeadStatus.APPROVED) {
      history.push(RouterPath.MEMBERS_VIEW)
    } else {
      history.goBack()
    }
  }

  return (
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      {!!lead && (
        <DefaultShowForm
          headerName={`${lead.full_name} (${leadStatusMap[lead.lead_status as LeadStatus] || 'Incomplete'})`}
          headerInfo={moment(lead.created_at).format('MM/DD/YYYY')}
          formData={getLeadFormData(lead)}
          loading={loading}
          error={statusError}
          onGoBack={onGoBack}
          headerActions={
            hasEditPermission && (
              <>
                {[LeadStatus.PENDING, LeadStatus.REJECTED].includes(lead.lead_status as LeadStatus) &&
                  (lead.plan === Plan.custom && !lead.chargify_product_id ? (
                    <AssignProductButton leadId={lead.id} refetchLead={loadLead} />
                  ) : (
                    <button
                      type='button'
                      className={classnames('button is-info', approving && 'is-loading')}
                      onClick={approveLead}
                    >
                      Approve
                    </button>
                  ))}
                {lead.lead_status === LeadStatus.REJECTED && (
                  <button
                    type='button'
                    className={classnames('button is-outlined is-info ml-2', pending && 'is-loading')}
                    onClick={pendingLead}
                  >
                    Pending
                  </button>
                )}
                {lead.lead_status === LeadStatus.PENDING && (
                  <button
                    type='button'
                    className={classnames('button is-danger ml-2', rejecting && 'is-loading')}
                    onClick={rejectLead}
                  >
                    Reject
                  </button>
                )}
                <Link to={`/panel/leads/${lead.id}/edit`} className='button ml-2'>
                  Edit
                </Link>
              </>
            )
          }
          footerActions={
            hasEditPermission && (
              <button type='button' className='button is-danger' onClick={() => deleteLead()}>
                Delete
              </button>
            )
          }
        />
      )}
    </DefaultLayout>
  )
}

export default ShowLead
