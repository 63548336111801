import React from 'react'
import { Typography, Box, Container } from '@material-ui/core'

const ApplicationHeader: React.FC = () => {
  return (
    <Container maxWidth='md'>
      <Box my={4}>
        <Typography variant='h5'>Thank you for your interest in EXEC membership.</Typography>
      </Box>

      <Box mb={4}>
        <Typography variant='body2'>
          Please complete the form below with additional details about yourself, your company, and provide a bit more
          information about your interest in EXEC. By completing this form, you will take the first step to enjoying the
          privileged Membership benefits of EXEC and fast-track your opportunity to join.
        </Typography>
      </Box>
    </Container>
  )
}

export default ApplicationHeader
