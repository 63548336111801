import React from 'react'
import { Box, Card, CardContent, Grid, Hidden, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './styles'

interface Props {
  sideImage: string
  onCloseModal?: () => void
  contentMaxWidth?: number
  customSideImageLoading?: boolean
  customLogo?: string
}

export const BoxLayout: React.FC<Props> = ({
  sideImage,
  onCloseModal,
  contentMaxWidth,
  customSideImageLoading,
  customLogo,
  children,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      {onCloseModal && (
        <IconButton onClick={onCloseModal} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
      <CardContent className={classes.content}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={6} style={{ position: 'relative' }}>
              {!customSideImageLoading && (
                <>
                  {customLogo && (
                    <Box className={classes.logoOverlay}>
                      <img src={customLogo} alt='custom-logo' width='30%' />
                    </Box>
                  )}
                  <img width='100%' src={sideImage} alt='thumbnail' />
                </>
              )}
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6} className={classes.mainContentWrapper}>
            <Box
              className={classes.mainContent}
              style={{ ...(contentMaxWidth ? { maxWidth: `${contentMaxWidth}px` } : {}) }}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
