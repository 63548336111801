import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '0.9rem',
      marginBottom: theme.spacing(1),
    },
    text: {
      fontSize: '1.4rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      },
    },
  }
})
