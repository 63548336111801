import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Box,
  Divider,
  Hidden,
  IconButton,
  Toolbar,
  Collapse,
  ClickAwayListener,
  Container,
  useScrollTrigger,
} from '@material-ui/core'
import Logo from 'components/shared/Logo'
import MenuIcon from '@material-ui/icons/Menu'
import { navigationBarItemsMobile, externalNavigationBarItems } from 'constants/Anchors'
import SearchInput from 'components/shared/SearchInput'
import NavigationLinks from 'components/shared/NavigationLinks'
import NavigationLink from 'components/shared/NavigationLink'
import ProfileImage from 'components/shared/ProfileImage'
import SettingsDropdown from 'components/shared/SettingsDropdown'
import SearchIcon from '@material-ui/icons/Search'
import { RoutePath } from 'constants/RoutePath'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer/store'
import { useLazyQuery } from '@apollo/client'
import { NavigationLinkProps } from 'components/shared/NavigationLink/NavigationLink'
import { GET_CATEGORIES, GET_CURRENT_USER } from 'constants/queries'
import { useHistory, useLocation } from 'react-router-dom'
import { commonRoutes } from 'constants/Routes'
import { useStyles } from './styles'

interface NavigationBarProps {
  isTransparent?: boolean
  externalNavBar?: boolean
  hideNavLinks?: boolean
}

interface CategoryProps {
  name: string
  slug: string
}

const getCategoryLinks = (categories: CategoryProps[] = []): NavigationLinkProps[] => {
  const items: NavigationLinkProps[] = []
  categories.forEach((category) => {
    if (category.slug === 'hotels') {
      items.push({
        text: category.name,
        to: `/client/hotels/discovery`,
      })
    } else {
      items.push({
        text: category.name,
        to: `/client/benefits/category/${category.slug}`,
      })
    }
  })
  items.push({
    text: 'All Benefits',
    to: '/client/benefits/category/all',
  })
  return items
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isTransparent, externalNavBar, hideNavLinks }) => {
  const credentials = useSelector((state: RootState) => state.auth.credentials)
  const history = useHistory()
  const location = useLocation()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const [getCategories, { data }] = useLazyQuery(GET_CATEGORIES)

  const [getCurrentUser, { data: userData }] = useLazyQuery(GET_CURRENT_USER)

  const showExternalNavBar =
    externalNavBar || userData?.currentUser?.preApplicationStep || userData?.currentUser?.onboardingData?.stepName

  useEffect(() => {
    if (credentials) {
      getCategories()
      getCurrentUser()
    }
  }, [credentials, getCategories, getCurrentUser])

  useEffect(() => {
    if (
      userData?.currentUser?.onboardingData?.stepName &&
      location.pathname !== RoutePath.Onboarding &&
      !commonRoutes.includes(location.pathname as RoutePath)
    ) {
      history.push(RoutePath.Onboarding)
    }

    if (
      userData?.currentUser?.preApplicationStep &&
      location.pathname !== RoutePath.PreApplication &&
      !commonRoutes.includes(location.pathname as RoutePath)
    ) {
      history.push(RoutePath.PreApplication)
    }
  }, [history, location.pathname, userData])

  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 80 })
  const classes = useStyles()

  const configProfileImage = () => {
    const currentUser = userData?.currentUser
    const firstName = currentUser?.firstName
    const lastName = currentUser?.lastName
    const alt = firstName && lastName ? `${firstName[0]}${lastName[0]}` : ''

    return {
      url: '/client/my-account',
      src: currentUser?.photo?.thumbnail || '',
      alt,
    }
  }

  // Close search input whenever menu is opened
  const toggleMenu = () => {
    setIsMenuOpen((wasOpen) => !wasOpen)
    setIsSearchOpen(false)
  }

  // Close menu whenever search input is opened
  const toggleSearch = () => {
    setIsSearchOpen((wasOpen) => !wasOpen)
    setIsMenuOpen(false)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsSearchOpen(false)
        setIsMenuOpen(false)
      }}
    >
      <AppBar
        component='header'
        position='sticky'
        className={`${classes.appBar} ${isTransparent ? classes.transparentBar : ''} ${
          isTransparent && (!trigger ? classes.appBarTransparentInitial : classes.appBarTransparentTriggered)
        }`}
      >
        <Container maxWidth='lg'>
          <Toolbar className={classes.toolbar}>
            <Logo withText />

            {!hideNavLinks && (
              <>
                <Box display='flex' alignItems='center'>
                  {/* Mobile and tablet view */}
                  <Hidden mdUp>
                    {!showExternalNavBar && (
                      <IconButton onClick={toggleSearch}>
                        <SearchIcon className={classes.icon} />
                      </IconButton>
                    )}

                    <IconButton
                      edge='start'
                      aria-label='menu'
                      aria-controls='menu'
                      aria-haspopup='true'
                      onClick={toggleMenu}
                      className={`${classes.hamburgerMenu} ${classes.icon}`}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>

                  {/* Desktop view */}
                  <Hidden smDown>
                    <NavigationLinks
                      items={showExternalNavBar ? externalNavigationBarItems : getCategoryLinks(data?.categories)}
                      display='flex'
                      alignItems='center'
                      mx={2}
                    />
                    {!showExternalNavBar && <SearchInput className={classes.searchInputDesktop} />}

                    {credentials && !showExternalNavBar && (
                      <>
                        <Divider orientation='vertical' className={classes.dividerVertical} />
                        <SettingsDropdown className={classes.profileImage} {...configProfileImage()} />
                      </>
                    )}
                  </Hidden>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
        {/* Mobile collapse menu and search input */}
        <Hidden mdUp>
          <Collapse in={isSearchOpen} className={classes.belowToolbar}>
            <Box display='flex' justifyContent='center' p={2}>
              <SearchInput fullWidth />
            </Box>
          </Collapse>

          <Collapse in={isMenuOpen} className={classes.belowToolbar}>
            <Box component='nav' display='flex' flexDirection='column' justifyContent='center' p={2}>
              {credentials && (
                <>
                  <Box ml={2} my={1} display='flex' alignItems='center'>
                    <ProfileImage mr={2} className={classes.profileImage} {...configProfileImage()} />
                    <NavigationLink to={RoutePath.MyAccount} text='Account' />
                  </Box>
                  <Divider className={classes.dividerHorizontal} />
                </>
              )}
              <NavigationLinks
                m={0}
                display='flex'
                flexDirection='column'
                onClick={toggleMenu}
                items={showExternalNavBar ? externalNavigationBarItems : navigationBarItemsMobile}
              />
            </Box>
          </Collapse>
        </Hidden>
      </AppBar>
    </ClickAwayListener>
  )
}

export default NavigationBar
