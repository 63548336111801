import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme>((theme) => {
  return {
    markerWrapper: {
      transform: 'translate(-50%, -100%)',
    },
    marker: {
      backgroundColor: '#fff',
      minWidth: 86,
      padding: 6,
      textAlign: 'center',
      border: '1px solid black',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
    section: {
      position: 'static',
    },
    main: {
      width: '100%',
      maxWidth: '100%',
      position: 'relative',
    },
  }
})
