import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useStyles } from './styles'

export interface ButtonLinkProps extends Omit<ButtonProps<RouterLink>, 'component'> {
  text: string
  to: string
  bold?: boolean
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ text, to, bold, className, ...props }) => {
  const classes = useStyles()

  return (
    <Button
      variant='contained'
      color='primary'
      className={`${className} ${bold ? classes.bold : ''}`}
      component={RouterLink}
      to={to}
      {...props}
    >
      {text}
    </Button>
  )
}

export default ButtonLink
