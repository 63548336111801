import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    tab: {
      padding: theme.spacing(2, 0),
      textTransform: 'none',
    },
  }
})
