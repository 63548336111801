import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
  dotsMarginBottom?: number
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    carousel: {
      height: '100%',
      '& .carousel, .slider-wrapper, .slider': {
        height: '100%',
      },
      '& .control-dots': {
        marginBottom: ({ dotsMarginBottom }) => (dotsMarginBottom || 10) + 10,
        [theme.breakpoints.down('sm')]: {
          marginBottom: ({ dotsMarginBottom }) => (dotsMarginBottom || 10) * 1.25 * 0.2 + 10,
        },
      },
      '& .carousel img ': {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        '-ms-transform': 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      },
    },
    primary: {
      '& .control-dots': {
        bottom: ({ dotsMarginBottom }) => (dotsMarginBottom ? 0 : '15%'),
      },
    },
    secondary: {
      '& .control-dots': {
        bottom: 0,
        textAlign: 'left',
        padding: theme.spacing(0, 0, 2, 2),
        margin: 0,
        [theme.breakpoints.down('sm')]: {
          top: 0,
          padding: theme.spacing(2, 0, 0, 2),
        },
      },
    },
    card: {
      height: '100%',
    },
    cardMedia: {
      position: 'relative',
      objectFit: 'cover',
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      minHeight: '100%',
    },
    cardOverlay: {
      // TODO: overlay "standard" and "dark"
      // background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
      // background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
      opacity: 0.9,
    },
    cardOverlayHero: {
      opacity: 0.99999999,
    },
    cardMediaOverlay: {
      position: 'relative',
      zIndex: -1,
    },
    title: {
      textTransform: 'capitalize',
    },
    content: {
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    innerContent: {
      color: 'white',
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    innerContentContainer: {
      width: '70%',
    },
    typography: {
      marginBottom: theme.spacing(2),
    },
  }
})
