import React, { useEffect, useState } from 'react'
import { Box, ModalProps, TextFieldProps, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { UPDATE_PAYMENT_METHOD_MUTATION } from 'constants/mutations'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import InputMask from 'react-input-mask'
import { handleCardNumberSpaces } from 'utils'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper'
import DefaultModalContent from 'components/shared/DefaultModal/DefaultModalContent'
import ButtonAction from 'components/shared/ButtonAction'
import { UserPaymentMethod, UpdatePaymentMethodInput } from 'types/generatedGql'

interface Props extends Omit<ModalProps, 'open' | 'children'> {
  refetchPaymentMethods: () => void
  paymentMethod?: UserPaymentMethod
  closeModal: () => void
  isOpened: boolean
}

const initialData: UpdatePaymentMethodInput = {
  name: '',
  cardNumber: '',
  cvc: '',
  expiryDate: '',
}

const AddPaymentMethodModal: React.FC<Props> = ({
  refetchPaymentMethods,
  paymentMethod,
  isOpened,
  closeModal,
  ...props
}) => {
  const [data, setData] = useState<UpdatePaymentMethodInput>(initialData)
  const formClasses = useFormStyles()

  const [updateUserProfile, { loading: submitting, error }] = useMutation(UPDATE_PAYMENT_METHOD_MUTATION, {
    onCompleted: () => {
      refetchPaymentMethods()
      closeModal()
    },
  })

  useEffect(() => {
    if (paymentMethod) {
      setData((prevState) => ({
        ...prevState,
        name: paymentMethod.name,
        cardNumber: `xxxx-xxxx-xxxx-${paymentMethod.lastFourDigitsOfCard}`,
        expiryDate: `${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`,
      }))
    }
  }, [paymentMethod, setData])

  const handleValue = (value: string, fieldName: string, prevData: UpdatePaymentMethodInput) => {
    if (fieldName === 'cardNumber') {
      if (paymentMethod?.lastFourDigitsOfCard === data?.cardNumber?.slice(-4)) {
        return ''
      }
      return handleCardNumberSpaces(value, prevData[fieldName] as string)
    }
    return value
  }

  const onSubmit = async () => {
    await updateUserProfile({
      variables: {
        ...data,
        cvc: data.cvc?.replace(/[^0-9]/g, ''),
        ...(paymentMethod?.lastFourDigitsOfCard === data?.cardNumber?.slice(-4)
          ? {
              keepCardNumber: true,
              cardNumber: null,
            }
          : {
              cardNumber: data.cardNumber?.replace(/[^0-9]/g, ''),
            }),
      },
    })
  }

  const onChange = (value: string, fieldName: keyof UpdatePaymentMethodInput) => {
    setData((prevData) => ({ ...prevData, [fieldName]: handleValue(value, fieldName, prevData) }))
  }

  return (
    <DefaultModalContent
      toggleModal={closeModal}
      isModalOpen={isOpened}
      width={400}
      content={
        <>
          <Box mb={4} mt={2}>
            <Typography color='primary' variant='h6' style={{ fontWeight: 'bold' }}>
              {paymentMethod ? 'Edit payment information.' : 'Add payment method.'}
            </Typography>
          </Box>
          <form noValidate autoComplete='off'>
            <Box className={formClasses.formRow}>
              <TextFieldCustom
                label='Full name as it appears on card'
                type='text'
                isTopLabel
                required
                value={data.name}
                onChange={(event) => onChange(event.target.value, 'name')}
                fullWidth
              />
            </Box>

            <Box className={formClasses.formRow}>
              <TextFieldCustom
                label='Card number'
                type='tel'
                isTopLabel
                required
                value={data.cardNumber}
                onChange={(event) => onChange(event.target.value, 'cardNumber')}
                fullWidth
              />
            </Box>

            <Box className={formClasses.formRow}>
              <InputMask
                mask='99/9999'
                type='text'
                value={data.expiryDate}
                onChange={(event) => onChange(event.target.value, 'expiryDate')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextFieldCustom label='Expiration' isTopLabel required fullWidth {...inputProps} />
                )}
              </InputMask>

              <InputMask
                mask='9999'
                type='text'
                value={data.cvc}
                onChange={(event) => onChange(event.target.value?.replaceAll('_', ''), 'cvc')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextFieldCustom label='CVC' isTopLabel required fullWidth {...inputProps} />
                )}
              </InputMask>
            </Box>
          </form>
          {error && (
            <Typography className='mt-2' color='error'>
              {error?.message}
            </Typography>
          )}
        </>
      }
      actions={
        <ButtonAction
          text='Save'
          type='submit'
          size='large'
          onClick={onSubmit}
          loading={submitting}
          disabled={Object.keys(data).reduce((previousValue, filedName) => previousValue || !data[filedName], false)}
        />
      }
      {...props}
    />
  )
}

export default AddPaymentMethodModal
