import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import AdminAppRouter from '../panel/router/AdminAppRouter'
import store from '../panel/reducer/store'

const tagManagerArgs = {
  gtmId: 'G-18LR5P48QL',
}
TagManager.initialize(tagManagerArgs)

const persistor = persistStore(store)

const Admin: React.FC = (): JSX.Element => {
  useEffect(() => {
    const googleApiKey = (document.querySelector('meta[name=google-api-key]') as HTMLMetaElement)?.content
    if (!googleApiKey) {
      console.error('No GOOGLE API KEY provided')
      return () => {}
    }
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=googleMapInit`
    script.async = true
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <AdminAppRouter />
      </PersistGate>
    </Provider>
  )
}

export default Admin
