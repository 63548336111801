import React, { SyntheticEvent, useState } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { Phrases } from 'constants/phrases'
import { OrganizationDTO } from '../dtos/OrganizationDTO'

interface Props {
  title: string
  formData: Partial<OrganizationDTO>
  onChange: (formData: Partial<OrganizationDTO>) => void
  onSubmit: (formData: Partial<OrganizationDTO> | FormData) => void
  errors: Partial<Record<keyof OrganizationDTO, Array<string>>>
  isSub?: boolean
}
const MEGA_BYTES_10 = 10485760

const OrganizationForm: React.FC<Props> = ({ title, formData, onChange, onSubmit, isSub, errors }) => {
  const handleSubmit = (event: SyntheticEvent) => {
    const fd = new FormData()
    !!formData.name && fd.append('name', formData.name)
    !!formData.subdomain && fd.append('subdomain', formData.subdomain)
    !!formData.website && fd.append('website', formData.website)
    !!formData.logo && fd.append('logo', formData.logo)
    !!formData.login_image && fd.append('login_image', formData.login_image)
    !!formData.chargify_subdomain && fd.append('chargify_subdomain', formData.chargify_subdomain)
    !!formData.description && fd.append('description', formData.description)
    fd.append('paid_by_organization', String(formData.paid_by_organization))
    fd.append('marketplace_offers_only', String(formData.marketplace_offers_only))
    !!formData.main_subscription_id && fd.append('main_subscription_id', formData.main_subscription_id)

    event.preventDefault()
    onSubmit(fd)
  }

  const [heroImageErrors, setHeroImageErrors] = useState<string[]>([])
  const [logoErrors, setLogoErrors] = useState<string[]>([])

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className='columns is-justify-content-center'>
        <div className='column is-half'>
          <div className='mb-4'>
            <h4 className='is-size-4 has-text-centered'>{title}</h4>
          </div>
          <div className='field'>
            <label>Name*</label>
            <div className='control'>
              <input
                autoComplete='none'
                className='input'
                required
                value={formData.name}
                onChange={(event) => onChange({ ...formData, name: event.target.value })}
              />
            </div>
          </div>
          <div className='field'>
            <label>Subdomain*</label>
            <div className='control'>
              <input
                type='text'
                className='input'
                required
                value={formData.subdomain}
                onChange={(event) => onChange({ ...formData, subdomain: String(event.target.value).toLowerCase() })}
              />
            </div>
            {errors.subdomain &&
              errors.subdomain.map((message) => (
                <p className='help is-danger' key={message}>
                  {message}
                </p>
              ))}
          </div>
          <div className='field'>
            <label>Description</label>
            <div className='control'>
              <textarea
                className='textarea'
                value={formData.description}
                onChange={(event) => onChange({ ...formData, description: event.target.value })}
              />
            </div>
          </div>
          {!isSub && (
            <>
              <div className='field'>
                <label>Website*</label>
                <div className='control'>
                  <input
                    type='text'
                    className='input'
                    required
                    value={formData.website}
                    onChange={(event) => onChange({ ...formData, website: event.target.value })}
                  />
                </div>
                {errors.website &&
                  errors.website.map((message) => (
                    <p className='help is-danger' key={message}>
                      {message}
                    </p>
                  ))}
              </div>
              <div className='field'>
                <div className='control'>
                  <label htmlFor='active' className='checkbox'>
                    <input
                      name='active'
                      type='checkbox'
                      checked={formData.paid_by_organization}
                      onChange={(event) => onChange({ ...formData, paid_by_organization: event.target.checked })}
                    />{' '}
                    Paid by organization
                  </label>
                </div>
                {errors?.paid_by_organization &&
                  errors.paid_by_organization?.map((message: string) => (
                    <p className='help is-danger' key={message.length}>
                      {message}
                    </p>
                  ))}
              </div>
              <div className='field'>
                <div className='control'>
                  <label htmlFor='active' className='checkbox'>
                    <input
                      name='active'
                      type='checkbox'
                      checked={formData.marketplace_offers_only}
                      onChange={(event) => onChange({ ...formData, marketplace_offers_only: event.target.checked })}
                    />{' '}
                    Marketplace offers only
                  </label>
                </div>
                {errors?.marketplace_offers_only &&
                  errors.marketplace_offers_only?.map((message: string) => (
                    <p className='help is-danger' key={message.length}>
                      {message}
                    </p>
                  ))}
              </div>
              <div className='field'>
                <label htmlFor='images' className='label'>
                  Hero image
                  <div className='control'>
                    <DropzoneArea
                      showAlerts={false}
                      filesLimit={1}
                      maxFileSize={MEGA_BYTES_10}
                      initialFiles={formData?.login_image_url ? [formData?.login_image_url as string] : undefined}
                      onChange={(fileImages) => {
                        onChange({ ...formData, login_image: fileImages[0] })
                      }}
                      onDropRejected={() => {
                        setHeroImageErrors(['File is rejected. Max size is 10Mb'])
                      }}
                      onDrop={() => {
                        setHeroImageErrors([])
                      }}
                    />
                  </div>
                </label>
                {(heroImageErrors || errors?.login_image)?.map((message: string) => (
                  <p className='help is-danger' key={message.length}>
                    {message}
                  </p>
                ))}
              </div>
              <div className='field'>
                <label htmlFor='images' className='label'>
                  Organization logo
                  <div className='control'>
                    <DropzoneArea
                      showAlerts={false}
                      filesLimit={1}
                      maxFileSize={MEGA_BYTES_10}
                      initialFiles={formData?.logo_url ? [formData?.logo_url as string] : undefined}
                      onChange={(fileImages) => {
                        onChange({ ...formData, logo: fileImages[0] })
                      }}
                      onDropRejected={() => {
                        setHeroImageErrors(['File is rejected. Max size is 10Mb'])
                      }}
                      onDrop={() => {
                        setHeroImageErrors([])
                      }}
                    />
                  </div>
                </label>
                {(logoErrors || errors?.logo)?.map((message: string) => (
                  <p className='help is-danger' key={message.length}>
                    {message}
                  </p>
                ))}
              </div>
              {formData.paid_by_organization && (
                <div className='field'>
                  <label>Main subscription ID</label>
                  <div className='control'>
                    <input
                      type='text'
                      className='input'
                      required
                      value={formData.main_subscription_id}
                      onChange={(event) => onChange({ ...formData, main_subscription_id: event.target.value })}
                    />
                  </div>
                  {errors.website &&
                    errors.website.map((message) => (
                      <p className='help is-danger' key={message}>
                        {message}
                      </p>
                    ))}
                </div>
              )}
            </>
          )}
          <div className='field has-text-centered'>
            <button type='submit' className='button is-primary'>
              Save
            </button>
            {!!errors && !!Object.values(errors).length && (
              <div className='has-text-danger mt-2'>{Phrases.FormError}</div>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default OrganizationForm
