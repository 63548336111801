import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import PageLayout from 'components/layouts/PageLayout'
import { Box, Container, Tabs } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import RouterTab from 'components/shared/RouterTab'
import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'constants/queries'
import { User } from 'types/generatedGql'
import { AccountHome } from 'pages/MyAccount/AccountHome'
import { History } from 'pages/MyAccount/History'
import { SubAccounts } from 'pages/MyAccount/SubAccounts'
import Payments from 'pages/MyAccount/Payments/Payments'
import { ProfilePhoto } from 'pages/MyAccount/ProfilePhoto'
import Favourites from 'pages/MyAccount/Favourites'
import { Profile } from './Profile'
import { useStyles } from './styles'
// import { SecurityAndPrivacy } from 'pages/MyAccount/Security'

const MyAccount: React.FC = () => {
  const classes = useStyles()
  const match = useRouteMatch()
  const location = useLocation()
  const [currentUser, setCurrentUser] = useState<User>()

  const {
    data: userData,
    error: userError,
    loading: userDataLoading,
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  })

  // Set current user on fetch user success
  useEffect(() => {
    if (userData) setCurrentUser(userData.currentUser)
  }, [userData])

  const userFullName = currentUser ? `${currentUser.firstName} ${currentUser.lastName || ''}` : ''
  const fetchingData = userDataLoading
  const hasSuccessfullyFetchData = !fetchingData && userData

  return (
    <PageLayout
      error={userError}
      hero={
        fetchingData ? (
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <Box className={classes.hero}>
            {currentUser && (
              <Box display='flex' justifyContent='center' alignItems='flex' flex={1} my={6}>
                <ProfilePhoto userId={currentUser.id} userPhoto={currentUser.photo} />
              </Box>
            )}
            {hasSuccessfullyFetchData && (
              <Container maxWidth='lg'>
                <Box className={classes.tabsWrapper}>
                  <Tabs
                    variant='scrollable'
                    aria-label='my-account-tabs'
                    scrollButtons='auto'
                    indicatorColor='primary'
                    value={location.pathname}
                  >
                    <RouterTab label='Account home' value={match.path} />
                    <RouterTab label='Profile' value={`${match.url}/profile`} />
                    <RouterTab label='History' value={`${match.url}/history`} />
                    {!currentUser?.parentId && <RouterTab label='Sub-accounts' value={`${match.url}/sub-accounts`} />}
                    <RouterTab label='Favorites' value={`${match.url}/favorites`} />
                    <RouterTab label='Payments' value={`${match.url}/payments`} />
                    {/* }<RouterTab label='Security & Privacy' value={`${match.url}/security-and-privacy`} /> */}
                  </Tabs>
                </Box>
              </Container>
            )}
          </Box>
        )
      }
    >
      <Box className={classes.body}>
        {hasSuccessfullyFetchData && (
          <Switch>
            <Route exact path={match.path}>
              {currentUser && <AccountHome currentUser={currentUser} userFullName={userFullName} />}
            </Route>
            {!currentUser?.parentId && (
              <Route exact path={`${match.url}/sub-accounts`}>
                <SubAccounts />
              </Route>
            )}
            <Route exact path={`${match.url}/history`}>
              <History />
            </Route>
            <Route path={`${match.path}/favorites`}>
              <Favourites />
            </Route>
            <Route path={`${match.path}/profile`}>
              {currentUser && (
                <Profile user={currentUser} onSaveSuccessCallback={(user: User) => setCurrentUser(user)} />
              )}
            </Route>
            <Route path={`${match.url}/payments`}>
              <Payments />
            </Route>
            {/* <Route path={`${match.path}/security-and-privacy`}> */}
            {/*   <SecurityAndPrivacy /> */}
            {/* </Route> */}
          </Switch>
        )}
      </Box>
    </PageLayout>
  )
}

export default MyAccount
