import React from 'react'
import DefaultLayout from 'panel/layouts/DefaultLayout'
import { useHistory } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import NewCityForm from './NewCityForm'

const NewCity: React.FC = (): JSX.Element => {
  const history = useHistory()

  return (
    <DefaultLayout>
      <div className='mb-4'>
        <BackButton />
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <h4 className='is-size-4 mb-4'>New City</h4>
          <NewCityForm afterSaveCallback={() => history.goBack()} />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default NewCity
