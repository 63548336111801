import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      backgroundColor: theme.palette.tertiary.main,
      transition: 'box-shadow 0.5s',
      boxShadow: 'none',
    },
    transparentBar: {
      position: 'fixed',
      [theme.breakpoints.down('sm')]: {
        position: 'sticky',
      },
    },
    appBarTransparentInitial: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    appBarTransparentTriggered: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.tertiary.main,
      },
    },
    toolbar: {
      justifyContent: 'space-between',
      padding: 0,
    },
    icon: {
      color: '#fff',
    },
    hamburgerMenu: {
      marginLeft: theme.spacing(1),
    },
    belowToolbar: {
      position: 'absolute',
      top: '80px',
      width: '100%',
      backgroundColor: theme.palette.tertiary.main,
      borderTop: '1px solid rgb(255, 255, 255, 0.1)',
    },
    searchInputDesktop: {
      maxWidth: '160px',
    },
    dividerVertical: {
      margin: theme.spacing('auto', 2),
      opacity: 0.2,
      height: '40px',
    },
    dividerHorizontal: {
      margin: theme.spacing(2),
      opacity: 0.2,
    },
    profileImage: {
      minHeight: 40,
      minWidth: 40,
    },
    menuItem: {
      height: '100%',
    },
  }
})
