import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { RouterPath } from '../../router/routes'
import useGetQueryParam from '../../hooks/useGetQueryParam'

const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const preEnteredEmail = useGetQueryParam('email')
  const [email, setEmail] = useState(preEnteredEmail || '')
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)
    setError('')
    const formData = new FormData()
    formData.append('email', email)

    try {
      await axios.post(`/api/forgot_admin_password`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      history.push(RouterPath.LOG_IN)
    } catch (err) {
      console.log(err)
      setError(err?.response?.data?.error)
      setIsSubmitting(false)
    }
  }

  return (
    <DefaultLayout hideNavbar>
      <div className='container'>
        <div className='columns is-justify-content-center'>
          <div className='column is-half'>
            <div className='box'>
              <h4 className='is-size-4 has-text-centered'>Forgot password</h4>
              <form onSubmit={handleSubmit}>
                <div className='field'>
                  <label className='label'>Email</label>
                  <div className='control'>
                    <input
                      type='email'
                      className='input'
                      name='email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>

                <div className='field'>
                  <button
                    type='submit'
                    className={`button is-primary is-fullwidth ${isSubmitting ? 'is-loading' : ''}`}
                    disabled={isSubmitting}
                  >
                    Send Reset Password Instructions
                  </button>
                </div>
                {error && <p className='has-text-danger'>{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ForgotPassword
