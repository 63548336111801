import React, { FC, useState, useEffect } from 'react'
import { AdminRoles, UserRole } from '../../const'
import { useStyles } from './styles'
import { validatePassword } from '../../../utils/validation'

interface Props {
  password?: string
  onPasswordChange: (password: string) => void
  passwordErrors?: Array<string>
  isEdit?: boolean
  currentRole?: UserRole
  setSubmitDisabled: (disabled: boolean) => void
}

const AdminPasswordField: FC<Props> = ({
  password,
  onPasswordChange,
  passwordErrors,
  isEdit = false,
  currentRole,
  setSubmitDisabled,
}) => {
  const [repeatedPassword, setRepeatedPassword] = useState('')
  const [passwordFormError, setPasswordFormError] = useState('')
  const classes = useStyles()

  useEffect(() => {
    setSubmitDisabled(false)
    setPasswordFormError('')
    if (!password) return
    const passwordValidationDetails = validatePassword(password)
    if (passwordValidationDetails?.length) {
      setPasswordFormError(passwordValidationDetails.map(({ message }) => message).join('. '))
      setSubmitDisabled(true)
    } else if (password?.length && password !== repeatedPassword) {
      setSubmitDisabled(true)
      setPasswordFormError('Password and Confirm Password must match.')
    }
  }, [setSubmitDisabled, password, repeatedPassword])

  return (
    <>
      <div className='field'>
        <label className='label'>
          {isEdit && AdminRoles.includes(currentRole as UserRole) ? 'New password' : 'Password*'}
        </label>
        <div className='control'>
          <input
            type='text'
            className={`input ${classes.passwordInput}`}
            autoComplete='none'
            required={!(isEdit && AdminRoles.includes(currentRole as UserRole))}
            value={password}
            onChange={(event) => onPasswordChange(event.target.value)}
          />
        </div>
        {passwordErrors?.map((message) => (
          <p className='help is-danger' key={message}>
            {message}
          </p>
        ))}
        {passwordFormError && <p className='help is-danger'>{passwordFormError}</p>}
      </div>

      {!!password?.length && (
        <div className='field'>
          <label className='label'>Repeat password</label>
          <div className='control'>
            <input
              type='text'
              className={`input ${classes.passwordInput}`}
              autoComplete='none'
              required={!(isEdit && AdminRoles.includes(currentRole as UserRole))}
              value={repeatedPassword}
              onChange={(event) => setRepeatedPassword(event.target.value)}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AdminPasswordField
