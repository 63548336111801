import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './styles'

interface Props {
  count: number
  current: number
}
const Dots: React.FC<Props> = ({ count, current }) => {
  const classes = useStyles()
  return (
    <Grid container justify='center'>
      <Box className={classes.dots}>
        {[...Array(count)].map((dotItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={`${classes.dot} ${current === index + 1 ? classes.currentDot : ''}`} key={index} />
        ))}
      </Box>
    </Grid>
  )
}

export default Dots
