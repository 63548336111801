import React, { useState, KeyboardEvent } from 'react'
import { BenefitFilter } from '../Benefits/Filters'

export interface CityFilter {
  name: string
  region: string
  featured: string
}

interface Props {
  applyFilters: (filters: CityFilter) => void
}

const CitiesFilters: React.FC<Props> = ({ applyFilters }) => {
  const [filters, setFilters] = useState<CityFilter>({
    name: '',
    region: '',
    featured: '',
  })

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters(filters)
    }
  }

  const onSelectFilter = (value: string, fieldName: keyof BenefitFilter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [fieldName]: value }
      applyFilters(newFilters)
      return newFilters
    })
  }

  const onSetFilter = (value: string, fieldName: keyof CityFilter) => {
    setFilters((prevFilters) => ({ ...prevFilters, [fieldName]: value }))
  }

  return (
    <>
      <th> </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Name'
          value={filters.name}
          onChange={(e) => onSetFilter(e.target.value, 'name')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Region'
          value={filters.region}
          onChange={(e) => onSetFilter(e.target.value, 'region')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <div className='select'>
          <select name='category' value={filters.featured} onChange={(e) => onSelectFilter(e.target.value, 'featured')}>
            <option value=''>All</option>
            <option value='true'>Featured</option>
            <option value='false'>Not featured</option>
          </select>
        </div>
      </th>
      <th className='is-flex is-justify-content-flex-end'>
        <button type='button' className='button' onClick={() => applyFilters(filters)}>
          <span className='icon is-small'>
            <i className='fas fa-search' />
          </span>
        </button>
      </th>
    </>
  )
}

export { CitiesFilters }
