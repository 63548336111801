import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  const cardWidth = '327px'
  const cardHeight = '514px'
  const cardBallWidth = '327px'
  const avatarSize = '92px'
  return {
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardContainer: {
      color: '#fff',
      backgroundColor: 'black',
      position: 'absolute',
      maxWidth: cardWidth,
      width: cardWidth,
      height: cardHeight,
      maxHeight: cardHeight,
      borderRadius: '15px',
      overflow: 'unset',
    },
    closeButton: {
      position: 'absolute',
      cursor: 'pointer',
      top: '10px',
      right: '10px',
      color: '#fff',
      zIndex: '100',
    },
    avatar: {
      position: 'absolute',
      width: avatarSize,
      height: avatarSize,
      left: `calc(${cardWidth}/2 - ${avatarSize} / 2)`,
      top: 0,
      marginTop: -42,
    },
    cardMediaBall: {
      position: 'absolute',
      bottom: 0,
      left: `calc(${cardWidth}/2 - ${cardBallWidth} / 2)`,
      width: cardBallWidth,
      height: 'auto',
      margin: 'auto',
      zIndex: 1,
    },
    website: {
      opacity: 0.5,
      marginBottom: theme.spacing(2),
    },
    logo: {
      height: 34,
      width: 34,
      marginRight: theme.spacing(1),
      color: '#fff',
    },
    execLogo: {
      width: `calc(${cardWidth} - 8px)`,
      maxWidth: '100%',
      marginBottom: 90,
    },
    membershipDate: {
      opacity: 0.5,
      marginTop: 6,
    },
  }
})
