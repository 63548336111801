import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    avatarUploadWrapper: {
      height: '160px',
      width: '160px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      position: 'relative',
      overflow: 'visible',
    },
    avatarUploadText: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      userSelect: 'none',
      cursor: 'pointer',
    },
    errorText: {
      whiteSpace: 'nowrap',
    },
    avatarProgress: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
    },
    avatarPreview: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      borderRadius: '90%',
    },
    avatar: {
      marginRight: theme.spacing(2),
      height: '100px',
      width: '100px',
      [theme.breakpoints.down('sm')]: {
        height: '80px',
        width: '80px',
      },
    },
    cropModalContainer: {
      position: 'relative',
      margin: `${theme.spacing(2)} auto`,
      height: '350px',
    },
  }
})
