import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import useCredentials from '../../hooks/useCredentials'
import Modal from '../../components/Modal/Modal'
import ErrorMessage from '../../components/ErrorMessage'

interface Props {
  loadMembers: () => void
}

interface Result {
  failed_records: string[]
  successfully_imported: number
}

const UploadMembers: React.FC<Props> = ({ loadMembers }): JSX.Element => {
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [resultData, setResultData] = useState<Result>()
  const [resultModalOpened, setResultModalOpened] = useState<boolean>(false)

  const uploadUsersCsv = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.[0]) {
      try {
        setLoading(true)
        const data = new FormData()
        data.append('users_csv', event.target?.files?.[0])
        const result = await axios.post('/api/panel/members/batch_upload', data, {
          headers: credentials,
        })
        setResultData(result.data)
        setResultModalOpened(true)
        await loadMembers()
        setLoading(false)
      } catch (e) {
        setError(e?.message)
        setResultModalOpened(true)
      } finally {
        setLoading(false)
      }
    }
  }

  const onUploadClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // possibility to load same file again
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <div className='file is-info is-medium mr-2'>
      <label className='file-label' htmlFor='fileUpload'>
        <input
          className='file-input'
          type='file'
          name='fileUpload'
          id='fileUpload'
          accept='text/csv'
          onChange={uploadUsersCsv}
          onClick={onUploadClick}
        />
        <span className='file-cta' style={{ maxHeight: '40px', fontSize: '1rem' }}>
          <span className='file-icon'>
            {loading ? <i className='fas fa-spinner fa-spin' /> : <i className='fas fa-upload' />}
          </span>
          <span className='file-label'>Upload CSV</span>
        </span>
      </label>
      <Modal opened={resultModalOpened} onClose={() => setResultModalOpened(false)} title='Upload results'>
        {error ? (
          <ErrorMessage text={error} />
        ) : (
          <>
            <div className='field mt-2'>
              <p className='label'>Successfully imported:</p>
              <div className='control'>
                <span>{resultData?.successfully_imported}</span>
              </div>
            </div>
            <div className='field'>
              <p className='label'>Failed records:</p>
              <div className='control'>
                {resultData?.failed_records?.length
                  ? resultData?.failed_records.map((record) => <div>{record}</div>)
                  : 0}
              </div>
            </div>
          </>
        )}
        <div className='is-flex is-justify-content-center mt-4'>
          <button type='button' className='button is-primary' onClick={() => setResultModalOpened(false)}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default UploadMembers
