import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMediaQuery, useTheme } from '@material-ui/core'
import Section from 'components/shared/Section'
import BenefitPreviews from 'components/shared/BenefitPreviews'
import AllBenefitsButton from 'components/shared/buttons/AllBenefitsButton'
import Hero from 'components/shared/Hero'
import PageLayout from 'components/layouts/PageLayout'
import WelcomeBox from 'components/shared/WelcomeBox'
import MoreDetailsButton from 'components/shared/buttons/MoreDetailsButton'
import ViewCategoryButton from 'components/shared/buttons/ViewCategoryButton'
import {
  GET_HOMEPAGE_HERO_BENEFITS,
  GET_CATEGORIES,
  GET_POPULAR_BENEFITS,
  GET_BENEFITS_FOR_YOU,
} from 'constants/queries'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Benefit, Category } from 'types/generatedGql'
import { configBenefitsForPreview } from 'utils'
import useGetWindowSize from 'hooks/useGetWindowSize'

interface HomeHeroBenefit {
  name: string
  image: string
  subTitle: string
  buttonComponent: JSX.Element
}

const Home: React.FC = () => {
  const [heroBenefits, setHeroBenefits] = useState<HomeHeroBenefit[]>([])
  const { isTablet } = useGetWindowSize()

  const [getHeroBenefits, { loading: heroBenefitsLoading, data: heroBenefitsData }] =
    useLazyQuery(GET_HOMEPAGE_HERO_BENEFITS)

  useEffect(() => {
    getHeroBenefits()
  }, [getHeroBenefits])

  useEffect(() => {
    if (heroBenefitsData) {
      const data = heroBenefitsData.getHomepageHeroBenefits.collection.map((benefit: Benefit) => {
        return {
          title: benefit.name,
          image: isTablet ? benefit.images[0]?.medium : benefit.images[0]?.large,
          subtitle: benefit.benefitSummary,
          buttonComponent: <MoreDetailsButton to={`/client/benefits/${benefit.slug}/details`} />,
        }
      })

      setHeroBenefits(data)
    }
  }, [heroBenefitsData, isTablet])

  const { data: categories, error: categoriesError } = useQuery(GET_CATEGORIES)

  const { data: popularBenefits, error: popularBenefitsError } = useQuery(GET_POPULAR_BENEFITS)

  const { data: benefitsForYou, error: benefitsForYouError } = useQuery(GET_BENEFITS_FOR_YOU)

  const configCategories = (data: { categories: Category[] }) => {
    if (!data?.categories) return []

    return data?.categories?.map((category: Category) => ({
      title: category.name,
      image: category.image?.carousel,
      buttonComponent: (
        <ViewCategoryButton category={category.name} to={`/client/benefits/category/${category.slug}`} />
      ),
    }))
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <PageLayout
      error={categoriesError || popularBenefitsError || benefitsForYouError}
      hero={
        heroBenefitsLoading ? (
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <Hero
            bottomComponent={WelcomeBox}
            height={isMobile ? '40vh' : '60vh'}
            overlayHero
            carousel={{ items: heroBenefits }}
          />
        )
      }
      transparentNavigation
    >
      <Section title='For You' overflowContentSm>
        <BenefitPreviews
          items={configBenefitsForPreview(benefitsForYou?.getBenefitsForYou?.collection, isTablet)}
          variant='long'
          carouselWideItem
          useOverlay
        />
      </Section>
      <Section title='Popular' overflowContentSm>
        <BenefitPreviews
          items={configBenefitsForPreview(popularBenefits?.getPopularBenefits?.collection, isTablet)}
          variant='standard'
          useOverlay
        />
      </Section>
      <Section title='Categories' fullWidthSm titleSibling={<AllBenefitsButton />}>
        <Hero
          mb={0}
          height='400px'
          maxHeight='400px'
          carousel={{
            items: configCategories(categories),
          }}
        />
      </Section>
    </PageLayout>
  )
}

export default Home
