import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    card: {
      height: '100%',
    },
    cardMedia: {
      position: 'relative',
      objectFit: 'cover',
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      minHeight: '100%',
    },
    cardOverlay: {
      // TODO: overlay "standard" and "dark"
      // background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
      // background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
      opacity: 0.9,
    },
    cardOverlayHero: {
      opacity: 0.99999999,
    },
    cardMediaOverlay: {
      position: 'relative',
      zIndex: -1,
    },
    title: {
      textTransform: 'capitalize',
    },
    content: {
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    innerContent: {
      color: 'white',
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    innerContentContainer: {
      width: '70%',
    },
    typography: {
      marginBottom: theme.spacing(2),
    },
  }
})
