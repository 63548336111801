import { NavigationLinkProps } from 'components/shared/NavigationLink/NavigationLink'
import { RoutePath } from './RoutePath'

export const footerItems: NavigationLinkProps[] = [
  { to: RoutePath.About, text: 'About' },
  { to: RoutePath.Contact, text: 'Contact' },
  { to: RoutePath.Terms, text: 'Terms' },
  { to: RoutePath.Privacy, text: 'Privacy' },
]

export const navigationBarItems: NavigationLinkProps[] = [
  { to: RoutePath.HotelsDiscovery, text: 'Hotels' },
  { to: RoutePath.BenefitsCategoryLifestyle, text: 'Lifestyle' },
  { to: RoutePath.BenefitsCategoryTravel, text: 'Travel' },
  { to: RoutePath.BenefitsCategoryExperiences, text: 'Experiences' },
  { to: RoutePath.BenefitsCategoryBusiness, text: 'Business' },
  { to: RoutePath.BenefitsList, text: 'All Benefits' },
]

export const navigationBarItemsMobile: NavigationLinkProps[] = [
  { to: RoutePath.HotelsDiscovery, text: 'Hotels' },
  { to: RoutePath.BenefitsCategoryLifestyle, text: 'Lifestyle' },
  { to: RoutePath.BenefitsCategoryTravel, text: 'Travel' },
  { to: RoutePath.BenefitsCategoryExperiences, text: 'Experiences' },
  { to: RoutePath.BenefitsCategoryBusiness, text: 'Business' },
  { to: RoutePath.BenefitsList, text: 'All Benefits' },
  { to: RoutePath.Login, text: 'Log out' },
]

export const externalNavigationBarItems: NavigationLinkProps[] = [
  { to: RoutePath.About, text: 'About' },
  { to: RoutePath.Contact, text: 'Contact' },
  { to: RoutePath.Terms, text: 'Terms' },
  { to: RoutePath.Privacy, text: 'Privacy' },
]
