import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { applyParamsToUrlPath, downloadFile } from '../utils'
import TableWrapper from '../layouts/TableWrapper'
import useCredentials from '../hooks/useCredentials'
import { usePagination } from '../hooks/usePagination'
import { TransactionDTO } from '../dtos/TransactionDTO'

const TransactionsHistoryTable: React.FC = (): JSX.Element => {
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [transactions, setTransactions] = useState<TransactionDTO[]>([])
  const { currentPageNumber, setPageNumber, filters } = usePagination({
    filters: {
      per_page: 5,
    },
  })
  const loadTransactions = useCallback(async (): Promise<void> => {
    try {
      setError('')

      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber, ...filters }, `/api/panel/members/${params.id}/transactions`),
        {
          headers: credentials,
        }
      )
      setTransactions(res.data.transactions)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters])

  useEffect(() => {
    loadTransactions()
  }, [loadTransactions, currentPageNumber, filters])

  const downloadTransaction = async (url: string) => {
    try {
      const res = await axios.get(url, {
        headers: credentials,
      })
      downloadFile(res.data, 'organizations')
    } catch (e) {
      setError(e?.message)
      console.error(e.message)
    }
  }

  return (
    <TableWrapper
      title='Transaction History'
      loading={loading}
      isError={!!error}
      errorMessage={error}
      isEmpty={!transactions?.length}
      pagination={{
        totalPageCount: 9999,
        currentPage: currentPageNumber,
        onChangePage: setPageNumber,
        navigateWithoutRange: true,
      }}
    >
      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Date and Time</th>
            <th>Amount Charged</th>
            <th>Subscription</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((item) => (
            <tr key={item.uid}>
              <td>
                <div className='py-3'>{item.uid}</div>
              </td>
              <td>{moment(item.created_at).format('MMMM D, YYYY (hh:mm)')}</td>
              <td>${item.total_amount}</td>
              <td>{item.subscription_type}</td>
              <td>
                <a
                  className='button is-info is-inverted p-0'
                  type='button'
                  href={item.invoice_url}
                  target='_blank'
                  rel='noreferrer'
                >
                  download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}

export default TransactionsHistoryTable
