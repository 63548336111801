import React from 'react'
import { Box, BoxProps, Typography } from '@material-ui/core'
import { useStyles } from './styles'

interface WelcomeBoxItemProps extends BoxProps {
  title: string
  text: string | number
}
const WelcomeBoxItem: React.FC<WelcomeBoxItemProps> = ({ title, text, ...props }) => {
  const classes = useStyles()

  return (
    <Box {...props}>
      <Typography variant='body2' className={classes.title}>
        {title.toUpperCase()}
      </Typography>
      <Typography variant='body1' className={classes.text}>
        {text}
      </Typography>
    </Box>
  )
}

export default WelcomeBoxItem
