import React, { useEffect, useRef, FC } from 'react'

interface Props {
  className?: string
  callback: () => void
}

const ClickOutside: FC<Props> = ({ className, children, callback }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [callback])

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}

export default ClickOutside
