import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import UserForm from '../../components/UserForm/UserForm'
import { UserDTO } from '../../dtos/UserDTO'
import { UserRole } from '../../const'
import { memberInitialState } from '../const'
import ErrorMessage from '../../components/ErrorMessage'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { deleteMember } from '../../common/requests'
import { RouterPath } from '../../router/routes'

const EditMember: FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<UserDTO>>(memberInitialState)
  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadMemberData = async () => {
    try {
      const res = await axios.get(`/api/panel/members/${params.id}`, {
        headers: credentials,
      })
      setFormData(res.data.member)
    } catch (e) {
      setLoadDataError(e.message)
    }
  }

  useEffect(() => {
    loadMemberData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      setSubmitting(true)
      await axios.put(`/api/panel/members/${params.id}`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const deleteUser = async () => {
    if (confirm('Do you want to delete?')) {
      try {
        await deleteMember(params.id, credentials)
        history.push(RouterPath.MEMBERS_VIEW)
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        <button type='button' className='button is-danger' onClick={deleteUser}>
          Delete
        </button>
      </div>
      <h4 className='is-size-4 mb-4'>Edit Member</h4>
      <div className='columns'>
        <div className='column is-half'>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            isEdit
            roles={[UserRole.Member]}
            onChange={handleChange}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditMember
