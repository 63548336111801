import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    card: {
      position: 'relative',
      height: '100%',
    },
    cardMedia: {
      height: '100%',
    },
    cardOverlay: {
      position: 'absolute',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 47.58%, rgba(0, 0, 0, 0.85) 100%)',
      display: 'block',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    cardMediaOverlay: {
      position: 'relative',
      zIndex: -1,
    },
    categoryLabel: {
      textTransform: 'uppercase',
    },
  }
})
