import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dots from 'components/shared/Dots'
import { useMutation, useQuery } from '@apollo/client'
import { MembershipPlan, Mutation } from 'types/generatedGql'
import { useStyles } from 'pages/PreApplication/ApplicationPlan/styles'
import classnames from 'classnames'
import EnterpriseModal from 'pages/PreApplication/ApplicationPlan/EnterpriseModal'
import ButtonAction from 'components/shared/ButtonAction'
import { GET_MEMBERSHIP_PLANS } from 'constants/queries'
import { SELECT_MEMBERSHIP_PLAN } from 'constants/mutations'

interface Props {
  onSuccess: (step?: number) => void
}

const ApplicationPlan: React.FC<Props> = ({ onSuccess }) => {
  const classes = useStyles()
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [selectedPackageId, setSelectedPackageId] = useState<number>()

  const handleCompleted = (mutationData: Mutation) => {
    if (mutationData?.selectMembershipPlan?.message === 'success') {
      onSuccess()
    }
  }

  const [selectMembershipPlan, { loading: submitting, error }] = useMutation(SELECT_MEMBERSHIP_PLAN, {
    onCompleted: handleCompleted,
  })

  const { data: packagesData, loading, error: packageError } = useQuery(GET_MEMBERSHIP_PLANS)

  const packages = packagesData?.getMembershipPlans

  const onSubmit = async (id: number) => {
    setSelectedPackageId(id)
    await selectMembershipPlan({ variables: { id } })
  }

  return (
    <>
      {loading && (
        <Grid container alignItems='center' justify='center'>
          <CircularProgress />
        </Grid>
      )}
      {packageError && <Typography color='error'>{packageError?.message}</Typography>}
      {packagesData && (
        <>
          <Grid container spacing={1} alignItems='center' justify='center'>
            {packages?.map(({ id, maxSubuserNumber, numberOfSeats, price, oneTimeInitiationFee }: MembershipPlan) => (
              <Grid item key={id}>
                <Box className={classes.packageContainer} onClick={() => {}}>
                  <div className={classnames(classes.packageRow, classes.subUsers)}>
                    <h2>{numberOfSeats} seats</h2>
                  </div>
                  <div className={classes.packageRow}>
                    <div>
                      <h2>${price}/yr</h2>
                    </div>
                    <div>+</div>
                    <div>one-time ${oneTimeInitiationFee} initiation-fee</div>
                    <ButtonAction
                      className={classes.button}
                      type='button'
                      text='Select plan'
                      size='large'
                      loading={selectedPackageId === id && submitting}
                      onClick={() => onSubmit(id)}
                    />
                  </div>
                  <div className={classnames(classes.packageRow, classes.usersOptions)}>
                    <div className={classes.usersOption}>1 primary user</div>
                    <div className={classes.usersOption}>
                      <span className={classes.subUsers}>{maxSubuserNumber}</span> Sub-users
                      <br />
                      with complimentary
                      <br />
                      access to EXEC benefits
                    </div>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>

          {error && (
            <Box my={2}>
              <Typography color='error'>{error?.message}</Typography>
            </Box>
          )}

          <Box mt={4} mb={2}>
            <Grid container spacing={0} alignItems='center' justify='center'>
              <Typography
                style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                component='a'
                color='primary'
                onClick={() => setModalIsOpened(true)}
              >
                <strong>Looking for something else? Inquire about a custom plan</strong>
              </Typography>
            </Grid>
          </Box>

          <Box mb={4}>
            <Dots count={4} current={3} />
          </Box>

          <EnterpriseModal
            isOpened={modalIsOpened}
            closeModal={() => setModalIsOpened(false)}
            onSuccess={() => onSuccess(5)}
          />
        </>
      )}
    </>
  )
}

export default ApplicationPlan
