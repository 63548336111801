import { createSlice } from '@reduxjs/toolkit'

interface Credentials {
  'access-token': string
  client: string
  uid: string
  'token-type': string
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    credentials: null,
  },
  reducers: {
    setAuth(state, { payload }) {
      state.credentials = payload
    },
  },
})

export const { setAuth } = authSlice.actions

export default authSlice.reducer
