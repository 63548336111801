import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { RootState } from 'reducer/store'
import { setAuth } from 'reducer/auth/authSlice'
import { RoutePath } from 'constants/RoutePath'

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const credentials = useSelector((state: RootState) => state.auth.credentials)
  const { search } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = new URLSearchParams(search)
  const accessToken = params.get('accessToken')

  if (!credentials && accessToken) {
    const newCredentials = {
      accessToken,
      client: params.get('client'),
      tokenType: params.get('tokenType'),
      uid: params.get('uid'),
    }
    dispatch(setAuth(newCredentials))
    history.replace({ search: '' })
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        credentials || accessToken
          ? children
          : history.location.pathname !== RoutePath.CSVUpload && (
              <Redirect
                to={{
                  pathname: '/client/login',
                  state: { from: location },
                }}
              />
            )
      }
    />
  )
}

export default PrivateRoute
