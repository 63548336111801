import { useSelector } from 'react-redux'
import { RootState } from '../reducer/store'
import { CredentialsDTO } from '../dtos/CredentialsDTO'
import { UserRole } from '../const'

interface Props {
  credentials: CredentialsDTO | null
  hasEditPermission: boolean
}

const useCredentials = (): Props => {
  const credentials = useSelector((state: RootState) => state.panelAuth?.credentials)
  const hasEditPermission = credentials?.role === UserRole.Admin

  return {
    credentials,
    hasEditPermission,
  }
}

export default useCredentials
