import React, { FC } from 'react'

const ActionsCell: FC = ({ children }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
    <div className='is-flex is-justify-content-flex-end' role='button' onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  )
}

export { ActionsCell }
