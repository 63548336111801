import React from 'react'
import { Typography, Box, Link, Container } from '@material-ui/core'

const ApplicationNote: React.FC = () => {
  return (
    <Container maxWidth='md'>
      <Box mt={4}>
        <Typography variant='body2'>
          <Typography component='span' color='primary'>
            *
          </Typography>
          This form is for new member applications. If you have already been provided access to EXEC, you do not need to
          complete this form. If you are trying to access your existing EXEC account, please contact{' '}
          <Link color='primary' href='mailto:concierge@exec.vip'>
            concierge@exec.vip
          </Link>{' '}
          or follow the activation instructions provided to you.
        </Typography>
      </Box>
    </Container>
  )
}

export default ApplicationNote
