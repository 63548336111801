import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useStyles } from 'components/shared/FormWrapper/styles'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import Dots from 'components/shared/Dots'
import { useMutation, useQuery } from '@apollo/client'
import { Mutation } from 'types/generatedGql'
import { UPDATE_USER_COMPANY_DATA } from 'constants/mutations'
import { GET_COMPANY_RANGES } from 'constants/queries'
import SelectCustom from 'components/shared/SelectCustom/SelectCustom'
import ButtonAction from '../../../components/shared/ButtonAction'

interface Props {
  onSuccess: () => void
}

const initialData = {
  companyName: '',
  position: '',
  annualRevenue: '',
  fundingAcquiredSinceInception: '',
  numberOfEmployees: '',
  companyWebsite: '',
  linkedinProfileUrl: '',
  additionalInfo: '',
}

const ApplicationCompanyForm: React.FC<Props> = ({ onSuccess }) => {
  const classes = useStyles()
  const { data: rangesData } = useQuery(GET_COMPANY_RANGES)
  const [data, setData] = useState(initialData)

  const handleCreateAccountCompleted = (mutationData: Mutation) => {
    if (mutationData?.updateUserCompanyData?.message === 'success') {
      onSuccess()
    }
  }

  const [updateUserCompanyData, { loading: submitting, error }] = useMutation(UPDATE_USER_COMPANY_DATA, {
    onCompleted: handleCreateAccountCompleted,
  })

  const onChange = (value: unknown, fieldName: keyof typeof initialData) => {
    setData((prevData) => ({ ...prevData, [fieldName]: value }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await updateUserCompanyData({ variables: data })
  }

  return (
    <form autoComplete='off' onSubmit={onSubmit}>
      <Box className={classes.formRow}>
        <TextFieldCustom
          label='Company name'
          isTopLabel
          required
          value={data.companyName}
          onChange={(event) => onChange(event.target.value, 'companyName')}
          fullWidth
        />

        <TextFieldCustom
          label='Job title'
          isTopLabel
          required
          value={data.position}
          onChange={(event) => onChange(event.target.value, 'position')}
          fullWidth
        />
      </Box>

      <Box className={classes.formRow}>
        {rangesData?.companyDataRanges?.annualRevenue && (
          <SelectCustom
            items={rangesData?.companyDataRanges?.annualRevenue}
            label='Annual revenue'
            isTopLabel
            value={data.annualRevenue}
            onChange={(event) => onChange(event.target.value, 'annualRevenue')}
            fullWidth
            required
          />
        )}

        {rangesData?.companyDataRanges?.fundingAcquiredSinceInception && (
          <SelectCustom
            items={rangesData?.companyDataRanges?.fundingAcquiredSinceInception}
            label='Funding acquired since inception (optional)'
            isTopLabel
            value={data.fundingAcquiredSinceInception}
            onChange={(event) => onChange(event.target.value, 'fundingAcquiredSinceInception')}
            fullWidth
          />
        )}
      </Box>

      <Box className={classes.formRow}>
        {rangesData?.companyDataRanges?.numberOfEmployees && (
          <SelectCustom
            items={rangesData?.companyDataRanges?.numberOfEmployees}
            label='Number of employees'
            isTopLabel
            value={data.numberOfEmployees}
            type='number'
            onChange={(event) => onChange(event.target.value, 'numberOfEmployees')}
            fullWidth
            required
          />
        )}

        <TextFieldCustom
          label='Company website (optional)'
          isTopLabel
          value={data.companyWebsite}
          onChange={(event) => onChange(event.target.value, 'companyWebsite')}
          fullWidth
        />
      </Box>

      <Box className={classes.formRow}>
        <TextFieldCustom
          label='LinkedIn profile URL (optional)'
          isTopLabel
          value={data.linkedinProfileUrl}
          onChange={(event) => onChange(event.target.value, 'linkedinProfileUrl')}
          fullWidth
        />
      </Box>

      <Box className={classes.formRow}>
        <TextFieldCustom
          label='Please share what interests you most about EXEC Membership and whom you wish to provide Membership access to from your organization (optional):'
          isTopLabel
          value={data.additionalInfo}
          onChange={(event) => onChange(event.target.value, 'additionalInfo')}
          fullWidth
          multiline
          rows={5}
        />
      </Box>

      <Box mb={2} mt={4}>
        <Dots count={4} current={2} />
      </Box>

      {error && (
        <Box my={2}>
          <Typography color='error'>{error?.message}</Typography>
        </Box>
      )}

      <Grid container spacing={0} alignItems='center' justify='center'>
        <ButtonAction text='Next' type='submit' variant='contained' color='primary' size='large' loading={submitting} />
      </Grid>
    </form>
  )
}

export default ApplicationCompanyForm
