import React, { useEffect, useState, FC, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import UserForm from '../../components/UserForm/UserForm'
import { UserDTO } from '../../dtos/UserDTO'
import { UserRole } from '../../const'
import ErrorMessage from '../../components/ErrorMessage'
import { adminInitialState } from '../const'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'

const EditAdmin: FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<UserDTO>>(adminInitialState)
  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadAdminData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/admins/${params.id}`, {
        headers: credentials,
      })
      setFormData(res.data)
    } catch (e) {
      setLoadDataError(e.message)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadAdminData()
  }, [loadAdminData])

  const handleSubmit = async (data: Partial<UserDTO>) => {
    try {
      setSubmitting(true)
      await axios.put(`/api/panel/admins/${params.id}`, data, {
        headers: credentials,
      })
      history.goBack()
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (data: Partial<UserDTO>) => {
    setFormData(data)
  }

  return (
    <DefaultLayout>
      {loadDataError && <ErrorMessage text={loadDataError} />}
      <div className='columns'>
        <div className='column is-half'>
          <div className='mb-4'>
            <BackButton />
          </div>
          <h4 className='is-size-4 mb-4'>Edit Admin</h4>
          <UserForm
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={validationErrors}
            formData={formData}
            isEdit
            roles={[UserRole.Admin, UserRole.Support]}
            onChange={handleChange}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditAdmin
