import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: '#F1EADE',
      padding: theme.spacing(2, '5%'),
      boxSizing: 'border-box',
      color: '#000',
      alignItems: 'stretch',
      height: '200px',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        alignItems: 'start',
        height: 'auto',
      },
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
    },
    welcomeMessage: {
      borderBottom: '1px solid #C97E48',
      alignSelft: 'start',
      paddingBottom: theme.spacing(1),
      fontSize: '1.25rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.6rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
        borderRight: '1px solid #C97E48',
        borderBottom: 'none',
        height: '60%',
        alignSelf: 'center',
      },
    },
    content: {
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        margin: theme.spacing(2, 0),
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 'auto',
        maxWidth: '200px',
      },
    },
  }
})
