import React, { useState, KeyboardEvent } from 'react'
import OrganizationsDropdown from './OrganizationsDropdown'

enum ActivationStatus {
  active = 'Active',
  dunning = 'Dunning',
  canceled = 'Canceled',
  expired = 'Expired',
  inactive = 'Inactive',
}

export interface UserFilter {
  activationStatus: ActivationStatus | ''
  email: string
  firstName: string
  lastName: string
  organization_name: string
}

interface Props {
  applyFilters: (filters: UserFilter) => void
  isAdmin?: boolean
}

const UserFilters: React.FC<Props> = ({ applyFilters, isAdmin }) => {
  const [filters, setFilters] = useState<UserFilter>({
    activationStatus: '',
    email: '',
    firstName: '',
    lastName: '',
    organization_name: '',
  })

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters(filters)
    }
  }

  const onSetFilters = (value: string, fieldName: keyof UserFilter, applyImmediately?: boolean) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [fieldName]: value }
      if (applyImmediately) {
        applyFilters(newFilters)
      }
      return newFilters
    })
  }

  const onSelectFilters = (value: string, fieldName: keyof UserFilter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [fieldName]: value }
      applyFilters(newFilters)
      return newFilters
    })
  }

  return (
    <>
      <th> </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          placeholder='Email'
          value={filters.email}
          onChange={(e) => onSetFilters(e.target.value, 'email')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='First name'
          value={filters.firstName}
          onChange={(e) => onSetFilters(e.target.value, 'firstName')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Last name'
          value={filters.lastName}
          onChange={(e) => onSetFilters(e.target.value, 'lastName')}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <div className='select'>
          <select
            name='category'
            value={filters.activationStatus}
            onChange={(e) => onSelectFilters(e.target.value, 'activationStatus')}
          >
            <option value=''>All</option>
            {Object.keys(ActivationStatus).map((status) => (
              <option key={status} value={status}>
                {ActivationStatus[status]}
              </option>
            ))}
          </select>
        </div>
      </th>
      {!isAdmin && (
        <th>
          <OrganizationsDropdown
            placeholder='All'
            organizationName={filters.organization_name}
            onOrganizationSelect={(name) => onSetFilters(name, 'organization_name', true)}
          />
        </th>
      )}
      <th> </th>
      <th className='is-flex is-justify-content-flex-end'>
        <button type='button' className='button' onClick={() => applyFilters(filters)}>
          <span className='icon is-small'>
            <i className='fas fa-search' />
          </span>
        </button>
      </th>
    </>
  )
}

export { UserFilters }
