import React from 'react'
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import { useStyles } from './styles'

interface TitleWithCaptionProps extends BoxProps {
  name: string
  caption: {
    text: string
    props?: Omit<TypographyProps, 'className'>
  }
  subtitle?: string
  website?: string
  brandName?: string
}

const TitleWithCaption: React.FC<TitleWithCaptionProps> = ({
  name,
  caption,
  subtitle,
  website,
  brandName,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Box mt={2} mb={2} {...props}>
      <Typography
        variant='caption'
        className={`${classes.caption} ${caption.props?.color ? '' : classes.defaultColor}`}
        {...caption.props}
      >
        {caption.text}
      </Typography>
      <Typography variant='h4' component='h2' className={classes.name}>
        {name}
      </Typography>
      <Typography variant='subtitle1' component='div'>
        {brandName}
      </Typography>
      <Typography
        variant='caption'
        className={`${classes.caption} ${caption.props?.color ? '' : classes.defaultColor}`}
        color={caption.props?.color}
        component='div'
      >
        {subtitle}
      </Typography>
      {website && (
        <a href={website} className={classes.websiteLink} target='_blank' rel='noreferrer'>
          Visit website
        </a>
      )}
    </Box>
  )
}

export default TitleWithCaption
