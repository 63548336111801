import React, { ReactNode } from 'react'
import classnames from 'classnames'
import CircularSpinner from '../components/CircularSpinner'
import ErrorMessage from '../components/ErrorMessage'
import { Pagination, PaginationProps } from '../components/Pagination'
import { useStyles } from './styles'

interface Props {
  title?: string
  pagination?: PaginationProps
  isEmpty?: boolean
  loading?: boolean
  isError?: boolean
  errorMessage?: string
  actions?: ReactNode
  flatStyling?: boolean
  boxClassname?: string
}

const TableWrapper: React.FC<Props> = ({
  isError = false,
  errorMessage,
  title,
  loading = false,
  isEmpty = false,
  children,
  pagination,
  actions,
  flatStyling,
  boxClassname = '',
}) => {
  const classes = useStyles()
  return (
    <>
      {!!(title || actions) && (
        <div className={classnames('is-size-4 has-text-centered mb-4', classes.headerBox)}>
          <h4 className={classnames('is-size-4 has-text-centered')}>{title || ' '}</h4>
          {actions && <div className={classes.actions}>{actions}</div>}
        </div>
      )}
      <div className={classnames(flatStyling ? '' : 'box', boxClassname)} style={{ overflowX: 'scroll' }}>
        {children}
        {loading ? (
          <CircularSpinner className='is-center' />
        ) : (
          <>
            {pagination && !isEmpty && <Pagination {...pagination} />}
            {isError && <ErrorMessage text={errorMessage} />}
            {isEmpty && !isError && <h5 className='is-size-5 has-text-centered'>The list is empty</h5>}
          </>
        )}
      </div>
    </>
  )
}

export default TableWrapper
