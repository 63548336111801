import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    formHeaderContainer: {
      position: 'relative',
    },
    formHeader: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.common.black,
      color: '#fff',
    },
    formBody: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6, 4),
    },
    divider: {
      position: 'absolute',
      bottom: '0',
      height: '8px',
      width: '100%',
      background: 'linear-gradient(89.81deg, #C97E48 -5.47%, rgba(28, 28, 28, 0) 104.55%)',
    },
    formBox: {
      backgroundColor: 'rgba(236, 236, 236, 0.62)',
    },
    formRow: {
      display: 'flex',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '&:not(:last-of-type)': {
          marginBottom: 0,
        },
        '& > *:not(:last-child)': {
          marginRight: 0,
        },
        '& > div': {
          marginBottom: theme.spacing(2),
        },
      },
    },
    spinner: {
      color: '#fff',
    },
    errorsList: {
      paddingLeft: '20px',
      '&>li::marker': {
        color: 'red',
      },
      '&>li p': {
        fontSize: '14px',
      },
    },
  }
})
