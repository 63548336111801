import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BenefitShowcase from 'components/shared/BenefitShowcase'
import Section from 'components/shared/Section'
import SortBenefitsSelect from 'components/shared/SortBenefitsSelect'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import PageLayout from 'components/layouts/PageLayout'
import { Box, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLazyQuery } from '@apollo/client'
import { GET_HOTELS_BY_CITY } from 'constants/queries'
import { Benefit, CollectionMetadata, Order } from 'types/generatedGql'
import useSorting from 'hooks/useSorting'
import { useStyles } from './styles'

interface Params {
  city: string
}

type PaginationMetadata = Omit<CollectionMetadata, 'limitValue'>

const HotelsList: React.FC = () => {
  const classes = useStyles()
  const [sortOrder, setSortOrder] = useSorting()
  const [collection, setCollection] = useState<Benefit[]>([])
  const [metadata, setMetadata] = useState<PaginationMetadata>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
  })
  const [isFetching, setIsFetching] = useState(true)
  const { city = 'new-york' } = useParams<Params>()

  const handleFetchMoreCompleted = () => {
    if (data) {
      const { getHotelsByCity } = data
      collection.push(...getHotelsByCity.collection)
      setCollection(collection)
      setMetadata(getHotelsByCity.metadata)
      setIsFetching(false)
    }
  }

  const [getHotels, { data, error }] = useLazyQuery(GET_HOTELS_BY_CITY, {
    onCompleted: handleFetchMoreCompleted,
  })

  const fetchHotels = useCallback(
    (page: number) => {
      setIsFetching(true)
      getHotels({
        variables: {
          page: page || 1,
          slug: city,
          order: sortOrder,
        },
      })
    },
    [city, getHotels, sortOrder]
  )

  useEffect(() => {
    setCollection([])
    fetchHotels(1)
  }, [fetchHotels, sortOrder])

  const fetchMoreHotels = useCallback(async () => {
    if (!error && !isFetching) {
      getHotels({
        variables: {
          slug: city,
          page: metadata.currentPage + 1,
        },
      })
      setIsFetching(true)
    }
  }, [city, error, getHotels, isFetching, metadata.currentPage])

  const onSortSelect = (order: Order) => {
    setSortOrder(order)
  }

  return (
    <PageLayout>
      <Box mt={4} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h2' className={classes.name}>
          {city.replace('-', ' ')}
        </Typography>
        <SortBenefitsSelect onSortChange={onSortSelect} sortBy={sortOrder} />
      </Box>
      <Section className={classes.resultsSection} fullWidthSm>
        <InfiniteScroller
          loadMore={fetchMoreHotels}
          hasMore={collection.length < metadata.totalCount}
          isFetching={isFetching}
        >
          {collection.map((item) => {
            return <BenefitShowcase key={item.id} benefit={item} />
          })}
        </InfiniteScroller>
        {isFetching && (
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
          </div>
        )}
      </Section>
    </PageLayout>
  )
}

export default HotelsList
