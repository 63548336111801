import Section from 'components/shared/Section'
import React, { useEffect } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import parse from 'html-react-parser'

const Privacy: React.FC = () => {
  return (
    <PageLayout>
      <Section title='Privacy Policy'>
        <div>
          {parse(`<p style="margin-bottom: 0cm;line-height: 115%;text-align: left;"><br></p>
            <p style="line-height: 115%;text-align: center;margin-bottom: 0.28cm;">Last Updated: June 15, 2021</p>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">This PRIVACY POLICY describes the policies and procedures on the collection, use and disclosure of Your information when You use the Service. We respect your privacy and We want to ensure you are aware of what data We intend to keep and how We will handle the data You provide. If you do not agree with Our policies and practices, You should not use the Website in any manner. By accessing or using Our Website, you agree to this Privacy Policy. This policy may change from time to time as provided for herein.</p>
            <ol type="I">
                <li>
                    <h2 style='margin-top: 0.53cm;line-height: 115%;text-align: left;font-family: "Arial", serif;font-size:21px;font-weight: normal;margin-bottom: 0.28cm;'><strong>DEFINITIONS</strong></h2>
                </li>
            </ol>
            <p style="margin-bottom: 0.28cm;line-height: 115%;text-align: left;">For the purposes of this Privacy Policy:</p>
            <ol>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Account</u>&rdquo; means a unique account created by or for You to access the Service and certain parts of the Site accessible only to members of the Program.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="2">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Company</u>&rdquo; (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Privacy Policy) refers to EXEC, LLC, a Delaware limited liability company.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="3">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Cookies</u>&rdquo; are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="4">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Device</u>&rdquo; means any device that accesses the Service such as a computer, cellphone or digital tablet.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="5">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Personal Data</u>&rdquo; is any information that relates to an identified or identifiable individual, such as a name, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="6">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Program</u>&rdquo; refers to the curated benefits program operated by Company including but not limited to through use of the Website.</p>
                </li>
            </ol>
            <p style="margin-bottom: 0cm;line-height: 115%;text-align: left;margin-left: 1.27cm;"><br></p>
            <ol start="7">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Service</u>&rdquo; refers to the Program.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="8">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Service Provider</u>&rdquo; means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals engaged by the Company to facilitate the Service or any benefit of the Services, to provide the Service on behalf of the Company or to You, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="9">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Usage Data</u>&rdquo; refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="10">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>Website</u>&rdquo; refers to <u><a href="http://www.joinexec.com/">www.joinexec.com</a></u>, together with all sub-domains or mobile applications or successors thereof.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="11">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">&ldquo;<u>You</u>&rdquo; means the individual accessing or using the Service, or other authorized individuals accessing or using the Service, as applicable.</p>
                </li>
            </ol>
            <ol start="2" type="I">
                <li>
                    <h1 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:27px;font-weight: normal;margin-top: 0.67cm;margin-bottom: 0.39cm;"><strong>PERSONAL DATA</strong></h1>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You or as otherwise set forth herein. Personally identifiable information may include, but is not limited to, email address, first name and last name and phone number.</p>
            <ol start="3" type="I">
                <li>
                    <h1 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:27px;font-weight: normal;margin-top: 0.67cm;margin-bottom: 0.39cm;"><strong>USAGE DATA</strong></h1>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device&apos;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of Our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When You access the Service by or through a mobile device, We may collect certain information related to the mobile device or information that Your browser sends automatically.</p>
            <ol start="4" type="I">
                <li>
                    <h3 style="color: rgb(67, 67, 67);line-height: 115%;text-align: justify;font-family: Arial, serif;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>TRACKING TECHNOLOGIES AND COOKIES</strong></h3>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
            <ol>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;"><u>Cookies or Browser Cookies</u>. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Service.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 2.54cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <p style="line-height: 115%;text-align: justify;margin-left: 2.54cm;margin-bottom: 0.28cm;"><u>Google Analytics</u>. We use Google Analytics cookies to understand how our website is used. A full description of how these cookies are used can be found at Google&rsquo;s website at: <u><a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a></u>. Google offers an opt-out service which you can add to your browser. The link to this add-in is: <u><a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a></u>. We use Google&rsquo;s cookies to achieve the following: improve the Program and to deliver better and more personalized service including by enabling Us to estimate our audience size and usage patterns; store information and preferences allowing Us to customize the Program to your individual interests and to recognize you when you return to the Website.</p>
            <p style="line-height: 115%;text-align: justify;margin-left: 2.54cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <p style="line-height: 115%;text-align: justify;margin-left: 2.54cm;margin-bottom: 0.28cm;"><u>HubSpot</u>. We use cookies from HubSpot to anonymously track your visits to our site so that we may know the unique number of visitors to our site as opposed to the number of returning visitors. <u>Flash Cookies</u>. Certain features of Our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on Our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. MacroMedia, the creators of Flash, have a website on how to manage these cookies. The link to this service is: https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html</p>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="2">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;"><u>Web Beacons</u>. Certain sections of Our Service and Our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</p>
                </li>
            </ol>
            <ol start="5" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>USE OF YOUR PERSONAL DATA</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">The Company may use Personal Data for the following purposes:</p>
            <ol>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To provide and maintain Our Service, including to monitor the usage of Our Service or provide or make available certain offerings.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="2">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To manage Your Account. The Personal Data You provide is required to verify your eligibility for and acceptance to the Program and can give You access to different functionalities of the Service that are available to members of the Program. Your Personal Data is required to become a member of the Program and to offer you, for you to redeem or otherwise have access to certain benefits of the Services.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="3">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="4">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To contact You, by email, telephone calls, SMS, or other equivalent forms of electronic communication, related to the functionalities, products or contracted services.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="5">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To provide You with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or enquired about (unless You have opted not to receive such information).</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="6">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To manage Your requests.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="7">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">To deliver targeted advertising to You: We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="8">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about Our Service users is among the assets transferred.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="9">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of Our promotional campaigns and to evaluate and improve Our Service, products, services, marketing and Your experience.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We may share or disclose Your Personal Data in the following situations:</p>
            <ol>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to advertise on third-party websites to You after You visited our Service, for payment processing and to contact You or to provide or make available certain offerings in connection with the Services. These Service Providers collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ol start="2">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, bankruptcy, liquidation or acquisition of all or a portion of Our business to another company.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="3">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include any parents, subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="4">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="5">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="6">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With law enforcement: under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="7">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">If, in Company&rsquo;s good faith belief that such action is necessary, to comply with legal processes, including, without limitation, to comply with a legal obligation, protect and defend the rights or property of the Company, prevent or investigate possible wrongdoing in connection with the Service, or Protect against legal liability.</p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-left: 1.27cm;margin-bottom: 0cm;"><br></p>
            <ol start="8">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">With Your consent: We may disclose Your personal information for any other purpose with Your consent.</p>
                </li>
            </ol>
            <ol start="6" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>RETENTION OF YOUR PERSONAL DATA</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with Our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce Our legal agreements and policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
            <ol start="7" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>TRANSFER OF YOUR PERSONAL DATA</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">Your information, including Personal Data, is processed at the Company&apos;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to such a transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
            <ol start="8" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>ANALYTICS; ADVERTISING; MARKETING&nbsp;</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We may use third-party Service providers to monitor and analyze the use of Our Service and to show advertisements to You to help support and maintain Our Service. We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.</p>
            <ol start="9" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>PAYMENTS</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors). We may store or collect Your payment card details or provide it directly to Our third-party payment processors whose use of Your Personal Data is governed by their privacy policy.</p>
            <ol start="10" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>MINORS UNDER 18 YEARS OF AGE</strong></h2>
                </li>
            </ol>
            <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;">The Website is not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on the Website or on or through any of its features or attempt to register for membership, use any of the features of the Website or provide any information about yourself, including Your name, address, telephone number, email address, or any screen name or user name you may use.</h2>
            <ol start="11" type="I">
                <li>
                    <h2 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:21px;font-weight: normal;margin-top: 0.53cm;margin-bottom: 0.28cm;"><strong>SALE OF PERSONAL INFORMATION</strong></h2>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us. The Service Providers we partner with may use technology on the Service that sells personal information. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales, you may do so by contacting Us.</p>
            <ol start="12" type="I">
                <li>
                    <h1 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:27px;font-weight: normal;margin-top: 0.67cm;margin-bottom: 0.39cm;"><strong>THIRD-PARTY WEBSITES</strong></h1>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">Our Service may contain links to third-party websites not operated by Us. If You click on a third-party link, You will be directed to that third-party&apos;s website and We strongly advise You to review the Privacy Policy of every website You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
            <ol start="13" type="I">
                <li>
                    <p style="text-align: justify;margin-bottom: 0.42cm;line-height: 100%;"><strong>INDIVIDUAL IN CANADA</strong></p>
                </li>
            </ol>
            <p style="text-align: justify;margin-bottom: 0.42cm;line-height: 100%;">This section applies only to Canadian citizens.</p>
            <p style="text-align: justify;margin-bottom: 0.42cm;line-height: 100%;">We will obtain your consent prior to collecting or using your personal information by express consent or implied consent. You may provide your express consent to us either orally, electronically or in writing. You may also consent to our collection, use and disclosure of personal information by voluntarily providing us with personal information through a clear, affirmative action (e.g. by submitting an information request, participating in a survey, completing a membership application, etc.). We may also rely on your implied consent to the collection, use, and disclosure of your personal information where implied consent is permitted under applicable law. We will use, disclose or retain your personal information for as long as necessary to fulfill the purposes for which that personal information was collected, or as permitted or required by law.</p>
            <p style="text-align: justify;margin-bottom: 0.42cm;line-height: 100%;">Subject to legal requirements, We will respect your right of access to your personal information by informing you upon request of our procedures and requirements for responding to your request for access. We will act diligently to give you access, at minimal or no cost wherever possible. In certain exceptional circumstances, We may not be able to give you access to your personal information. You can dispute the accuracy of your personal information and request corrections if you believe there are errors or omissions. You may also require in certain circumstances that an annotation be attached to your personal information reflecting any correction requested but not made, or that such annotation or correction be notified to certain individuals. To modify your personal information, contact us by email at <u><a href="mailto:concierge@exec.vip">concierge@exec.vip</a></u>. To file a privacy complaint concerning the collection, use, disclosure, retention, and disposal of or access to personal information, please contact us at concierge@exec.vip. We will respond to each of your written requests no later than thirty (30) days after receipt of such request. We will advise you in writing if We cannot meet your requests within this time limit.</p>
            <ol start="14" type="I">
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;"><strong>INDIVIDUALS IN THE EUROPEAN ECONOMIC AREA (the &ldquo;</strong><u><strong>EU</strong></u><strong>&rdquo;)</strong></p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">This section applies only to individuals located in the EU.</p>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">If you are a resident in the EU, you have the following data protection rights with respect to your Personal Data (as such term is defined in the General Data Protection Regulation 2016/679):</p>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;"><br>&nbsp;</p>
            <ul>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">request to be informed of and have access to the Personal Data we process about you;</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">request that we amend or update your Personal Data when it is inaccurate or incomplete;</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">request that we delete your Personal Data;</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">request that we temporarily or permanently stop processing all or some of your Personal Data;</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">object to us processing your Personal Data on grounds relating to your particular situation;</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">object to your Personal Data being processed for direct marketing purposes; and</p>
                </li>
                <li>
                    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">request a copy of your Personal Data in electronic format and to be able to transmit that Personal Data for use in another party&apos;s service.</p>
                </li>
            </ul>
            <h1 style="line-height: 115%;text-align: justify;font-family: Arial, serif;font-size:27px;font-weight: normal;margin-left: 0.64cm;margin-top: 0.67cm;margin-bottom: 0.39cm;">You can exercise these rights by contacting our Privacy Team at concierge@exec.vip ; or EXEC, LLC, 228 Park Ave S, Suite 52536, New York, New York 10003 Attention: Privacy Team.</h1>
            <ol start="15" type="I">
                <li>
                    <p style="margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>CHANGES TO THIS PRIVACY POLICY</strong></p>
                </li>
            </ol>
            <p style="line-height: 115%;text-align: justify;margin-bottom: 0.28cm;">We may update this Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes, which changes shall be effective when they are posted on this page.</p>
            <ol start="16" type="I">
                <li>
                    <p style="margin-bottom: 0.28cm;line-height: 115%;text-align: left;"><strong>CONTACT&nbsp;</strong></p>
                </li>
            </ol>
            <p style="text-align: justify;margin-bottom: 0.42cm;line-height: 100%;">To ask questions or comment about this Privacy Policy and Our related practices, or otherwise to contact Us in connection with this Privacy Policy, please contact us at: EXEC, LLC, Email <u><a href="mailto:concierge@exec.vip">concierge@exec.vip</a></u> or by mail to EXEC, LLC, 228 Park Ave S, Suite 52536, New York, New York 10003 attn: Privacy Team</p>`)}
        </div>
      </Section>
    </PageLayout>
  )
}

export default Privacy
