export enum RoutePath {
  Home = '/client',
  Login = '/client/login',
  CcoToken = '/client/cco_token',
  Logout = '/client/logout',
  CreateAccount = '/client/create-account',
  Onboarding = '/client/onboarding',
  PreApplication = '/client/pre-application',
  HotelsDiscovery = '/client/hotels/discovery',
  HotelsList = '/client/hotels/list/:city',
  HotelsFeaturedCities = '/client/hotels/list/featured-city/:city',
  SearchResults = '/client/search-results/:category',
  BenefitDetails = '/client/benefits/:slug/details',
  BenefitsCategory = '/client/benefits/category/:category',
  BenefitsList = '/client/benefits/category/all',
  BenefitsCategoryLifestyle = '/client/benefits/category/lifestyle',
  BenefitsCategoryTravel = '/client/benefits/category/travel',
  BenefitsCategoryExperiences = '/client/benefits/category/experiences',
  BenefitsCategoryBusiness = '/client/benefits/category/business',
  MyAccount = '/client/my-account',
  TheJournal = '/journal',
  About = '/client/about',
  Contact = '/client/contact',
  Terms = '/client/terms',
  Privacy = '/client/privacy',
  PageNotFound = '/client/page-not-found',
  SetPassword = '/client/set-password',
  ResetPassword = '/client/reset-password',
  ForgotPassword = '/client/forgot-password',
  CSVUpload = '/organizations-members-upload',
}
