import { useLocation } from 'react-router-dom'

const useGetQueryParam = (query: string): string | null => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return queryParams.get(query)
}

export default useGetQueryParam
