import { makeStyles } from '@material-ui/core'
import FooterBackground from 'images/footer-background.svg'

export const useStyles = makeStyles((theme) => {
  return {
    footerBox: {
      backgroundImage: `url(${FooterBackground})`,
      backgroundSize: 'cover',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
    footerContainer: {
      paddingTop: theme.spacing(4),
      minHeight: 360,
      width: '90%',
      maxWidth: theme.breakpoints.values.lg,
      margin: '0 auto',
    },
    divider: {
      opacity: 0.2,
      width: '100%',
    },
  }
})
