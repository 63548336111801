import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core'
import { RedeemWithRegistrationFormInput, User } from 'types/generatedGql'
import ButtonAction from 'components/shared/ButtonAction'
import { useMutation } from '@apollo/client'
import { REDEEM_WITH_REGISTRATION_MUTATION } from 'constants/mutations'
import { validate } from 'components/shared/modals/RedeemBenefitModal/validation'
import InputMask from 'react-input-mask'
import { useStyles } from './styles'

interface Props {
  slug: string
  user: User
  setIsRedeemed: Dispatch<SetStateAction<boolean>>
}

const RedeemBenefitModal: React.FC<Props> = ({ slug, user, setIsRedeemed }) => {
  const classes = useStyles()
  const [redeemWithRegistrationForm, { loading, error }] = useMutation(REDEEM_WITH_REGISTRATION_MUTATION, {
    onCompleted: () => {
      setIsRedeemed(true)
    },
  })
  const [formData, setFormData] = useState<Partial<RedeemWithRegistrationFormInput>>({})
  const [errors, setErrors] = useState<Partial<Record<keyof RedeemWithRegistrationFormInput, string>>>({})

  useEffect(() => {
    if (user?.firstName) {
      setFormData({ userName: `${user.firstName} ${user.lastName}`, userEmail: user.email })
    }
  }, [user])

  const onChange = (fieldData: Record<string, string>) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...fieldData }))
  }

  const sendRegistrationForm = () => {
    const currentErrors = validate<RedeemWithRegistrationFormInput>(formData)
    setErrors(currentErrors)
    if (Object.values(currentErrors).some((err) => !!err)) {
      return
    }
    redeemWithRegistrationForm({
      variables: {
        benefitSlug: slug,
        ...formData,
      },
    })
  }

  return (
    <form autoComplete='off'>
      <TextField className={classes.textField} disabled type='text' value={formData.userName} fullWidth />
      <TextField
        type='email'
        className={classes.textField}
        InputProps={{
          className: classes.formInput,
        }}
        required
        placeholder='Email Address'
        error={!!errors.userEmail}
        helperText={errors.userEmail}
        value={formData.userEmail}
        onChange={(e) => onChange({ userEmail: e.target.value })}
        fullWidth
      />
      <InputMask
        mask='+9 999 999 9999'
        maskChar=' '
        type='tel'
        value={formData.userPhone}
        onChange={(e: { target: { value: string } }) => onChange({ userPhone: e.target.value })}
      >
        {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            className={classes.textField}
            InputProps={{
              className: classes.formInput,
            }}
            placeholder='Phone Number'
            error={!!errors.userPhone}
            helperText={errors.userPhone}
            fullWidth
            {...inputProps}
          />
        )}
      </InputMask>
      <TextField
        className={classes.textField}
        InputProps={{
          className: classes.formInput,
        }}
        type='text'
        placeholder='Additional Information'
        value={formData.additionalInfo}
        onChange={(e) => onChange({ additionalInfo: e.target.value })}
        fullWidth
        multiline
      />
      {error && <Typography color='error'>{error?.message ? error?.message : 'Something is wrong'}</Typography>}
      <Box my={2}>
        <ButtonAction disabled={loading} text='Submit' size='large' fullWidth bold onClick={sendRegistrationForm} />
      </Box>
    </form>
  )
}

export default RedeemBenefitModal
