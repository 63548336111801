import React, { ChangeEvent, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { BenefitDTO } from 'panel/dtos/BenefitDTO'
import { BenefitErrorDTO } from 'panel/dtos/BenefitErrorDTO'
import './BenefitForm.scss'
import { RedemptionType } from 'types/generatedGql'
import RedemptionTypeDropdown from './RedemptionTypeDropdown'

interface BenefitFormState {
  formData: BenefitDTO
  onChange: (data: BenefitDTO) => void
  errors: BenefitErrorDTO
}

const redemptionsInitialState = {
  redemption_code: null,
  redemption_code_csv: null,
  redemption_link: null,
}

const RedemptionsFields: React.FC<BenefitFormState> = ({ formData, onChange, errors }): JSX.Element => {
  const [codesFileName, setCodesFileName] = useState('')
  const onFilesUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.[0]) {
      setCodesFileName(event.target?.value)
      onChange({ ...formData, redemption_code_csv: event.target?.files?.[0] })
    }
  }

  const onUploadClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // possibility to load same file again
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <>
      <div className='field'>
        <label className='label'>Redemption type*</label>
        <div className='control'>
          <RedemptionTypeDropdown
            required
            redemptionType={formData.redemption_type}
            onTypeSelect={(type) => onChange({ ...formData, ...redemptionsInitialState, redemption_type: type })}
          />
        </div>
      </div>
      {formData.redemption_type === RedemptionType.GeneralCode && (
        <div className='field'>
          <label htmlFor='redemptionCode' className='label'>
            Redemption General Code*
            <div className='control'>
              <input
                required
                name='redemptionCode'
                className='input'
                value={formData.redemption_code}
                onChange={(event) => onChange({ ...formData, redemption_code: event.target.value })}
              />
            </div>
          </label>
          {errors?.redemption_code &&
            errors.redemption_code?.map((message: string) => (
              <p className='help is-danger' key={message.length}>
                {message}
              </p>
            ))}
        </div>
      )}
      {formData.redemption_type === RedemptionType.PersonalizedCode && (
        <div className='field'>
          <label className='label'>Redemption Personalized code*</label>
          <div className='control'>
            <label htmlFor='redemptionCodes' className='file-label'>
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='fas fa-upload' />
                </span>
                <span className='file-label'>Choose a file…</span>
              </span>
              <input
                id='redemptionCodes'
                name='redemptionCodes'
                hidden
                type='file'
                accept='text/csv'
                onChange={onFilesUpload}
                onClick={onUploadClick}
              />
              {codesFileName && <span className='file-name'>{codesFileName}</span>}
            </label>
          </div>
          {errors?.redemption_code_csv?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
      )}
      {(!formData.redemption_type ||
        [RedemptionType.ReferralLink, RedemptionType.GeneralCode, RedemptionType.PersonalizedCode].includes(
          formData.redemption_type
        )) && (
        <div className='field'>
          <label htmlFor='redemptionLink' className='label'>
            Redemption Link{formData.redemption_type === RedemptionType.ReferralLink ? '*' : ''}
            <div className='control'>
              <input
                required={formData.redemption_type === RedemptionType.ReferralLink}
                name='redemptionLink'
                className='input'
                value={formData.redemption_link}
                onChange={(event) => onChange({ ...formData, redemption_link: event.target.value })}
              />
            </div>
          </label>
          {errors?.redemption_link?.map((message: string) => (
            <p className='help is-danger' key={message.length}>
              {message}
            </p>
          ))}
        </div>
      )}
      <div className='field'>
        <label className='label'>Redemption Instruction</label>
        <div className='control'>
          <CKEditor
            editor={ClassicEditor}
            data={formData.redemption_instruction}
            onChange={(event, editor) => {
              onChange({ ...formData, redemption_instruction: editor.getData() })
            }}
          />
        </div>
        {errors?.redemption_instruction?.map((message: string) => (
          <p className='help is-danger' key={message.length}>
            {message}
          </p>
        ))}
      </div>
    </>
  )
}

export default RedemptionsFields
