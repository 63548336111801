import React from 'react'
import { useHistory } from 'react-router-dom'

const PermissionMessage: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className = '' }): JSX.Element => {
  const history = useHistory()

  return (
    <div className='box'>
      {history.length && (
        <div className='mb-4'>
          <button onClick={history.goBack} className='button is-link' type='button'>
            Back
          </button>
        </div>
      )}
      <h2 className={`has-text-danger is-size-4 mb-2 ${className}`}>
        Access denied, you don&apos;t have the required permissions
      </h2>
    </div>
  )
}

export default PermissionMessage
