import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      maxWidth: theme.breakpoints.values.sm,
      width: '90%',
      maxHeight: '90%',
      overflow: 'auto',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#000',
      opacity: 0.3,
      zIndex: 1500,
    },
    content: {
      padding: theme.spacing(6, 6, 4, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 3, 2, 3),
      },
    },
    actions: {
      padding: theme.spacing(0, 6, 6, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3, 3, 3),
      },
    },
  }
})
