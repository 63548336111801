import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    cardBox: {
      border: '1px solid #000',
    },
    emptyText: {
      color: '#bdbdbd',
    },
    logo: {
      height: '40px',
      marginRight: theme.spacing(1),
    },
  }
})
