import Section from 'components/shared/Section'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import AddSubAccountModal from 'components/shared/modals/AddSubAccountModal'
import DeleteAccountModal from 'components/shared/modals/DeleteAccountModal'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CURRENT_USER, GET_PAYMENT_METHOD, GET_SUB_ACCOUNTS } from 'constants/queries'
import { useStyles } from 'pages/MyAccount/styles'
import { User } from 'types/generatedGql'
import moment from 'moment'
import { DayFormat } from 'const'
import ErrorMessage from '../../../panel/components/ErrorMessage'
import { VALIDATE_SUBACCOUNT_MUTATION } from 'constants/mutations'
import Alert from 'components/shared/Alert'
import AddPaymentMethodModal from 'pages/MyAccount/Payments/AddPaymentMethodModal'

const getFormattedSubAccounts = (accounts: Array<User>) => {
  if (!accounts) return []

  return accounts.map((user) => {
    const fullName = `${user.firstName || ''} ${user.lastName || ''}`

    return {
      name: fullName,
      email: user.email,
      dateCreated: user.createdAt,
      id: user.uid,
    }
  })
}

export const SubAccounts: React.FC = () => {
  const classes = useStyles()

  const { data, error, refetch } = useQuery(GET_SUB_ACCOUNTS)
  const { data: currentUserData } = useQuery(GET_CURRENT_USER)
  const { data: paymentData, refetch: refetchPaymentMethod } = useQuery(GET_PAYMENT_METHOD)

  const [paymentModalOpen, setPaymentModalOpen] = useState(false)

  const subAccounts = data?.subAccounts?.accounts
  const remainingSubAccounts = data?.subAccounts?.remaining

  const [showAlertError, setShowAlertError] = useState(false)

  const [validateSubAccount, { loading: validateLoading, data: validateData }] =
    useMutation(VALIDATE_SUBACCOUNT_MUTATION)

  const isPaymentData = paymentData?.currentUser?.paymentMethod
  const isPaidByOrganization = currentUserData?.currentUser?.paidByOrganization

  const validateErrors = validateData?.validateSubAccount?.errors?.join(', ')

  useEffect(() => {
    if (!remainingSubAccounts) {
      validateSubAccount()
    }
  }, [remainingSubAccounts])

  return (
    <>
      <AddPaymentMethodModal
        refetchPaymentMethods={() => {
          refetchPaymentMethod()
          validateSubAccount()
          setShowAlertError(false)
        }}
        isOpened={paymentModalOpen}
        closeModal={() => setPaymentModalOpen(false)}
      />
      <Section
        title='Sub-accounts'
        hideTitleSm
        titleSibling={
          <Box display='flex' className={classes.titleSiblingWrapper}>
            <Box px={3} border={2} display='flex' alignItems='center' mr={2}>
              Remaining:&nbsp;{remainingSubAccounts}
            </Box>
            <AddSubAccountModal
              toggleButtonClassName={classes.addSubAccountButton}
              getSubAccounts={refetch}
              remainingSubAccounts={remainingSubAccounts}
              validateData={validateData}
              validateLoading={validateLoading}
              showValidateError={() => setShowAlertError(true)}
              validateErrors={validateErrors}
            />
          </Box>
        }
      >
        {showAlertError && (
          <Box>
            <Alert
              severity='error'
              onClose={() => setShowAlertError(false)}
              action={
                !isPaymentData && isPaidByOrganization ? (
                  <Button color='inherit' onClick={() => setPaymentModalOpen(true)}>
                    Add payment method
                  </Button>
                ) : undefined
              }
            >
              {validateErrors}
            </Alert>
          </Box>
        )}
        {error && <ErrorMessage text={error?.message} />}
        <Box mb={4}>
          <p>
            Your Membership provides you the ability to share access to additional members of your executive team at
            your company. Please carefully select who you wish to provide EXEC access to and ensure they understand the
            importance of only utilizing the benefits for themselves.
          </p>

          <p>
            Once activated, <strong>you may only change a team member once every twelve months</strong>. Should you
            cancel your primary membership affiliation that provides you access to EXEC, your additional users will also
            lose their EXEC Membership privileges.
          </p>
        </Box>
        {!!subAccounts?.length && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell className={classes.hideXs}>Email</TableCell>
                <TableCell>Date added</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {getFormattedSubAccounts(subAccounts).map(({ name, email, dateCreated }) => (
                <TableRow key={email}>
                  <TableCell>{name}</TableCell>
                  <TableCell className={classes.hideXs}>{email}</TableCell>
                  <TableCell>{dateCreated && moment(dateCreated).format(DayFormat)}</TableCell>
                  <TableCell align='right'>
                    <DeleteAccountModal getSubAccounts={refetch} email={email} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Section>
    </>
  )
}
