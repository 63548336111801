import React from 'react'
import { Link } from 'react-router-dom'
import PageLayout from 'components/layouts/PageLayout'

const PageNotFound: React.FC = () => {
  return <PageLayout>
    <div>Excuse us, you've reached this page in error. <Link to='/client'>Click here </Link>
      to return to the home page
    </div>
  </PageLayout>
}

export default PageNotFound
