import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    addSubAccount: {
      padding: 0,
    },
    textfield: {
      marginBottom: theme.spacing(2),
      color: 'black',
    },
    inputNotchedOutline: {
      borderColor: '#E2E2E2 !important',
    },
    inputLabel: {
      color: '#777',
    },
    input: {
      color: '#000',
    },
    icon: {
      position: 'relative',
      top: '5px',
      fontSize: theme.spacing(5),
    },
  }
})
