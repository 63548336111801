import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[24],
      maxWidth: '1100px',
      width: '90%',
    },
    mainContentWrapper: {
      padding: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
    },
    mainContent: {
      margin: '0 auto',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#000',
      opacity: 0.3,
      zIndex: 1500,
    },
    content: {
      padding: '0 !important',
    },
    logoOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 999,
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(0, 0, 0, 0.5)',
    },
  }
})
