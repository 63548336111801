import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'constants/queries'
import React from 'react'
import { Box, Typography, BoxProps } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ExecLogo from 'images/exec-logo.svg'
import MarketplaceLogo from 'images/marketplace_by_exec.png'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer/store'

interface LogoProps extends BoxProps {
  withText?: boolean
}

const Logo: React.FC<LogoProps> = ({ withText, className, ...props }) => {
  const classes = useStyles()
  const history = useHistory()

  const credentials = useSelector((state: RootState) => state.auth.credentials)

  const { data: userData, loading: userLoading } = useQuery(GET_CURRENT_USER)

  const goToHomepage = () => {
    if (credentials?.client) {
      history.push('/client')
    } else {
      history.push('/client/login')
    }
    window.scroll(0, 0)
  }

  if (userLoading) {
    return <Box />
  }

  return (
    <Box
      display='inline-flex'
      alignItems='center'
      onClick={goToHomepage}
      className={`${classes.logoBox} ${className || ''}`}
      {...props}
    >
      {userData?.currentUser?.marketplaceMembership ? (
        <img className={classes.marketplaceLogo} src={MarketplaceLogo} alt='logo' />
      ) : (
        <>
          <img className={classes.logo} src={ExecLogo} alt='logo' />
          {withText && (
            <Typography variant='h5' component='span' className={classes.text}>
              EXEC
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default Logo
