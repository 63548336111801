import React from 'react'
import Section from 'components/shared/Section'
import { Grid, Box } from '@material-ui/core'
import BenefitPreviews from 'components/shared/BenefitPreviews'
import DestinationPreview from 'components/shared/DestinationPreview'
import PageLayout from 'components/layouts/PageLayout'
import Hero from 'components/shared/Hero'
import HotelsHome from 'images/hotelshome.jpeg'
import WhereToGoSelect from 'components/shared/WhereToGoSelect'
import { useQuery } from '@apollo/client'
import { GET_FEATURED_CITIES, GET_FEATURED_BENEFITS_BY_CATEGORY } from 'constants/queries'
import ButtonLink from 'components/shared/ButtonLink'
import CircularProgress from '@material-ui/core/CircularProgress'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { configBenefitsForPreview } from 'utils'
import { City } from 'types/generatedGql'
import { useStyles } from './styles'
import ErrorMessage from '../../../panel/components/ErrorMessage'

const HotelsDiscovery: React.FC = () => {
  const classes = useStyles()
  const { isTablet } = useGetWindowSize()

  const {
    data: featuredDestinationData,
    loading: featuredDestinationsLoading,
    error,
  } = useQuery(GET_FEATURED_CITIES, {
    variables: {
      featured: true,
    },
  })

  // const { data: brandsData, error: brandsDataError } = useQuery(GET_BRANDS)
  // const brands = brandsData?.brands

  const {
    data: featuredBenefitsData,
    loading: featuredBenefitsLoading,
    error: featuredBenefitsError,
  } = useQuery(GET_FEATURED_BENEFITS_BY_CATEGORY, {
    variables: {
      categorySlug: 'hotels',
    },
  })

  const featuredDestinations = featuredDestinationData?.getFeaturedCities || []

  const featuredBenefits = configBenefitsForPreview(
    featuredBenefitsData?.getFeaturedBenefitsByCategory?.collection || [],
    isTablet
  )

  return (
    <PageLayout
      hero={
        <Hero
          bottomComponent={WhereToGoSelect}
          height='68vh'
          maxHeight='320px'
          minHeight='270px'
          carousalClassName={classes.hotelDiscovery}
          overlayHero
          carousel={{
            items: [
              {
                image: HotelsHome,
                title: 'Hotels',
                subtitle: 'Explore the StayEXEC Hotels & Resorts program and enjoy special offers as an EXEC member.',
              },
            ],
          }}
        />
      }
    >
      <Section title='Destinations'>
        {error && <ErrorMessage text={error.message} />}
        {featuredDestinationsLoading && (
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <CircularProgress />
          </div>
        )}
        <Grid container spacing={2}>
          {featuredDestinations.map((item: City) => (
            <Grid item xs={6} md={4} key={item.slug} className={classes.gridItem}>
              <DestinationPreview {...item} />
            </Grid>
          ))}
        </Grid>
      </Section>
      {
        //
        // <Section title='Hotel Brands' overflowContentSm>
        //   <Box display='flex' flexWrap='nowrap' className={classes.brands}>
        //     {brands?.map((item) => (
        //       <Box key={item.name} mr={2} flex='1 0 auto' className={classes.brandItem}>
        //         <BrandPreview {...item} />
        //       </Box>
        //     ))}
        //   </Box>
        // </Section>
      }
      <Section title='Featured Hotels' overflowContentSm>
        <BenefitPreviews
          items={featuredBenefits}
          variant='standard'
          useOverlay
          loading={featuredBenefitsLoading}
          error={featuredBenefitsError?.message}
        />
      </Section>
      <Box className={classes.hotelLinkWrap}>
        <ButtonLink
          variant='outlined'
          size='large'
          className={classes.hotelsLink}
          text='View All Hotels'
          to='/client/benefits/category/hotels'
        />
      </Box>
    </PageLayout>
  )
}

export default HotelsDiscovery
