import React, { ReactNode } from 'react'
import CircularSpinner from './CircularSpinner'
import BackButton from './BackButton'
import ErrorMessage from './ErrorMessage'

export interface ShowFormData {
  fieldName: string
  fieldLabel: string
  fieldValue: string | number | ReactNode | null | undefined
  hideField?: boolean
}

export interface DefaultShowFormProps {
  headerName: string
  headerInfo?: string
  formData: ShowFormData[]
  loading?: boolean
  error?: string
  headerActions?: ReactNode
  footerActions?: ReactNode
  onGoBack?: () => void
}

const DefaultShowForm: React.FC<DefaultShowFormProps> = ({
  headerName,
  formData,
  loading,
  error,
  headerActions,
  headerInfo,
  footerActions,
  onGoBack,
}) => {
  return loading ? (
    <CircularSpinner />
  ) : (
    <>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton onGoBack={onGoBack} />
        {headerActions && <div>{headerActions}</div>}
      </div>
      <div className='is-flex is-align-items-center is-flex-direction-column mb-4'>
        <h4 className='is-size-4 has-text-centered'>{headerName}</h4>
        {headerInfo}
      </div>
      <div className='box'>
        {error && <ErrorMessage text={error} />}
        {formData.map(
          ({ fieldLabel, fieldValue, hideField }) =>
            fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== '' &&
            !hideField && (
              <div className='field' key={fieldLabel}>
                <p className='label'>{fieldLabel}</p>
                <div className='control'>{fieldValue}</div>
              </div>
            )
        )}
        {footerActions && <div className='mt-4'>{footerActions}</div>}
      </div>
    </>
  )
}

export { DefaultShowForm }
