import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from 'components/shared/FormWrapper/styles'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import SelectCustom from 'components/shared/SelectCustom/SelectCustom'
import Dots from 'components/shared/Dots'
import { useMutation, useQuery } from '@apollo/client'
import { GET_COUNTRIES } from 'constants/queries'
import { Mutation } from 'types/generatedGql'
import { UPDATE_BASIC_USER_ADDRESS } from 'constants/mutations'
import ButtonAction from '../../../components/shared/ButtonAction'

interface Props {
  onSuccess: () => void
}

const USCountryCode = 'US'

const initialData = {
  address: '',
  apartment: '',
  country: USCountryCode,
  state: '',
  city: '',
  zipCode: '',
}

const ApplicationAddressForm: React.FC<Props> = ({ onSuccess }) => {
  const classes = useStyles()
  const [data, setData] = useState(initialData)
  const { data: countriesData, loading: countriesLoading, error: countriesError } = useQuery(GET_COUNTRIES)
  const countries = countriesData?.getCountries || []

  const handleUpdateUserAddressCompleted = (mutationData: Mutation) => {
    if (mutationData?.updateUserAddress?.message === 'success') {
      onSuccess()
    }
  }

  const [updateUserAddress, { loading: submitting, error }] = useMutation(UPDATE_BASIC_USER_ADDRESS, {
    onCompleted: handleUpdateUserAddressCompleted,
  })

  const onChange = (value: unknown, fieldName: keyof typeof initialData) => {
    setData((prevData) => ({ ...prevData, [fieldName]: value }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await updateUserAddress({ variables: data })
  }

  return countriesLoading ? (
    <Grid container alignItems='center' justify='center'>
      <CircularProgress />
    </Grid>
  ) : (
    <form autoComplete='off' onSubmit={onSubmit}>
      <Box className={classes.formRow}>
        <TextFieldCustom
          label='Address'
          isTopLabel
          required
          value={data.address}
          onChange={(event) => onChange(event.target.value, 'address')}
          fullWidth
        />

        <TextFieldCustom
          label='Apt, suite, etc.'
          isTopLabel
          value={data.apartment}
          onChange={(event) => onChange(event.target.value, 'apartment')}
          fullWidth
        />
      </Box>

      <Box className={classes.formRow}>
        <SelectCustom
          items={countries}
          label='Country / Region'
          isTopLabel
          required
          value={data.country}
          onChange={(event) => onChange(event.target.value, 'country')}
          fullWidth
        />
        {countriesError && <Typography color='error'>{countriesError?.message}</Typography>}

        <TextFieldCustom
          label={data.country === USCountryCode ? 'State / Region' : 'State / Province / Region'}
          isTopLabel
          required
          value={data.state}
          onChange={(event) => onChange(event.target.value, 'state')}
          fullWidth
        />
      </Box>

      <Box className={classes.formRow}>
        <TextFieldCustom
          label='City'
          isTopLabel
          required
          value={data.city}
          onChange={(event) => onChange(event.target.value, 'city')}
          fullWidth
        />

        <TextFieldCustom
          label='Zipcode'
          isTopLabel
          required
          value={data.zipCode}
          onChange={(event) => onChange(event.target.value, 'zipCode')}
          fullWidth
        />
      </Box>

      <Box mt={4} mb={2}>
        <Dots count={4} current={1} />
      </Box>

      {error && (
        <Box my={2}>
          <Typography color='error'>{error?.message}</Typography>
        </Box>
      )}

      <Grid container spacing={0} alignItems='center' justify='center'>
        <ButtonAction text='Next' type='submit' variant='contained' color='primary' size='large' loading={submitting} />
      </Grid>
    </form>
  )
}

export default ApplicationAddressForm
