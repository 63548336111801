import React, { useEffect, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { Box, Checkbox, Link, Typography } from '@material-ui/core'
import { useStyles as useModalStyles } from 'pages/Login/styles'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { useStyles as useFormStyles } from 'components/shared/FormWrapper/styles'
import { BoxLayout } from 'components/layouts/BoxLayout'
import TextFieldCustom from 'components/shared/TextFieldCustom/TextFieldCustom'
import validator from 'validator'
import { useMutation } from '@apollo/client'
import { CREATE_USER_ACCOUNT, LOGIN_MUTATION } from 'constants/mutations'
import { Mutation } from 'types/generatedGql'
import { RoutePath } from 'constants/RoutePath'
import { useDispatch } from 'react-redux'
import { setAuth } from 'reducer/auth/authSlice'
import useSubdomain from 'hooks/useSubdomain'
import { excludeSubdomainFromUrl } from 'utils'
import { useStyles } from 'pages/CreateAccount/styles'
import classnames from 'classnames'

import CrateAccountImage from './images/create-account.png'
import { validatePassword } from '../../../utils/validation'
import ButtonAction from '../../components/shared/ButtonAction'

const initialData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  termsAreAgreed: false,
}

export const CreateAccount = (): React.ReactElement => {
  const modalClasses = useModalStyles()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState<Array<string>>([])

  const { hasSubDomain } = useSubdomain()
  useEffect(() => {
    if (hasSubDomain) {
      window.location.assign(excludeSubdomainFromUrl(window.location.href))
    }
  }, [hasSubDomain])

  const handleCreateAccountCompleted = async (mutationData: Mutation) => {
    if (mutationData?.createAccount?.message === 'success') {
      const res = await login({ variables: { email: data.email, password: data.password } })

      if (!res?.errors?.length) {
        const { credentials } = res.data.login
        dispatch(setAuth(credentials))
        history.push(RoutePath.PreApplication)
      } else {
        setErrors(res?.errors)
      }
    } else {
      setErrors([mutationData?.createAccount?.message || 'Something is wrong'])
    }
  }

  const [createAccount, { loading: submitting, error: createAccountError }] = useMutation(CREATE_USER_ACCOUNT, {
    onCompleted: handleCreateAccountCompleted,
  })

  const [login, { loading: loginSubmitting, error }] = useMutation(LOGIN_MUTATION)

  const onChange = (value: unknown, fieldName: keyof typeof initialData) => {
    setData((prevData) => ({ ...prevData, [fieldName]: value }))
  }

  const validateForm = (): boolean => {
    setErrors([])

    if (!validator.isEmail(data.email)) {
      setErrors((err: string[]) => {
        err.push(`Email: is invalid`)
        return err
      })
      return false
    }

    if (!validator.isMobilePhone(data.phone)) {
      setErrors((err: string[]) => {
        err.push(`Phone number: is invalid`)
        return err
      })
      return false
    }

    const passwordValidationDetails = validatePassword(data.password)
    if (passwordValidationDetails?.length) {
      setErrors((err: string[]) => {
        err.push(`Password: ${passwordValidationDetails.map(({ message }) => message).join('. ')}`)
        return err
      })
      return false
    }

    return true
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formIsValid = validateForm()
    if (!formIsValid) {
      return
    }
    await createAccount({ variables: data })
  }

  return (
    <PageLayout externalNavigation>
      <Box className={modalClasses.modal} mt={4}>
        <BoxLayout sideImage={CrateAccountImage}>
          <Typography variant='h4' className={classnames(modalClasses.h4, classes.header)}>
            Create account
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box className={classnames(formClasses.formRow, classes.formRow)}>
              <TextFieldCustom
                label='First name'
                name='first_name'
                isTopLabel
                value={data.firstName}
                required
                onChange={(event) => onChange(event.target.value, 'firstName')}
                fullWidth
              />

              <TextFieldCustom
                label='Last name'
                name='last_name'
                isTopLabel
                value={data.lastName}
                onChange={(event) => onChange(event.target.value, 'lastName')}
                required
                fullWidth
              />
            </Box>

            <Box className={classnames(formClasses.formRow, classes.formRow)}>
              <TextFieldCustom
                label='Email'
                type='email'
                name='email'
                isTopLabel
                value={data.email}
                onChange={(event) => onChange(event.target.value, 'email')}
                required
                fullWidth
              />

              <TextFieldCustom
                label='Phone number'
                type='tel'
                name='tel'
                isTopLabel
                value={data.phone}
                onChange={(event) => onChange(event.target.value, 'phone')}
                required
                fullWidth
              />
            </Box>

            <Box className={classnames(formClasses.formRow, classes.formRow)}>
              <TextFieldCustom
                label='Password'
                type='password'
                isTopLabel
                value={data.password}
                onChange={(event) => onChange(event.target.value, 'password')}
                required
                autoComplete='off'
                fullWidth
              />
            </Box>

            <Box mt={2}>
              <Checkbox
                style={{ padding: 0 }}
                checked={data.termsAreAgreed}
                onChange={(event) => onChange(event.currentTarget.checked, 'termsAreAgreed')}
                required
              />{' '}
              <Typography component='span' variant='body2'>
                By checking this box, I agree to EXEC’s{' '}
              </Typography>
              <Link color='primary' href={RoutePath.Terms} target='_blank'>
                terms and services
              </Link>
            </Box>

            {!!errors?.length && (
              <ul className={formClasses.errorsList}>
                {errors.map((message) => (
                  <li key={message}>
                    <Typography color='error'>{message}</Typography>
                  </li>
                ))}
              </ul>
            )}

            {(error || createAccountError) && (
              <Typography color='error'>{error?.message || createAccountError?.message}</Typography>
            )}

            <Box mt={4} className={classes.submitButton} display='flex' alignItems='center' justifyContent='center'>
              <ButtonAction
                text='Create account'
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                loading={submitting || loginSubmitting}
              />
            </Box>
          </form>

          <Box mt={2} display='flex' justifyContent='center'>
            <Typography variant='body2' component='span'>
              Already have an account?&nbsp;
            </Typography>
            <Typography
              style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
              component={RouterLink}
              variant='body2'
              color='primary'
              to={RoutePath.Login}
            >
              Log in
            </Typography>
          </Box>
        </BoxLayout>
      </Box>
    </PageLayout>
  )
}
