import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { Area, Point } from 'react-easy-crop/types'
import { useStyles } from './styles'

interface Props {
  image: string
  onApplyCrop: (cropArea: Area) => void
}

const CropPhotoModal: React.FC<Props> = ({ image, onApplyCrop }) => {
  const classes = useStyles()
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [area, setArea] = useState<Area>({})
  const [loading, setLoading] = useState<boolean>(false)

  const applyCropArea = () => {
    setLoading(true)
    onApplyCrop(area)
  }

  return (
    <Box>
      <div className={classes.cropModalContainer}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={(croppedArea: Area, croppedAreaPixels: Area) => setArea(croppedAreaPixels)}
          objectFit='contain'
        />
      </div>
      <Button variant='contained' color='primary' fullWidth size='large' onClick={applyCropArea}>
        Crop avatar {loading && <CircularProgress color='inherit' size={16} />}
      </Button>
    </Box>
  )
}

export default CropPhotoModal
