import { useLocation } from 'react-router-dom'
import React, { useEffect, FC } from 'react'

const ScrollToTop: FC = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    if (!location.pathname.includes('featured-city')) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return <>{children}</>
}

export default ScrollToTop
