import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import useCredentials from '../../hooks/useCredentials'
import { DefaultEditForm } from '../../components/DefaultEditForm'
import { getLeadFormData } from './const'
import { CompanyRange, LeadDTO, LeadStatus, Plan } from '../../dtos/LeadDTO'
import { RouterPath } from '../../router/routes'
import AssignProductButton from './AssignProductButton'

const EditLead: FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [formData, setFormData] = useState<Partial<LeadDTO>>()
  const [loading, setLoading] = useState<boolean>(true)
  const [validationErrors, setValidationErrors] = useState({})
  const [loadDataError, setLoadDataError] = useState('')
  const [companyRanges, setCompanyRanges] = useState<CompanyRange | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadLeadData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/leads/${params.id}/edit`, {
        headers: credentials,
      })
      setFormData(res.data.record)
    } catch (e) {
      setLoadDataError(e.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, params.id])

  const loadCompanyRanges = useCallback(async () => {
    try {
      const res = await axios.get('/api/panel/leads/company_data_ranges', {
        headers: credentials,
      })
      setCompanyRanges(res.data)
    } catch (e) {
      console.error(e.message)
    }
  }, [credentials])

  useEffect(() => {
    loadLeadData()
    loadCompanyRanges()
  }, [loadLeadData, loadCompanyRanges])

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      await axios.put(`/api/panel/leads/${params.id}`, formData, {
        headers: credentials,
      })
      history.push(RouterPath.LEADS_VIEW)
    } catch (err) {
      setValidationErrors(err.response?.data)
    } finally {
      setSubmitting(false)
    }
  }

  const handleChange = (name: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <DefaultLayout>
      <DefaultEditForm
        name='Edit lead'
        formData={getLeadFormData(formData, companyRanges, true)}
        loading={loading}
        onChange={handleChange}
        onSubmit={handleSubmit}
        validationErrors={validationErrors}
        loadingError={loadDataError}
        submitting={submitting}
        headerActions={
          formData &&
          [LeadStatus.PENDING, LeadStatus.REJECTED].includes(formData.lead_status as LeadStatus) &&
          formData?.plan === Plan.custom && <AssignProductButton leadId={formData?.id} refetchLead={loadLeadData} />
        }
      />
    </DefaultLayout>
  )
}

export default EditLead
