import { RouteProps } from 'react-router-dom'
import About from 'pages/About'
import BenefitDetails from 'pages/BenefitDetails'
import Contact from 'pages/Contact'
import Home from 'pages/Home'
import HotelsDiscovery from 'pages/HotelsDiscovery'
import HotelsList from 'pages/HotelsList'
import HotelsFeaturedCities from 'pages/HotelsFeaturedCities'
import BenefitsCategory from 'pages/BenefitsCategory'
import MyAccount from 'pages/MyAccount'
import PageNotFound from 'pages/PageNotFound'
import Privacy from 'pages/Privacy'
import SearchResults from 'pages/SearchResults'
import Terms from 'pages/Terms'
import Login from 'pages/Login'
import SetPassword from 'pages/SetPassword'
import ForgotPassword from 'pages/ForgotPasword'
import PreApplication from 'pages/PreApplication'
import CreateAccount from 'pages/CreateAccount'
import Onboarding from 'pages/Onboarding'
import CcoToken from 'pages/CCOToken'
import CSVUpload from 'pages/CSVUpload'
import { RoutePath } from './RoutePath'

interface AppRoute {
  id: number
  page: React.FC
  props?: RouteProps
  noAuth?: boolean
}

export const routes: AppRoute[] = [
  { id: 1, page: Home, props: { path: RoutePath.Home } },
  { id: 2, page: Login, props: { path: RoutePath.Login }, noAuth: true },
  { id: 22, page: CcoToken, props: { path: RoutePath.CcoToken }, noAuth: true },
  { id: 23, page: CSVUpload, props: { path: RoutePath.CSVUpload }, noAuth: true },
  { id: 3, page: HotelsDiscovery, props: { path: RoutePath.HotelsDiscovery } },
  { id: 4, page: HotelsList, props: { path: RoutePath.HotelsList } },
  { id: 5, page: HotelsFeaturedCities, props: { path: RoutePath.HotelsFeaturedCities } },
  { id: 7, page: SearchResults, props: { path: RoutePath.SearchResults } },
  { id: 8, page: BenefitDetails, props: { path: RoutePath.BenefitDetails } },
  { id: 9, page: MyAccount, props: { path: RoutePath.MyAccount, exact: false } },
  { id: 10, page: BenefitsCategory, props: { path: RoutePath.BenefitsCategory } },
  { id: 11, page: About, props: { path: RoutePath.About }, noAuth: true },
  { id: 12, page: Contact, props: { path: RoutePath.Contact }, noAuth: true },
  { id: 13, page: Terms, props: { path: RoutePath.Terms }, noAuth: true },
  { id: 14, page: Privacy, props: { path: RoutePath.Privacy }, noAuth: true },
  { id: 15, page: SetPassword, props: { path: RoutePath.SetPassword }, noAuth: true },
  { id: 16, page: SetPassword, props: { path: RoutePath.ResetPassword }, noAuth: true },
  { id: 17, page: ForgotPassword, props: { path: RoutePath.ForgotPassword }, noAuth: true },
  { id: 18, page: PreApplication, props: { path: RoutePath.PreApplication } },
  { id: 19, page: CreateAccount, props: { path: RoutePath.CreateAccount }, noAuth: true },
  { id: 20, page: Onboarding, props: { path: RoutePath.Onboarding } },
  { id: 21, page: PageNotFound, props: { exact: false } },
]

export const commonRoutes = [RoutePath.Terms, RoutePath.Privacy, RoutePath.Contact, RoutePath.About]

export default routes
