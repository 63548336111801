import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
  translateY: number
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    content: {
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    bottomContent: {
      zIndex: 1,
      bottom: '0',
      transform: ({ translateY }) => `translateY(${translateY}%)`,
    },
    bottomContentContainer: {
      width: `calc(90% - ${theme.spacing(3)}px * 2)`,
      padding: 0,
      boxSizing: 'content-box',
      maxWidth: theme.breakpoints.values.lg - theme.spacing(3) * 2,
      [theme.breakpoints.down('xs')]: {
        width: `calc(90% - ${theme.spacing(2)}px * 2)`,
      },
    },
  }
})
