import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    select: {
      color: '#000',
      padding: `${theme.spacing(2, 8, 2, 2)} !important`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(1.5, 5, 1.5, 1.5)} !important`,
      },
    },
    notchedOutline: {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    typography: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        fontSize: '0.9rem',
      },
    },
  }
})
