import { makeStyles } from '@material-ui/core'
import CheckIcon from './images/check.svg'

export const useStyles = makeStyles((theme) => {
  return {
    formBox: {
      backgroundColor: 'rgba(236, 236, 236, 0.62)',
    },
    formContent: {
      width: '100%',
      maxWidth: '600px',
    },
    hubspotForm: {
      fontFamily: 'Proxima Nova',
      fontSize: '18px',
      padding: `${theme.spacing(3)} 0`,
      '& p': {
        marginTop: `${theme.spacing(2)} !important`,
      },
      '& fieldset': {
        maxWidth: '100% !important',
      },
      '& .hs-form-field': {
        marginBottom: theme.spacing(2),
      },
      '& input.hs-button': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '14px',
        fontWeight: 700,
        padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
        border: 'none',
        marginTop: theme.spacing(2),
        textTransform: 'uppercase',
      },
      '& .hs-input:not([type=radio]):not([type=checkbox])': {
        fontFamily: 'Proxima Nova',
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#111',
        backgroundColor: 'rgba(255,255,255,1.0)',
        border: '1px solid #cfcfcf',
        borderRadius: '0px',
        fontSize: '13px',
        padding: '14px 8px',
        margin: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`,
        width: '100% !important',
        maxWidth: '100%',
      },
      '& ul.inputs-list': {
        margin: `${theme.spacing(1)} 0`,
        listStyle: 'none',
        padding: 0,
        '& li': {
          display: 'inline-block',
          margin: '0 !important',
          background: `url(${CheckIcon}) 0px 6px/auto no-repeat`,
          paddingLeft: '24px',
          '&.hs-form-radio': {
            width: '49.5%',
          },
          '& span': {
            paddingLeft: theme.spacing(2),
          },
        },
      },
      '& .hs_recaptcha': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
    },
  }
})
