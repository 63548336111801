import { gql } from '@apollo/client'

export const GET_SEARCH_RESULTS = gql`
  query ($category: String, $term: String!, $page: Int, $order: Order) {
    getResultsBySearch(categorySlug: $category, term: $term, page: $page, order: $order) {
      benefits {
        collection {
          id
          name
          slug
          images {
            medium
          }
          description
          shortDescription
          benefits
          address1
          otherRateOffer
          rates {
            category
            execRate
            standardRate
          }
          city
          favorited
          benefitSummary
          category {
            name
            slug
          }
          isNew
          isMarketplace
        }
        metadata {
          currentPage
          totalCount
          totalPages
        }
      }
      categoryMetadata {
        business
        experiences
        hotels
        lifestyle
        travel
      }
    }
  }
`
