import React, { useState, KeyboardEvent, useCallback, useEffect } from 'react'
import axios from 'axios'
import { CategoryDTO } from '../../dtos/CategoryDTO'
import useCredentials from '../../hooks/useCredentials'
import useSetQueryParam from '../../hooks/useSetQueryParam'

export enum BenefitFilterKeys {
  name = 'name',
  slug = 'slug',
  category = 'category',
  brand = 'brand',
  active = 'active',
}

export interface BenefitFilter {
  [BenefitFilterKeys.name]: string
  [BenefitFilterKeys.slug]: string
  [BenefitFilterKeys.category]: string
  [BenefitFilterKeys.brand]: string
  [BenefitFilterKeys.active]: string
}

interface Props {
  applyFilters: (filters: BenefitFilter) => void
  initialFilters?: Partial<BenefitFilter>
}

const initialState = {
  name: '',
  slug: '',
  category: '',
  brand: '',
  active: '',
}

const BenefitFilters: React.FC<Props> = ({ applyFilters, initialFilters = {} }) => {
  const { credentials } = useCredentials()
  const { setQueryParams } = useSetQueryParam<BenefitFilter>()
  const [filters, setFilters] = useState<BenefitFilter>({
    name: initialFilters.name || '',
    slug: initialFilters.slug || '',
    category: initialFilters.category || '',
    brand: initialFilters.brand || '',
    active: initialFilters.active || '',
  })
  const [categories, setCategories] = useState<Array<CategoryDTO>>([])

  const loadCategories = useCallback(async (): Promise<void> => {
    try {
      const resCategories = await axios.get('/api/panel/categories', {
        headers: credentials,
      })
      setCategories(resCategories.data)
    } catch (e) {
      console.error(e.message)
    }
  }, [credentials])

  useEffect(() => {
    loadCategories()
  }, [loadCategories])

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters(filters)
      setQueryParams(filters)
    }
  }

  const onSelectFilter = (value: string, fieldName: keyof BenefitFilter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [fieldName]: value }
      applyFilters(newFilters)
      setQueryParams(newFilters)
      return newFilters
    })
  }

  const onSetFilters = (value: string, fieldName: keyof BenefitFilter) => {
    setFilters((prevFilters) => ({ ...prevFilters, [fieldName]: value }))
  }

  const clearFilters = () => {
    setFilters(initialState)
    applyFilters(initialState)
    setQueryParams(initialState)
  }

  return (
    <>
      <th> </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Name'
          onChange={(e) => onSetFilters(e.target.value, 'name')}
          value={filters.name}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Slug'
          onChange={(e) => onSetFilters(e.target.value, 'slug')}
          value={filters.slug}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <div className='select'>
          <select name='category' value={filters.category} onChange={(e) => onSelectFilter(e.target.value, 'category')}>
            <option value=''>All categories</option>
            {categories?.length &&
              categories.map((categoryItem) => (
                <option key={categoryItem.id} value={categoryItem.name}>
                  {categoryItem.name}
                </option>
              ))}
          </select>
        </div>
      </th>
      <th>
        <input
          className='dtsp-paneInputButton input search'
          style={{ minWidth: '100px' }}
          placeholder='Brand'
          onChange={(e) => onSetFilters(e.target.value, 'brand')}
          value={filters.brand}
          onKeyDown={onKeyDown}
        />
      </th>
      <th>
        <div className='select'>
          <select name='active' value={filters.active} onChange={(e) => onSelectFilter(e.target.value, 'active')}>
            <option value=''>Active Y/N</option>
            <option value='true'>Active</option>
            <option value='false'>Inactive</option>
          </select>
        </div>
      </th>
      <th> </th>
      <th className='is-flex is-justify-content-flex-end'>
        <button type='button' className='button mr-2' onClick={clearFilters}>
          <span className='icon is-small'>
            <i className='fas fa-eraser' />
          </span>
        </button>
        <button type='button' className='button' onClick={() => applyFilters(filters)}>
          <span className='icon is-small'>
            <i className='fas fa-search' />
          </span>
        </button>
      </th>
    </>
  )
}

export { BenefitFilters }
